import {useState} from "react";
import {LoadingObjectsEnum} from "../../models";


export type Loaders = {
    [key in LoadingObjectsEnum]?: boolean;
};

const useLoading = (loaders: Loaders) => {
    const [loadingData, setLoadingData] = useState(loaders);
    const showLoader = (loader: LoadingObjectsEnum) =>
        setLoadingData((prev) => ({...prev, [loader]: true}));
    const hideLoader = (loader: LoadingObjectsEnum) =>
        setLoadingData((prev) => ({...prev, [loader]: false}));

    return {loadingData, showLoader, hideLoader};
};

export default useLoading;
