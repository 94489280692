import React, {FC, ReactNode, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {LayoutFooter} from "../../..";
import {useCulture} from "../../../../hooks";
import {
    CookieGet, getLabelName,
    getUserName,
    isUserAuthenticated,
} from "../../../../utils";
import {SystemConfiguration} from "../../../../configuration";
import {LangSwitcherReactI18} from "../../../languageSwitcher/react-i18/langSwitcher";
import face1 from "../../../../assets/images/faces/face1.jpg";
import {logoutUser} from "../../../../slice/userAuthincateSlice";

export const AuthenticatedWithoutMenuLayout: FC<{ children?: ReactNode | undefined }> = ({children,}) => {
    //#region state
    const {changeCulture} = useCulture("");
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOpen] = useState(true);
    //#endregion
    //#region variables
    //@ts-ignore
    const uiLanguage: string =
        SystemConfiguration.keys.culture !== null &&
        SystemConfiguration.keys.culture !== undefined
            ? SystemConfiguration.keys.culture
            : "";
    //@ts-ignore
    const language: string = Cookies.get(uiLanguage)
        ? CookieGet(uiLanguage)
        : SystemConfiguration.keys.defaultUiLanguage;
    //#endregion
    //#region use-Effect
    useEffect(() => {
        document.body.classList.toggle('sidebar-close', isOpen);
    }, [isOpen])
    useEffect(() => {
        //@ts-ignore
        changeCulture(language).then(() => {
        });
    }, [language]);
    //#endregion
    //#region  functions
    const handleLogout = async () => {
        dispatch(
            // @ts-ignore
            logoutUser());
        navigate(SystemConfiguration.other.logoutRedirectUrl);
    }
    //#endregion
    //#region html
    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    {isUserAuthenticated() && (
                        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row">
                            <div className="navbar-menu-wrapper d-flex align-items-stretch">
                                <ul className="navbar-nav navbar-nav-right">
                                    <li className="nav-item nav-profile dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            id="profileDropdown"
                                            href="#"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <div className="nav-profile-img">
                                                <img src={face1} alt="image"/>
                                                <span className="availability-status online"></span>
                                            </div>
                                            <div className="nav-profile-text">
                                                <p className="mb-1 text-black">{getUserName()}</p>
                                            </div>
                                        </a>
                                        <div
                                            className="dropdown-menu navbar-dropdown"
                                            aria-labelledby="profileDropdown"
                                        >
                                            <>
                                                <div className="dropdown-item" onClick={async () => {
                                                    await handleLogout();
                                                }}>
                                                    <i className="mdi mdi-logout me-2 text-primary"></i>
                                                    {getLabelName("logout")}
                                                </div>
                                            </>
                                            {/* )} */}
                                        </div>
                                    </li>
                                    <li>
                                        <LangSwitcherReactI18/>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    )}
                    <div className="content-wrapper">
                        {children}
                    </div>
                    {<LayoutFooter/>}
                </div>
            </div>
        </div>

    );
    //#endregion
};