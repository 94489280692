import AlYusrAxiosApiInstance, {AxiosSearchParams,} from "../axios/alYusrAxiosApiInstance";
import {GeneralPrintResponseModel, ItemReportSearchParamsModel, ResponseBaseModel,} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region
export const searchReportItemData = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.itemReportController.reportItemsData, {
        params: AxiosSearchParams(request, "searchParmeter"),
    });
};
export const searchReportBestLeastSellingItems = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportBestLeastSellingItems,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const searchReportExpiryDateOfItems = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportExpiryDateOfItems,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const searchReportEditUserItemData = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportEditUserItemData,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const searchReportProductionItemsData = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportProductionItemsData,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const searchReportItemsReachedToLimitOrder = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportItemsReachedToLimitOrder,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        })
};
export const searchReportInventoryCheckIn = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportInventoryChecking,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const searchReportStagnantItems = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.itemReportController.reportStagnantItems, {
        params: AxiosSearchParams(request, "searchParmeter"),
    });
};
export const searchReportFollowItemBalance = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportFollowItemBalance,
        {params: AxiosSearchParams(request, "searchParmeter")}
    );
};
export const searchReportSummeryItemTransaction = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportSummeryItemTransaction,
        {params: AxiosSearchParams(request, "searchParmeter")}
    );
};
export const searchReportSummeryItemTransactionQuantityWithValue = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportSummeryItemTransactionQuantityWithValue,
        {params: AxiosSearchParams(request, "searchParmeter")}
    );
};
export const searchReportSummeryItemTransactionQuantity = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportSummeryItemTransactionQuantity,
        {params: AxiosSearchParams(request, "searchParmeter")}
    );
};
export const searchReportItemsDifferentInCost = async (
    request: ItemReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.itemReportController.reportItemsDifferentInCost,
        {params: AxiosSearchParams(request, "searchParmeter")}
    );
};
//#endregion

