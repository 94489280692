import React, {FC, useState} from "react";
import {Link} from "react-router-dom";
import {MenuList} from "../..";
import {SystemConfiguration} from "../../../configuration";
import {MenuModel} from "../../../models";
import {
    isArabicCurrentLanguage,
    isUserAuthenticated,
    SecureLocalStorageGet,
} from "../../../utils";
import logo from "../../../assets/images/logo-sidebar.svg";
import logomini from "../../../assets/images/logo-mini.svg";

export const LayoutHeader: FC<any> = () => {
    //#region variables
    const isArabic: boolean = isArabicCurrentLanguage();
    let menuItems: MenuModel[] = JSON.parse(
        // @ts-ignore
        SecureLocalStorageGet(SystemConfiguration.keys.menu || "")
    ) as MenuModel[];
    //#endregion
    //#region state
    const [isOpen] = useState(false);
    //#endregion
    //#region html
    return (
        <>
            {isUserAuthenticated() && (
                <>
                    <div className="main-sidebar">
                        <nav className="sidebar sidebar-offcanvas">
                            <div
                                className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                                <Link className="navbar-brand brand-logo" to="/">
                                    <img src={logo} alt="logo"/>
                                </Link>
                                <Link className="navbar-brand brand-logo-mini" to="/">
                                    <img src={logomini} alt="logo"/>
                                </Link>
                            </div>
                            <ul className="nav">
                                {isUserAuthenticated() &&
                                    menuItems !== null &&
                                    menuItems !== undefined &&
                                    menuItems.length !== 0 && !isOpen && menuItems.length !== 0 && (
                                        <MenuList request={menuItems} isArabic={isArabic}/>
                                    )}
                            </ul>
                        </nav>
                    </div>
                </>
            )}
        </>
    );
    //#endregion
};
