import {FC} from "react";
import {CategoryMainComponent} from "../../components";
import {PageEnum} from "../../models";

export const CategoriesPage: FC<{ formId: number }> = ({formId = PageEnum.Categories}) => {
    //#region html
    return (
        <>
            <CategoryMainComponent formId={formId || 0}/>
        </>
    );
    //#endregion
};
