import React, {Dispatch, FC, MutableRefObject, SetStateAction} from "react";
import {
    getLabelName,
    getLookUpItemValue,
    getOriginalValueInCurrency,
    isArabicCurrentLanguage,
    updateStateDynamically
} from "../../../utils";
import {
    fillItemListPerCategory,
    getTextBoxNumericOrDefaultValue,
    getTextBoxValue,
    handleCalculateItemRecordValuesUpdate,
    handleChangeNewRecordItemCalculation,
    handleSearchAndFilterItems,
    handleSearchAndFilterItemsUsingBarCode,
    transactionItemInitialValues
} from "../common/businessLogic/transactionBl";
import {SelectBox, LabelBox, ButtonBox, TextBox} from "../..";
import {
    ActionTypeEnum,
    ChangeItemValueTypeEnum, CompanySettingModel, DailyTransactionTypeEnum,
    KeyValueStateModel,
    LookupItemModel, RequestActionModel, TransactionDetailResponseModel,
    TransactionItemResponseModel,
    UserTransactionsSettingResponseModel
} from "../../../models";

export const RegisterTransactionItem: FC<{
    transactionType: DailyTransactionTypeEnum,
    data: TransactionDetailResponseModel,
    transactionItemObject: TransactionItemResponseModel,
    setTransactionItemObject: Dispatch<SetStateAction<TransactionItemResponseModel>>,
    userTransactionSetting: UserTransactionsSettingResponseModel,
    companySettings: CompanySettingModel,
    itemList: LookupItemModel[],
    setItemList: Dispatch<SetStateAction<LookupItemModel[]>>,
    itemsPerCategoryList: LookupItemModel[],
    setItemPerCategoryList: Dispatch<SetStateAction<LookupItemModel[]>>,
    categoryList: LookupItemModel[],
    calcTypeList: LookupItemModel[],
    itemCategorySelectBoxMultiselectRef: React.MutableRefObject<any>,
    itemSelectBoxMultiselectRef: React.MutableRefObject<any>,
    onActionEvent: (o: RequestActionModel) => void
}> = ({
          transactionType,
          data,
          transactionItemObject,
          setTransactionItemObject,
          userTransactionSetting,
          itemList,
          setItemList,
          itemsPerCategoryList,
          setItemPerCategoryList,
          categoryList,
          calcTypeList,
          companySettings,
          itemCategorySelectBoxMultiselectRef,
          itemSelectBoxMultiselectRef,
          onActionEvent = () => {
          },
      }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    const discountPercentageMaxValue: number = 50;
    //#endregion
    //#region functions
    const updateTransactionItemObjectState = async (key?: string | null, value?: any | null, keyValues?: KeyValueStateModel[] | null) => {
        await updateStateDynamically(
            setTransactionItemObject,
            transactionItemObject,
            key,
            value, keyValues
        );
    };
    //#endregion
    //#region html
    return (<>
        <div
            className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
            <TextBox labelName={getLabelName("Barcode")} inputName={"barCode"}
                     inputValue={transactionItemObject.barCode}
                     onChange={async (e: any) => {
                         const value = getTextBoxValue(e);
                         await updateTransactionItemObjectState("barCode", value);
                     }}
                     onKeyPress={async (inputValue: any) => {
                         await updateTransactionItemObjectState("barCode", inputValue);
                         if (inputValue !== null && inputValue !== "") {
                             await handleSearchAndFilterItemsUsingBarCode(setItemList, setItemPerCategoryList, setTransactionItemObject, itemList, itemsPerCategoryList, calcTypeList, data, transactionItemObject, userTransactionSetting, transactionType, inputValue, isArabic)
                         }
                     }}
            />
            <SelectBox
                id="categorySelectBox"
                labelName={getLabelName("Category")}
                isSingleSelect={true}
                selectedValues={
                    transactionItemObject.Categ_ID !== null &&
                    transactionItemObject.Categ_ID !== undefined
                        ? [transactionItemObject.Categ_ID.toString()]
                        : []
                }
                source={categoryList}
                //multiselectRef={itemCategorySelectBoxMultiselectRef}
                //@ts-ignore
                multiselectRef={(node: MutableRefObject<any>) => node ? (itemCategorySelectBoxMultiselectRef.current = node) : null}
                onStatusChange={async (e: LookupItemModel) => {
                    const value = getLookUpItemValue(e);
                    if (value !== null && value !== undefined) {
                        itemSelectBoxMultiselectRef.current && itemSelectBoxMultiselectRef.current.clearValue();
                        setTransactionItemObject({...transactionItemInitialValues, Categ_ID: Number(value)});
                        //await updateTransactionItemObjectState("Categ_ID", value);
                    }else{ setTransactionItemObject({...transactionItemInitialValues, Categ_ID: null});}
                    await fillItemListPerCategory(value, setItemPerCategoryList, itemList);
                }}
            />
            <SelectBox
                id="itemsSelectBox"
                labelName={getLabelName("Item")}
                isSingleSelect={true}
                selectedValues={
                    transactionItemObject.ItemUnit_ID !== null &&
                    transactionItemObject.ItemUnit_ID !== undefined
                        ? [transactionItemObject.ItemUnit_ID.toString()]
                        : []
                }
                source={itemsPerCategoryList}
                //multiselectRef={itemSelectBoxMultiselectRef}
                //@ts-ignore
                multiselectRef={(node: MutableRefObject<any>) => node ? (itemSelectBoxMultiselectRef.current = node) : null}
                //onInputChange={handleInputChange}
                onInputChange={(inputValue: string) => {
                    handleSearchAndFilterItems(setItemList, setItemPerCategoryList, itemList, itemsPerCategoryList, inputValue, isArabic).then()
                }}
                onStatusChange={async (e: LookupItemModel) => {
                    const value = getLookUpItemValue(e);
                    if (value !== null && value !== undefined) {
                        const transactionItemObj = handleChangeNewRecordItemCalculation({
                            ...transactionItemObject,
                            ItemUnit_ID: Number(value)
                        }, data, userTransactionSetting, transactionType, itemList, calcTypeList);
                        setTransactionItemObject(transactionItemObj);
                    }else{setTransactionItemObject({...transactionItemInitialValues, ItemUnit_ID: null}); }
                }}
            />
            <LabelBox
                labelName={getLabelName("Balance")}
                inputValue={transactionItemObject.UnitBalance}
            />
            {/*server values*/}
            {/*<TextBox*/}
            {/*    labelName={getLabelName("Price")}*/}
            {/*    isReadOnly={!userTransactionSetting.EnableShangPrice}*/}
            {/*    inputName={"Unit_Price"}*/}
            {/*    type="number"*/}
            {/*    inputValue={transactionItemObject.Unit_Price}*/}
            {/*    onChange={async (e: any) => {*/}
            {/*        const value: number = getTextBoxNumericOrDefaultValue(e);*/}
            {/*        await updateTransactionItemObjectState(null,null,[{key:"Unit_Price",value:value},{key:"changeValueType",value:ChangeItemValueTypeEnum.ChangePrice}]);*/}
            {/*    }}*/}
            {/*    onBlur={async (e: any) => {*/}
            {/*        const obj= handleCalculateItemRecordValuesUpdate(transactionItemObject);*/}
            {/*        setTransactionItemObject(obj);*/}
            {/*    }}*/}
            {/*/>*/}
            <TextBox
                labelName={getLabelName("Price")}
                isReadOnly={!userTransactionSetting.EnableShangPrice}
                inputName={"Unit_PriceInCurrencyCalculated"}
                type="number"
                inputValue={transactionItemObject.Unit_PriceInCurrencyCalculated}
                onChange={async (e: any) => {
                    const value: number = getTextBoxNumericOrDefaultValue(e);
                    await updateTransactionItemObjectState(null, null, [{
                        key: "Unit_PriceInCurrencyCalculated",
                        value: value
                    }, {key: "changeValueType", value: ChangeItemValueTypeEnum.ChangePriceInCurrency}]);
                }}
                onBlur={async () => {
                    const obj = handleCalculateItemRecordValuesUpdate(transactionItemObject);
                    setTransactionItemObject(obj);
                }}
            />
            {companySettings.ApplyTax && <TextBox
                labelName={getLabelName("PriceSaleWithTax")}
                isReadOnly={!userTransactionSetting.EnableShangPrice}
                inputName={"UnitPriceWithTaxInCurrency"}
                type="number"
                inputValue={transactionItemObject.UnitPriceWithTaxInCurrency}
                onChange={async (e: any) => {
                    const value: number = getTextBoxNumericOrDefaultValue(e);
                    await updateTransactionItemObjectState(null, null, [{
                        key: "UnitPriceWithTaxInCurrency",
                        value: value
                    }, {key: "changeValueType", value: ChangeItemValueTypeEnum.ChangePriceWithTaxInCurrency}]);
                }}
                onBlur={async () => {
                    const obj = handleCalculateItemRecordValuesUpdate(transactionItemObject);
                    setTransactionItemObject(obj);
                }}
            />}

            <TextBox
                labelName={getLabelName("Quantity")}
                inputName={"Quantity"}
                type="number"
                inputValue={transactionItemObject.Quantity}
                onChange={async (e: any) => {
                    const value: number = getTextBoxNumericOrDefaultValue(e);
                    await updateTransactionItemObjectState("Quantity", value)
                }}
            />
            <TextBox
                labelName={getLabelName("Discount")}
                isReadOnly={!userTransactionSetting.EnableDiscount}
                inputName={"ItemDiscountInCurrency"}
                type="number"
                inputValue={transactionItemObject.ItemDiscountInCurrency}
                onChange={async (e: any) => {
                    const value: number = getTextBoxNumericOrDefaultValue(e);
                    await updateTransactionItemObjectState(null, null, [
                        {key: "ItemDiscount", value: getOriginalValueInCurrency(value, data.ValueCurrency || 0)},
                        {key: "ItemDiscountInCurrency", value: value},
                        {key: "changeValueType", value: ChangeItemValueTypeEnum.None}
                    ]);
                }}
            />
            <TextBox
                labelName={getLabelName("Discount %")}
                isReadOnly={!userTransactionSetting.EnableDiscount}
                inputName={"ItemDiscountRatio"}
                type="number"
                inputValue={transactionItemObject.ItemDiscountRatio}
                onChange={async (e: any) => {
                    const value: number = getTextBoxNumericOrDefaultValue(e);
                    if (value <= discountPercentageMaxValue) {
                        await updateTransactionItemObjectState("ItemDiscountRatio", value);
                    }
                }}
            />
        </div>
        <div className="row mt-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                <ButtonBox
                iconType="plus"
                    id="addItemBtn"
                    variant=""
                    className="btn-gradient-info me-2"
                    size="sm"
                    onClick={async () => {
                        onActionEvent({
                            request: transactionItemObject,
                            action: ActionTypeEnum.AddItem,
                        });
                        //await handleAddItem(transactionItemObject, transactionType);
                    }}
                >
                    {getLabelName("Add")}
                </ButtonBox>
            </div>
        </div>
    </>)
    //#endregion
}