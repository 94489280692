import { LabelBox } from "../../";
import { getLabelName } from "../../../utils";
import React, { FC } from "react";
import {
  CompanySettingModel,
  DailyTransactionTypeEnum,
  TransactionDetailResponseModel,
  TransactionItemResponseModel,
} from "../../../models";

export const PosTransactionSummary: FC<{
  data: TransactionDetailResponseModel;
  companySettings: CompanySettingModel;
}> = ({ data, companySettings }) => {
  //#region functions
  const handleCalculateTotalQuantity = (
    request: TransactionDetailResponseModel
  ): number => {
    return (
      request?.TransactionDetaillist?.reduce(
        (accumulator: number, currentItem: TransactionItemResponseModel) => {
          return accumulator + currentItem.Quantity;
        },
        0
      ) || 0
    );
  };
  //#endregion
  //#region html
  return (
    <>
      <div
        style={{
          backgroundColor:
            data.TransactionType === DailyTransactionTypeEnum.POSSalesRefund
              ? "lightblue"
              : "#fff",
        }}
        className="card mb-3"
      >
        <div className="card-body pb-0">
          <div className="row row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-6 row-cols-sm-6 g-md-4 g-sm-4">
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <LabelBox
                id="totalQuantities"
                labelName={getLabelName("Quantity")}
                inputValue={handleCalculateTotalQuantity(data)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <LabelBox
                id="totalMoneyInCurrency"
                labelName={getLabelName("Total")}
                inputValue={data.TotalMonyInCurrency}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <LabelBox
                id="discountInCurrency"
                labelName={getLabelName("Discount")}
                inputValue={data.DiscountInCurrency}
              />
            </div>
            {companySettings.ApplyTax && (
              <div className="col-12 col-sm-6 col-md-4 mb-3">
                <LabelBox
                  id="taxValueInCurrency"
                  labelName={getLabelName("Tax_Value")}
                  inputValue={data.TotalTaxInCurrency}
                />
              </div>
            )}
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <LabelBox
                id="netBillInCurrency"
                labelName={getLabelName("Net Bill")}
                inputValue={data.TotalNetInCurrency}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
  //#endregion
};
