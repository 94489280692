import {useEffect, useState} from "react";
import {LookupItemModel, LookupTypeEnum} from "../../models";
import {getLookUp} from "../../components/transactions/common/businessLogic/transactionBl";

const useSupplierAccounts = () => {
    const [storedSuppliers, setStoreSuppliersList] = useState<LookupItemModel[]>([]);
    const [loading, setIsLoading] = useState(false);
    const fetchSuppliers = async () => {
        setIsLoading(true);
        const fetchedSuppliers = await getLookUp(
            [setStoreSuppliersList],
            LookupTypeEnum.Supplier,
            true,
            true
        );
        setStoreSuppliersList(fetchedSuppliers);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchSuppliers().then(() => {
        });
    }, []);

    return {
        storedSuppliers,
        suppliersLoading: loading,
    };
};

export default useSupplierAccounts;
