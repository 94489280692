import {FC} from "react";
import {getLabelName} from "../../utils";

export const NotFoundPage: FC = () => {
    return (
        <>
            {/* we need to add page with image */}
            <p>{getLabelName("Page Not Found-please check url")}</p>
        </>
    );
};
