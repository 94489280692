import React, {FC} from "react";
import {CustomerComplexReportSearchParamsModel, PageEnum} from "../../../models";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {ReportCustomersComplexSearchPanel, ReportPage,} from "../../../components";
import {SearchReportCustomerPayments} from "../../../serviceBroker/customersReportApiServiceBroker";

export const ReportsCustomersPayments: FC = () => {
    const initialState: CustomerComplexReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={SearchReportCustomerPayments}
            searchHeader={getPageNameByPageId(PageEnum.ReportCustomersPayments)}
            SearchPanel={ReportCustomersComplexSearchPanel}
            formId={PageEnum.ReportCustomersPayments}
        />
    );
};
