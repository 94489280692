import {useEffect, useState} from "react";
import {getItemConfigurations} from "../../serviceBroker/itemApiServiceBroker";
import {ItemConfigurationsResponseModel} from "../../models";

const initialConfigs: ItemConfigurationsResponseModel = {
    ShowproductionItem: false,
    ShowExpireDate: false,
    ShowModifiers: false,
    ShowGatherItem: false,
    ShowItemSerial: false,
    IsItemVanishConsign: false,
    IsOutOldestExiredDateByDefault: false,
    WriteItemNameIncaseUseImageAsBackground: false,
    NumberDevidedOnScalePrice: 100,
    IncludeItemCodeWithItemName: false,
    DirectAddItemToBillOnScaneBarcode: false,
    ShowItemSuppleer: false,
    ShowFilterOilOption: false,
    CanAddAddationlCost: false,
};

const useItemConfigs = () => {
    const [itemConfigs, setItemConfigs] = useState(initialConfigs);
    const [isLoading, setIsLoading] = useState(false);

    const fetchItemConfigs = async () => {
        try {
            setIsLoading(true);
            const response = await getItemConfigurations();
            if (!response.Result) return;
            setItemConfigs(response.Result);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchItemConfigs().then(() => {});
    }, []);

    return {
        itemConfigs,
        configsLoading: isLoading,
    };
};

export default useItemConfigs;
