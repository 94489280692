import {useEffect, useReducer} from "react";
import {searchAccountTransaction} from "../../serviceBroker/acTransactionsApiServiceBroker";
import {AcTransactionSearchModel, AcTransactionTypeEnum, SearchRequestPayloadModel,} from "../../models";

enum ActionType {
    SET_SEARCHED_TRANSACTIONS,
    SET_TOTAL_ROWS,
    SET_LOADING,
    SET_SEARCH_PARAMS,
    RESET_SEARCH_PARAMS,
    SET_PAGE_SIZE,
    SET_PAGE_NUMBER,
}

type Action = {
    type: ActionType;
    payload?: any;
};

interface TransactionsState {
    searchedTransactions: AcTransactionSearchModel[];
    totalRows: number;
    loading: boolean;
    searchParams: SearchRequestPayloadModel;
}

const initialTransactionsState: TransactionsState = {
    searchedTransactions: [],
    totalRows: 1,
    loading: false,
    searchParams: {pageSize: 10, pageNumber: 1},
};

const transactionsReducer = (
    state: TransactionsState,
    action: Action
): TransactionsState => {
    switch (action.type) {
        case ActionType.SET_SEARCHED_TRANSACTIONS:
            return {...state, searchedTransactions: action.payload};
        case ActionType.SET_TOTAL_ROWS:
            return {...state, totalRows: action.payload};
        case ActionType.SET_LOADING:
            return {...state, loading: action.payload};
        case ActionType.SET_PAGE_SIZE:
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    pageSize: action.payload,
                    pageNumber: 1,
                },
            };
        case ActionType.SET_PAGE_NUMBER:
            return {
                ...state,
                searchParams: {...state.searchParams, pageNumber: action.payload},
            };
        case ActionType.SET_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: {...state.searchParams, ...action.payload},
            };
        case ActionType.RESET_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: {
                    pageNumber: state.searchParams.pageNumber,
                    pageSize: state.searchParams.pageSize,
                },
            };
        default:
            return state;
    }
};

const useTransactions = (acTransactionType?: AcTransactionTypeEnum) => {
    const [state, dispatch] = useReducer(
        transactionsReducer,
        initialTransactionsState
    );

    const fetchAccountTransactions = async () => {
        try {
            dispatch({type: ActionType.SET_LOADING, payload: true});
            const transactions = await searchAccountTransaction(
                state.searchParams,
                acTransactionType
            );
            if (transactions !== null) {
                dispatch({
                    type: ActionType.SET_SEARCHED_TRANSACTIONS,
                    payload: transactions.Result as AcTransactionSearchModel[],
                });
                dispatch({
                    type: ActionType.SET_TOTAL_ROWS,
                    payload: transactions.TotalRecoredCount as number,
                });
            }
        } catch (err) {
        } finally {
            dispatch({type: ActionType.SET_LOADING, payload: false});
        }
    };

    useEffect(() => {
        fetchAccountTransactions().then(() => {
        });
    }, [state.searchParams]);

    return {
        searchedTransactions: state.searchedTransactions,
        areTransactionsLoading: state.loading,
        setSearchParams: (params: SearchRequestPayloadModel) =>
            dispatch({type: ActionType.SET_SEARCH_PARAMS, payload: params}),
        resetSearchParams: () => dispatch({type: ActionType.RESET_SEARCH_PARAMS}),
        setPageSize: (pageSize: number) =>
            dispatch({type: ActionType.SET_PAGE_SIZE, payload: pageSize}),
        setPageNumber: (pageNumber: number) =>
            dispatch({type: ActionType.SET_PAGE_NUMBER, payload: pageNumber}),
        setSearchedTransactions: (trans: AcTransactionSearchModel[]) =>
            dispatch({type: ActionType.SET_SEARCHED_TRANSACTIONS, payload: trans}),
        currentPage: state.searchParams.pageNumber,
        totalRows: state.totalRows,
        currentPageSize: state.searchParams.pageSize,
        fetchAccountTransactions,
    };
};

export default useTransactions;
