import {SecureLocalStorageGet} from "..";
import {SystemConfiguration} from "../../configuration";
import {AppConfigurationModel} from "../../models";

export const GetAppConfig = (): AppConfigurationModel => {
    let result: AppConfigurationModel = {
        alyusrApiEndpoint: "",
    };
    try {
        result = JSON.parse(
            // @ts-ignore
            SecureLocalStorageGet(SystemConfiguration.keys.appConfig || "")
        ) as AppConfigurationModel;
    } catch (err: any) {
    }
    return result;
};
// export const SetAppConfiguration = (): AppConfiguration => {
//   let result: AppConfiguration = {
//     alyusrApiEndpoint: "",
//   };
//   fetch("config/appConfig.json", {
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//   })
//     .then(function (response) {
//       console.log(response);
//       return response.json();
//     })
//     .then(function (myJson) {
//       SecureLocalStorageSet(
//         SystemConfiguration.keys.appConfig || "",
//         JSON.stringify(myJson)
//       );
//       setData(myJson);
//     });
// };
