import {FC} from "react";
import {PageEnum, ReportVatSearchParamsModel} from "../../../models";
import {ReportPage, ReportVatSearchPanel} from "../../../components";
import {searchReportVat} from "../../../serviceBroker/transationReportApiServiceBroker";
import {getPageNameByPageId} from "../../../utils";

export const ReportVatPage: FC = () => {
    const initialState: ReportVatSearchParamsModel = {};
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportVat}
            searchHeader={getPageNameByPageId(PageEnum.ReportEndorsementVAT)}
            SearchPanel={ReportVatSearchPanel}
            formId={PageEnum.ReportEndorsementVAT}
        />
    );
};
