import {TableColumn} from "react-data-table-component";
import {getLabelName} from "../../../utils";
import {TextBox, CheckBox} from "../../index";
import {ChangeEvent} from "react";
import {ShownResponseModel} from "../../../models";

export interface CommissionRowData extends ShownResponseModel {
    groupName: string;
    ID: number;
    CalcCommissionAfterCost: boolean;
    ProfitPercentage: number;
    CategoryId: number;
    isChanged: boolean;
}

interface TransactionHandlers {
    handleCheck: (id: number) => void;
    handlePercentChange: (id: number, enteredValue: number) => void;
}

const setCommissionsCategoryColumns: (
    handlers: TransactionHandlers
) => TableColumn<CommissionRowData>[] = ({
                                             handleCheck,
                                             handlePercentChange,
                                         }) => {
    const handleInputChange = (event: any, id: number) => {
        const enteredValue = event.target.value;
        if (+enteredValue < 0 || +enteredValue > 100) return;
        handlePercentChange(id, enteredValue);
    };
    const currentTransactionColumns: TableColumn<CommissionRowData>[] = [
        {
            name: getLabelName("CategoryNameEn"),
            center: true,
            selector: (row) => getLabelName(row.groupName || ""),
            sortable: true,
            wrap: true,
        },
        {
            name: getLabelName("Percentage"),
            center: true,
            id: "Percentage",
            cell: ({ProfitPercentage, ID}) => (
                <div style={{maxWidth: "150px"}}>
                    <TextBox
                        inputValue={ProfitPercentage}
                        labelName=""
                        inputName="Percentage"
                        type="number"
                        onChange={(e: ChangeEvent) => handleInputChange(e, ID)}
                    />
                </div>
            ),
            sortable: true,
            wrap: true,
            allowOverflow: false,
        },
        {
            name: getLabelName("Calc Commission After Cost"),
            id: "commission_after_cost",
            center: true,
            cell: ({CalcCommissionAfterCost, ID}) => (
                <CheckBox
                    onChange={() => handleCheck(ID)}
                    id="comissionAfterCost"
                    checked={CalcCommissionAfterCost}
                    inputName="comissionAfterCost" labelName=""/>
            ),
            allowOverflow: true,
        },
    ];

    return currentTransactionColumns;
};

export default setCommissionsCategoryColumns;
