import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
    CustomerDeleteResponseModel,
    CustomerResponseModel,
    CustomerTypeResponseModel,
    LookupItemModel,
    ResponseBaseModel,
    SearchCustomerRequestModel,
    SearchCustomerResponseModel,
} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getAllCustomersForDropDown = async (): Promise<LookupItemModel[]> => {
    let customerResult: ResponseBaseModel<CustomerResponseModel[]>;
    let response: LookupItemModel[] = [];
    customerResult = await AlYusrAxiosApiInstance.get(AlYuserApiUrls.customerController.getAllCustomers);
    if (
        customerResult !== null &&
        customerResult.Result !== null &&
        customerResult.Result !== undefined &&
        customerResult.Result.length !== 0
    ) {
        customerResult.Result.forEach((row) => {
            response.push({
                nameAr: row.Name,
                name: row.Name_En,
                value: row.ID.toString(),
            });
        });
    }
    return response;
};
export const searchCustomers = async (
    request: SearchCustomerRequestModel
): Promise<ResponseBaseModel<SearchCustomerResponseModel[]>> => {
    let response: ResponseBaseModel<SearchCustomerResponseModel[]> = {};
    let url: string = `${AlYuserApiUrls.customerController.searchCustomer}?searchCustomer.lang=1`;
    url = `${url}&serachParmeter.pageSize=${request.pageSize || 10}`;
    url = `${url}&serachParmeter.custmertype=${request.type}`;
    url = `${url}&serachParmeter.pageNumber=${request.pageNumber || 1}`;
    url = request.searchValue ? `${url}&serachParmeter.name=${request.searchValue}` : url;
    const fetchResult: ResponseBaseModel<SearchCustomerResponseModel[]> =
        await AlYusrAxiosApiInstance.get(url);
    response.Errors = fetchResult.Errors;
    response.TotalRecoredCount = fetchResult.TotalRecoredCount;
    if (
        fetchResult.Result !== null &&
        fetchResult.Result !== undefined &&
        fetchResult.Result.length !== 0
    ) {
        response.Result = [];

        fetchResult.Result.forEach((row) => {
            // if(row.Cust_Type==2){
            response.Result?.push({
                ID: row.ID,
                City_ID: row.City_ID,
                Cust_Type: row.Cust_Type,
                CustomerType: row.CustomerType,
                Mail: row.Mail,
                MaxDebit: row.MaxDebit,
                Notes: row.Notes,
                address: row.address,
                Account_ID: row.Account_ID,
                TaxNumber: row.TaxNumber,
                Code: row.Code,
                Name: row.Name,
                Name_En: row.Name_En,
                Mobile: row.Mobile,
                Phone: row.Phone,
                Address: row.Address,
                IDNumber: row.IDNumber,
                BalanceOfPoint: row.BalanceOfPoint,
            });
            //}
        });
    }
    return response;
};
export const getCustomerInvoiceType = async (): Promise<LookupItemModel[]> => {
    try {
        const result: ResponseBaseModel<CustomerTypeResponseModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.customerController.getCustomerInvoiceType);
        // @ts-ignore
        let response: LookupItemModel[] = [];
        result.Result?.forEach((item) => {
            response.push({
                name: item.EnglishName,
                nameAr: item.ArabicName,
                value: item.Code,
            });
        });
        return response;
    } catch (err) {
        alert(err);
    }
    return [];
};

export const getCustomerById = async (id: number): Promise<CustomerResponseModel> => {
    let url: string = `GetCustomerById?CustomerId=${id}`;
    const result: ResponseBaseModel<CustomerResponseModel> =
        await AlYusrAxiosApiInstance.get(url);
    // @ts-ignore
    return result !== null && result !== undefined ? result.Result : {};
};
//#endregion
//#region post
export const deleteCustomer = async (
    id: number
): Promise<CustomerDeleteResponseModel> => {
    let apiResponse: CustomerDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        let url: string = `${AlYuserApiUrls.customerController.deleteCustomer}?id=${id}`;
        apiResponse = await AlYusrAxiosApiInstance.post(url);
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
export const addCustomer = async (
    request: CustomerResponseModel
): Promise<ResponseBaseModel<CustomerResponseModel>> => {
    let apiResponse: ResponseBaseModel<CustomerResponseModel> = {};
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.customerController.saveCustomer, {...request});
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
//#endregion
