import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {isUserCurrentBranchAssigned} from "../../utils";
import {RoutePageConfigurations} from "../../configuration";

export const CurrentBranchRoute = ({children,}: { children: React.ReactNode; }) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!isUserCurrentBranchAssigned()) {
            navigate(RoutePageConfigurations.currentBranchPage);
        }
    }, []);
    return <>{children}</>;
}