import React, {useEffect, useRef, useState} from "react";
import {SelectBox, TextBox, ButtonBox} from "..";
import {getLabelName} from "../../utils";
import {AntdTableComponent} from "../common/dataTable/antdDataTable";
import {setGatheredItemsColumns} from "./uiHelper/gatheredTableColumns";
import {LookupItemModel, RowStateEnum, SearchItemsTypeEnum, GatherItemModel, SearchItemApiResponseModel} from "../../models";
import {searchItems} from "../../serviceBroker/itemApiServiceBroker";
import {uniqueId} from "lodash";

interface GatherItemFormProps {
    categoryList: LookupItemModel[];
    gatherItemsList: GatherItemModel[];
    calculateBeginPriceQyt: (gatherItems: GatherItemModel[]) => void;
    setGatherItems: React.Dispatch<React.SetStateAction<GatherItemModel[]>>;
}

export const GatherItemForm: React.FC<GatherItemFormProps> = ({
                                                                  categoryList,
                                                                  gatherItemsList,
                                                                  setGatherItems,
                                                                  calculateBeginPriceQyt,
                                                              }) => {
    //#region state
    const [categorySelected, setCategorySelected] = useState<LookupItemModel>(
        categoryList[0] || null
    );
    const [items, setItems] = useState<SearchItemApiResponseModel[]>([]);
    const itemRef = useRef<any>();
    const [selectedItem, setSelectedItem] =
        useState<SearchItemApiResponseModel | null>(null);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [quantity, setQuantity] = useState(0);
//#endregion
    //#region functions
    const searchItemsByCategoryID = async () => {
        if (categorySelected === null) return;
        try {
            setItemsLoading(true);
            const items = await searchItems({
                categoryId: +categorySelected.value!,
                searchType: SearchItemsTypeEnum.None,
                pageSize: 10000,
            });
            setItems(
                items.Result?.filter(({ItemUnit_ID}) =>
                    gatherItemsList.every(
                        ({ItemUnit_ID: id, rowState}) => id !== ItemUnit_ID
                    )
                ) as SearchItemApiResponseModel[]
            );
        } catch (error) {
        } finally {
            setItemsLoading(false);
        }
    };
    const handleAddingItem = () => {
        if (selectedItem === null) return;
        resetItemSelected();
        setGatherItems((prev) => {
            const gatherItemsUpdated = [
                ...prev,
                {
                    ID: null,
                    Item_ID: selectedItem.ID,
                    ItemInstore_ID: selectedItem.ItemInstore_ID,
                    ItemUnit_ID: selectedItem.ItemUnit_ID,
                    ItemNameAr: selectedItem.ItemName,
                    ItemNameEn: selectedItem.ItemName_En,
                    PriceCost: selectedItem.PriceCost,
                    PriceSale: selectedItem.PriceSale,
                    Quantity: quantity,
                    rowState: RowStateEnum.Add,
                    rowKey: uniqueId(),
                },
            ];
            calculateBeginPriceQyt(gatherItemsUpdated);
            return gatherItemsUpdated;
        });
    };
    const resetItemSelected = () => {
        setQuantity(0);
        setSelectedItem(null);
        if (itemRef.current) itemRef.current.clearValue();
    };
    const handleDeletion = (id: number | string) => {
        const itemToBeDeleted = gatherItemsList.find(
            ({ID, rowKey}) => (ID ? ID : rowKey) === id
        );
        if (itemToBeDeleted?.rowState === RowStateEnum.Add) {
            setGatherItems((prev) => {
                const updatedGatherItems = prev.filter(({rowKey}) => rowKey !== id);
                calculateBeginPriceQyt(updatedGatherItems);
                return updatedGatherItems;
            });
        } else {
            setGatherItems((prev) => {
                const updatedGatherItems = prev.map((item) => {
                    if (item.ID === id) return {...item, rowState: RowStateEnum.Delete};
                    return item;
                });
                calculateBeginPriceQyt(updatedGatherItems);
                return updatedGatherItems;
            });
        }
    };
    const handleQtyEdit = (newQty: number, id: number | string) => {
        setGatherItems((prev) => {
            const updatedGatherItems = prev.map((item) => {
                if ((item.ID ? item.ID : item.rowKey) === id)
                    return {...item, Quantity: newQty};
                return item;
            });
            calculateBeginPriceQyt(updatedGatherItems);
            return updatedGatherItems;
        });
    };
//#endregion
    //#region useEffect
    useEffect(() => {
        searchItemsByCategoryID().then(() => {
        });
        resetItemSelected();
    }, [categorySelected]);
    useEffect(() => {
        setItems((prev) =>
            prev.filter(({ItemUnit_ID}) =>
                gatherItemsList.every(
                    ({ItemUnit_ID: id, rowState}) => id !== ItemUnit_ID
                )
            )
        );
    }, [gatherItemsList]);
//#endregion
    //#region html
    return (
        <>
            <div
                className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-center">
                <SelectBox
                    labelName={getLabelName("Categorys")}
                    source={categoryList}
                    isSingleSelect={true}
                    onStatusChange={setCategorySelected}
                    selectedValues={
                        categorySelected !== null ? [categorySelected.value!] : null
                    }
                    multiselectRef={undefined}
                />
                <SelectBox
                    labelName={getLabelName("item")}
                    source={items.map(({ItemUnit_ID, ItemName, ItemName_En}) => ({
                        value: `${ItemUnit_ID}`,
                        nameAr: ItemName,
                        name: ItemName_En,
                    }))}
                    isSingleSelect={true}
                    onStatusChange={(e: any) => {
                        if (e === null) return setSelectedItem(null);
                        const item =
                            items.find(({ItemUnit_ID}) => ItemUnit_ID === +e.value) || null;
                        setSelectedItem(item);
                        setQuantity(1);
                    }}
                    selectedValues={
                        selectedItem !== null ? [`${selectedItem.ItemUnit_ID}`] : null
                    }
                    multiselectRef={itemRef}
                    isDataLoading={itemsLoading}
                />
                <TextBox
                    type={"number"}
                    inputName="quantity"
                    inputValue={quantity}
                    onChange={(e: any) => {
                        if (e.target.value > 0) setQuantity(e.target.value);
                    }}
                    labelName={getLabelName("quantity")}
                />
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end mb-4 mt-2">
                <ButtonBox
                iconType="plus"
                    type="button"
                    className="btn  btn-sm mx-2"
                    variant="primary"
                    onClick={handleAddingItem}
                    disabled={selectedItem === null}
                >
                    {getLabelName("add")}
                </ButtonBox>
            </div>
            <div className="mt-4">
                <AntdTableComponent
                    data={gatherItemsList.filter(
                        ({rowState}) => rowState !== RowStateEnum.Delete
                    )}
                    columns={setGatheredItemsColumns(handleQtyEdit, handleDeletion)}
                    rowKey="rowKey"
                />
            </div>
        </>
    );
    //#endregion
};
