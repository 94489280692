import {FC} from "react";
import {ReportGeneralLedgerPageSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {AccountsReportSearchParamsModel, PageEnum} from "../../../models";
import {searchReportGeneralLedger} from "../../../serviceBroker/acTransactionReportApiServiceBroker";

export const ReportGeneralLedgerPage: FC = () => {
    const initialState: AccountsReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportGeneralLedger}
            searchHeader={getPageNameByPageId(PageEnum.ReportAccountsTransaction)}
            SearchPanel={ReportGeneralLedgerPageSearchPanel}
            formId={PageEnum.ReportAccountsTransaction}
        />
    );
};
