import {FC} from "react";
import {StoreTransferMainComponent} from "../../../components";
import {DailyTransactionTypeEnum, PageEnum} from "../../../models";

export const StoreTransferPage:FC=()=>{

    //#region html
    return (<>
        <StoreTransferMainComponent formId={PageEnum.MoveItemToStore} transactionType={DailyTransactionTypeEnum.MoveToStore}/>
    </>)
    //#endregion
}