import Dexie from "dexie";

interface MyDatabase extends Dexie {
    // @ts-ignore
    [key: string]: Dexie.Table<any, number>;
}

//#region public
export const setIndexDb = async <T>(
    request: T[],
    dbName: string,
    objectStoreName: string
) => {
    try {
        const dexieDb = new Dexie(dbName) as MyDatabase;
        const updatedObjectStoreName = getObjectStoreName(objectStoreName);
        const properties: string[] = getPropertiesList(updatedObjectStoreName);
        dexieDb.version(1).stores({
            [updatedObjectStoreName]: `++id, ${properties.join(", ")}`,
        });
        await dexieDb.open();

        // @ts-ignore
        await dexieDb[updatedObjectStoreName].bulkPut(request);
        await refreshIndexedDB(dbName);
    } catch (error) {
        console.error("Error saving data to IndexedDB!: ", error);
    }
};
export const fetchIndexedDbData = async <T>(
    dbName: string,
    objectStoreName: string
): Promise<T> => {
    const updatedObjectStoreName = getObjectStoreName(objectStoreName);
    try {
        const dexieDb = new Dexie(dbName) as MyDatabase;
        const properties: string[] = getPropertiesList(updatedObjectStoreName);
        dexieDb.version(1).stores({
            [updatedObjectStoreName]: `++id, ${properties.join(", ")}`,
        });
        await dexieDb.open();
        //console.log('Existing object stores:', dexieDb.tables.map(table => table.name));
        // @ts-ignore
        if (dexieDb.tables.some((table) => table.name === updatedObjectStoreName)) {
            // @ts-ignore
            return (await dexieDb[updatedObjectStoreName].toArray()) as T;
        } else {
            return {} as T;
        }
    } catch (error) {
        console.error(
            `Error fetching data from IndexedDB for ${updatedObjectStoreName}: ${error}`
        );
        return {} as T;
    }
};
export const refreshIndexedDB = async (dbName: string) => {
    try {
        const db = new Dexie(dbName) as MyDatabase;
        db.close();
        await db.open();
    } catch (error) {
        console.error("Error refreshing IndexedDB:", error);
    }
};
//#endregion
//#region private
const openDatabase = (dbName: string, objectStoreName: string) => {
    objectStoreName = getObjectStoreName(objectStoreName);
    const db = new Dexie(dbName) as MyDatabase;
    const properties: string[] = getPropertiesList(objectStoreName);
    db.version(1).stores({
        [objectStoreName]: `++id, ${properties.join(", ")}`,
    });
    return db.open();
};
const getObjectStoreName = (objectStoreName: string): string => {
    return objectStoreName.toLowerCase().trim();
};
const getPropertiesList = (objectStoreName: string): string[] => {
    let properties: string[] = [];
    switch (objectStoreName) {
        case "items":
        case "ft7dzthmu8obj":
            properties = [
                "value",
                "name",
                "nameAr",
                "categoryId",
                "sellPrice",
                "additionalPrice",
                "wholeSalePrice",
                "costPrice",
                "purchasePrice",
                "balance",
                "factor",
                "itemUnitId",
                "code",
                "tax",
                "taxPercentage",
                "itemInStoreId",
                "barCode",
            ];
            break;
    }
    return properties;
};
//#endregion
