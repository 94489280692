import {
    CustomerComplexReportSearchParamsModel,
    CustomersSimpleReportSearchParamsModel,
    CustomersTransactionAndAcTransactionSearchParamsModel,
    DebitCustomerReportSearchParamsModel,
    GeneralPrintResponseModel,
    ResponseBaseModel
} from "../models";
import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const SearchReportDebitCustomerPage = async (
    request: DebitCustomerReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.customerReportController.reportDebitCustomer,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const SearchReportDebitSupplierPage = async (
    request: DebitCustomerReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.customerReportController.reportDebitSupplier,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const SearchReportCustomersTransactionAndAcTransactionPage = async (
    request: CustomersTransactionAndAcTransactionSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.customerReportController.reportCustomersTransactionAndAcTransaction,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const SearchReportSuppliersTransactionAndAcTransactionPage = async (
    request: CustomersTransactionAndAcTransactionSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.customerReportController.reportSuppliersTransactionAndAcTransaction,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
export const SearchReportCustomersData = async (request: CustomersSimpleReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.customerReportController.reportCustomersData, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const SearchReportCustomerPayments = async (request: CustomerComplexReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.customerReportController.reportCustomerPayments, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const SearchReportCustomersAccount = async (request: CustomerComplexReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.customerReportController.reportCustomersAccount, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const SearchReportAllCustomersTransaction = async (request: CustomerComplexReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.customerReportController.reportAllCustomersTransaction, {params: AxiosSearchParams(request, 'searchParmeter')});
};
//#endregion