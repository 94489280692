import {ActionTypeEnum, RequestActionModel, TableActionColumnsStyleEnum} from "../../../../../models";
import {getLabelName} from "../../../../../utils";
import { ColumnActions} from "../utils/actions";
import {Dropdown} from "react-bootstrap";
import {ButtonBox,PrivilegesChecker,CustomDropdown} from "../../../..";
import { IconType } from "react-icons";

//#region 1. GetActionsColumns
export const getActionsColumns = (
    actionsPermitted: ActionTypeEnum[],
    formId: number,
    onActionEvent: (o: RequestActionModel) => void,
    style: TableActionColumnsStyleEnum = TableActionColumnsStyleEnum.Dropdown
) => {
    return {
        cell: (row: any) => {
            if (style === TableActionColumnsStyleEnum.Button) {
                return <ButtonActions actionsPermitted={actionsPermitted} formId={formId} onActionEvent={onActionEvent} row={row} />;
            } else {
                const isIconRender:boolean=style===TableActionColumnsStyleEnum.Dropdown||style===TableActionColumnsStyleEnum.DropdownIcon;
                const isTextRender:boolean=style===TableActionColumnsStyleEnum.Dropdown||style===TableActionColumnsStyleEnum.DropDownText;
                return <DropdownActions actionsPermitted={actionsPermitted} formId={formId} onActionEvent={onActionEvent} row={row} isIconRender={isIconRender} isTextRender={isTextRender}/>;
            }
        },
    };
};
//#endregion
//#region 2. ButtonActions
const ButtonActions = ({
                           actionsPermitted,
                           formId,
                           onActionEvent,
                           row
                       }: {
    actionsPermitted: ActionTypeEnum[];
    formId: number;
    onActionEvent: (o: RequestActionModel) => void;
    row:any
}) => (
    <div className="d-flex gap-3 align-items-center">
        {actionsPermitted.map((action, index) => (
            <PrivilegesChecker
                formId={formId}
                action={ColumnActions[action]?.privilege}
                key={`privilege_${action.toString()}_${index}`}
            >
                <ButtonBox
                    className="btn btn-sm"
                    variant={ColumnActions[action]?.variant}
                    id={`button_id_b_${action.toString()}_${index}`}
                    key={`button_${action.toString()}_${index}`}
                    onClick={() => {
                        onActionEvent({
                            id: row.ID,
                            request: row,
                            action: action,
                        });
                    }}
                >
                    {getLabelName(ColumnActions[action]?.btnText??"")}
                </ButtonBox>
            </PrivilegesChecker>
        ))}
    </div>
);
//#endregion
//#region 3. DropdownActions
const DropdownActions = ({
                             actionsPermitted,
                             formId,
                             onActionEvent,
                             row,
                             isIconRender,
                             isTextRender
                         }: {
    actionsPermitted: ActionTypeEnum[];
    formId: number;
    onActionEvent: (o: RequestActionModel) => void,
    row:any,
    isIconRender:boolean,
    isTextRender:boolean
}) => {
    const dropdownItems = actionsPermitted
        ?.map((action, index) => {
            const IconComponent: IconType |null = isIconRender && ColumnActions[action]?.icon||null;

            return (
                <PrivilegesChecker
                    key={index} // Ensure unique key for each item
                    formId={formId}
                    action={ColumnActions[action]!.privilege}
                >
                    <Dropdown.Item
                        variant={ColumnActions[action]!.variant}
                        id={`button_id_d_${action.toString()}_${index}`}
                        onClick={() => {
                            onActionEvent({
                                id: row.ID,
                                request: row,
                                action,
                            });
                        }}
                    >
                        {IconComponent && <IconComponent style={{ marginRight: 8 }} />}
                        {isTextRender &&  <span>{getLabelName(ColumnActions[action]!.btnText)}</span>}
                    </Dropdown.Item>
                </PrivilegesChecker>
            );
        })
        .filter(Boolean); // This will remove null values from the array
    return (
        <div className="d-flex gap-3 align-items-center">
            {dropdownItems.length > 0 && (
                <CustomDropdown
                    style={{
                        position: "absolute",
                        left: "20px",
                        top: "11px",
                        // zIndex: 1,
                    }}
                    dropdownIcon={{ color: "#EFEFEF", size: 16 }}
                >
                    {dropdownItems}
                </CustomDropdown>
            )}
        </div>
    );
};
//#endregion