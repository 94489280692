import {useState} from "react";
import {
    ComponentActionTypeEnum,
    LookupItemModel, StoreTransferSearchTransactionRequestModel,
    StoreTransferSearchTransactionResponseModel,
    StoreTransferTransactionDetailResponseModel,
    ToastModel,
    TransactionItemResponseModel, TransactionResponseModel,
    UserTransactionsSettingResponseModel,
    ValidationErrorModel
} from "../../../../../models";
import {transactionItemInitialValues} from "../../../common/businessLogic/transactionBl";
import {storeTransferTransactionDetailInitialValue} from "../../../common/businessLogic/storeTransferTransactionBl";

export const useStoreTransferState = () => {
    //#region state
    const [loading, setLoading] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [forceRerender, setForceRerender] = useState(false);
    const[isResetReference,setIsResetReference]=useState<boolean>(false);
    const [showPrintoutPdfModel, setShowPrintoutPdfModel] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>([]);
    const[lookupList,setLookupList]=useState<LookupItemModel[]>([]);
    const [settings,setSettings]=useState<UserTransactionsSettingResponseModel[]>([])
    const[transactionList,setTransactionList]=useState<StoreTransferSearchTransactionResponseModel[]>([]);

    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
        variant: "success",
    });

    const [data, setData] = useState<StoreTransferTransactionDetailResponseModel >(
        storeTransferTransactionDetailInitialValue
    );
    const [transactionItemObject, setTransactionItemObject] =
        useState<TransactionItemResponseModel>(transactionItemInitialValues);

    const[fromStoreId,setFromStoreId]=useState<number|null>(null);
    const[toStoreId,setToStoreId]=useState<number|null>(null);
    const [componentActionType, setComponentActionType] =
        useState<ComponentActionTypeEnum>(ComponentActionTypeEnum.None);


    const [totalRows, setTotalRows] = useState(0);
    const [searchRequest, setSearchRequest] =
        useState<StoreTransferSearchTransactionRequestModel>({pageNumber: 1,pageSize: 50});
    const [printTransactionId, setPrintTransactionId] = useState(0);

    const [transactionResponseObject, setTransactionResponseObject] = useState<TransactionResponseModel | null>(null);
    //#endregion
    return {
        loading, setLoading,
        validationErrors, setValidationErrors,
        toastModel, setToastModel,
        lookupList,setLookupList,
        settings,setSettings,
        fromStoreId,setFromStoreId,
        toStoreId,setToStoreId,
        data, setData,
        transactionItemObject, setTransactionItemObject,
        forceRerender,setForceRerender,
        isRefresh,setIsRefresh,
        isResetReference,setIsResetReference,
        componentActionType, setComponentActionType,
        transactionList,setTransactionList,
        totalRows, setTotalRows,
        searchRequest, setSearchRequest,
        printTransactionId, setPrintTransactionId,
        showPrintoutPdfModel, setShowPrintoutPdfModel,
        showDeleteModel, setShowDeleteModel,
        transactionResponseObject, setTransactionResponseObject
    };
}