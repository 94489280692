export const TRANSLATIONS_EN = {
    "TRANSLATOR.SELECT": "Select your language",
    "user name": "User Name",
    "enter user name": "enter user name",
    "login.password": "Password",
    "login.enter_password": "enter password",
    "login.submit": "Submit",
    "user nameMissing": "enter user name",
    "login.passwordMissing": "enter password",



   
    Search: "Search",
    name: "Name",
    calcType: "Calc Type",
    previousData: "Previous Data",
    paymentType: "Payment Type",
    "footer_copyRights": "Copyright ©",
    "footer_allRightsReserved": "Alyuser - All Right Reserved",
    "empid.missing": "Select Employee",
    "ruleId.missing": "Select Rule",
    "cache.Refresh": "Cache Refresh",
    "closeDay.miniValue": "close Day Value must be at least 0",
    "refund.items": "select one item at least",
    "refund.noItems.ForRefund": "The invoice does not contain any return items",
    "refund item qty": "Quantity can not exceed the maximum Quantity",
    "Let's get started": "Let's get started",
    "Sign in to continue": "Sign in to continue",
    "Alyusr System": "Alyusr System",
    "Alyusr.System.login.description": "To provide excellent services to customers\n" +
        "              and the preservation of the environment and public health and the\n" +
        "              development of building regulations and investment in human resources."
};
