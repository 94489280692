import {
    AccountsReportSearchParamsModel,
    GeneralPrintResponseModel,
    IncomeStatementReportSearchParamsModel,
    IncomeStatementReportSearchParamsStringModel,
    ResponseBaseModel
} from "../models";
import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const searchReportGeneralLedger = async (
    request: AccountsReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.acTransactionReportController.searchReportGeneralLedger, {
        params: AxiosSearchParams(request, "searchParmeter"),
    });
};
export const searchReportIncomeStatement = async (
    request: IncomeStatementReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    let req: IncomeStatementReportSearchParamsStringModel = {
        ...request,
        fromDate: request.fromDate?.toISOString(),
        toDate: request.toDate?.toISOString(),
    };
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.acTransactionReportController.searchReportIncomeStatement, {
        params: AxiosSearchParams(req, "searchParmeter"),
    });
};
export const searchReportBalanceSheet = async (
    request: IncomeStatementReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    let req: IncomeStatementReportSearchParamsStringModel = {
        ...request,
        fromDate: request.fromDate?.toISOString(),
        toDate: request.toDate?.toISOString(),
    };
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.acTransactionReportController.searchReportBalanceSheet, {
        params: AxiosSearchParams(req, "searchParmeter"),
    });
};
//#endregion