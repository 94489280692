import React, {Dispatch, FC, MutableRefObject, RefObject, SetStateAction, useEffect, useRef} from "react";
import {
    InputDatePickerTimeDisplayModeEnum,
    StoreTransferTransactionDetailResponseModel,
    TransactionItemResponseModel,
    LookupItemModel,
    LookupItemFilterModel,
    LookupTypeEnum,
    RequestActionModel, ActionTypeEnum, KeyValueStateModel
} from "../../../models";
import {getLabelName, getLookUpItemValue, updateStateDynamically} from "../../../utils";
import {InputDatePicker, SelectBox, TextBox} from "../..";
export const StoreTransferRegisterTransactionHeader:FC<{ formik: any,
    data: StoreTransferTransactionDetailResponseModel,
    transactionItemObject: TransactionItemResponseModel,
    setData: Dispatch<SetStateAction<StoreTransferTransactionDetailResponseModel>>,
    isResetReference:boolean,
    lookups:LookupItemFilterModel[],
    onActionEvent: (o: RequestActionModel) => void
    }>   = ({formik,data,transactionItemObject,setData,lookups,isResetReference,onActionEvent = () => {}}) => {
    //#region ref
    const fromStoreSelectBoxMultiselectRef = useRef<any>();
    const toStoreSelectBoxMultiselectRef = useRef<any>();
    const employeeSelectBoxMultiselectRef = useRef<any>();
    //#endregion
    //#region function
    const getRefByKey = (request:KeyValueStateModel[] ,key:string): RefObject<any> => {
        const refObject = request.find(refObj => refObj.key === key);
        return refObject ? refObject.value : useRef<any>();//React.createRef<any>() ;
    };
    const handleClearControls = async () => {
        fromStoreSelectBoxMultiselectRef.current && fromStoreSelectBoxMultiselectRef.current.clearValue();
        toStoreSelectBoxMultiselectRef.current && toStoreSelectBoxMultiselectRef.current.clearValue();
        employeeSelectBoxMultiselectRef.current && employeeSelectBoxMultiselectRef.current.clearValue();
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            if(isResetReference){
                await handleClearControls();
                onActionEvent({
                    request:false,
                    action:ActionTypeEnum.ResetReference
                })
            }
        };
        fillData().then(() => {});
    }, [isResetReference]);
    //#endregion
    //#region html
    return (<>
        <div
            className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
            <TextBox
                labelName={getLabelName("Code")}
                isReadOnly={true}
                inputName={"Code"}
                errorText={formik.errors.Code}
                inputValue={data.Code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
                <InputDatePicker
                    selectedDate={new Date(data.Date.toString())}
                    timeMode={InputDatePickerTimeDisplayModeEnum.None}
                    timeIntervals={15}
                    className="form-control"
                    InputLabel={"Date"}
                    onChange={(date: Date) => {
                        setData({
                            ...data,
                            Date: date,
                        });
                    }}
                    maxDate={new Date()}
                />
                <SelectBox
                    id="fromStoreSelectBox"
                    labelName={ getLabelName("FromStore")}
                    isSingleSelect={true}
                    selectedValues={
                        data.Store_IDFrom !== null && data.Store_IDFrom !== undefined
                            ? [data.Store_IDFrom.toString()]
                            : []
                    }
                    source={lookups.find(x=>x.key===LookupTypeEnum.Stores)?.value||[]}
                    //@ts-ignore
                    multiselectRef={(node: MutableRefObject<any>) => node ? (fromStoreSelectBoxMultiselectRef.current  = node) : null}
                    onStatusChange={async (e: LookupItemModel) => {
                        const val = getLookUpItemValue(e);
                        await updateStateDynamically(
                            setData,
                            data,
                            null, null, [
                                {key: "Store_IDFrom", value: val},
                            ]
                        );
                        // if (val !== null && val !== undefined) {
                        //     await updateStateDynamically(
                        //         setData,
                        //         data,
                        //         null, null, [
                        //             {key: "Store_IDFrom", value: val},
                        //         ]
                        //     );
                        // }
                        onActionEvent({
                            request: val,
                            action: ActionTypeEnum.ChangeFromStoreId,
                        });
                    }}
                    errorText={formik.errors.Store_IDFrom}
                    ></SelectBox>
            <SelectBox
                id="toStoreSelectBox"
                labelName={ getLabelName("ToStore")}
                isSingleSelect={true}
                selectedValues={
                    data.Store_IDTo !== null && data.Store_IDTo !== undefined
                        ? [data.Store_IDTo.toString()]
                        : []
                }
                source={lookups.find(x=>x.key===LookupTypeEnum.Stores)?.value||[]}
                //@ts-ignore
                multiselectRef={(node: MutableRefObject<any>) => node ? (toStoreSelectBoxMultiselectRef.current = node) : null}
                onStatusChange={async (e: LookupItemModel) => {
                    const val = getLookUpItemValue(e);
                        await updateStateDynamically(
                            setData,
                            data,
                            null, null, [
                                {key: "Store_IDTo", value: val},
                            ]
                        );
                    onActionEvent({
                        request: val,
                        action: ActionTypeEnum.ChangeToStoreId,
                    });
                }}
                errorText={formik.errors.Store_IDTo}></SelectBox>
            <SelectBox
                id="employeeSelectBox"
                labelName={ getLabelName("Employee")}
                isSingleSelect={true}
                selectedValues={
                    data.Emp_ID !== null && data.Emp_ID !== undefined
                        ? [data.Emp_ID.toString()]
                        : []
                }
                source={lookups.find(x=>x.key===LookupTypeEnum.Employee)?.value||[]}
                //@ts-ignore
                multiselectRef={(node: MutableRefObject<any>) => node ? (employeeSelectBoxMultiselectRef.current  = node) : null}
                onStatusChange={async (e: LookupItemModel) => {
                    const val = getLookUpItemValue(e);
                        await updateStateDynamically(
                            setData,
                            data,
                            null, null, [
                                {key: "Emp_ID", value: val},
                            ]
                        );
                }}
                errorText={formik.errors.Store_IDTo}></SelectBox>
        </div>
        <div
            className="row row-cols-1 row-cols-xxl-12 row-cols-xl-12 row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-md-12 g-sm-12">
            <TextBox
                labelName={getLabelName("Notes")}
                type="textarea"
                inputName={"Note"}
                inputValue={data.Note}
                onChange={async (e: any) => {
                    await updateStateDynamically(setData, data, "Note", e.target.value)
                }}
                //placeHolder="Type Here"
            />
        </div>
    </>)
    //#endregion
}