import _ from "lodash";
import {FC} from "react";
import {
    KeyValueModel,
    NumericControlSizeType,
    NumericLabelSizeType,
} from "../../../models";

type BoxType = "radio" | "checkbox";
export const RadioCheckBox: FC<{
    labelName?: string;
    items: KeyValueModel[];
    selectedValues?: string[] | null;
    errorText?: string | null;
    isMandatory?: boolean | null;
    controlSize?: NumericControlSizeType;
    labelSize?: NumericLabelSizeType;
    onChange?: any | undefined;
    onBlur?: any | undefined;
    type?: BoxType | null;
    isDisabled?: boolean;
}> = ({
          items,
          selectedValues,
          controlSize = 4,
          onChange = () => {},
          onBlur = () => {},
          type = "radio",
          isDisabled = false,
      }) => {
    return (
        <>
            {/* <div
        className={`col-xxl-${labelSize} col-xl-${labelSize} col-lg-${labelSize} col-md-${labelSize} col-sm-12 col-12 pt-2 pt-1`}
      >
        {labelName && (
          <label>
            {getLabelName(labelName)}
            {isMandatory && <span className="asterisk">*</span>}
          </label>
        )}
      </div> */}
            <div
                className={`d-flex col-xxl-${controlSize} col-xl-${controlSize} col-lg-${controlSize} col-md-${controlSize} col-sm-12 col-12 read-only-wrap`}
            >
                {items.map((row) => {
                    return (
                        <div className="me-3 d-flex align-items-center" key={row.text}>
                            <input
                                // @ts-ignore
                                type={type}
                                // @ts-ignore
                                name={row.name}
                                value={row.value}
                                disabled={isDisabled}
                                // @ts-ignore
                                checked={
                                    !(selectedValues !== null &&
                                        _.filter(selectedValues, (record) => {
                                            return record === row.value;
                                        }).map((x) => x).length === 0)
                                }
                                // @ts-ignore
                                onChange={onChange}
                                // @ts-ignore
                                onBlur={onBlur}
                            />
                            <label className="me-1">{row.text}</label>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
