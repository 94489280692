import {FC} from "react";
import {ReportCustomersTransactionAndAcTransactionSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {
    SearchReportCustomersTransactionAndAcTransactionPage
} from "../../../serviceBroker/customersReportApiServiceBroker";
import {CustomersTransactionAndAcTransactionSearchParamsModel, PageEnum} from "../../../models";

export const ReportCustomersTransactionAndAcTransactionPage: FC = () => {
    const initialState: CustomersTransactionAndAcTransactionSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={SearchReportCustomersTransactionAndAcTransactionPage}
            searchHeader={getPageNameByPageId(PageEnum.ReportAllCustomerTransaction)}
            SearchPanel={ReportCustomersTransactionAndAcTransactionSearchPanel}
            formId={PageEnum.ReportAllCustomerTransaction}
        />
    );
};
