import { FC } from "react";
import {
  ActionTypeEnum,
  HasFormIdModel,
  CurrencyResponseModel,
} from "../../models";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { ButtonBox, PrivilegesChecker } from "..";

interface CurrencyListProps extends HasFormIdModel {
  request: CurrencyResponseModel[];
  isModifyAble?: boolean | null;
  isDeleteAble?: boolean | null;
  isPermissionAble?: boolean | null;
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

export const CurrencyList: FC<CurrencyListProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region variable
  const isArabic: boolean = isArabicCurrentLanguage();
  //#endregion
  //#region html
  return (
    <>
      {request != null && request.length !== 0 && (
        <table className="table table-xs table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th className="width-50">#</th>
              <th>{getLabelName("currency.ArabicName")}</th>
              <th>{getLabelName("currency.EnglishName")}</th>
              <th>{getLabelName("currency.ShortCutArab")}</th>
              <th>{getLabelName("currency.Value")}</th>
              <th colSpan={2}>{getLabelName("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {request.map((row, index) => {
              return (
                <tr key={`user-${index}`}>
                  <td>{index + 1}</td>
                  <td>{row.ArabicName}</td>
                  <td>{row.EnglishName}</td>
                  <td>{isArabic ? row.ShortCutArab : row.ShortCutArab}</td>
                  <td>{isArabic ? row.Value : row.Value}</td>
                  <td className="text-end">
                    <PrivilegesChecker formId={formId} action="EnableUpdate">
                      <ButtonBox
                        type="button"
                        variant="primary"
                        className="mx-2 btn-xs"
                        onClick={() => {
                          onActionEvent({
                            id: row.ID,
                            action: ActionTypeEnum.Update,
                          });
                        }}
                      >
                        {getLabelName("Edit")}
                      </ButtonBox>
                    </PrivilegesChecker>

                    <PrivilegesChecker formId={formId} action="EnableDelete">
                      <ButtonBox
                        className="btn-xs"
                        type="button"
                        variant="danger"
                        onClick={() => {
                          //onSelect({ id: row.ID, type: "update" });
                          onActionEvent({
                            id: row.ID,
                            action: ActionTypeEnum.Delete,
                          });
                        }}
                      >
                        {getLabelName("Delete")}
                      </ButtonBox>
                    </PrivilegesChecker>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
  //#endregion
};
