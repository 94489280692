import { useEffect, useState } from "react"
import { getLookupByType } from "../../../serviceBroker/lookupApiServiceBroker";
import {  LookupItemModel, LookupTypeEnum } from "../../../models";
import { getChildrenTables } from "../../../serviceBroker/transactionApiServiceBroker";

const useRestaurantTables = () => {
    const [parentTables, setParentTables] = useState<LookupItemModel[]>();
    const [childrenTables, setChildrenTables] = useState<LookupItemModel[]>();
    const [parentTableID, setParentTableID] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchParentTables = async () => {
            setIsLoading(true)
            const tables = await getLookupByType(LookupTypeEnum.AllMainParentRestaurant);
            setParentTables(tables)
            setIsLoading(false)
        }
        fetchParentTables().then(() => {});
    }, []);

    useEffect(() => {
        if(parentTableID === null) return;
        const fetchChildrenTables = async () => {
            setIsLoading(true)
            const tables = await getChildrenTables(parentTableID);
            setChildrenTables(tables)
            setIsLoading(false)
        }
        fetchChildrenTables().then(() => {});
    }, [parentTableID])

    return {
        parentTables,
        setParentTableID,
        childrenTables,
        setIsLoading,
        isLoading
    }
}
export default useRestaurantTables;