import {useFormik} from "formik";
import * as Yup from "yup";
import {FC, useState, useEffect} from "react";
import {
    RequestActionModel,
    ValidationErrorModel,
    ActionTypeEnum,
    RowStateEnum,
    LookupItemModel,
    CurrencyResponseModel,
    HasFormIdModel,
} from "../../models";
import {
    ErrorValidationBox,
    TextBox,
    LoadingBox,
    SelectBox, ButtonBox, PrivilegesChecker
} from "..";
import {getLabelName} from "../../utils";
import {
    getCurrenciesShortCutList,
    addCurrency,
} from "../../serviceBroker/currencyApiServiceBroker";

interface AddCurrencyProps extends HasFormIdModel {
    request: CurrencyResponseModel;
    onActionEvent: (o: RequestActionModel) => void;
}

export const AddCurrency: FC<AddCurrencyProps> = ({
                                                      request,
                                                      onActionEvent, formId,
                                                  }) => {
    //#region variables
    const initialValues: CurrencyResponseModel = request;
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [shortCutArabList, setShortCutArabList] = useState<LookupItemModel[]>([]);
    const [_, setShortCutArabId] = useState<number>(17);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    //#endregion
    //#region functions
    const handleSubmit = async (request: CurrencyResponseModel) => {
        alert("enter handleSubmit");

        try {
            setLoading(true);
            request.rowState =
                request.ID === 0 ? Number(RowStateEnum.Add) : Number(RowStateEnum.Update);
            const res = await addCurrency(request);
            if (res != null && res.Errors != null && res.Errors.length !== 0) {
                setValidationErrors(res.Errors);
                setLoading(false);
            } else {
                setValidationErrors([]);
                setLoading(false);
                onActionEvent({id: 0, action: ActionTypeEnum.AddSuccess});
                setLoading(false);
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    const validationSchema = Yup.object({
        ArabicName: Yup.string().required(getLabelName("required")),
    });
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            // var calcType = await getLookupByType(LookupTypeEnum.PaymentType);
            const shortcutList = await getCurrenciesShortCutList();
            setShortCutArabList(shortcutList);
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, {resetForm}) => {
            await handleSubmit(values);
            resetForm();
        },
    });

    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form className="forms-sample" onSubmit={formik.handleSubmit}>
                <div
                    className=" row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 g-md-4 g-sm-4">
                    <TextBox
                        key="ArabicName"
                        labelName="ArabicName"
                        inputName="ArabicName"
                        placeHolder="Arbic Name"
                        isMandatory={true}
                        inputValue={formik.values.ArabicName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.ArabicName}
                    />

                    <TextBox
                        labelName="English Name"
                        placeHolder="English Name"
                        inputName="EnglishName"
                        isMandatory={true}
                        inputValue={formik.values.EnglishName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.EnglishName}
                    />
                    <SelectBox
                        labelName={getLabelName("ShortCutArab")}
                        source={shortCutArabList}
                        isSingleSelect={true}
                        errorText={formik.errors.ShortCutArab}
                        onStatusChange={(e: any) => {
                            formik.values.ShortCutArab = e.value;
                            setShortCutArabId(e.value);
                        }}
                        selectedValues={[request.ShortCutArab.toString()]}
                        multiselectRef={undefined}
                    />
                    <TextBox
                        labelName="Value"
                        placeHolder="Value"
                        inputName="Value"
                        inputValue={formik.values.Value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.Value}
                    />
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <PrivilegesChecker formId={formId} action="EnableSave">
                            <ButtonBox
                            iconType="content-save"
                                type="submit"
                                variant=""
                                className="btn-primary btn-sm mx-3"
                            >
                                {getLabelName("Save")}
                            </ButtonBox>
                        </PrivilegesChecker>
                        <ButtonBox
                        iconType="close-circle"
                            variant="danger"
                            type="button"
                            onClick={(e) => {
                                formik.handleReset(e);
                                onActionEvent({id: 0, action: ActionTypeEnum.Clear});
                            }}
                        >
                            {getLabelName("Cancel")}
                        </ButtonBox>
                    </div>
                </div>
            </form>
        </>
    );
    //#endregion
};
