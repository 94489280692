import React, { FC, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  ConfirmModelDialogBox,
  ItemList,
  RegisterItem,
  ToastBox,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  ItemModel,
  RequestActionModel,
  ResponseBaseModel,
  ToastModel,
} from "../../models";
import {
  deleteItem,
  getItemFullDetailsById,
} from "../../serviceBroker/itemApiServiceBroker";
import { getLabelName, scrollToTop } from "../../utils";

export const ItemsPage: FC = () => {
  //#region state
  const [isFormLoading, setFormLoading] = useState(false);
  const [isTableLoading, setTableLoading] = useState(false);
  const [isModalLoading, setModalLoading] = useState(false);
  const [isRefreshItemList, setIsRefreshItemList] = useState(false);
  const [showDeleteUserModel, setShowDeleteUserModel] = useState(false);
  const [deletedId, setDeletedId] = useState<null | number>(null);
  const [isRefreshItem, setIsRefreshItem] = useState(false);
  const [object, setObject] = useState<ItemModel | null>(null);
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
  });
  const pageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  //#endregion
  //#region function
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.Add:
        setObject(null);
        break;
      case ActionTypeEnum.Update:
        setFormLoading(true);
        let item = (await getItemFullDetailsById(
          request.id!
        )) as ResponseBaseModel<ItemModel>;
        item.Result!.rowState = 2;
        item.Result!.ItemsInstores[0].rowState = 2;
        item.Result!.ItemsInstores[0].Item_unit =
          item.Result!.ItemsInstores[0].Item_unit.map((item) => ({
            ...item,
            rowState: 2,
          }));
        if (item.Result!.Item_Modifiers !== null)
          item.Result!.Item_Modifiers = item.Result!.Item_Modifiers.map(
            (item) => ({ ...item, rowState: 2 })
          );
        if (item.Result!.ItemsInstores[0].Item_unit[0].ItemGathers !== null)
          item.Result!.ItemsInstores[0].Item_unit[0].ItemGathers =
            item.Result!.ItemsInstores[0].Item_unit[0].ItemGathers.map(
              (item) => ({ ...item, rowState: 2 })
            );
        item.Result!.rowState = 2;
        setObject(item.Result!);
        setIsRefreshItem(true);
        setFormLoading(false);
        scrollToTop(pageRef);
        break;
      case ActionTypeEnum.Delete:
        setDeletedId(request.request.ID);
        setShowDeleteUserModel(true);
        break;
      case ActionTypeEnum.DeleteOperationStart:
        setDeletedId(request.request.ID);
        setShowDeleteUserModel(true);
        break;
      case ActionTypeEnum.AddSuccess:
      case ActionTypeEnum.DeleteSuccess:
      case ActionTypeEnum.Success:
        setToastModel({ ...toastModel, show: true, variant: "success" });
        setIsRefreshItemList(true);
        if (object?.ID === deletedId)
          await handleAction({ action: ActionTypeEnum.Clear });
        setDeletedId(null);
        setShowDeleteUserModel(false);
        break;
      case ActionTypeEnum.RaiseError:
        scrollToTop(pageRef);
        break;
      case ActionTypeEnum.Clear:
        setObject(null);
        setIsRefreshItem(true);
        scrollToTop(pageRef);
        break;
      case ActionTypeEnum.Failed:
        setToastModel({
          ...toastModel,
          variant: "danger",
          show: true,
        });
        setIsRefreshItemList(true);
        setDeletedId(null);
        setShowDeleteUserModel(false);
        break;
    }
  };
  const deleteItemAction: (id: number) => ActionButtonsModel[] = (
    id: number
  ) => {
    return [
      {
        text: getLabelName("yes"),
        onClick: async () => {
          setModalLoading(true);
          const result = await deleteItem(id);
          setModalLoading(false);
          await handleAction({
            id: 0,
            action: result.Result?.Result
              ? ActionTypeEnum.Success
              : ActionTypeEnum.Failed,
          });
        },
      },
      {
        text: getLabelName("no"),
        onClick: () => {
          setDeletedId(null);
          setShowDeleteUserModel(false);
        },
      },
    ];
  };
  //#endregion
  //#region html
  return (
    <>
      <div ref={pageRef} />
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <ConfirmModelDialogBox
        isModelVisible={showDeleteUserModel}
        onCloseEvent={() => {
          setShowDeleteUserModel(false);
          setDeletedId(null);
        }}
        isLoading={isModalLoading}
        actions={deleteItemAction(deletedId as number)}
      >
        <div className="alert alert-warning">
          {getLabelName("Are You Sure You Want Delete")}
        </div>
      </ConfirmModelDialogBox>
      <RegisterItem
        request={object}
        setIsRefresh={setIsRefreshItem}
        isRefresh={isRefreshItem}
        onActionEvent={async (o: RequestActionModel) => {
          await handleAction(o);
        }}
        isFormLoading={isFormLoading}
        setFormLoading={setFormLoading}
      />
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body className="TUEFO-header">
            <ItemList
              onActionEvent={async (o: RequestActionModel) => {
                await handleAction(o);
              }}
              setIsRefresh={setIsRefreshItemList}
              isRefresh={isRefreshItemList}
              isTableLoading={isTableLoading}
              setTableLoading={setTableLoading}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
