import React, {
    FC,
    useEffect,
    useState,
} from "react";
import {
    LookupItemModel,
    LookupTypeEnum,
    SearchPanelPropsModel,
    TransactionReportSearchParamsModel,
} from "../../../models";
import {getItemsByCategoryId} from "../../../serviceBroker/itemApiServiceBroker";
import {useLookups} from "../../../hooks";

export const ReportTransactionSearchPanel: FC<
    SearchPanelPropsModel<TransactionReportSearchParamsModel>
> = ({
         setLoading,
         searchParams,
     }) => {
    //#region state
    const {getLookupItems} = useLookups(setLoading, [
        LookupTypeEnum.ReportTypes,
        LookupTypeEnum.Customers,
        LookupTypeEnum.Users,
        LookupTypeEnum.Employee,
        LookupTypeEnum.CostCenters,
        LookupTypeEnum.Currency,
        LookupTypeEnum.AllCategories,
        LookupTypeEnum.DaysOfWeek,
    ]);
    const [itemsLookUps, setItemsLookUps] = useState<LookupItemModel[]>([]);
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            const results = await getItemsByCategoryId(searchParams.categoryId);
            setItemsLookUps(results);
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, [searchParams.categoryId]);
    //#endregion
    //#region html
    return (
        <>
        </>
    );
    //#endregion
};
