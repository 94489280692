import {useFormik} from "formik";
import * as Yup from "yup";
import {FC, useState, useEffect} from "react";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";
import {Col, Form, Row} from "react-bootstrap";
import {savePointOfSaleSettings} from "../../serviceBroker/userApiServiceBroker";
import {ValidationErrorModel} from "../../models";
import {ButtonBox, ErrorValidationBox, TextBox, LoadingBox, SelectBox} from "..";
import {
    ActionTypeEnum,
    LookupItemModel,
    LookupTypeEnum,
    POSUserSettingModel,
    RowStateEnum,
} from "../../models";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";
import {getAllCustomersForDropDown} from "../../serviceBroker/customerApiServiceBroker";

export const POSSettings: FC<{
    pOSUserSettingModel: POSUserSettingModel;
    onActionEvent?: any | null;
    onComplete?: any | null;
}> = ({
          pOSUserSettingModel,
          onComplete = () => {
          },
          onActionEvent = () => {
          },
      }) => {
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            const[discounts,notes,currency,employee,customers]=await Promise.all([
                getLookupByType(LookupTypeEnum.DefaultDiscountType),
                getLookupByType(LookupTypeEnum.DefaultNote),
                getLookupByType(LookupTypeEnum.Currency),
                getLookupByType(LookupTypeEnum.Employee),
                getAllCustomersForDropDown()
            ]);
            setDiscountTypeList(discounts);
            setNoteTypeList(notes);
            setCurrencyList(currency);
            setCustomerList(customers);
            setEmployeeList(employee);
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState<LookupItemModel[]>([]);
    const [customerList, setCustomerList] = useState<LookupItemModel[]>([]);
    const [currencyList, setCurrencyList] = useState<LookupItemModel[]>([]);
    const [discountTypeList, setDiscountTypeList] = useState<LookupItemModel[]>([]);
    const [noteTypeList, setNoteTypeList] = useState<LookupItemModel[]>([]);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [validationSchema, _] = useState(Yup.object({}));
    //#endregion
    //#region function
    const handleReset = async () => {
        onComplete(null);
    };
    const handleSubmit = async (request: POSUserSettingModel) => {
        try {
            setLoading(true);
            request.rowState = RowStateEnum.Add;
            const res = await savePointOfSaleSettings(request);
            if (res != null && res.Errors != null && res.Errors.length !== 0) {
                setValidationErrors(res.Errors);
                onActionEvent({
                    id: ActionTypeEnum.POSSetting,
                    action: ActionTypeEnum.Failed,
                    request: isArabic
                        ? res?.Errors[0].MessageAr
                        : res?.Errors[0].MessageEn,
                    requestBeforeError: request,
                });
                setLoading(false);
            } else {
                setValidationErrors([]);
                onActionEvent({
                    id: ActionTypeEnum.POSSetting,
                    action: ActionTypeEnum.AddSuccess,
                    request: request,
                });
                setLoading(false);
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#en
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: pOSUserSettingModel,
        validationSchema: validationSchema,
        enableReinitialize: true,
        // onReset: (values) => {
        //   onComplete(false);
        // },
        onSubmit: async (values, {resetForm}) => {
            await handleSubmit(values);
            resetForm();
        },
    });
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form onSubmit={formik.handleSubmit}>
                <div
                    className="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
                    <SelectBox
                        labelName={getLabelName("Default Discount")}
                        source={discountTypeList}
                        isSingleSelect={true}
                        errorText={formik.errors.DefaultDiscountType}
                        onStatusChange={(e: any) => {
                            formik.values.DefaultDiscountType = e.value;
                            pOSUserSettingModel.DefaultDiscountType = e.value;
                        }}
                        selectedValues={[
                            pOSUserSettingModel.DefaultDiscountType.toString(),
                        ]}
                        multiselectRef={undefined}
                    />
                    <SelectBox
                        labelName={getLabelName("Default Notes")}
                        source={noteTypeList}
                        isSingleSelect={true}
                        errorText={formik.errors.DefaultNoteType}
                        onStatusChange={(e: any) => {
                            formik.values.DefaultNoteType = e.value;
                            pOSUserSettingModel.DefaultNoteType = e.value;
                        }}
                        selectedValues={[pOSUserSettingModel.DefaultNoteType.toString()]}
                        multiselectRef={undefined}
                    />
                    <SelectBox
                        labelName={getLabelName("Customer")}
                        source={customerList}
                        isSingleSelect={true}
                        errorText={formik.errors.Customer_ID}
                        onStatusChange={(e: any) => {
                            formik.values.Customer_ID = e.value;
                            pOSUserSettingModel.Customer_ID = e.value;
                        }}
                        selectedValues={[pOSUserSettingModel.Customer_ID.toString()]}
                        multiselectRef={undefined}
                    />
                    <SelectBox
                        labelName={getLabelName("Employee")}
                        source={employeeList}
                        isSingleSelect={true}
                        errorText={formik.errors.Emp_ID}
                        onStatusChange={(e: any) => {
                            formik.values.Emp_ID = e.value;
                            pOSUserSettingModel.Emp_ID = e.value;
                        }}
                        selectedValues={[pOSUserSettingModel.Emp_ID.toString()]}
                        multiselectRef={undefined}
                    />
                    <TextBox
                        labelName={getLabelName("Wellcome Message")}
                        inputName={"WelcomeMessage"}
                        errorText={formik.errors.WelcomeMessage}
                        inputValue={formik.values.WelcomeMessage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextBox
                        labelName={getLabelName("Port Name")}
                        inputName={"PortName"}
                        errorText={formik.errors.PortName}
                        inputValue={formik.values.PortName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextBox
                        labelName={getLabelName("Discount Default Ration")}
                        inputName={"DefaultRatioDiscount"}
                        errorText={formik.errors.DefaultRatioDiscount}
                        inputValue={formik.values.DefaultRatioDiscount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />
                    <SelectBox
                        labelName={getLabelName("Currency")}
                        source={currencyList}
                        isSingleSelect={true}
                        errorText={formik.errors.Currency_ID}
                        onStatusChange={(e: any) => {
                            formik.values.Currency_ID = e.value;
                            pOSUserSettingModel.Currency_ID = e.value;
                        }}
                        selectedValues={[pOSUserSettingModel.Currency_ID.toString()]}
                        multiselectRef={undefined}
                    />
                </div>

                <Row className="mt-4">
                    <Col md="6" className="list-group-wrapper">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="AllowClickNew"
                                    name="AllowClickNew"
                                    checked={formik.values.AllowClickNew}
                                    label={getLabelName("Allow New")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="AllowDeleteItems"
                                    checked={formik.values.AllowDeleteItems}
                                    name="AllowDeleteItems"
                                    label={getLabelName("Allow Delete Item From Bill")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="AllowReturnWithoutBill"
                                    name="AllowReturnWithoutBill"
                                    checked={formik.values.AllowReturnWithoutBill}
                                    label={getLabelName("Allow Return ")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="Ask_MoneyBox_Station"
                                    checked={formik.values.Ask_MoneyBox_Station}
                                    name="Ask_MoneyBox_Station"
                                    label={getLabelName(
                                        "Ask For Money In Drawer On Close or Open Day"
                                    )}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableChangeCalcType"
                                    name="EnableChangeCalcType"
                                    checked={formik.values.EnableChangeCalcType}
                                    label={getLabelName("Enable Change CalcT ype")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableCloseDay"
                                    checked={formik.values.EnableCloseDay}
                                    name="EnableCloseDay"
                                    label={getLabelName("Close Day")}
                                />
                            </li>
                        </ul>
                    </Col>
                    <Col md="6" className="list-group-wrapper">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnablePrintSaleReportAndPrintCloseDay"
                                    name="EnablePrintSaleReportAndPrintCloseDay"
                                    checked={formik.values.EnablePrintSaleReportAndPrintCloseDay}
                                    label={getLabelName(
                                        "Enable Print Sales Report And Print Close Day"
                                    )}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnablePrintSavedTransaction"
                                    checked={formik.values.EnablePrintSavedTransaction}
                                    name="EnablePrintSavedTransaction"
                                    label={getLabelName("Print Previous Bill")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="PrintInvoiceInA4"
                                    checked={formik.values.PrintInvoiceInA4}
                                    name="PrintInvoiceInA4"
                                    label={getLabelName("Use A4 Invoice Print")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowItemForSecondScreenCustomerDisplay"
                                    checked={formik.values.ShowItemForSecondScreenCustomerDisplay}
                                    name="ShowItemForSecondScreenCustomerDisplay"
                                    label={getLabelName(
                                        "Show Item On Second Screen Customer Display"
                                    )}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="UseCustomerDisplay"
                                    name="UseCustomerDisplay"
                                    checked={formik.values.UseCustomerDisplay}
                                    label={getLabelName("Customer Display")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="UseItemImageAsBackGround"
                                    checked={formik.values.UseItemImageAsBackGround}
                                    name="UseItemImageAsBackGround"
                                    label={getLabelName("Use Item Image As BackGround")}
                                />
                            </li>
                        </ul>
                    </Col>
                </Row>
                <div className="col-md-12 d-flex justify-content-end mt-3">
                    <ButtonBox type="submit" size="sm" variant="primary" className="mx-3" iconType="content-save">
                        {getLabelName("save")}
                    </ButtonBox>
                    <ButtonBox
                    iconType="close-circle"
                        size="sm"
                        variant="danger"
                        type="button"
                        onClick={handleReset}
                    >
                        {getLabelName("close")}
                    </ButtonBox>
                </div>
            </form>
        </>
    );
    //#endregion
};
