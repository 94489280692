import React, {Dispatch, FC, SetStateAction} from "react";
import {PaymentValueDetailsModel, PaymentTypeItemsModel} from "../../models";
import {isArabicCurrentLanguage, updateStateDynamically} from "../../utils";
import {ButtonBox} from "..";

export const PaymentTypeDetails: FC<{
    clickedPaymentType: string;
    setShowPaymentTypeDetailsModel: Dispatch<SetStateAction<boolean>>;
    paymentTypeItems: PaymentTypeItemsModel[];
    paymentValues: PaymentValueDetailsModel;
    setPaymentValues: Dispatch<SetStateAction<PaymentValueDetailsModel>>;
}> = ({
          clickedPaymentType,
          setShowPaymentTypeDetailsModel,
          paymentTypeItems,
          paymentValues,
          setPaymentValues,
      }) => {
    //#region variables
    const isArabic: boolean = isArabicCurrentLanguage();
    //#endregion
    //#region functions
    const handleClickPaymentItem = async (subTypeId: number) => {
        let paymentTypeObj = {key: "", value: 0};
        // @ts-ignore
        const value = Number(paymentValues[clickedPaymentType]) > 0 ? Number(paymentValues[clickedPaymentType]) : paymentValues.remaining;
        paymentTypeObj = {key: clickedPaymentType, value: value};
        await updateStateDynamically(setPaymentValues, paymentValues, null, null, [
            {key: "paymentTypeId", value: subTypeId},
            {
                key: "remaining",
                value:
                    paymentTypeObj.value === paymentValues.remaining
                        ? 0
                        : paymentValues.remaining,
            },
            paymentTypeObj,
        ]);
        setShowPaymentTypeDetailsModel(false);
    };
    //#endregion
    //#region style
    const cardItem = {
        borderRadius: "8px",
        border: "1px solid #ccc",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "150px",
    };
    const cardBtn = {
        width: "100%",
        minHeight: "150px",
        fontSize: "18px",
        fontWeight: "bold",
        background:
            "linear-gradient(90deg, rgb(42 81 103) 0%, rgb(45 100 135) 100%)",
    };
    //#endregion
    //#region html
    return (
        <div className="row" style={{minHeight: "500px"}}>
            {paymentTypeItems.map((item, index) => (
                <div className="col-md-4 mb-3" key={index}>
                    <div style={cardItem}>
                        <ButtonBox
                            style={cardBtn}
                            value={item.ID}
                            onClick={() => handleClickPaymentItem(item.ID)}
                        >
                            {isArabic ? item.ArabicName : item.EnglishName}
                        </ButtonBox>
                    </div>
                </div>
            ))}
        </div>
    );
    //#endregion
};
