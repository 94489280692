import {FallbackRenderPropsModel} from "../../../models";
import {RoutePageConfigurations} from "../../../configuration";
import {ButtonBox} from "../..";

export const FallbackErrorBoundaryRender = ({error}: FallbackRenderPropsModel) => {
    //#region html
    return (
        <>
        <div className="content-wrapper d-flex align-items-center auth center-box">
            <div className="row flex-grow">
            <div className="col-lg-4 mx-auto" role="alert">
                <div className="auth-form-light text-center p-5">
                <i className="mdi mdi-alert-circle-outline fs-1"></i>
                <h4 className="alert-title"> Something went wrong </h4>
                <p className="font-weight-light">{error.message}</p>
                <p className="font-weight-light">{error.name}</p>
                <ButtonBox iconType="refresh" onClick={() => {
                    window.location.reload()
                }} className="mx-3">Reload Page</ButtonBox>
                <ButtonBox iconType="home-variant" onClick={() => {
                    window.location.href = RoutePageConfigurations.homePage
                }}>Home Page</ButtonBox>
                </div>
            </div>
            </div>
        </div>
        </>
    );
    //#endregion
}
