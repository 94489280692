import {useEffect, useState} from "react";
import {LookupItemModel, LookupTypeEnum} from "../../models";
import {getLookUp} from "../../components/transactions/common/businessLogic/transactionBl";

const useStoredAccountTypes = () => {
    const [storedAccountTypes, setStoredAccountTypes] = useState<LookupItemModel[]>(
        []
    );
    const [loading, setIsLoading] = useState(false);
    const fetchAccountTypes = async () => {
        setIsLoading(true);
        const fetchedAccountTypes = await getLookUp(
            [setStoredAccountTypes],
            LookupTypeEnum.AccountType,
            false,
            false
        );
        setStoredAccountTypes(fetchedAccountTypes);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchAccountTypes().then(() => {
        });
    }, []);

    return {
        storedAccountTypes,
        accountTypesLoading: loading,
    };
};
export default useStoredAccountTypes;
