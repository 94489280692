import { FC, useEffect, useState } from "react";
import {
  CompanySettingDetails,
  ElectronicBillSetting,
  LoadingBox,
  LoyaltySetting,
  TabView,
  ToastBox,
} from "../../components";
import {
  CompanySettingModel,
  ElectronicBillSettingModel,
  LoyaltySettingModel,
  ToastModel,
} from "../../models";
import {
  getActiveVouchersSettings,
  getCompanySetting,
  getEgyptInvoiceSettings,
} from "../../serviceBroker/companySettingApiServiceBroker";
import { getLabelName } from "../../utils";

export const CompanySettingPage: FC<{ formId: number }> = ({ formId }) => {
  //#region state
  const [loading, setLoading] = useState(false);
  const [showToastModel, setShowToastModel] = useState(false);
  const [setting, setSetting] = useState<CompanySettingModel | null>();
  const [loyaltySetting, setLoyaltySetting] =
    useState<LoyaltySettingModel | null>();
  const [electronicBillSetting, setElectronicBillSetting] =
    useState<ElectronicBillSettingModel | null>();
  const [toastModel, setToastModel] = useState<ToastModel>({ show: false });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getSetting();
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const getSetting = async () => {
    setLoading(true);
    const settingDetails = await getCompanySetting();
    setSetting(settingDetails);
    const loyaltySettings = await getActiveVouchersSettings();
    setLoyaltySetting(loyaltySettings);
    const electronicBillSetting = await getEgyptInvoiceSettings();
    setElectronicBillSetting(electronicBillSetting);
    setLoading(false);
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {showToastModel && (
        <ToastBox
          isShown={showToastModel}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setShowToastModel(false);
          }}
        />
      )}
      {setting && (
        <TabView
          tabs={[
            {
              name: getLabelName("CompanySettingDetails"),
              content: (
                <CompanySettingDetails
                  request={setting}
                  onComplete={async (e: any) => {
                    if (e == true) {
                      setShowToastModel(true);
                    }
                    await getSetting();
                  }}
                  formId={formId}
                />
              ),
            },
            {
              name: getLabelName("LoyaltySetting"),
              content: (
                <LoyaltySetting
                  request={loyaltySetting}
                  onComplete={async (e: any) => {
                    if (e == true) {
                      setShowToastModel(true);
                    }
                    await getSetting();
                  }}
                />
              ),
            },
            {
              name: getLabelName("Electronic Bill"),
              content: (
                <ElectronicBillSetting
                  request={electronicBillSetting}
                  onComplete={async (e: any) => {
                    if (e == true) {
                      setShowToastModel(true);
                    }
                    await getSetting();
                  }}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
  //#endregion
};
