export const RoutePageConfigurations = {
    defaultPage: "/default",
    homePage: "/",
    notFoundPage: "/404",
    unAuthorizedPage: "/401",
    forbiddenPage: "/403",
    dashboardPage: "/dashboard",
    logoutRedirectPage: "/logoutRedirect",
    userPage: "/users",
    userPermissionsPage: "/user/permissions",
    companySettingPage: "/company/setting",
    itemPage: "/items",
    branchPage: "/BranchPage",
    currentBranchPage: "/branch/currentBranchPage",
    salesInvoicePage: "/transactions/sales/invoice",
    purchaseBillPage: "/transactions/purchases/invoice",
    salesReturnPage: "/transactions/sales/return",
    purchasesReturnPage: "/transactions/purchases/return",
    requestOutPage: "/transactions/RequestOut/request",
    showPricePage: "/transactions/ShowPrice/price",
    posSalesPage: "/PosSales",
    storeTransferPage: "/transactions/storetransfer",
    moneyOutPage: "/moneyOutPage",
    moneyInPage: "/moneyInPage",
    employeeDue: "/EmployeeDue",
    employeeSubtract: "/EmployeeSubtract",
    supplierPaymentsPage: "/supplierPaymentsPage",
    customerPaymentPage: "/customerPaymentPage",
    refundSupplierPaymentsPage: "/refundSupplierPaymentsPage",
    updateTaxCategoryPage: "/UpdateTaxCategoryPage",
    ruleExpenseMoneyPage: "/ruleExpenseMoneyPage",
    ruleReceiveMoneyPage: "/RuleRecieveMoneyPage",
    estihkaksubtractRulePage: "/EstihkaksubtractRulePage",
    estihkaksubtractExpenseRulePage: "/EstihkaksubtractExpenseRulePage",
    unitPage: "/units",
    employeePage: "/employees",
    employeeDueRulesPage: "/EmployeeDueRules",
    employeeSubtractRulesPage: "/EmployeeSubtractRules",
    currencyPage: "/currency",
    categoryPage: "/Categories",
    customerPage: "/customers",
    supplierPage: "/suppliers",
    storePage: "/storePage",
    inventoryCheckingPage: "/InventoryChecking",
    cityPage: "/lookup/cities",
    countryPage: "/lookup/countries",
    costCenterPage: "/lookup/costCenters",
    additionAndRecommendationPage: "/lookup/restaurants/additionAndRecommendation",
    gradePage: "/lookup/grades",
    nationalityPage: "/lookup/nationalities",
    religionPage: "/lookup/religions",
    socialStatusPage: "/lookup/socialStatus",
    employeeStatusPage: "/lookup/employees/status",
    orderStatusPage: "/lookup/orders/status",
    carTypePage: "/lookup/cars/types",
    carColorPage: "/lookup/cars/colors",
    carServicePage: "/lookup/cars/services",
    costAdditionalPage: "/lookup/costs/additional",
    journalVoucherPage: "/JournalVoucherPage",
    acBeginBalancePage: "/AcBeginBalancePage",
    accountTreePage: "/AccountTreePage",
    closeDayPage: "/closeDayPage",
    acDailyBoxPage: "/AcDailyBoxPage",
    calcTypePage: "/CalcType",
    applicationDeliveryPage: "/ApplicationDeliveryPage",
    paymentTypePage: "/paymentType",
    dictionaryPage: "/Dictionary",
    restaurantTablePage: "/resturantTablePage",
    reportFollowItemBalancePage: "/reportFollowItemBalancePage",
    reportItemsReachedToLimitOrderPage: "/ReportItemsReatchToLimitOrderPage",
    reportBestLeastSellingItemsPage: "/reportBestLeastSellingItemsPage",
    reportExpiryDateOfItemsPage: "/reportExpiryDateOfItemsPage",
    reportSalesAndPurchaseInGraphPage: "/ReportSalesAndPurchaseInGraph",
    reportProfitSalesInGraphPage: "/ReportProfitSalesInGraph",
    reportEmployeeEsthkakSubtractsPage: "/reportEmployeeEsthkakSubtractsPage",
    reportItemsDifferentInVatCalculationPage: "/reportItemsDiffrentInVatCalculationPage",
    reportEmployeeSalesCommissionPage: "/ReportEmployeeSalesCommissionPage",
    reportEmployeeSalesPage: "/reportEmplyeeSalesPage",
    reportStationsPage: "/reportStationsPage",
    reportOrderStatusLogPage: "/reportOrderStatusLogPage",
    reportDebitCustomerPage: "/reportDebitCustomerPage",
    reportDebitSupplierPage: "/reportDebitSupplierPage",
    reportCustomersTransactionAndAcTransactionPage: "/reportCustomersTransactionAndAcTransaqctionPage",
    reportSuppliersTransactionAndAcTransactionPage: "/reportSuppliersTransactionAndAcTransaqctionPage",
    reportGeneralLedgerPage: "/reportGeneralLedgerPage",
    reportBalanceSheetPage: "/reportBalanceSheetPage",
    reportIncomeStatementPage: "/reportIncomeStatmentPage",
    reportInventoryCheckingPage: "/reportInventoryCheckingPage",
    reportStagnantItemsPage: "/reportStagnantItemsPage",
    reportItemDataPage: "/reportItemDataPage",
    reportItemsDifferenceInCostPage: "/reportItemsDifferenceInCostPage",
    reportProductionItemsDataPage: "/reportProductionItemsDataPage",
    reportEditUserItemDataPage: "/reportEditUserItemDataPage",
    reportSummeryItemTransactionPage: "/reportSummeryItemTransactionPage",
    reportSummeryItemTransactionQuantityPage: "/reportSummeryItemTransactionQuantityPage",
    reportSummeryItemTransactionQuantityWithValuePage: "/reportSummeryItemTransactionQuantityWithValuePage",
    reportCustomersDataPage: "/reportCustomersData",
    reportCustomersPaymentsPage: "/reportsCustomersPayments",
    reportsCustomersAccountsPage: "/reportsCustomersAccounts",
    reportAllCustomersTransactionsPage: "/reportAllCustomersTransactions",
    reportSuppliersDataPage: "/reportSuppliersData",
    reportAllSuppliersTransactionsPage: "/reportAllSuppliersTransactions",
    reportSuppliersPaymentsPage: "/reportSuppliersPayments",
    reportSuppliersAccountPage: "/reportSuppliersAccount",
    reportSalesTransactionsPage: "/salesTransactionsReport",
    reportReturnSalesTransactionsPage: "/returnSalesTransactionsReport",
    reportAllSalesAndReturnTransactionsPage: "/allSalesAndReturnTransactionsReport",
    reportPurchaseTransactionsPage: "/purchaseTransactionsReport",
    reportReturnPurchaseTransactionsPage: "/returnPurchaseTransactionsReport",
    reportCashierPage: "/reportCashier",
    reportEmployeeSalesV2Page: "/reportEmployeeSales",
    reportVatPage: "/reportVatPage",
    reportProfitOfSalePage: "/reportProfitOfSalePage",
    reportInMoneyPage: "/reportInMoneyPage",
    reportOutMoneyPage: "/reportOutMoneyPage",
    reportInAndOutMoneyPage: "/reportInAndOutMoneyPage",
    reportAllInAndOutMoneyPage: "/reportAllInAndOutMoneyPage",
    restaurantPosPage: "/PosResrurant",

}