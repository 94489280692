import {AccountResponseResultModel, RowStateEnum} from "../../../models";
import {getUserId} from "../../../utils";

export const initialAccountInfo: AccountResponseResultModel = {
    Code: "",
    AccountType_ID: 1,
    Orders: 0,
    ArabicName: "",
    EnglishName: "",
    Parent_ID: null,
    AllParent: "",
    RegisterInTrans: true,
    Active: true,
    Notes: "",
    BeginBalance: 0,
    Total_Money: 0,
    Money_Pay: 0,
    Balance: 0,
    ShowToUser: true,
    TotalDebit: 0,
    TotalCredit: 0,
    NatureType: 1,
    IsParent: false,
    IsShowInCostCenter: true,
    BranchID: 0,
    IsSkipValidateOnSave: false,
    CreatedBy: getUserId(),
    Name: null,
    VerifyOnUpdate: false,
    rowState: RowStateEnum.Add,
    Account_UniqueID: "",
    AccountTypeId: null,
    BalanceInCurrency: 0,
    CreationDate: "",
    ID: null,
    ModificationDate: null,
    ModifiedBy: 0,
    DateCreate: null,
};

export const initialFormValues = {
    Code: "",
    AccountType_ID: 1,
    Orders: 0,
    ArabicName: "",
    EnglishName: "",
    rowState: 0,
    NatureType: 1,
    Notes: "",
    IsShowInCostCenter: false,
    Balance: 0,
};
