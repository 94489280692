import {FC} from "react";
import {EmployeeDueAndSubtractRules} from "../../components";
import {PageEnum} from "../../models";

export const EmployeeSubtractRulesPage: FC<{ formId: number }> = ({
                                                                      formId = PageEnum.RuleSubtract,
                                                                  }) => {
    //#region html
    return (
        <EmployeeDueAndSubtractRules formId={formId}/>
    );
    //#endregion
};
