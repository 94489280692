import React, { FC, useEffect, useState } from "react";
import {
  ActionButtonsModel,
  LookupItemModel,
  LookupTypeEnum,
  ruleExpenseMoneySearchModel,
  SearchRuleExpenseMoneyRequestModel,
  PageEnum,
  RuleExpRecipeAccountsEnum,
} from "../../models";

import { ModelDialogBox,ConfirmModelDialogBox,PrivilegesChecker,RuleExpenseSearchPanel,RegisterRuleExpenseMoney,RuleExpenseMoneyList } from "..";
import { getLabelName, getUserId } from "../../utils";
import {
  deleteAcTransaction,
} from "../../serviceBroker/acTransactionsApiServiceBroker";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import {
  searchRuleExpenseMoneyAccounts,
  searchRuleReceiveMoneyAccounts,
} from "../../serviceBroker/accountApiServiceBroker";

export const RuleExpenseCompo: FC<{
  acTransactionType: RuleExpRecipeAccountsEnum;
  formId: PageEnum;
}> = ({ acTransactionType, formId }) => {
  const defaultPageSize: number = 50;
  const acTransactionInitValue: ruleExpenseMoneySearchModel = {
    Code: "",
    AccountType_ID: 0,
    Orders: 0,
    ArabicName: "",
    EnglishName: "",
    Parent_ID: 0,
    AllParent: "",
    RegisterInTrans: false,
    Active: true,
    Notes: "",
    BeginBalance: 0,
    Total_Money: 0,
    Money_Pay: 0,
    Balance: 0,
    ShowToUser: true,
    DateCreate: new Date(),
    BalanceInCurrency: 0,
    Account_UniqueID: "",
    TotalDebit: 0,
    TotalCredit: 0,
    NatureType: 0,
    IsParent: true,
    IsShowInCostCenter: false,
    AccountTypeId: 0,
    BranchID: 0,
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    Name: "",
    CreationDate: new Date(),
    ModificationDate: new Date(),
    VerifyOnUpdate: false,
    rowState: 1,
  };
  let searchPanelHeader = "";
  switch (+acTransactionType) {
    case RuleExpRecipeAccountsEnum.Expense:
      searchPanelHeader = "Expense Money Account";
      acTransactionInitValue.AccountType_ID = 1;
      break;
    case RuleExpRecipeAccountsEnum.Receive:
      searchPanelHeader = "Receive Money Account";
      acTransactionInitValue.AccountType_ID = 2;
      break;
    default:
  }

  const [loading, setLoading] = useState(false);
  const [showAcTransactionModel, setShowAcTransactionModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState({
    show: false,
    id: 0,
  });
  const [showPrintModel, setShowPrintModel] = useState({
    show: false,
    content: "",
  });

  const [totalRows, setTotalRows] = useState(0);

  const [searchParams, setSearchParams] =
    useState<SearchRuleExpenseMoneyRequestModel>({
      pageNumber: 1,
      pageSize: defaultPageSize,
      accountId: 0,
    });
  const [acTransaction, setAcTransaction] =
    useState<ruleExpenseMoneySearchModel>(acTransactionInitValue);
  const [customerAccountLookups, setCustomerAccountLookups] = useState<
    LookupItemModel[]
  >([]);
  const [allAccountLookups, setAllAccountLookups] = useState<LookupItemModel[]>(
    []
  );
  const [suppliersAccountLookups, setSuppliersAccountLookups] = useState<
    LookupItemModel[]
  >([]);
  const [userLookUps, setUserLookUps] = useState<LookupItemModel[]>([]);
  const [coastCenterLookUps, setCoastCenterLookUps] = useState<
    LookupItemModel[]
  >([]);
  const [currencyLookUps, setCurrencyLookUps] = useState<LookupItemModel[]>([]);
  const [allTreasuryInAccount, setAllTreasuryInAccount] = useState<
    LookupItemModel[]
  >([]);
  const [allTreasuryOutAccount, setAllTreasuryOutAccount] = useState<
    LookupItemModel[]
  >([]);
  const [acTransactionSearch, setAcTransactionSearch] = useState<
    SearchRuleExpenseMoneyRequestModel[]
  >([]);

  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await handelSearch();
      setLoading(false);
    };
    fillData();
  }, []);

  const getAllLookups = async () => {
    const allAccounts = await getLookupByType(
      LookupTypeEnum.AllAccounts,
      true,
      true
    );

    setAllAccountLookups(allAccounts);
  };

  const handelSearch = async () => {
    setLoading(true);
    let response;

    if (acTransactionType == RuleExpRecipeAccountsEnum.Expense) {
      response = await searchRuleExpenseMoneyAccounts(searchParams);
    } else {
      response = await searchRuleReceiveMoneyAccounts(searchParams);
    }

    if (response.Result != undefined) {
      setAcTransactionSearch(response.Result);
    } else {
      setAcTransactionSearch([]);
    }
    if (response.TotalRecoredCount !== undefined) {
      setTotalRows(response.TotalRecoredCount);
    } else {
      setTotalRows(0);
    }
    setLoading(false);
  };

  const onCurrentPageChange = async (pageNumber: number) => {
    if (pageNumber !== searchParams.pageNumber) {
      const obj = { ...searchParams };
      obj.pageNumber = pageNumber;
      setSearchParams(obj);
      await handelSearch();
    }
  };
  const onPageSizeChange = async (pageSize: number) => {
    if (pageSize !== searchParams.pageSize) {
      const obj = { ...searchParams };
      obj.pageSize = pageSize;
      setSearchParams(obj);
      await handelSearch();
    }
  };

  const handleSaveEmployeeComplete = async (isSaveComplete: boolean) => {
    if (isSaveComplete) {
      setShowAcTransactionModel(false);

      await handelSearch();
    } else {
      setShowAcTransactionModel(false);
      setAcTransaction(acTransactionInitValue);
    }
  };
  const deleteEmployeeActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        setLoading(true);
        await deleteAcTransaction(showDeleteModel.id, getUserId());
        await handelSearch();
        setShowDeleteModel({ show: false, id: 0 });
        setLoading(false);
      },
    },
    {
      text: getLabelName("No"),
      onClick: () => {
        setShowDeleteModel({ show: false, id: 0 });
      },
    },
  ];

  return (
    <>
      <PrivilegesChecker formId={formId} action="EnableSearch">
        <RuleExpenseSearchPanel
          acTransactionType={acTransactionType}
          header={searchPanelHeader}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          handelSearch={async (e) => handelSearch()}
          customerAccountLookups={customerAccountLookups}
          allTreasuryOutAccount={allTreasuryOutAccount}
          allTreasuryInAccount={allTreasuryInAccount}
          setShowAcTransactionModel={setShowAcTransactionModel}
          userLookUps={userLookUps}
        />
      </PrivilegesChecker>

      <RuleExpenseMoneyList
        acTransactionSearch={acTransactionSearch}
        totalRows={totalRows}
        searchParams={searchParams}
        defaultPageSize={defaultPageSize}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        setShowAcTransactionModel={setShowAcTransactionModel}
        setAcTransaction={setAcTransaction}
        setShowDeleteModel={setShowDeleteModel}
        setShowPrintModel={setShowPrintModel}
        setLoading={setLoading}
      />

      <ModelDialogBox
        isModelVisible={showAcTransactionModel}
        isCloseButtonVisible={false}
        size="xl"
      >
        <RegisterRuleExpenseMoney
          searchPanelHeader={searchPanelHeader}
          acTransactionType={acTransactionType}
          acTransaction={acTransaction}
          setLoading={setLoading}
          customerAccountLookups={customerAccountLookups}
          handleSaveEmployeeComplete={handleSaveEmployeeComplete}
          currencyLookUps={currencyLookUps}
          coastCenterLookUps={coastCenterLookUps}
          suppliersAccountLookups={suppliersAccountLookups}
        />
      </ModelDialogBox>
      <ConfirmModelDialogBox
        isModelVisible={showDeleteModel.show}
        onCloseEvent={() => {
          setShowDeleteModel({ show: false, id: 0 });
        }}
        actions={deleteEmployeeActions}
      >
        <>Are you sure?</>
      </ConfirmModelDialogBox>
    </>
  );
};
