import {TableColumn} from "react-data-table-component";
import {AccountResponseResultModel, PageEnum} from "../../../models";
import {getLabelName} from "../../../utils";
import {ButtonBox, PrivilegesChecker} from "../../index";

interface AccountHandler {
    handleDeletion: (id: number) => void;
    handleEdit: (id: number | null) => void;
}

const setAccountsTable: (
    handlers: AccountHandler
) => TableColumn<AccountResponseResultModel>[] = ({
                                                 handleDeletion,
                                                 handleEdit,
                                             }) => {
    const accountsColumns: TableColumn<AccountResponseResultModel>[] = [
        {
            name: getLabelName("code"),
            selector: (row) => row.Code,
            sortable: true,
        },
        {
            name: getLabelName("ArabicName"),
            selector: (row) => {
                return row.ArabicName;
            },
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("EnglishName"),
            selector: (row) => row.EnglishName,
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },

        {
            cell: (row) => (
                <div>
                    <PrivilegesChecker formId={PageEnum.AccountsTree} action="EnableUpdate">
                        <ButtonBox
                        iconType="pencil-box"
                            id="deleteVoucher"
                            variant="info"
                            className="btn btn-info"
                            onClick={() => handleEdit(row.ID)}
                        >
                            {getLabelName("edit")}
                        </ButtonBox>
                    </PrivilegesChecker>
                    <PrivilegesChecker formId={PageEnum.AccountsTree} action="EnableDelete">
                        <ButtonBox
                        iconType="delete"
                            style={{marginInlineStart: "10px"}}
                            id="deleteVoucher"
                            variant="danger"
                            className="btn btn-danger"
                            onClick={() => handleDeletion(row.ID as number)}
                        >
                            {getLabelName("Delete")}
                        </ButtonBox>
                    </PrivilegesChecker>
                </div>
            ),
        },
    ];

    return accountsColumns;
};

export default setAccountsTable;
