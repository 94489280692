import React, {createContext, ReactNode, useContext, useState} from "react";

// Define the context type
interface SidebarContextType {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

// Create the context with a default value
const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // const [isOpen, setIsOpen] = useState<boolean>(() => {
    //     const savedState =LocalStorageGet(SystemConfiguration.keys.sidebarIsOpen);// localStorage.getItem('sidebarIsOpen');
    //     return savedState ? JSON.parse(savedState) : false;
    // });
    //
    // // Save state to localStorage whenever it changes
    //     useEffect(() => {
    //         LocalStorageSet(SystemConfiguration.keys.sidebarIsOpen, JSON.stringify(isOpen));
    //     //localStorage.setItem('sidebarIsOpen', JSON.stringify(isOpen));
    //     }, [isOpen]);


    const [isOpen, setIsOpen] = useState(false);
    return (
        <SidebarContext.Provider value={{ isOpen, setIsOpen }}>
            {children}
        </SidebarContext.Provider>
    );
};

// Custom hook to use the sidebar context
export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error("useSidebar must be used within a SidebarProvider");
    }
    return context;
};