import {searchReportEmployeeSales} from "../../../serviceBroker/transationReportApiServiceBroker";
import React, {FC} from "react";
import {PageEnum, TransactionReportSearchParamsModel} from "../../../models";
import {ReportPage, ReportTransactionSearchPanel} from "../../../components";
import {getPageNameByPageId} from "../../../utils";

export const ReportEmployeeSales: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportEmployeeSales}
            searchHeader={getPageNameByPageId(PageEnum.ReportEmployeeSales)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportEmployeeSales}
        />
    );
};
