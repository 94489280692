import {Dispatch, SetStateAction} from "react";
import {KeyValueStateModel} from "../../models";

export const updateStateDynamically = async (
    setState: Dispatch<SetStateAction<any>>,
    stateValue: any,
    key?: string | null,
    value?: any | null,
    keyValues?: KeyValueStateModel[] | null,
) => {
    if (value !== null && value !== undefined && key !== null && key !== undefined) {
        setState({
            ...stateValue,
            [key]: value,
        });
    }
    if (keyValues !== null && keyValues !== undefined && keyValues.length !== 0) {
        keyValues.forEach((row) => {
            stateValue = {...stateValue, [row.key]: row.value}
        });
        setState({...stateValue})
    }
};
