import {SystemConfiguration} from "../../configuration";
import {DictionaryModel, LookupItemModel, LookupKeyValueModel, ResponseBaseModel} from "../../models";
import {isArabicCurrentLanguage,SecureLocalStorageGet} from "..";
import {v4 as uuidv4} from "uuid";
import React from "react";

export const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
export const generateGuid = (): string => {
    return uuidv4();
};
export const getLabelName = (value: string): string => {
    try {
        value = value.toLowerCase();
        let dictionariesList: DictionaryModel[] = JSON.parse(
            // @ts-ignore
            SecureLocalStorageGet(SystemConfiguration.keys.dictionary)
        ); //.filter((p: any) => p.Name === value)[0];
        const record = dictionariesList.filter((p: any) => p.Name === value)[0];
        if (record !== null && record !== undefined) {
            return isArabicCurrentLanguage() ? record.NameAr : record.NameEn;
        } else {
            // saveNotExistTranslation(value, 3).then((result) => {
            //   if (result !== null) {
            //     dictionariesList.push(result);
            //     SecureLocalStorageSet(
            //       SystemConfiguration.keys.dictionary,
            //       JSON.stringify(dictionariesList)
            //     );
            //   }
            // });
        }
        // return data !== null && data !== undefined
        //   ? isArabicCurrentLanguage()
        //     ? data.NameAr
        //     : data.NameEn
        //   : value;
    } catch {
    }
    return value;
};
export const scrollToTop = (ref: React.MutableRefObject<HTMLInputElement>) => {
    ref.current?.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "start",
    });
    window.scrollTo(0, 0);
};
export const scrollToDocumentTop = () => {
    document.documentElement.scrollTop = 0; // For modern browsers
    document.body.scrollTop = 0; // For older browsers
};
export const getLookUpItemValue = (
    request?: LookupItemModel | null
): string | null => {
    return request !== null &&
    request !== undefined &&
    request.value !== null &&
    request.value !== undefined
        ? request.value
        : null;
};
export const getLookUpItemNumericValue = (
    request?: LookupItemModel | null
): number | null => {
    const value: string | null =
        request !== null &&
        request !== undefined &&
        request.value !== null &&
        request.value !== undefined
            ? request.value
            : null;
    return value !== null && !isNaN(parseFloat(value)) ? Number(value) : null;
};
export const getValueFromLookupKeyValue=(array: LookupKeyValueModel[], keyToSearch: string): string | null =>{
    if (!keyToSearch) return null;
    keyToSearch = keyToSearch.toLowerCase();
    const foundItem = array.find(item => item.key.toLowerCase() === keyToSearch);
    return foundItem?.value || null;
}
export function getResultFromResponse<T>(
    obj: ResponseBaseModel<T> | null | undefined
): T | null {
    if (
        obj !== null &&
        obj !== undefined &&
        obj.Result !== null &&
        obj.Result !== undefined
    ) {
        if (Array.isArray(obj.Result)) {
            if (obj.Result.length != 0) {
                return obj.Result as T;
            } else {
                return null;
            }
        } else if (obj.Result !== null) {
            return obj.Result as T;
        }
    }
    return null;
}
//#region url-Params
export const urlParamsFormatValue = (value: string | Date | number | null | undefined): string => {
    if (value instanceof Date) {
        return value.toISOString(); // Convert date to ISO string
    }
    if (value === null || value === undefined) {
        return ''; // Return empty string for null or undefined
    }
    return value.toString(); // Convert number or string to string
};
export const updateUrlWithParam = (url:string,key: string, value: string | Date | number | null | undefined):string => {
    if (value === null || value === undefined || value === '') {
        return url
    }

    const formattedValue = urlParamsFormatValue(value);
    const newParam = `${key}=${encodeURIComponent(formattedValue)}`;
    return url.includes('?')? `${url}&${newParam}`: `${url}?${newParam}`;
    ////Update the URL without reloading the page
    //window.history.pushState({ path: newUrl }, '', newUrl);
};
//#endregion
