import {useState} from "react";
import {AccountResponseResultModel} from "../../models";
import {getAccountInfoById} from "../../serviceBroker/accountApiServiceBroker";

const useAccountInfo = (initialAccount: AccountResponseResultModel) => {
    const [currentAccountInfo, setCurrentAccountInfo] =
        useState<AccountResponseResultModel>(initialAccount);

    const [isLoading, setIsLoading] = useState(false);

    const resetCurrentAccountInfo = () => {
        setCurrentAccountInfo({
            ...initialAccount,
            ID: null,
        });
    };

    const fetchAccountInfoByID = async (accountID: number | null) => {
        try {
            if (accountID === null) return setCurrentAccountInfo(initialAccount);
            setIsLoading(true);
            const fetchedAccountInfo = await getAccountInfoById(accountID);
            if (!fetchedAccountInfo.Result) return;
            setCurrentAccountInfo(fetchedAccountInfo.Result);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };
    // useEffect(() => {
    //   console.log(currentAccountInfo);
    //   if (currentAccountID === null) return setCurrentAccountInfo(initialAccount);
    //   fetchAccountInfoByID(currentAccountID);
    // }, [currentAccountID]);

    return {
        currentAccountInfo,
        isAccountInfoLoading: isLoading,
        resetCurrentAccountInfo,
        fetchAccountInfoByID,
    };
};

export default useAccountInfo;
