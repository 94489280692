import {useRestaurantTables} from "../../../../hooks";
import {
  LookupItemModel,
  TransactionDetailResponseModel,
} from "../../../../models";
import { getReservedTransactions } from "../../../../serviceBroker/resturantApiServiceBroker";
import { LoadingBox,RestaurantTablesList } from "../../..";
import React from "react";

interface RestaurantTableListProps {
  table_id: number;
  onSelectTable: (
    tableID: number,
    table?: TransactionDetailResponseModel | null
  ) => void;
}

const RestaurantTables: React.FC<RestaurantTableListProps> = ({
  onSelectTable,
  table_id,
}) => {
  const {
    childrenTables,
    isLoading,
    setIsLoading,
    parentTables,
    setParentTableID,
  } = useRestaurantTables();
  const handleParentSelect = (table: LookupItemModel) => {
    setParentTableID(Number(table.value));
  };
  const handleChildSelect = async (table: LookupItemModel) => {
    const tableID = Number(table.value);
    setIsLoading(true);
    const tableReservation = await getReservedTransactions(tableID);
    setIsLoading(true);
    onSelectTable(tableID, tableReservation);
  };
  return (
    <LoadingBox isLoading={isLoading}>
      <RestaurantTablesList
        tables={parentTables}
        handleClick={handleParentSelect}
      />
      <RestaurantTablesList
        tables={childrenTables}
        handleClick={handleChildSelect}
      />
    </LoadingBox>
  );
};

export default RestaurantTables;
