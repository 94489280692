import _ from "lodash";
import { FC, useEffect, useState } from "react";
import {
  PermissionModel,
  PermissionTypeEnum,
  UserRegistrationResponseModel,
} from "../../models";
import {
  getUserPermission,
  saveUserPermissions,
} from "../../serviceBroker/userApiServiceBroker";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { LoadingBox, ButtonBox } from "..";

export const UserPermissions: FC<{
  userObject?: UserRegistrationResponseModel | null;
  onComplete?: any | null;
}> = ({ userObject, onComplete }) => {
  //#region variable
  const isArabic: boolean = isArabicCurrentLanguage();
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [userPermissionObject, setUserPermissionObject] = useState<
    PermissionModel[] | null
  >(null);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      // @ts-ignore
      const permissions = await getPermissions(userObject.ID);
      setUserPermissionObject(permissions);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const getPermissions = async (id: number): Promise<PermissionModel[]> => {
    let permissions = await getUserPermission(id, isArabic);
    if (
      permissions !== null &&
      permissions !== undefined &&
      permissions.length !== 0
    ) {
      return _.sortBy(permissions, "ID");
    }
    return [];
  };
  const getPermissionRecord = (
    data: PermissionModel[],
    id: number
  ): PermissionModel | null => {
    return _.find(data, (obj) => {
      return obj.ID === id;
    }) as PermissionModel;
  };
  const handleHorizontalChange = (id: number, checked: boolean) => {
    const data: PermissionModel[] = userPermissionObject as PermissionModel[];
    const record = getPermissionRecord(data, id);
    if (record != null) {
      record.EnableDelete = checked;
      record.EnableSave = checked;
      record.EnableSearch = checked;
      record.EnableUpdate = checked;
      record.IsEnabledForTablet = checked;
      _.remove(data, (obj) => {
        return obj.ID === record.ID;
      });
      data.push(record);
      setUserPermissionObject(_.sortBy(data, "ID"));
    }
  };
  const handleVerticalChange = (checked: boolean, type: PermissionTypeEnum) => {
    const data: PermissionModel[] = userPermissionObject as PermissionModel[];
    _.each(data, (o) => {
      switch (type) {
        case PermissionTypeEnum.Save:
          o.EnableSave = checked;
          break;
        case PermissionTypeEnum.Update:
          o.EnableUpdate = checked;
          break;
        case PermissionTypeEnum.Delete:
          o.EnableDelete = checked;
          break;
        case PermissionTypeEnum.Search:
          o.EnableSearch = checked;
          break;
        case PermissionTypeEnum.Tablet:
          o.IsEnabledForTablet = checked;
          break;
        case PermissionTypeEnum.All:
          o.EnableSave = checked;
          o.EnableUpdate = checked;
          o.EnableDelete = checked;
          o.EnableSearch = checked;
          o.IsEnabledForTablet = checked;
          break;
      }
    });
    setUserPermissionObject(_.sortBy(data, "ID"));
  };
  const handleCheckBoxChange = (id: number, type: PermissionTypeEnum) => {
    const data: PermissionModel[] = userPermissionObject as PermissionModel[];
    const record = getPermissionRecord(data, id);
    if (record != null) {
      switch (type) {
        case PermissionTypeEnum.Save:
          record.EnableSave = !record.EnableSave;
          break;
        case PermissionTypeEnum.Update:
          record.EnableUpdate = !record.EnableUpdate;
          break;
        case PermissionTypeEnum.Delete:
          record.EnableDelete = !record.EnableDelete;
          break;
        case PermissionTypeEnum.Search:
          record.EnableSearch = !record.EnableSearch;
          break;
        case PermissionTypeEnum.Tablet:
          record.IsEnabledForTablet = !record.IsEnabledForTablet;
          break;
      }
      _.remove(data, (obj) => {
        return obj.ID === record.ID;
      });
      data.push(record);
      setUserPermissionObject(_.sortBy(data, "ID"));
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {userPermissionObject != null && userPermissionObject.length !== 0 && (
        <>
          <div className="table-responsive mx-height-500">
            <table className="table table-xs table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th className="width-50">#</th>
                  <th></th>
                  <th>{getLabelName("permission Name")}</th>
                  <th></th>
                  <th>{getLabelName("Enable Save")}</th>
                  <th>{getLabelName("Enable Update")}</th>
                  <th>{getLabelName("Enable Delete")}</th>
                  <th>{getLabelName("Enable Search")}</th>
                  <th>{getLabelName("IsEnabledForTablet")}</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <div className="form-check m-0">
                      <input
                        type="checkbox"
                        id={`vertical_save`}
                        className="form-check-input m-0"
                        defaultChecked={false}
                        onChange={(o) => {
                          handleVerticalChange(
                            o.target.checked,
                            PermissionTypeEnum.Save
                          );
                        }}
                      ></input>
                      <label
                        className="form-check-label sr-only"
                        htmlFor={`vertical_save`}
                      >
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th>
                    <div className="form-check m-0">
                      <input
                        type="checkbox"
                        id={`vertical_update`}
                        className="form-check-input m-0"
                        defaultChecked={false}
                        onChange={(o) => {
                          handleVerticalChange(
                            o.target.checked,
                            PermissionTypeEnum.Update
                          );
                        }}
                      ></input>
                    </div>
                  </th>
                  <th>
                    <div className="form-check m-0">
                      <input
                        type="checkbox"
                        id={`vertical_delete`}
                        className="form-check-input m-0"
                        defaultChecked={false}
                        onChange={(o) => {
                          handleVerticalChange(
                            o.target.checked,
                            PermissionTypeEnum.Delete
                          );
                        }}
                      ></input>
                    </div>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      id={`vertical_search`}
                      className="form-check-input m-0"
                      defaultChecked={false}
                      onChange={(o) => {
                        handleVerticalChange(
                          o.target.checked,
                          PermissionTypeEnum.Search
                        );
                      }}
                    ></input>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      id={`vertical_tablet`}
                      className="form-check-input m-0"
                      defaultChecked={false}
                      onChange={(o) => {
                        handleVerticalChange(
                          o.target.checked,
                          PermissionTypeEnum.Tablet
                        );
                      }}
                    ></input>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userPermissionObject.map((row, index) => {
                  return (
                    <tr key={`permission-${index}`}>
                      <td>{index + 1}</td>
                      <td>{row.IDForm}</td>
                      <td>
                        <label>{row.Name}</label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`horizontal_${index}`}
                          className="form-check-input m-0"
                          defaultChecked={false}
                          onChange={(o) => {
                            handleHorizontalChange(row.ID, o.target.checked);
                          }}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`EnableSave_${index}`}
                          className="form-check-input m-0"
                          checked={row.EnableSave}
                          onChange={() => {
                            handleCheckBoxChange(
                              row.ID,
                              PermissionTypeEnum.Save
                            );
                          }}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`EnableUpdate${index}`}
                          className="form-check-input m-0"
                          checked={row.EnableUpdate}
                          onChange={() => {
                            handleCheckBoxChange(
                              row.ID,
                              PermissionTypeEnum.Update
                            );
                          }}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`EnableDelete${index}`}
                          className="form-check-input m-0"
                          checked={row.EnableDelete}
                          onChange={() => {
                            handleCheckBoxChange(
                              row.ID,
                              PermissionTypeEnum.Delete
                            );
                          }}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`EnableSearch${index}`}
                          className="form-check-input m-0"
                          checked={row.EnableSearch}
                          onChange={() => {
                            handleCheckBoxChange(
                              row.ID,
                              PermissionTypeEnum.Search
                            );
                          }}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          id={`IsEnabledForTablet${index}`}
                          className="form-check-input m-0"
                          checked={row.IsEnabledForTablet}
                          onChange={() => {
                            handleCheckBoxChange(
                              row.ID,
                              PermissionTypeEnum.Tablet
                            );
                          }}
                        ></input>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <ButtonBox
              iconType="content-save"
              size="sm"
              type="submit"
              variant="primary"
              className="btn btn-orange"
              onClick={async () => {
                setLoading(true);
                const data = userPermissionObject;
                _.each(data, (o) => {
                  o.rowState = 2;
                });
                await saveUserPermissions(data);
                setLoading(false);
                onComplete();
              }}
            >
              {getLabelName("save")}
            </ButtonBox>
            <ButtonBox
              iconType="close-circle"
              size="sm"
              type="button"
              variant="outline-primary"
              className="btn btn-orange"
              onClick={onComplete}
            >
              {getLabelName("cancel")}
            </ButtonBox>
          </div>
        </>
      )}
    </>
  );
  //#endregion
};
