import React, {FC} from "react";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {ReportItemsSearchPanel, ReportPage} from "../../../components";
import {ItemReportSearchParamsModel, PageEnum} from "../../../models";
import {searchReportSummeryItemTransactionQuantityWithValue} from "../../../serviceBroker/itemsReportApiServiceBroker";

export const ReportSummeryItemTransactionQuantityWithValuePage: FC = () => {
    const initialState: ItemReportSearchParamsModel = {lang: 1, userId: getUserId()};
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportSummeryItemTransactionQuantityWithValue}
            searchHeader={getPageNameByPageId(PageEnum.frmReportItemsBalanceWithValue)}
            SearchPanel={ReportItemsSearchPanel}
            formId={PageEnum.frmReportItemsBalanceWithValue}
        />
    );
};
