import React, {FC} from "react";
import {searchReturnSalesTransactionsReport} from "../../../serviceBroker/transationReportApiServiceBroker";
import {PageEnum, TransactionReportSearchParamsModel} from "../../../models";
import {ReportPage, ReportTransactionSearchPanel} from "../../../components";
import {getPageNameByPageId} from "../../../utils";

export const ReportReturnSalesTransactions: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReturnSalesTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportReturnSales)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportReturnSales}
        />
    );
};
