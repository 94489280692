import React, {FC} from "react";
import {ReportPage, ReportTreasurySearchPanel} from "../../../components";
import {PageEnum, TreasuryReportSearchParamsModel} from "../../../models";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {searchReportOutMoney} from "../../../serviceBroker/treasuryReportApiServiceBroker";

export const ReportOutMoneyPage: FC = () => {
    const initialState: TreasuryReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportOutMoney}
            searchHeader={getPageNameByPageId(PageEnum.ReportOutMoney)}
            SearchPanel={ReportTreasurySearchPanel}
            formId={PageEnum.ReportOutMoney}
        />
    );
};
