import {useEffect, useState} from "react";
import {LookupItemModel, LookupTypeEnum} from "../../models";
import {getLookUp} from "../../components/transactions/common/businessLogic/transactionBl";

const useStoredEmployees = () => {
    const [storedEmployeeList, setStoreEmployeeList] = useState<LookupItemModel[]>([]);
    const [loading, setIsLoading] = useState(false);
    const fetchEmployees = async () => {
        setIsLoading(true);
        const fetchedEmployees = await getLookUp(
            [setStoreEmployeeList],
            LookupTypeEnum.Employee,
            true,
            true
        );
        setStoreEmployeeList(fetchedEmployees);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchEmployees().then(() => {
        });
    }, []);

    return {
        storedEmployeeList,
        employeesLoading: loading,
    };
};

export default useStoredEmployees;
