import {useEffect, useState} from "react";
import {BranchResponseModel} from "../../models";
import {getAllBranches} from "../../serviceBroker/branchesServiceBroker";

const useBranchesData = () => {
    const [branches, setBranches] = useState<BranchResponseModel[]>([]);
    const [loading, setIsLoading] = useState(false);
    const fetchBranches = async () => {
        setIsLoading(true);
        const branchesData = await getAllBranches();
        if (branchesData === null) return;
        setBranches(branchesData.Result || []);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchBranches().then(() => {
        });
    }, []);

    return {
        branches,
        branchesLoading: loading,
        fetchBranches,
        setBranches,
    };
};
export default useBranchesData;
