import {UserResponseModel} from "../../models";
import {getUser} from "./secureManager";

export const isUserCurrentBranchAssigned = (): boolean => {
    const user: UserResponseModel | null = getUser();
    return !user?.IsMultiBranch || (user.currentBranchId !== null && user.currentBranchId !== undefined && user.currentBranchId > 0);
    // if(user?.IsMultiBranch)
    // {
    //     return  user.currentBranchId !== null && user.currentBranchId !== undefined && user.currentBranchId > 0
    // }
    // return true;
}
export const getUserCurrentBranchId = (): number | null => {
    const user: UserResponseModel | null = getUser();
    return user?.currentBranchId !== undefined ? user.currentBranchId : null;
};
export const getUserBranchId = (): number | null => {
    const user: UserResponseModel | null = getUser();
    return user?.BranchID !== undefined ? user.BranchID : null;
};
