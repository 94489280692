import {FC, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {UnitModel, ValidationErrorModel} from "../../models";
import {
    getLabelName,
    isArabicCurrentLanguage, reValidateLocalStorageCachedData,
} from "../../utils";
import {saveUnit} from "../../serviceBroker/unitApiServiceBroker";
import {ButtonBox, ErrorValidationBox, TextBox, LoadingBox, PrivilegesChecker} from "..";
import {
    ActionTypeEnum,
    HasFormIdModel,
    LookupTypeEnum,
    RequestActionModel,
    RowStateEnum,
} from "../../models";

interface RegisterUnitProps extends HasFormIdModel {
    request: UnitModel;
    onActionEvent: (o: RequestActionModel) => void;
}

export const RegisterUnit: FC<RegisterUnitProps> = ({
                                                        request,
                                                        onActionEvent, formId,
                                                    }) => {
    //#region variables
    const initialValues: UnitModel = request;
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const validationSchema = Yup.object({
        Name: Yup.string().required(getLabelName("required")),
    });

    //#endregion
    //#region function
    const handleSubmit = async (request: UnitModel) => {
        try {
            setLoading(true);
            request.rowState =
                request.ID === 0 ? Number(RowStateEnum.Add) : Number(RowStateEnum.Update);
            const res = await saveUnit(request);
            if (res != null && res.Errors != null && res.Errors.length !== 0) {
                setValidationErrors(res.Errors);
                onActionEvent({
                    id: 0,
                    action: ActionTypeEnum.Failed,
                    request: isArabic
                        ? res.Errors[0].MessageAr
                        : res?.Errors[0].MessageEn,
                    requestBeforeError: request,
                });
                setLoading(false);
            } else {
                setValidationErrors([]);
                setLoading(false);
                await reValidateLocalStorageCachedData(LookupTypeEnum.Units);
                onActionEvent({id: 0, action: ActionTypeEnum.AddSuccess});
                // revalidate units
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, {resetForm}) => {
            await handleSubmit(values);
            resetForm();
        },
    });
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form className="forms-sample" onSubmit={formik.handleSubmit}>
                <div
                    className="row row-cols-2 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start">
                    <TextBox
                        labelName={getLabelName("Name")}
                        inputName="Name"
                        isMandatory={true}
                        inputValue={formik.values.Name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.Name}
                    />

                    <TextBox
                        labelName={getLabelName("Eng Name")}
                        inputName="Name_En"
                        inputValue={formik.values.Name_En}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.Name_En}
                    />
                    <TextBox
                        key="IsDefault"
                        labelName="Is Default"
                        inputName="IsDefault"
                        type="checkbox"
                        inputValue={formik.values.IsDefault}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>

                <div className="row">
                    <div className="col-12 d-flex justify-content-end mb-3">
                        <PrivilegesChecker formId={formId} action="EnableSave">
                            <ButtonBox
                            iconType="content-save"
                                type="submit"
                                variant=""
                                className="btn-primary btn-sm mx-3"
                            >
                                {getLabelName("Save")}
                            </ButtonBox>
                        </PrivilegesChecker>
                        <ButtonBox
                        iconType="close-circle"
                            variant="danger"
                            type="button"
                            className="btn-sm"
                            onClick={() => {
                                onActionEvent({id: 0, action: ActionTypeEnum.Clear});
                            }}
                        >
                            {getLabelName("Cancel")}
                        </ButtonBox>
                    </div>
                </div>
            </form>
        </>
    );
    //#endregion
};
