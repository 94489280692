import {FC} from "react";
import {ReportPage, ReportSalesInGraphSearchPanel} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {searchReportProfitSalesInGraph,} from "../../../serviceBroker/transationReportApiServiceBroker";
import {PageEnum, SalesInGraphReportSearchParamsModel} from "../../../models";

export const ReportProfitSalesInGraph: FC = () => {
    const initialState: SalesInGraphReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportProfitSalesInGraph}
            searchHeader={getPageNameByPageId(PageEnum.ReportProfitOfSalesGraph)}
            SearchPanel={ReportSalesInGraphSearchPanel}
            formId={PageEnum.ReportProfitOfSalesGraph}
        />
    );
};
