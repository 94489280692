import React from "react";
import {
  RequestActionModel,
  TransactionDetailResponseModel,
} from "../../../../../models";
import {TransactionOrdersForm,TransactionOrdersList} from "../../../..";

interface TransactionOrdersProps {
  transactionData: TransactionDetailResponseModel;
  onActionEvent: (o: RequestActionModel) => void;
}

const TransactionOrders: React.FC<TransactionOrdersProps> = ({
  onActionEvent,
  transactionData,
}) => {
  return (
    <>
      <TransactionOrdersForm />
      <TransactionOrdersList />
    </>
  );
};

export default TransactionOrders;
