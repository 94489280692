import { FC, useMemo, useState } from "react";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { TableColumn } from "react-data-table-component";
import {
  ActionTypeEnum,
  EmployeeEstihkakSubtractResponseModel,
} from "../../models";
import { TableComponent, TextBox, ButtonBox } from "..";

export const EmployeeEstihkakSubtractRuleList: FC<{
  request: EmployeeEstihkakSubtractResponseModel[];
  onActionEvent?: any | null;
}> = ({ request, onActionEvent }) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const defaultPageSize: number = 50;
  const data = {
    nodes: request.filter((item) =>
      item.Name.toLowerCase().includes(searchValue.toLowerCase())
    ),
  };
  //#endregion
  //#region state
  const columns: TableColumn<EmployeeEstihkakSubtractResponseModel>[] = useMemo(
    () => [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        sortable: true,
        width: "100px",
      },

      {
        name: getLabelName("Name"),
        selector: (row) => (isArabic ? row.Name : row.Name_En),
        sortable: true,
        wrap: true,
        allowOverflow: true,
      },

      {
        name: getLabelName("Notes"),
        selector: (row) => row.Notes,
        sortable: true,
        allowOverflow: true,
      },

      {
        // eslint-disable-next-line react/button-has-type
        cell: (row: any) => (
          <ButtonBox
            iconType="pencil-box"
            onClick={() => {
              onActionEvent({
                id: row.ID,
                request: row,
                action: ActionTypeEnum.Update,
              });
            }}
          >
            {getLabelName("Edit")}
          </ButtonBox>
        ),
      },
      {
        // eslint-disable-next-line react/button-has-type
        cell: (row: any) => (
          <ButtonBox
            iconType="delete"
            onClick={() => {
              onActionEvent({
                id: row.ID,
                request: row,
                action: ActionTypeEnum.Delete,
              });
            }}
          >
            {getLabelName("Delete")}
          </ButtonBox>
        ),
      },
    ],
    []
  );
  const [searchValue, setSearchValue] = useState("");
  //#endregion
  //#region html
  return (
    <>
      <TextBox
        labelName={"Search"}
        inputName={"filterTextBox"}
        inputValue={searchValue}
        onChange={(e: any) => {
          setSearchValue(e.target.value);
        }}
      />
      <TableComponent
        columns={columns}
        data={data.nodes}
        totalRows={data.nodes.length}
        currentPage={1}
        pageSize={defaultPageSize}
        paginationType="client"
      />
    </>
  );
  //#endregion
};
