import {useEffect, useState} from "react";
import {companySettingsInitialValues} from "../../components/transactions/common/businessLogic/transactionBl";
import {CompanySettingModel} from "../../models";
import {SecureLocalStorageGet} from "../../utils";
import {SystemConfiguration} from "../../configuration";
import {getCompanySetting} from "../../serviceBroker/companySettingApiServiceBroker";

export const useCompanySettings = () => {
    const [companySettings, setCompanySettings] = useState<CompanySettingModel>(
        companySettingsInitialValues
    );

    const fetchedSettings = async () => {
        const settings = await getCompanySetting();
        if (settings !== null) return setCompanySettings(settings);
    };

    useEffect(() => {
        const storedCompanySettings = SecureLocalStorageGet(
            SystemConfiguration.keys.companySettings
        );
        if (storedCompanySettings !== null)
            return setCompanySettings(JSON.parse(`${storedCompanySettings}`));
        fetchedSettings().then(() => {
        });
    }, []);

    return {companySettings};
};

