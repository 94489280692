import {FC} from "react";
import {ReportPage, ReportTransactionSearchPanel} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {SearchReportEmployeeSalesPage,} from "../../../serviceBroker/employeeReportApiServiceBroker";

import {PageEnum, TransactionReportSearchParamsModel,} from "../../../models";

export const ReportEmployeeSalesPage: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={SearchReportEmployeeSalesPage}
            searchHeader={getPageNameByPageId(PageEnum.ReportEmployeeSales)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportEmployeeSales}
        />
    );
};
