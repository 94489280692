import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  paymentTypesApiResponseModel,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import {
  deletePaymentTypeById,
  getPaymentTypeById,
} from "../../serviceBroker/paymentTypeApiServiceBroker";
import {
  getLabelName,
  getResultFromResponse,
  scrollToDocumentTop,
} from "../../utils";
import {
  LoadingBox,
  ErrorValidationBox,
  ToastBox,
  ConfirmModelDialogBox,
  AddPaymentType,
  PaymentTypeList,
} from "..";
import { Accordion } from "react-bootstrap";
export const PaymentTypeMainComponent: FC = () => {
  //#region state
  const { t } = useTranslation();
  const [paymentTypeObject, setPaymentTypeObject] =
    useState<paymentTypesApiResponseModel | null>(null);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
    body: getLabelName("process Success"),
  });
  const [isRefresh, setIsRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region functions
  const handleDeletePaymentType = async () => {
    setLoading(true);
    paymentTypeObject!.rowState = Number(RowStateEnum.Delete);
    const deleteResponse = await deletePaymentTypeById(paymentTypeObject!.ID);
    const isDeleteOperationSuccess: boolean =
      deleteResponse?.Result !== null && deleteResponse?.Result?.Result == true;
    setToastModel({
      ...toastModel,
      variant: isDeleteOperationSuccess ? "success" : "danger",
      body: isDeleteOperationSuccess
        ? getLabelName("process Success")
        : t("processFailed"),
      show: true,
    });
    if (isDeleteOperationSuccess) {
      setShowDeleteModel(false);
      setPaymentTypeObject(null);
      setIsRefresh(true);
    }
    setLoading(false);
  };
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleDeletePaymentType();
      },
    },
    {
      text: t("no"),
      onClick: () => {
        setPaymentTypeObject(null);
        setShowDeleteModel(false);
      },
    },
  ];
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        scrollToDocumentTop();
        setValidationErrors([]);
        setToastModel({ ...toastModel, show: true });
        setPaymentTypeObject(null);
        setIsRefresh(true);
        break;
      case ActionTypeEnum.Update:
        setLoading(true);
        scrollToDocumentTop();
        setValidationErrors([]);
        const paymentTypeRequest = await getPaymentTypeById(request.id || 0);
        const paymentTypeResponse: paymentTypesApiResponseModel | null =
          getResultFromResponse<paymentTypesApiResponseModel>(
            paymentTypeRequest
          );
        if (paymentTypeResponse !== null) {
          setPaymentTypeObject(paymentTypeResponse);
        }
        setLoading(false);
        break;
      case ActionTypeEnum.Delete:
        if (request.request) {
          setPaymentTypeObject(request.request);
          setShowDeleteModel(true);
        }
        break;
      case ActionTypeEnum.Failed:
        setValidationErrors(request.request);
        break;
      case ActionTypeEnum.Clear:
        setValidationErrors([]);
        setPaymentTypeObject(null);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      {showDeleteModel && (
        <ConfirmModelDialogBox
          isModelVisible={showDeleteModel}
          onCloseEvent={() => {
            setShowDeleteModel(false);
          }}
          actions={deleteActions}
        >
          <div className="alert alert-warning">Are you sure?</div>
        </ConfirmModelDialogBox>
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("PaymentType")}</Accordion.Header>
          <Accordion.Body>
            <AddPaymentType
              request={paymentTypeObject}
              onActionEvent={(o: RequestActionModel) => {
                handleAction(o).then();
              }}
              formId={161}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("previous Data")}</Accordion.Header>
          <Accordion.Body>
            <PaymentTypeList
              setIsRefresh={setIsRefresh}
              isRefresh={isRefresh}
              onActionEvent={(o: RequestActionModel) => {
                handleAction(o).then();
              }}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
