import React from "react";
import {getBase64, getLabelName} from "../../utils";
import {ItemImageModel, RowStateEnum} from "../../models";
import {ButtonBox, Loading, EntityUploader} from "../";

export interface ItemImageFormProps {
    image: ItemImageModel | null;
    setFileBase64: (base64: string | null) => void;
    isImageLoading: boolean;
    deleteImage: () => void;
}

export const ItemImageForm: React.FC<ItemImageFormProps> = ({
                                                                image,
                                                                setFileBase64,
                                                                isImageLoading,
                                                                deleteImage,
                                                            }) => {
    //#region variables
    const isImageLoaded = !!image;
    //#endregion
    //#region functions
    const handleFileReject = () => {
        // Define your logic to handle file rejection here
        // Error to be handled here
    };
    const handleFileDrop = async (files: any) => {
        // Define your logic to handle dropped files here
        const base64 = await getBase64(files[0]);
        setFileBase64(base64.replace(/^data:image\/(jpeg|png|jpg);base64,/, ""));
    };
    const handleFileDelete = async () => {
        deleteImage();
    };
//#endregion
    //#region html
    if (isImageLoading) return <Loading/>;
    if (isImageLoaded && image.rowState !== RowStateEnum.Delete)
        return (
            <>
                <img
                    src={`data:image/png;base64,${image.File64Bit}`}
                    style={{display: "block", maxWidth: "80%", margin: "0px auto"}}
                    alt=""/>
                <div className="my-4 d-flex justify-content-end">
                    <ButtonBox
                    iconType="delete"
                        variant="danger"
                        type="button"
                        className="btn btn-sm"
                        onClick={handleFileDelete}
                    >
                        {getLabelName("delete")}
                    </ButtonBox>
                </div>
            </>
        );
    return (
        <>
            {
                <EntityUploader
                    acceptTypes={{
                        allowedMimeTypes: ["image/png", "image/jpg", "image/jpeg"],
                    }}
                    handleFileDrop={handleFileDrop}
                    handleFileReject={handleFileReject}
                />
            }
        </>
    );
    //#endregion
};
