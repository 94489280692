import {
    EmployeeSalesCommissionReportSearchParamsModel,
    EmployeeSalesCommissionReportSearchParamsStringModel,
    GeneralPrintResponseModel,
    SalesInGraphReportSearchParamsModel,
    SalesInGraphReportSearchParamsStringModel,
    ReportVatSearchParamsModel,
    ResponseBaseModel,
    TransactionReportSearchParamsModel
} from "../models";
import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const searchReportItemsDifferentInVatCalculation = async (
    request: SalesInGraphReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    let req: SalesInGraphReportSearchParamsStringModel = {
        lang: request?.lang,
        userId: request?.userId,
    };
    req.fromDate = request?.fromDate?.toISOString();
    req.toDate = request?.toDate?.toISOString();
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.transactionReportController.reportItemsDifferentInVatCalculation,
        {
            params: AxiosSearchParams(req, "searchParmeter"),
        }
    );
};
export const searchReportEmployeeSalesCommission = async (
    request: EmployeeSalesCommissionReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    let req: EmployeeSalesCommissionReportSearchParamsStringModel = {
        lang: request?.lang,
        userId: request?.userId,
        categoryId: request?.calcTypeId,
        itemId: request?.itemId,
        calcTypeId: request?.calcTypeId,
        employeeId: request?.employeeId,
        custmerId: request?.custmerId,
        currencyId: request?.currencyId,
        code: request?.code,
    };
    req.fromDate = request?.fromDate?.toISOString();
    req.toDate = request?.toDate?.toISOString();
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.transactionReportController.reportItemsDifferentInVatCalculation,
        {
            params: AxiosSearchParams(req, "searchParmeter"),
        }
    );
};
export const searchReportStation = async (
    request: SalesInGraphReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    let req: SalesInGraphReportSearchParamsStringModel = {
        lang: request?.lang,
        userId: request?.userId,
    };
    req.fromDate = request?.fromDate?.toISOString();
    req.toDate = request?.toDate?.toISOString();
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.reportStations, {
        params: AxiosSearchParams(req, "searchParmeter"),
    });
};
export const searchReportOrderStatusLog = async (
    request: TransactionReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.reportOrderStatusLog, {
        params: AxiosSearchParams(request, "searchParmeter"),
    });
};
export const searchReportSalesAndPurchaseInGraph = async (
    request: SalesInGraphReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    let req: SalesInGraphReportSearchParamsStringModel = {
        lang: request?.lang,
        userId: request?.userId,
    };
    req.fromDate = request.fromDate?.toISOString();
    req.toDate = request.toDate?.toISOString();
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.reportSalesInGraph, {
        params: AxiosSearchParams(req, "searchParmeter"),
    });
};
export const searchReportProfitSalesInGraph = async (
    request: SalesInGraphReportSearchParamsModel
    // request: ReportSalesInGraphSearchParamsString
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    let req: SalesInGraphReportSearchParamsStringModel = {
        lang: request?.lang,
        userId: request?.userId,
    };
    req.fromDate = request.fromDate?.toISOString();
    req.toDate = request.toDate?.toISOString();
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.transactionReportController.reportProfitSalesInGraph,
        {
            params: AxiosSearchParams(req, "searchParmeter"),
        }
    );
};
export const searchSalesTransactionsReport = async (request: TransactionReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.salesTransactionsReport, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReturnSalesTransactionsReport = async (request: TransactionReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.returnSalesTransactionsReport, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchAllSalesAndReturnTransactionsReport = async (request: TransactionReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.allSalesAndReturnTransactionsReport, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchPurchaseTransactionsReport = async (request: TransactionReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.purchaseTransactionsReport, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReturnPurchaseTransactionsReport = async (request: TransactionReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.returnPurchaseTransactionsReport, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReportCashier = async (request: TransactionReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.reportCashier, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReportEmployeeSales = async (request: TransactionReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.reportEmployeeSales, {params: AxiosSearchParams(request, 'searchParmeter')});
}
export const searchReportProfitOfSales = async (request: TransactionReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.reportProfitOfSales, {params: AxiosSearchParams(request, 'searchParm')});
};
export const searchReportVat = async (request: ReportVatSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.transactionReportController.reportVat, {params: AxiosSearchParams(request, 'searchCasherReport')});
};
//#endregion
