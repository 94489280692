import React from "react";
import { LookupItemModel } from "../../../../../models";

interface RestaurantTablesListProps {
  tables?: LookupItemModel[];
  handleClick: (value: LookupItemModel) => void;
  className?: string;
}

const RestaurantTablesList: React.FC<RestaurantTablesListProps> = ({
  tables,
  handleClick,
}) => {
  return (
    <ul className="d-flex gap-2">
      {tables?.map((child) => (
        <button
          key={child.value}
          id={`btn-${child.value}`}
          onClick={() => handleClick(child)}
          className={`btn ${
            !child.otherValue.Active
              ? "btn-danger"
              : child.otherValue.IsPrinted
              ? "btn-warning"
              : "btn-success"
          }`}
        >
          {child.name}
        </button>
      ))}
    </ul>
  );
};

export default RestaurantTablesList;
