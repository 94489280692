import {FC, useEffect, useState} from "react";
import {TableColumn} from "react-data-table-component";
import {TableComponent} from "../../components";
import {LoadingBox} from "../../components";
import {UserRegistrationResponseModel} from "../../models/user/userRegisterationResponseModel";
import {getUsers} from "../../serviceBroker/userApiServiceBroker";

export const DataTablePageWithClientPagination: FC<{}> = () => {
    //#region state
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [data, setData] = useState<UserRegistrationResponseModel[]>([]);
    //#endregion
    //#region varaibles
    interface DataRow {
        first_name: string;
        last_name: string;
        email: string;
    }

    const columns: TableColumn<UserRegistrationResponseModel>[] = [
        {
            name: "ItemCode",
            selector: (row) => row.Name,
            sortable: true,
        },
        {
            name: "ItemName",
            selector: (row) => row.Name_EN,
            sortable: true,
        },
        {
            name: "PriceSale",
            selector: (row) => row.User_Name,
            sortable: true,
        },
        {
            name: "Current_Balance",
            selector: (row) => row.JWT,
            sortable: true,
        },
        {
            name: "PriceCost",
            selector: (row) => row.Password,
            sortable: true,
        },
    ];

    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await fetchUsers();
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region function
    const fetchUsers = async () => {
        setLoading(true);
        const url: string = `https://reqres.in/api/users?page=${1}&per_page=${10000}&delay=1`;
        const response = await getUsers();
        var rows: UserRegistrationResponseModel[] = [];
        response.forEach((row: UserRegistrationResponseModel) => {
            rows.push(row);
        });
        for (let i = 0; i < 2000; i++) {
            response.forEach((row: UserRegistrationResponseModel) => {
                rows.push(row);
            });
        }
        setData(rows);
        setTotalRows(rows.length);
        setLoading(false);
    };
    //#endregion
    //#region html

    return (
        <>
            {loading && <LoadingBox/>}
            {data !== null && data.length !== 0 && (
                <TableComponent
                    columns={columns}
                    data={data}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    paginationType="client"
                />
            )}
        </>
    );
    //#endregion
};
