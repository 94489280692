import { FC } from "react";
import { getLabelName } from "../../utils";
import {
  ActionTypeEnum,
  HasFormIdModel,
  LookupFormTypeEnum,
  LookupModel,
} from "../../models";
import { TableColumn } from "react-data-table-component";
import {
  TextBox,
  TableComponent,
  LabelBox,
  ButtonBox,
  PrivilegesChecker,
} from "..";

interface LookupListProps extends HasFormIdModel {
  request: LookupModel[];
  lookupType: LookupFormTypeEnum;
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

export const LookupList: FC<LookupListProps> = ({
  request,
  lookupType,
  onActionEvent,
  formId,
}) => {
  //#region variables
  const generateColumnList = (): TableColumn<LookupModel>[] => {
    return [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        width: "50px",
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>{getLabelName("Name")}</label>
        ),
        selector: (row) => row.Name,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Name English")}
          </label>
        ),
        selector: (row) => row.NameEn,
        sortable: true,
      },
      {
        name: (lookupType === LookupFormTypeEnum.Tables ||
          lookupType === LookupFormTypeEnum.Cities) && (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Value")}
          </label>
        ),
        cell: (row: LookupModel) =>
          (lookupType === LookupFormTypeEnum.Tables ||
            lookupType === LookupFormTypeEnum.Cities) && (
            <LabelBox inputValue={row.Value} labelName="" />
          ),
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("isDefault")}
          </label>
        ),
        cell: (row: LookupModel) => (
          <TextBox
            inputName="IsDefault"
            inputValue={row.IsDefault}
            labelName=""
            type={"checkbox"}
          />
        ),
      },
      {
        cell: (row: LookupModel) => (
          <PrivilegesChecker formId={formId} action="EnableUpdate">
            <ButtonBox
              onClick={async () => {
                onActionEvent({
                  id: row.ID,
                  action: ActionTypeEnum.Update,
                });
              }}
            >
              {getLabelName("Edit")}
            </ButtonBox>
          </PrivilegesChecker>
        ),
      },
      {
        cell: (row: LookupModel) => (
          <PrivilegesChecker formId={formId} action="EnableDelete">
            <ButtonBox
              variant="danger"
              className="btn-xs"
              onClick={async () => {
                onActionEvent({
                  id: row.ID,
                  action: ActionTypeEnum.Delete,
                });
              }}
            >
              {getLabelName("Delete")}
            </ButtonBox>
          </PrivilegesChecker>
        ),
      },
    ];
  };
  //#endregion
  //#region html
  return (
    <>
      <TableComponent
        columns={generateColumnList()}
        data={request}
        totalRows={10000}
        currentPage={1}
        paginationType="none"
        pageSize={10000}
      />
    </>
  );
  //#endregion
};
