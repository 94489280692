import React, {FC, useEffect, useRef, useState} from "react";
import {saveRestaurant} from "../../serviceBroker/resturantApiServiceBroker";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    ActionTypeEnum,
    RestaurantResponseModel,
    LookupItemModel,
    LookupTypeEnum,
    ValidationErrorModel,
    RequestActionModel,
    ResponseBaseModel,
    StoreResponseModel,
    HasFormIdModel,
} from "../../models";
import {LoadingBox, ErrorValidationBox, TextBox, SelectBox, ButtonBox, PrivilegesChecker} from "..";
import {
    getLabelName,
    getLookUpItemNumericValue,
    isArabicCurrentLanguage,
} from "../../utils";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";

interface AddRestaurantProps extends HasFormIdModel {
    request: RestaurantResponseModel;
    onActionEvent: (o: RequestActionModel) => void;
}

export const AddRestaurant: FC<AddRestaurantProps> = ({
                                                          request,
                                                          onActionEvent = () => {
                                                          }, formId,
                                                      }) => {
    //#region Variables
    const initialState: RestaurantResponseModel = request;
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region State
    const [loading, setLoading] = useState(false);
    const [restaurantList, setRestaurantList] = useState<LookupItemModel[]>([]);
    const tablesSelectBoxMultiselectRef = useRef<any>();
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    //#endregion
    //#region validation
    const [validationSchema] = useState(
        Yup.object({
            Name: Yup.string().required(getLabelName("required")),
            Name_En: Yup.string().required(getLabelName("required")),
            Parent_ID: Yup.string().required(getLabelName("required")),
        })
    );
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            const restaurantList = await getLookupByType(
                LookupTypeEnum.AllMainParentRestaurant
            );
            if (restaurantList !== null && restaurantList.length !== 0) {
                setRestaurantList(restaurantList);
            }
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region functions
    const handleSubmit = async (request: RestaurantResponseModel) => {
        try {
            setLoading(true);
            const res: ResponseBaseModel<StoreResponseModel> = await saveRestaurant(request);
            if (
                res !== null &&
                res !== undefined &&
                res.Errors !== null &&
                res.Errors !== undefined &&
                res.Errors.length !== 0
            ) {
                setValidationErrors(res.Errors);
                setLoading(false);
                onActionEvent({
                    id: 0,
                    action: ActionTypeEnum.Failed,
                    request: isArabic
                        ? res.Errors[0].MessageAr
                        : res?.Errors[0].MessageEn,
                    requestBeforeError: request,
                });
            } else {
                setValidationErrors([]);
                setLoading(false);
                onActionEvent({id: 0, action: ActionTypeEnum.AddSuccess});
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#endregion
    //#region  formik
    const formik = useFormik({
        initialValues: initialState,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: RestaurantResponseModel): any => {
            const errors: any = {};
            if (
                values.Parent_ID === null ||
                values.Parent_ID === undefined ||
                values.Parent_ID === 0
            ) {
                errors.Parent_ID = getLabelName("required");
            }
            return errors;
        },
        onSubmit: async (values) => {
            //values.Parent_ID = values.Parent_ID;
            await handleSubmit(values);
        },
    });
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form className="forms-sample" onSubmit={formik.handleSubmit}>
                <div
                    className="row row-cols-2 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start">
                    <SelectBox
                        labelName={getLabelName("Tables")}
                        errorText={formik.errors.Parent_ID}
                        source={restaurantList}
                        isSingleSelect={true}
                        onStatusChange={(e: any) => {
                            const val: number | null = getLookUpItemNumericValue(e);
                            formik.values.Parent_ID = val;
                            formik.setFieldValue("Parent_ID", val);
                            request.Parent_ID = val;
                        }}
                        selectedValues={[
                            request.Parent_ID == null ? "" : request.Parent_ID.toString(),
                        ]}
                        multiselectRef={tablesSelectBoxMultiselectRef}
                    />
                    <TextBox
                        labelName={getLabelName("Arabic Name")}
                        inputName={"Name"}
                        errorText={formik.errors.Name}
                        inputValue={formik.values.Name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />

                    <TextBox
                        labelName={getLabelName("Name English")}
                        inputName={"Name_En"}
                        errorText={formik.errors.Name_En}
                        inputValue={formik.values.Name_En}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextBox
                        labelName={getLabelName("Code")}
                        inputName={"Code"}
                        errorText={formik.errors.Code}
                        inputValue={formik.values.Code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextBox
                        labelName={getLabelName("Is Active")}
                        inputName={"Active"}
                        errorText={formik.errors.Active}
                        inputValue={formik.values.Active}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="checkbox"
                    />
                    <TextBox
                        labelName={getLabelName("Is Default")}
                        inputName={"IsDefault"}
                        errorText={formik.errors.IsDefault}
                        inputValue={formik.values.IsDefault}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="checkbox"
                    />
                    <TextBox
                        labelName={getLabelName("Is Parent")}
                        inputName={"IsParent"}
                        errorText={formik.errors.IsParent}
                        inputValue={formik.values.IsParent}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="checkbox"
                    />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                    <PrivilegesChecker formId={formId} action="EnableSave">
                        <ButtonBox
                        iconType="content-save"
                            type="submit"
                            variant=""
                            className="btn-primary btn-sm mx-3"
                        >
                            {getLabelName("Save")}
                        </ButtonBox>
                    </PrivilegesChecker>
                    <ButtonBox
                    iconType="close-circle"
                        variant="danger"
                        type="button"
                        className="btn-sm"
                        onClick={() => {
                            tablesSelectBoxMultiselectRef.current.clearValue();
                            formik.resetForm();
                            onActionEvent({id: 0, action: ActionTypeEnum.Clear});
                        }}
                    >
                        {getLabelName("Cancel")}
                    </ButtonBox>
                </div>
            </form>
        </>
    );
    //#endregion
};
