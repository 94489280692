import {FC, useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {
  ConfirmModelDialogBox,
  ErrorValidationBox,
  LoadingBox,
  RegisterUnit,
  ToastBox,
  UnitList,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  PageEnum,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  UnitModel,
  ValidationErrorModel,
} from "../../models";
import {deleteUnit, getUnits,} from "../../serviceBroker/unitApiServiceBroker";

import {getLabelName, getPageNameByPageId, isArabicCurrentLanguage,} from "../../utils";

export const UnitPage: FC<{ formId: number }> = ({formId = PageEnum.Units}) => {
    //#region variables
    const initialValues: UnitModel = {
        IsDefault: false,
        Name_En: "",
        Name: "",
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Errors: [],
        rowState: Number(RowStateEnum.Add),
    };
    const deleteUnitActions: ActionButtonsModel[] = [
        {
            text: getLabelName("yes"),
            onClick: async () => {
                await handleUnitAction({
                    id: unit.ID,
                    action: ActionTypeEnum.DeleteOperationStart,
                });
            },
        },
        {
            text: getLabelName("no"),
            onClick: () => {
                setUnit(initialValues);
                setShowDeleteUnitModel(false);
            },
        },
    ];
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [units, setUnits] = useState<UnitModel[]>([]);
    const [unit, setUnit] = useState<UnitModel>(initialValues);
    const [toastModel, setToastModel] = useState<ToastModel>({show: false});
    const [pageName, setPageName] = useState<string | null>(null);
    const [showDeleteUnitModel, setShowDeleteUnitModel] = useState(false);
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await getAllUnits();
        };
        fillData().then(() => {
        });
    }, []);
    useEffect(() => {
        if (formId !== 0 && formId !== null && formId !== undefined) {
            setPageName(getPageNameByPageId(formId));
        }
    }, [isArabicCurrentLanguage()]);
    //#endregion
    //#region function
    const handleUnitAction = async (request: RequestActionModel) => {
        switch (request.action) {
            case ActionTypeEnum.AddSuccess:
                setLoading(true);
                setUnit(initialValues);
                await getAllUnits();
                setToastModel({
                    ...toastModel,
                    variant: "success",
                    show: true,
                });
                setLoading(false);
                break;
            case ActionTypeEnum.Clear:
                setLoading(true);
                setUnit(initialValues);
                await getAllUnits();
                setLoading(false);
                break;
            case ActionTypeEnum.Update:
                window.scrollTo(0, 0);
                setUnit(request.request);
                break;
            case ActionTypeEnum.DeleteOperationStart:
                setLoading(true);
                setShowDeleteUnitModel(false);
                const result = await deleteUnit(request.id!);
                setLoading(false);
                //alert(JSON.stringify(result));
                //@ts-ignore
                await getAllUnits();
                await handleUnitAction({
                    id: 0,
                    action: result.Result?.Result ? ActionTypeEnum.Success : ActionTypeEnum.Failed
                });
                setUnit(initialValues);
                break;
            case ActionTypeEnum.Delete:
                setUnit(request.request);
                setShowDeleteUnitModel(true);
                break;
            case ActionTypeEnum.Success:
                setToastModel({...toastModel, variant: "success", show: true});
                break;
            case ActionTypeEnum.Failed:
                setToastModel({
                    ...toastModel,
                    body: request.request,
                    variant: "danger",
                    show: true,
                });
                setUnit(request.requestBeforeError);
                break;
        }
    };
    const getAllUnits = async () => {
        setLoading(true);
        const unitList = await getUnits();
        setUnits(unitList?.Result == null ? units : unitList?.Result);
        setLoading(false);
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{pageName}</Accordion.Header>
                    <Accordion.Body>
                        <Card>
                            <Card.Body>
                                <RegisterUnit
                                    request={unit}
                                    onActionEvent={async (o: RequestActionModel) => {
                                        await handleUnitAction(o);
                                    }}
                                    formId={formId}
                                />
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body>
                        <Card className="card-custom">
                            {/* <Card.Header></Card.Header> */}
                            <Card.Body>
                                <ConfirmModelDialogBox
                                    isModelVisible={showDeleteUnitModel}
                                    onCloseEvent={() => {
                                        setShowDeleteUnitModel(false);
                                    }}
                                    actions={deleteUnitActions}
                                >
                                </ConfirmModelDialogBox>
                                {/* Unit list */}
                                {units && (
                                    //&& Units.length !== 0
                                    <UnitList
                                        request={units}
                                        onActionEvent={async (o: RequestActionModel) => {
                                            await handleUnitAction(o);
                                        }}
                                        formId={formId}
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
};
