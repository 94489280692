import React, {useState} from "react";
import "./tabView.css";
import {ButtonBox} from "../..";

function TabView({tabs = {}}: { tabs: any }) {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const activateTab = (index: any) => {
        setActiveTabIndex(index);
    };
    return (
        <>
            {Object.keys(tabs).length === 0 ? (
                <div>No Tabs</div>
            ) : (
                <div className="card p-3">
                    <ul className="nav nav-tabs tabs mb-3" id="myTab" role="tablist">
                        {tabs.map((tab: any, index: any) => (
                            <>
                                <li className="nav-item" role="presentation">
                                    <ButtonBox
                                        key={index}
                                        onClick={() => activateTab(index)}
                                        className={
                                            index === activeTabIndex ? "nav-link active" : "nav-link"
                                        }
                                        id="tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="tab-pane"
                                        aria-selected={index === activeTabIndex}
                                    >
                                        {tab.name}
                                    </ButtonBox>
                                </li>
                            </>
                        ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade show active "
                            id="tab-pane"
                            role="tabpanel"
                            aria-labelledby="tab"
                        >
                            {tabs[activeTabIndex].content}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TabView;
