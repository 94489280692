import {useState} from "react";

const useSearchBox = () => {
    const [value, setValue] = useState("");
    const [searchedWord, setSearchWord] = useState("");
    const onChange = (e: any) => setValue(e.target.value);
    const onSearch = () => setSearchWord(value);
    const onReset = () => setValue("");

    return {value, onReset, onChange, onSearch, searchedWord};
};

export default useSearchBox;
