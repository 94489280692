import {
    GeneralPrintResponseModel,
    SalesInGraphReportSearchParamsModel,
    SalesInGraphReportSearchParamsStringModel,
    ResponseBaseModel,
    TransactionReportSearchParamsModel
} from "../models";
import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const SearchReportEmployeeEsthkakSubtracts = async (
    request: SalesInGraphReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    let req: SalesInGraphReportSearchParamsStringModel = {
        lang: request?.lang,
        userId: request?.userId,
    };
    req.fromDate = request?.fromDate?.toISOString();
    req.toDate = request?.toDate?.toISOString();
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.employeeReportController.reportEmployeeEsthkakSubtracts,
        {
            params: AxiosSearchParams(req, "searchParmeter"),
        }
    );
};
export const SearchReportEmployeeSalesPage = async (
    request: TransactionReportSearchParamsModel
): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.employeeReportController.reportEmployeeCashierSales,
        {
            params: AxiosSearchParams(request, "searchParmeter"),
        }
    );
};
//#endregion
