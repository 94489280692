import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import * as Yup from "yup";
import {ToastModel, ValidationErrorModel} from "../../models";
import {useFormik} from "formik";
import {TextBox, ErrorValidationBox, LoadingBox, ButtonBox, PrivilegesChecker} from "..";
import {getLabelName} from "../../utils";
import {
    getBranchById,
    saveBranch,
} from "../../serviceBroker/branchesServiceBroker";
import {BranchesChildComponent} from "./branches";

interface BranchFormProps extends BranchesChildComponent {
    setToastModel: Dispatch<SetStateAction<ToastModel>>;
    setBranchesFormLoading: React.Dispatch<React.SetStateAction<boolean>>;
    branchesFormLoading: boolean;
}

const validationSchema = Yup.object({
    ArabicName: Yup.string().required(getLabelName("required")),
    EnglishName: Yup.string(),
    CompanyID: Yup.string(),
    CityName: Yup.string(),
    CitySubdivisionName: Yup.string(),
    StreetName: Yup.string(),
    BuildingNumber: Yup.string(),
    PostalZone: Yup.string(),
    PlotIdentification: Yup.string(),
});
const initialValues = {
    ArabicName: "",
    EnglishName: "",
    CompanyID: "",
    CityName: "",
    CitySubdivisionName: "",
    StreetName: "",
    BuildingNumber: "",
    PostalZone: "",
    PlotIdentification: "",
};

export const BranchForm: React.FC<BranchFormProps> = ({
                                                          setToastModel,
                                                          currentBranchID,
                                                          setCurrentBranchID,
                                                          fetchBranches,
                                                          branchesFormLoading,
                                                          setBranchesFormLoading,
                                                      }) => {
    //#region states
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setValidationErrors([]);
            const {
                ArabicName,
                BuildingNumber,
                CityName,
                CitySubdivisionName,
                CompanyID,
                EnglishName,
                PlotIdentification,
                PostalZone,
                StreetName,
            } = values;
            setBranchesFormLoading(true);
            const result = await saveBranch({
                ArabicName: ArabicName,
                EnglishName: EnglishName,
                rowState: currentBranchID === null ? 1 : 2,
                ID: currentBranchID || 0,
                SupplierPartyInfo: {
                    postalAddress: {
                        BuildingNumber,
                        CityName,
                        CitySubdivisionName,
                        PostalZone,
                        StreetName,
                        PlotIdentification,
                    },
                    partyTaxScheme: {
                        CompanyID: CompanyID,
                    },
                },
            });
            if (result.Errors && result.Errors?.length !== 0)
                return setValidationErrors(result.Errors);
            setCurrentBranchID(null);
            formik.resetForm();
            setToastModel({show: true});
            await fetchBranches();
            setBranchesFormLoading(false);
        },
        validateOnChange: false,
        validateOnBlur: false,
    });
//#endregion
    //#region functions
    const fetchBranchInfoByID = async (branchID: number | null) => {
        try {
            if (branchID === null) return;
            setBranchesFormLoading(true);
            const fetchedBranchInfo = await getBranchById(branchID);
            if (!fetchedBranchInfo.Result) return;
            const {ArabicName, SupplierPartyInfo, EnglishName} =
                fetchedBranchInfo.Result;
            formik.setErrors({});
            formik.resetForm();
            await formik.setFieldValue("ArabicName", ArabicName);
            await formik.setFieldValue("EnglishName", EnglishName);
            await formik.setFieldValue(
                "CompanyID",
                SupplierPartyInfo?.partyTaxScheme.CompanyID || ""
            );
            await formik.setFieldValue(
                "CityName",
                SupplierPartyInfo?.postalAddress.CityName || ""
            );
            await formik.setFieldValue(
                "CitySubdivisionName",
                SupplierPartyInfo?.postalAddress.CitySubdivisionName || ""
            );
            await formik.setFieldValue(
                "BuildingNumber",
                SupplierPartyInfo?.postalAddress.BuildingNumber || ""
            );
            await formik.setFieldValue(
                "PlotIdentification",
                SupplierPartyInfo?.postalAddress.PlotIdentification || ""
            );
            await formik.setFieldValue(
                "PostalZone",
                SupplierPartyInfo?.postalAddress.PostalZone || ""
            );
            await formik.setFieldValue(
                "StreetName",
                SupplierPartyInfo?.postalAddress.StreetName || ""
            );
        } catch (error) {
        } finally {
            setBranchesFormLoading(false);
        }
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
        fetchBranchInfoByID(currentBranchID).then(() => {
        });
    }, [currentBranchID]);
//#endregion
    //#region html
    return (
        <LoadingBox isLoading={branchesFormLoading}>
            <form
                onSubmit={formik.handleSubmit}
                onReset={() => {
                    formik.resetForm();
                    setCurrentBranchID(null);
                }}
            >
                {<ErrorValidationBox errors={validationErrors}/>}
                <div
                    className=" row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-center">
                    <TextBox
                        key="ArabicName"
                        labelName="Arabic Name"
                        inputName="ArabicName"
                        placeHolder={getLabelName("Arabic Name")}
                        inputValue={formik.values.ArabicName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.ArabicName}
                    />

                    <TextBox
                        key="EnglishName"
                        labelName="EnglishName"
                        placeHolder={getLabelName("English Name")}
                        inputName="EnglishName"
                        inputValue={formik.values.EnglishName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.EnglishName}
                    />

                    <TextBox
                        key="Register Number"
                        labelName="Register Number"
                        placeHolder={getLabelName("Register Number")}
                        inputName="CompanyID"
                        inputValue={formik.values.CompanyID}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.CompanyID}
                        type={"number"}
                    />

                    <TextBox
                        key="City"
                        labelName="City"
                        placeHolder={getLabelName("City")}
                        inputName="CityName"
                        inputValue={formik.values.CityName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.CityName}
                    />

                    <TextBox
                        key="Area"
                        labelName="Area"
                        placeHolder={getLabelName("Area")}
                        inputName="CitySubdivisionName"
                        inputValue={formik.values.CitySubdivisionName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.CitySubdivisionName}
                        type={"text"}
                    />

                    <TextBox
                        key="Street"
                        labelName="Street"
                        placeHolder={getLabelName("Street")}
                        inputName="StreetName"
                        inputValue={formik.values.StreetName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.StreetName}
                        type={"text"}
                    />

                    <TextBox
                        key="Building Number"
                        labelName="Building Number"
                        placeHolder={getLabelName("Building Number")}
                        inputName="BuildingNumber"
                        inputValue={formik.values.BuildingNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.BuildingNumber}
                        type={"number"}
                    />

                    <TextBox
                        key="Postal Number"
                        labelName="Postal Number"
                        placeHolder={getLabelName("Postal Number")}
                        inputName="PostalZone"
                        inputValue={formik.values.PostalZone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.PostalZone}
                        type={"number"}
                    />
                    <TextBox
                        key="PlotIdentification Number"
                        labelName="PlotIdentification Number"
                        placeHolder={getLabelName("PlotIdentification Number")}
                        inputName="PlotIdentification"
                        inputValue={formik.values.PlotIdentification}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorText={formik.errors.PlotIdentification}
                        type={"number"}
                    />
                </div>
                <div className="my-4 d-flex justify-content-end">
                    <PrivilegesChecker formId={69} action={"EnableSave"}>
                        <ButtonBox
                        iconType="content-save"
                            type="submit"
                            className="btn  btn-sm mx-2"
                            variant="primary"
                        >
                            {getLabelName("save")}
                        </ButtonBox>
                    </PrivilegesChecker>
                    <ButtonBox variant="danger" type="reset" className="btn btn-sm" iconType="refresh">
                        {getLabelName("reset")}
                    </ButtonBox>
                </div>
            </form>
        </LoadingBox>
    );
    //#endregion
};
