import { FC, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidationErrorModel, ElectronicBillSettingModel } from "../../models";
import {getLabelName, getUserId} from "../../utils";
import { Card } from "react-bootstrap";
import { ButtonBox, ErrorValidationBox, TextBox, LoadingBox } from "..";

export const ElectronicBillSetting: FC<{
  request?: ElectronicBillSettingModel | null;
  onComplete?: any | null;
}> = ({ request, onComplete = () => {} }) => {
  //#region variables
  const initialValues: ElectronicBillSettingModel = request ?? {
    CreatedBy: getUserId(),
    CreationDate: new Date(),
    ID: 0,
    ModificationDate: new Date(),
    ModifiedBy: 0,
    Name: "",
    ActivityCode: "",
    ClientID: "",
    ClientSecret1: "",
    ClientSecret2: "",
    CompanyTypeCode: "",
    DllLibPath: "",
    DocumentTypeVersion: "",
    DysAllowedToUploadEinvoice: 0,
    HoursDelayToUploadTransaction: 0,
    TokenCertificate: "",
    TokenPin: "",
    address: null,
    VerifyOnUpdate: false,
    rowState: 0,
  };
  initialValues.address = initialValues.address ?? {
    branchID: "",
    buildingNumber: "",
    country: "",
    governate: "",
    regionCity: "",
    street: "",
  };
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [validationSchema] = useState(
    Yup.object({
      HoursDelayToUploadTransaction: Yup.number()
        .positive(getLabelName("mustpositive"))
        .integer(getLabelName("mustintegar")),
      DysAllowedToUploadEinvoice: Yup.number()
        .positive(getLabelName("mustpositive"))
        .integer(getLabelName("mustintegar")),
    })
  );
  //#endregion
  //#region function
  const handleSubmit = async (data: ElectronicBillSettingModel) => {
    try {
      setLoading(true);
      // @ts-ignore
      const res = await saveEgypteInvoiceSettings(data);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
        setLoading(false);
      } else {
        setValidationErrors([]);
        setLoading(false);
        onComplete(true);
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    // enableReinitialize: true,
    onReset: () => {},
    onSubmit: async (values) => {
      await handleSubmit(values);
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
        <Card className="card-custom">
          <Card.Header>
            <span>{getLabelName("Electronic Bill")}</span>
          </Card.Header>
          <Card.Body>
            <div className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
              <TextBox
                labelName={getLabelName("Register Number")}
                inputName={"CompanyTypeCode"}
                errorText={formik.errors.CompanyTypeCode}
                inputValue={formik.values.CompanyTypeCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Activity Code")}
                inputName={"ActivityCode"}
                errorText={formik.errors.ActivityCode}
                inputValue={formik.values.ActivityCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Client ID")}
                inputName={"ClientID"}
                errorText={formik.errors.ClientID}
                inputValue={formik.values.ClientID}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Client Secret 1")}
                inputName={"ClientSecret1"}
                errorText={formik.errors.ClientSecret1}
                inputValue={formik.values.ClientSecret1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Client Secret 2")}
                inputName={"ClientSecret2"}
                errorText={formik.errors.ClientSecret2}
                inputValue={formik.values.ClientSecret2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Branch Code")}
                inputName={"address.branchID"}
                inputValue={formik.values.address?.branchID}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Governate")}
                inputName={"address.governate"}
                inputValue={formik.values.address?.governate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Area")}
                inputName={"address.regionCity"}
                inputValue={formik.values.address?.regionCity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Street")}
                inputName={"address.street"}
                inputValue={formik.values.address?.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Building Number")}
                inputName={"address.buildingNumber"}
                inputValue={formik.values.address?.buildingNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Hours Waiting To Upload")}
                inputName={"HoursDelayToUploadTransaction"}
                inputValue={formik.values.HoursDelayToUploadTransaction}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
              />
              <TextBox
                labelName={getLabelName("Days Can Upload Transaction")}
                inputName={"DysAllowedToUploadEinvoice"}
                inputValue={formik.values.DysAllowedToUploadEinvoice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
              />
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <ButtonBox
              type="submit"
              className="mx-2"
              variant="primary"
              iconType="content-save"
            >
              {getLabelName("save")}
            </ButtonBox>
            <ButtonBox
              iconType="refresh"
              variant="primary"
              type="button"
              onClick={formik.handleReset}
            >
              {getLabelName("reset")}
            </ButtonBox>
          </Card.Footer>
        </Card>
      </form>
    </>
  );
  //#endregion
};
