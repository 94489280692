import React, {FC} from "react";
import {PageEnum, TransactionReportSearchParamsModel} from "../../../models";
import {ReportPage, ReportTransactionSearchPanel} from "../../../components";
import {getPageNameByPageId} from "../../../utils";
import {searchPurchaseTransactionsReport} from "../../../serviceBroker/transationReportApiServiceBroker";

export const ReportPurchaseTransactions: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchPurchaseTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportPurchases)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportPurchases}
        />
    );
};
