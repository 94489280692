import {GeneralPrintResponseModel, ResponseBaseModel, TreasuryReportSearchParamsModel} from "../models";
import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const searchReportInMoney = async (request: TreasuryReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.treasuryReportController.reportInMoney, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReportOutMoney = async (request: TreasuryReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.treasuryReportController.reportOutMoney, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReportInAndOutMoney = async (request: TreasuryReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.treasuryReportController.reportInAndOutMoney, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReportAllInAndOutMoney = async (request: TreasuryReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.treasuryReportController.reportAllInAndOutMoney, {params: AxiosSearchParams(request, 'searchParmeter')});
};
//#endregion
