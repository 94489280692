import {getGridColumns} from "../../../../../serviceBroker/commonApiServiceBroker";
import { getUserId} from "../../../../../utils";
import {TableColumn} from "react-data-table-component";
import React from "react";

const parseSelectorValue = (value: any): any => {
    if (typeof value == "boolean") {
        return <input type="checkbox" checked={value} disabled={false} />;
    }
    return value;
};

interface getStaticColumnsProps {
    gridId: number;
    data: any[];
    isArabic: boolean;
}

export const getStaticColumns = async ({
                                           gridId,
                                           data,
                                           isArabic,
                                       }: getStaticColumnsProps) => {

    const userId = getUserId();
    const listKeys = Object.keys(data[0]).map(key => key.toLowerCase());
    const columnsSettings = await getGridColumns(userId, gridId);
    if (!columnsSettings) return { settingsColumns: [], gridColumns: [] };
    const gridColumns: TableColumn<any>[] = columnsSettings
        .filter((item) => listKeys.includes(item.Field.toLowerCase()))
        .filter(({ Visable }) => Visable)
        .sort(({ DisplayIndex: a }, { DisplayIndex: b }) => Number(a) - Number(b))
        .map((item) => {
            return {
                name: isArabic ? item.ArabicName : item.EnglishName,
                selector: (row: any) => {
                    const fieldKey = Object.keys(row).find(key => key.toLowerCase() === item.Field.toLowerCase());
                    return parseSelectorValue(row[fieldKey||item.Field]);
                },
                sortable: true,
                wrap: true,
                grow: item.Grow || 1,
                width: `${item.Width}%` || "auto",
            };
        });
          //console.log('res_x_0', JSON.stringify( Object.keys(data[0])))
          //console.log('res_x_1',  JSON.stringify(columnsSettings))
          //console.log('res_x_2',  JSON.stringify(gridColumns))
    const settingsColumns = columnsSettings
        .filter((item) => listKeys.includes(item.Field.toLowerCase()))
        .filter(({ ShowToUser }) => ShowToUser)
        .sort(({ DisplayIndex: a }, { DisplayIndex: b }) => Number(b) - Number(a))
        .sort(({ Visable }) => (Visable ? -1 : 1));
    return { gridColumns, settingsColumns };
};
