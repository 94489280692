import {FC, ReactNode} from "react";
import {Modal} from "react-bootstrap";
import {getLabelName, isArabicCurrentLanguage} from "../../../utils";
import {ButtonBox} from "../..";

type ModelSize = "sm" | "lg" | "xl" | undefined;
export const ModelDialogBox: FC<{
    isModelVisible: boolean;
    isCloseButtonVisible?: boolean;
    isXCloseButtonVisible?: boolean;
    isEscapeCloseEnabled?: boolean;
    title?: string;
    size?: ModelSize | undefined;
    fullScreen?: boolean | undefined;
    onCloseEvent?: any;
    children?: ReactNode | undefined;
}> = ({
          isModelVisible = false,
          isCloseButtonVisible = false,
          isXCloseButtonVisible = false,
          isEscapeCloseEnabled = false,
          title,
          size = undefined,
          fullScreen = false,
          onCloseEvent,
          children,
      }) => {
    //#region variables
    const isArabic: boolean = isArabicCurrentLanguage();
    const direction: string = isArabic ? "rtl" : "ltr";
    //#endregion
    //#region html
    return (
        <>
            <Modal
                show={isModelVisible}
                onHide={() => {
                    isEscapeCloseEnabled && onCloseEvent();
                }}
                centered
                size={size}
                // @ts-ignore
                fullscreen={fullScreen}
            >
                {/*<Modal.Header
          dir={direction}
          closeButton={isCloseButtonVisible}
        ></Modal.Header>
         {title && <Modal.Title>{title}</Modal.Title>}
        <Modal.Body dir={direction}></Modal.Body> 
        {isCloseButtonVisible && (
          <Modal.Footer dir={direction}>
            <ButtonBox variant="primary" onClick={() => onCloseEvent()}>
              {t("close")}
            </ButtonBox>
          </Modal.Footer>
        )} */}
                <Modal.Header dir={direction} closeButton={isCloseButtonVisible || isXCloseButtonVisible}>
                    {title && <Modal.Title>{title}</Modal.Title>}
                </Modal.Header>
                <Modal.Body dir={direction}>{children}</Modal.Body>
                {isCloseButtonVisible && (
                    <Modal.Footer dir={direction}>
                        <ButtonBox variant="danger" size="sm" iconType="close-circle" onClick={() => onCloseEvent()}>
                            {getLabelName("close")}
                        </ButtonBox>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
    //*#endregion
};
