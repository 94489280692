import {useEffect, useState} from "react";
import {LookupItemModel, LookupTypeEnum} from "../../models";
import {getLookUp} from "../../components/transactions/common/businessLogic/transactionBl";

const useStoredUsers = () => {
    const [storedUsersList, setStoredUsersList] = useState<LookupItemModel[]>([]);
    const [loading, setIsLoading] = useState(false);
    const fetchEmployees = async () => {
        setIsLoading(true);
        const fetchedEmployees = await getLookUp(
            [setStoredUsersList],
            LookupTypeEnum.Users,
            true,
            true
        );
        setStoredUsersList(fetchedEmployees);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchEmployees().then(() => {
        });
    }, []);

    return {
        storedUsersList,
        usersLoading: loading,
    };
};

export default useStoredUsers;
