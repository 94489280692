import React, {FC, useEffect, useState} from "react";
import {CategoryListTax, LoadingBox, ToastBox} from "../../components";
import {CategoryResponseModel, HasFormIdModel, RequestActionModel, ToastModel,} from "../../models";
import {getCategories} from "../../serviceBroker/categoryApiServiceBroker";

export const UpdateTaxCategoryPage: FC<HasFormIdModel> = ({formId}) => {
    //#region state
    const [loading, setLoading] = useState(false);
    const [objects, setObjects] = useState<CategoryResponseModel[]>([]);
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await getAllObjects();
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region function
    const getAllObjects = async () => {
        setLoading(true);
        const result = await getCategories();
        setObjects(result.Result || []);
        setLoading(false);
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {/* modify category  */}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            {objects !== null &&
                objects !== undefined &&
                objects.length !== 0 && (
                    <CategoryListTax
                        formId={formId}
                        request={objects}
                        onActionEvent={(o: RequestActionModel) => {}}
                        onCompleteEvent={getAllObjects}
                    />
                )}
        </>
    );
    //#endregion
};
