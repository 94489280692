import {FC} from "react";
import {ReportOrderStatusLogSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {searchReportOrderStatusLog,} from "../../../serviceBroker/transationReportApiServiceBroker";
import {PageEnum, TransactionReportSearchParamsModel} from "../../../models";

export const ReportOrderStatusLogPage: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportOrderStatusLog}
            searchHeader={getPageNameByPageId(PageEnum.OrderStatusSummery)}
            SearchPanel={ReportOrderStatusLogSearchPanel}
            formId={PageEnum.OrderStatusSummery}
        />
    );
};
