import {AcTransactionTypeEnum, PageEnum} from "../../../models";
import {getPageNameByPageId} from "../../../utils";
import {getCurrentAccountBalance} from "../../../serviceBroker/accountApiServiceBroker";

export const getFromAccountIdBasedOnTransactionType = (acTransactionType: AcTransactionTypeEnum): number => {
    let accountId: number = 0;
    switch (+acTransactionType) {
        case AcTransactionTypeEnum.MoneyOut:
        case AcTransactionTypeEnum.SupplierPayments:
        case AcTransactionTypeEnum.RefundCustomerPayments:
            accountId = -30;
            break;
    }
    return accountId;
}
export const getToAccountIdBasedOnTransactionType = (acTransactionType: AcTransactionTypeEnum): number => {
    let accountId: number = 0;
    switch (+acTransactionType) {
        case AcTransactionTypeEnum.MoneyIn:
        case AcTransactionTypeEnum.CustomerPayment:
        case AcTransactionTypeEnum.RefundSupplierPayments:
            accountId = -30;
            break;
    }
    return accountId;
}

export const getAccountBalance = async (
    accountId: number,
    currencyId: number | null,
): Promise<number> => {
    try {

        if (accountId !== 0) {
            return await getCurrentAccountBalance(accountId || 0, currencyId);
        }
    } catch (err) {
    }
    return 0;
};
export const getPageNameBasedOnTransactionType = (acTransactionType: AcTransactionTypeEnum): string => {
    let pageName: string = "";
    switch (+acTransactionType) {
        case AcTransactionTypeEnum.MoneyIn:
            pageName = getPageNameByPageId(PageEnum.moneyIn)
            break;
        case AcTransactionTypeEnum.MoneyOut:
            pageName = getPageNameByPageId(PageEnum.moneyOut)
            break;
        case AcTransactionTypeEnum.SupplierPayments:
            pageName = getPageNameByPageId(PageEnum.SuppliersPayments)
            break;
        case AcTransactionTypeEnum.CustomerPayment:
            pageName = getPageNameByPageId(PageEnum.CustomersPayments)
            break;
        case AcTransactionTypeEnum.RefundCustomerPayments:
            pageName = getPageNameByPageId(PageEnum.RefundCustomersPayment)
            break;
        case AcTransactionTypeEnum.RefundSupplierPayments:
            pageName = getPageNameByPageId(PageEnum.RefundSuppliersPayment)
            break;
    }
    return pageName;
}
