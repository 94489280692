import {
    ChangeItemValueTypeEnum, StoreTransferTransactionDetailResponseModel,
    TransactionItemResponseModel
} from "../../../../models";
import React, {Dispatch, SetStateAction} from "react";
import {TableColumn} from "react-data-table-component";
import { getLabelName, roundNumber} from "../../../../utils";
import {updateTransactionItemStateOnChange,handleCalculateExistItemValuesChange} from "../businessLogic/storeTransferTransactionBl";

export const getStoreTransferTransactionItemsColumns = (
    isArabic: boolean,
    setState: Dispatch<SetStateAction<StoreTransferTransactionDetailResponseModel>>,
    stateValue: StoreTransferTransactionDetailResponseModel,
): TableColumn<TransactionItemResponseModel>[] => {
    let columns: TableColumn<TransactionItemResponseModel>[] = [
        {
            name: "#",
            selector: (_row, index) => Number(index || 0) + 1,
            width: "50px",
        },
        {
            name: (
                <label style={{width: "fit-content"}}>
                    {getLabelName("ItemCode")}
                </label>
            ),
            selector: (row) => row.ItemCode || "",
            sortable: true,
        },
        {
            name: (
                // <div style={{ width: "fit-content" }}>{getLabelName("ItemName")}</div>
                <label style={{width: "fit-content"}}>
                    {getLabelName("ItemName")}
                </label>
            ),
            selector: (row) => (isArabic ? row.ItemName : row.ItemName_En),
            sortable: true,
        },
        {
            name: getLabelName("Quantity"),
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`quantity_key_${index}`}
                    value={row.Quantity}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                    onChange={async (e: any) => {
                        const val = e.target.value === null ? 0 : Number(e.target.value);
                        await updateTransactionItemStateOnChange(setState, stateValue, {
                            ...row,
                            changeValueType: ChangeItemValueTypeEnum.None,
                            Quantity: val,
                        });
                    }}
                    onBlur={async () => {
                        await updateTransactionItemStateOnChange(setState, stateValue, row);
                    }}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Price"),
            id: "price_column",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`price_key_${index}`}
                    value={row.Unit_Price}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                    onChange={async (e: any) => {
                        const val = e.target.value === null ? 0 : Number(e.target.value);
                        await updateTransactionItemStateOnChange(setState, stateValue, {
                            ...row,
                            changeValueType: ChangeItemValueTypeEnum.ChangePrice,
                            Unit_Price: val,
                        })
                    }}
                    onBlur={async () => {
                        await handleCalculateExistItemValuesChange(setState, stateValue, row);
                    }}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Price"),
            id: "price_inCurrency_column",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`price_in_currency_key_${index}`}
                    value={row.Unit_PriceInCurrencyCalculated}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                    onChange={async (e: any) => {
                        const val = e.target.value === null ? 0 : Number(e.target.value);
                        await updateTransactionItemStateOnChange(setState, stateValue, {
                            ...row,
                            changeValueType: ChangeItemValueTypeEnum.ChangePriceInCurrency,
                            Unit_PriceInCurrencyCalculated: val,
                        });
                    }}
                    onBlur={async () => {
                        await handleCalculateExistItemValuesChange(setState, stateValue, row);
                    }}

                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Price_With_Tax"),
            id: "price_with_tax_column",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`price_with_tax_key_${index}`}
                    value={row.UnitPriceWithTaxCalculated}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                    onChange={async (e: any) => {
                        const val = e.target.value === null ? 0 : Number(e.target.value);
                        await updateTransactionItemStateOnChange(setState, stateValue, {
                            ...row,
                            changeValueType: ChangeItemValueTypeEnum.ChangePriceWithTax,
                            UnitPriceWithTaxCalculated: val,
                        });
                    }}
                    onBlur={async () => {
                        await handleCalculateExistItemValuesChange(setState, stateValue, row);
                    }}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Price_With_Tax"),
            id: "price_with_tax_inCurrency_column",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`price_with_tax_inCurrency_key_${index}`}
                    value={row.UnitPriceWithTaxInCurrency}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                    onChange={async (e: any) => {
                        const val = e.target.value === null ? 0 : Number(e.target.value);
                        await updateTransactionItemStateOnChange(setState, stateValue, {
                            ...row,
                            changeValueType: ChangeItemValueTypeEnum.ChangePriceWithTaxInCurrency,
                            UnitPriceWithTaxInCurrency: val,
                        });
                    }}
                    onBlur={async () => {
                        await handleCalculateExistItemValuesChange(setState, stateValue, row);
                    }}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Discount"),
            id: "discount_colum",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`discount_key_${index}`}
                    value={row.ItemDiscount}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                    onChange={async (e: any) => {
                        const val = e.target.value === null ? 0 : Number(e.target.value);
                        await updateTransactionItemStateOnChange(setState, stateValue, {
                            ...row,
                            changeValueType: ChangeItemValueTypeEnum.None,
                            ItemDiscount: val,
                        })
                    }}
                    onBlur={async () => {
                        await handleCalculateExistItemValuesChange(setState, stateValue, row);
                    }}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Discount"),
            id: "discount_inCurrency_colum",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`discount_inCurrency_key_${index}`}
                    value={row.ItemDiscountInCurrency}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                    onChange={async (e: any) => {
                        const val = e.target.value === null ? 0 : Number(e.target.value);
                        await updateTransactionItemStateOnChange(setState, stateValue, {
                            ...row,
                            changeValueType: ChangeItemValueTypeEnum.ChangeDiscountInCurrency,
                            ItemDiscountInCurrency: val,
                        })
                    }}
                    onBlur={async () => {
                        await handleCalculateExistItemValuesChange(setState, stateValue, row);
                    }}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Tax"),
            id: "item_tax_column",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`item_tax_key_${index}`}
                    value={row.Tax}
                    readOnly={true}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("t_i_c"),
            id: "item_tax_inCurrency_column",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`item_tax_inCurrency_key_${index}`}
                    value={row.TaxInCurrency}
                    readOnly={true}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Total_Tax"),
            id: "item_total_tax_column",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`item_total_tax_key_${index}`}
                    value={row.TotalTax}
                    readOnly={true}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("t_t_i_c"),
            id: "item_total_tax_inCurrency_column",
            cell: (row: TransactionItemResponseModel, index) => (
                <input
                    type="number"
                    className="form-control"
                    key={`item_total_tax_inCurrency_key_${index}`}
                    value={row.TotalTaxInCurrency}
                    readOnly={true}
                    onWheel={event => {
                        event.currentTarget.blur()
                    }}
                />
            ),
            width: "100px",
        },
        {
            name: getLabelName("Total"),
            id: "total_column",
            selector: (row) => row.Total,
            sortable: true,
            width: "100px",
        },
        {
            name: getLabelName("Total"),
            id: "total_inCurrency_column",
            selector: (row) => roundNumber(row.TotalInCurrency),
            sortable: true,
            width: "100px",
        },
    ];
    // if (!companySettings.ApplyTax) {
    //     columns = columns.filter(column => column.id !== "price_with_tax_inCurrency_column");
    //     columns = columns.filter(column => column.id !== "item_tax_inCurrency_column");
    //     columns = columns.filter(column => column.id !== "item_total_tax_inCurrency_column");
    // }
    columns = columns.filter(colum => colum.id !== "price_column");
    columns = columns.filter(column => column.id !== "price_with_tax_column");
    columns = columns.filter(column => column.id !== "discount_colum");
    columns = columns.filter(column => column.id !== "item_tax_column");
    columns = columns.filter(column => column.id !== "item_total_tax_column");
    columns = columns.filter(column => column.id !== "total_column");
    return columns;
};

// export const getStoreTransferTransactionListColumns = (
//     isArabic: boolean,
//     transactionType: DailyTransactionTypeEnum,
//     onActionEvent: (o: RequestActionModel) => void,
//     FormId: number,
// ): TableColumn<StoreTransferTransactionDetailResponseModel>[] => {
//     let columns: TableColumn<TransactionResponseModel>[] = [
//         {
//             name: "#",
//             selector: (_row, index) => Number(index || 0) + 1,
//             width: "50px",
//         },
//         {
//             name: (
//                 // <div style={{ width: "fit-content" }}>{getLabelName("ItemName")}</div>
//                 <label style={{width: "fit-content"}}>
//                     {getLabelName("CustomerName")}
//                 </label>
//             ),
//             selector: (row) => (isArabic ? row. : row.customerNameEn),
//             sortable: true,
//         },
//         {
//             name: getLabelName("identity"),
//             selector: (row) => row.id,
//             sortable: true,
//             wrap: true,
//             allowOverflow: true,
//         },
//         {
//             name: getLabelName("Code"),
//             selector: (row) => row.code,
//             sortable: true,
//             allowOverflow: true,
//         },
//         {
//             name: getLabelName("Date"),
//             selector: (row) => formatDate(row.creationDate, "dd/mm/yyyy hh:mm TT"),
//             width: "150px",
//             sortable: true,
//             allowOverflow: true,
//         },
//         {
//             name: getLabelName("Discount"),
//             selector: (row) => row.discount || 0,
//             sortable: true,
//             allowOverflow: true,
//         },
//         {
//             name: getLabelName("NetMoney"),
//             selector: (row) => row.netMoney || 0,
//             sortable: true,
//             allowOverflow: true,
//         },
//         {
//             // eslint-disable-next-line react/button-has-type
//             cell: (row: TransactionResponseModel) =>
//                 getPagePrivileges().EnableUpdate && (
//                     <PrivilegesChecker formId={FormId} action="EnableUpdate">
//                         <ButtonBox
//                             iconType="pencil-box"
//                             onClick={async () => {
//                                 if (
//                                     await validateTransactionObject(
//                                         row,
//                                         ValidateTransactionModeEnum.Modify,
//                                         onActionEvent
//                                     )
//                                 ) {
//                                     onActionEvent({
//                                         id: row.id,
//                                         //request: row,
//                                         action: ActionTypeEnum.ModifyStart,
//                                     });
//                                 } else {
//                                 }
//                             }}
//                         >
//                             {getLabelName("Edit")}
//                         </ButtonBox>
//                     </PrivilegesChecker>
//                 ),
//         },
//         {
//             // eslint-disable-next-line react/button-has-type
//             cell: (row: TransactionResponseModel) =>
//                 getPagePrivileges().EnableDelete && (
//                     <PrivilegesChecker formId={FormId} action="EnableDelete">
//                         <ButtonBox
//                             iconType="delete"
//                             variant="danger"
//                             className="btn-xs"
//                             onClick={async () => {
//                                 if (
//                                     await validateTransactionObject(
//                                         row,
//                                         ValidateTransactionModeEnum.Delete,
//                                         onActionEvent
//                                     )
//                                 ) {
//                                     onActionEvent({
//                                         id: row.id,
//                                         request: row,
//                                         action: ActionTypeEnum.DeleteStart,
//                                     });
//                                 }
//                             }}
//                         >
//                             {getLabelName("Delete")}
//                         </ButtonBox>
//                     </PrivilegesChecker>
//                 ),
//         },
//         {
//             // eslint-disable-next-line react/button-has-type
//             cell: (row: TransactionResponseModel) =>
//                 <ButtonBox
//                     iconType="printer"
//                     //variant="danger"
//                     className="me-2"
//                     onClick={async () => {
//                         onActionEvent({
//                             id: row.id,
//                             action: ActionTypeEnum.PrintPdf,
//                         });
//                     }}
//                 >
//                     {getLabelName("Print")}
//                 </ButtonBox>,
//         },
//         {
//             id: "isValidForReturn_column",
//             cell: (row: TransactionResponseModel) =>
//                 <ButtonBox
//                     iconType="replay"
//                     //variant="danger"
//                     className="me-2"
//                     onClick={async () => {
//                         onActionEvent({
//                             id: row.id,
//                             action: ActionTypeEnum.TransactionRefundIInitialization,
//                         });
//                     }}
//                 >
//                     {getLabelName("Return")}
//                 </ButtonBox>,
//         }
//     ];
//     if (isRefund) {
//         columns = columns.filter(column => column.id !== "isValidForReturn_column");
//     }
//     return columns;
// };