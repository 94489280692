import {searchSalesTransactionsReport} from "../../../serviceBroker/transationReportApiServiceBroker";
import React, {FC} from "react";
import {PageEnum, TransactionReportSearchParamsModel} from "../../../models";
import {ReportPage, ReportTransactionSearchPanel} from "../../../components";
import {getPageNameByPageId} from "../../../utils";

export const ReportSalesTransactions: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchSalesTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportSales)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportSales}
        />
    );
};
