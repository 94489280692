import { FC, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ActionTypeEnum,
  RequestActionModel,
  ValidationErrorModel,
  EmployeeEstihkakSubtractResponseModel,
} from "../../models";
import { ButtonBox, ErrorValidationBox, LoadingBox, TextBox } from "..";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { Table } from "react-bootstrap";
import { SaveEstihkakSubtractRule } from "../../serviceBroker/employeeEstihkakSubtracApiServiceBroker";

export const AddEstihkaksubtractRule: FC<{
  request?: EmployeeEstihkakSubtractResponseModel | null;
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ request, onActionEvent = () => {} }) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const initialValues: EmployeeEstihkakSubtractResponseModel = request ?? {
    Notes: "",
    TypeRule_ID: 1,
    Name_En: "",
    Row_State: 0,
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: "",
    Name: "",
    CreationDate: new Date(),
    ModificationDate: new Date(),
    VerifyOnUpdate: false,
    rowState: 1,
  };
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [validationSchema] = useState(
    Yup.object({
      Name: Yup.string().required(getLabelName("Name.missing")),
    })
  );
  //#endregion
  //#region functions
  const handleSubmit = async (
    request: EmployeeEstihkakSubtractResponseModel
  ) => {
    try {
      setLoading(true);
      //@ts-ignore
      const res: ResponseBase<EmployeeEstihkakSubtracResponse> =
        await SaveEstihkakSubtractRule(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Failed,
          request: isArabic
            ? res.Errors[0].MessageAr
            : res?.Errors[0].MessageEn,
        });
        setLoading(false);
      } else {
        setValidationErrors([]);
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.AddSuccess,
          request: res?.Result,
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    // enableReinitialize: true,
    onReset: () => {
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.Clear,
      });
    },
    onSubmit: async (values) => {
      await handleSubmit(values);
      //resetForm();
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <form onSubmit={formik.handleSubmit}>
        <Table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <TextBox
                  labelName={getLabelName("Name")} //{t("lookup.nameAr")}
                  inputName={"Name"}
                  errorText={formik.errors.Name}
                  inputValue={formik.values.Name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </td>
              <td>
                <TextBox
                  //labelContainerClassName="col-md-2"
                  labelName={getLabelName("Name_En")}
                  inputName={"Name_En"}
                  errorText={formik.errors.Name_En}
                  inputValue={formik.values.Name_En}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <TextBox
                  //labelContainerClassName="col-md-2"
                  labelName={getLabelName("Notes")}
                  inputName={"Notes"}
                  errorText={formik.errors.Notes}
                  inputValue={formik.values.Notes}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className="accordion-footer">
                  <div className="col-md-12 d-flex justify-content-end">
                    <ButtonBox
                      iconType="account-plus"
                      type="submit"
                      className="btn btn-orange"
                      variant="outline-primary"
                    >
                      {getLabelName("Register")}
                    </ButtonBox>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </form>
    </>
  );

  //#endregion
};
