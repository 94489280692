import React, {useEffect, useState} from "react";
import {getFormPrivileges} from "../../../utils";
import {PrivilegesCheckerPropsModel} from "../../../models";

export type PrivilegeActions =
    | "EnableSave"
    | "EnableDelete"
    | "EnableUpdate"
    | "EnableSearch";

export const PrivilegesChecker: React.FC<PrivilegesCheckerPropsModel> = ({
                                                                        children,
                                                                        formId,
                                                                        action,
                                                                    }) => {
    const [isUserEligible, setIsUserEligible] = useState(false);

    const setUserPermission = () => {
        const permissions = getFormPrivileges(formId);
        // In case the response doesn't contain info about form permissions
        if (!permissions) return setIsUserEligible(true);
        if (!action) return setIsUserEligible(true);
        setIsUserEligible(permissions[action]);
    };

    useEffect(() => {
        setUserPermission();
    }, []);
    if (!isUserEligible) return <></>;
    return <>{children}</>;
};
