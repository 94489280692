import React, {FC} from "react";
import {ReportItemsSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {ItemReportSearchParamsModel, PageEnum} from "../../../models";
import {searchReportItemsReachedToLimitOrder,} from "../../../serviceBroker/itemsReportApiServiceBroker";

export const ReportItemsReachedToLimitOrderPage: FC = () => {
    const initialState: ItemReportSearchParamsModel = {lang: 1, userId: getUserId()};
    return (
        <ReportPage
            InitialState={initialState}
            SearchPanel={ReportItemsSearchPanel}
            SearchApi={searchReportItemsReachedToLimitOrder}
            searchHeader={getPageNameByPageId(PageEnum.ItemsReachedToLimitOrder)}
            formId={PageEnum.ItemsReachedToLimitOrder}
        />
    );
};
