import React, { useEffect } from "react";
import {
  ActionTypeEnum,
  DailyTransactionTypeEnum,
  PaymentValueDetailsModel,
  PosUserPermissionActionTypeEnum,
  RequestActionModel, RowStateEnum,
  TransactionDetailResponseModel,
  TransactionItemPermissionActionResponseModel,
  TransactionItemResponseModel,
} from "../../../models";
import {
  getLabelName,
  getUserId,
  isArabicCurrentLanguage,
  scrollToDocumentTop,
} from "../../../utils";
import {
  ButtonBox,
  ErrorValidationBox,
  LoadingBox,
  PosActionButtons,
  PosTransactionSummary,
  PosUserPermissionLogin,
  TableComponent,
  TextBox,
  ToastBox,
} from "./../..";
import {
  handleTransactionTotalValues,
  handleUpdateExistingTransactionValues,
  transactionDetailInitialValue,
} from "../common/businessLogic/transactionBl";
import { TableColumn } from "react-data-table-component";
import { getPosTransactionItemsColumns } from "../common/uiHelper/posDataTableColumns";
import {
  getPosCategories,
  getPosItemList,
  handleAddItemPos,
  handleDeleteItemPos,
  handleResetTransactionPos,
  handleSaveAndPay,
  handleShowPrint,
  handleSortAlphabetically,
  handleValidateTransaction,
  initializePosTransactionValuesConfiguration,
  transformReservedTransactionData,
} from "../common/businessLogic/posTransactionBl";
import {
  cancelReservation,
  saveTransaction,
} from "../../../serviceBroker/transactionApiServiceBroker";
import { usePosTransactionsState } from "./helper/state/posTransactionsState";
import { handleItemRowStyle } from "../common/uiHelper/dataTableColumns";
import { getUserTransactionsSettings } from "../../../serviceBroker/userApiServiceBroker";
import { SystemConfiguration } from "../../../configuration";
import { UpdateTransactionItemStateOnChange } from "../common/businessLogic/commonBl";
import { useSidebar } from "../../../hooks";
import POSInvoiceType, {
  POSInvoiceTypeProps,
} from "./restaurantPOS/posInvoiceType";

interface PosTransactionsMainComponentProps {
  InvoiceTypeComponent?: React.FC<POSInvoiceTypeProps>;
  transactionTypeID: DailyTransactionTypeEnum;
}

export const PosTransactionsMainComponent: React.FC<
  PosTransactionsMainComponentProps
> = ({ InvoiceTypeComponent, transactionTypeID }) => {
  //#region state
  const {
    loading,
    setLoading,
    isItemListLoading,
    setIsItemListLoading,
    validationErrors,
    setValidationErrors,
    toastModel,
    setToastModel,
    categoriesList,
    setCategoriesList,
    itemsList,
    setItemsList,
    transactionData,
    setTransactionData,
    employee,
    setEmployee,
    beneficiary,
    setBeneficiary,
    showPaymentDetailsModel,
    setShowPaymentDetailsModel,
    activeTabItem,
    setActiveTabItem,
    printTransactionId,
    setPrintTransactionId,
    showPrintoutPdfModel,
    setShowPrintoutPdfModel,
    transactionType,
    searchText,
    setSearchText,
    posSetting,
    setPosSetting,
    companySettings,
    setTransactionUserSettingList,
    transactionUserSetting,
    setTransactionUserSetting,
    itemPerAction,
    setItemPerAction,
    userActionUiModel,
    setUserActionUiModel,
    userTransactionPermissionRequest,
    setUserTransactionPermissionRequest,
    searchTextInputRef,
  } = usePosTransactionsState(transactionTypeID);
  const { setIsOpen } = useSidebar();
  //#endregion
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const columns: TableColumn<TransactionItemResponseModel>[] = [
    ...getPosTransactionItemsColumns(
      isArabic,
      transactionData.TransactionType,
      setTransactionData,
      transactionData,
      setUserTransactionPermissionRequest,
      userTransactionPermissionRequest,
      (o) => {
        handleAction(o).then(() => {});
      }
    ),
  ];
  //#endregion
  //#region functions
  const handleItemsList = async (categoryId: number) => {
    setIsItemListLoading(true);
    setActiveTabItem(categoryId);
    const Result = await getPosItemList(
      categoryId,
      null,
      null,
      posSetting.Store_ID
    );
    setItemsList(Result);
    setIsItemListLoading(false);
  };
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddPaymentValuesSuccess:
        setShowPaymentDetailsModel(false);
        const paymentDetails: PaymentValueDetailsModel =
          request.request as PaymentValueDetailsModel;
        let dataObjet: TransactionDetailResponseModel = {
          ...transactionData,
          CashMoney: paymentDetails.cash,
          CardMoney: paymentDetails.credit,
          DelayMoney: paymentDetails.delay,
          OthersMoney: paymentDetails.voucher,
          Voucher_ID: paymentDetails.voucherId,
          TransactionType:
            transactionData.TransactionType === 0
              ? Number(transactionType)
              : transactionData.TransactionType ===
                DailyTransactionTypeEnum.Reserve_Resturant_Bill
              ? DailyTransactionTypeEnum.POSResurant
              : transactionData.TransactionType,
        };
        setTransactionData(dataObjet);
        await handleSaveTransactionDetails(dataObjet);
        break;
      case ActionTypeEnum.ResetObject:
        setLoading(true);
        await handleResetTransactionPos(
          setTransactionData,
          beneficiary,
          setBeneficiary,
          employee,
          setEmployee,
          setValidationErrors,
          setPosSetting,
          transactionType
        );
        setLoading(false);
        break;
      case ActionTypeEnum.AddBeneficiaryResponse:
        setBeneficiary({
          value: request.request.request.ID,
          name: request.request.request.Name_En,
          nameAr: request.request.request.Name,
        });
        break;
      case ActionTypeEnum.PrintPdf:
        await handleShowPrint(
          printTransactionId,
          setShowPrintoutPdfModel,
          setValidationErrors
        );
        break;
      case ActionTypeEnum.Save:
        await handleSaveAndPay(
          { ...transactionData },
          setShowPaymentDetailsModel,
          setValidationErrors
        );
        break;
      case ActionTypeEnum.SelectEmployeeResponse:
        setEmployee(request.request);
        break;
      case ActionTypeEnum.SelectCustomerResponse:
        setBeneficiary(request.request);
        break;
      case ActionTypeEnum.SelectItemResponse:
        setLoading(true);
        await handleAddItemPos(
          request.request,
          setTransactionData,
          transactionData
        );
        setLoading(false);
        break;
      case ActionTypeEnum.TransactionRefund:
        setLoading(true);
        setSearchText("");
        setValidationErrors([]);
        const modifyTransactionObject: TransactionDetailResponseModel =
          request.request;
        //setTransactionType( modifyTransactionObject.TransactionType);
        if (
          modifyTransactionObject.TransactionDetaillist !== null &&
          modifyTransactionObject.TransactionDetaillist !== undefined &&
          modifyTransactionObject.TransactionDetaillist.length !== 0
        ) {
          modifyTransactionObject.TransactionDetaillist.forEach((row) => {
            row = handleUpdateExistingTransactionValues(row);
            return { ...row };
          });
        }
        const dataObject = handleTransactionTotalValues(
          modifyTransactionObject
        );
        setTransactionData({
          ...dataObject,
          TransactionDetaillist: [...dataObject.TransactionDetaillist],
        });
        setLoading(false);
        break;
      case ActionTypeEnum.UpdatePosOptions:
        setLoading(true);
        setTransactionData(request.request);
        setLoading(false);
        break;
      case ActionTypeEnum.SelectReservedTable:
        setLoading(true);
        const reservedTableTransaction = transformReservedTransactionData(
          request.request
        );
        setTransactionData(reservedTableTransaction);
        setLoading(false);
        break;
      case ActionTypeEnum.CancelReservation:
        setLoading(true);
        //  here goes tha api call
        await cancelReservation(
          transactionData.ID,
          transactionData.User_Create,
          request.request.reason
        );
        await handleResetTransactionPos(
          setTransactionData,
          beneficiary,
          setBeneficiary,
          employee,
          setEmployee,
          setValidationErrors,
          setPosSetting,
          transactionType
        );
        setLoading(false);
        break;
      case ActionTypeEnum.ReserveTable:
        // setLoading(true);
        // const reservedTableTransaction = transformReservedTransactionData(
        //   request.request
        // );
        // setTransactionData(reservedTableTransaction);
        // setLoading(false);
        const isUpdated = !!transactionData.ID;
        await handleSaveTransactionDetails({
          ...transactionData,
          TransactionType: DailyTransactionTypeEnum.Reserve_Resturant_Bill,
          rowState: isUpdated ? RowStateEnum.Update : RowStateEnum.Add,
          User_Update: isUpdated ? getUserId() : 0,
        });
        break;
      case ActionTypeEnum.DeleteTransactionItem:
        if (!posSetting.AllowDeleteItems) {
          handleDeleteItemPos(
            request.request,
            setTransactionData,
            transactionData
          );
        } else {
          setItemPerAction(request.request);
          setUserActionUiModel({
            ...userActionUiModel,
            userPermissionType:
              PosUserPermissionActionTypeEnum.DeleteItemAction,
            showLoginModel: true,
          });
        }
        break;
      case ActionTypeEnum.EditTransactionItemPrice:
        if (posSetting.HasPermToEditPrice) {
          await UpdateTransactionItemStateOnChange(
            setTransactionData,
            transactionData,
            request.request
          );
        } else {
          setItemPerAction(request.request);
          setUserActionUiModel({
            ...userActionUiModel,
            userPermissionType:
              PosUserPermissionActionTypeEnum.EditItemPriceAction,
            showLoginModel: true,
          });
        }
        break;
      case ActionTypeEnum.EditTransactionItemDiscount:
        if (posSetting.HasPermToDiscount) {
          await UpdateTransactionItemStateOnChange(
            setTransactionData,
            transactionData,
            request.request
          );
        } else {
          setItemPerAction(request.request);
          setUserActionUiModel({
            ...userActionUiModel,
            userPermissionType:
              PosUserPermissionActionTypeEnum.EditItemDiscountAction,
            showLoginModel: true,
          });
        }
        break;
      case ActionTypeEnum.GetUserPOSPermission:
        const requestObject: TransactionItemPermissionActionResponseModel =
          request.request as TransactionItemPermissionActionResponseModel;
        switch (requestObject.userPermissionType) {
          case PosUserPermissionActionTypeEnum.DeleteItemAction:
            // @ts-ignore
            handleDeleteItemPos(
              itemPerAction!,
              setTransactionData,
              transactionData
            );
            setItemPerAction(null);
            break;
          case PosUserPermissionActionTypeEnum.EditItemPriceAction:
            // @ts-ignore
            await UpdateTransactionItemStateOnChange(
              setTransactionData,
              transactionData,
              itemPerAction!
            );
            setUserTransactionPermissionRequest({
              ...userTransactionPermissionRequest,
              isTransactionPriceEditable: true,
            });
            setItemPerAction(null);
            break;
          case PosUserPermissionActionTypeEnum.EditItemDiscountAction:
            // @ts-ignore
            await UpdateTransactionItemStateOnChange(
              setTransactionData,
              transactionData,
              itemPerAction!
            );
            setUserTransactionPermissionRequest({
              ...userTransactionPermissionRequest,
              isTransactionDiscountEditable: true,
            });
            setItemPerAction(null);
            break;
        }
        break;
      case ActionTypeEnum.RefundWithoutBill:
        setTransactionData({
          ...transactionDetailInitialValue,
          TransactionType: DailyTransactionTypeEnum.POSSalesRefund,
        });
        break;
    }
  };
  const handleSaveTransactionDetails = async (
    request: TransactionDetailResponseModel
  ) => {
    setLoading(true);
    const errors = await handleValidateTransaction(request);
    if (errors.length !== 0) {
      setValidationErrors(errors);
      return;
    }
    const result = await saveTransaction(request);
    if (
      result.Errors !== null &&
      result.Errors !== undefined &&
      result.Errors.length !== 0
    ) {
      scrollToDocumentTop();
      setValidationErrors(result.Errors);
    } else {
      scrollToDocumentTop();
      setPrintTransactionId(result.Result?.TransactionId || 0);
      setTransactionData({ ...transactionDetailInitialValue });
      setToastModel({ ...toastModel, show: true });
      setValidationErrors([]);
    }
    setLoading(false);
  };
  //#endregion
  //# region useEffect
  useEffect(() => {
    if (searchTextInputRef.current) {
      searchTextInputRef.current.focus();
    }
    const fillData = async () => {
      setLoading(true);
      let categories = await getPosCategories();
      handleSortAlphabetically(categories);
      setCategoriesList(categories);
      await initializePosTransactionValuesConfiguration(
        setTransactionData,
        transactionData,
        beneficiary,
        setBeneficiary,
        employee,
        setEmployee,
        setPosSetting,
        transactionType,
        [
          transactionTypeID,
          DailyTransactionTypeEnum.ReturnPOSResturant,
          DailyTransactionTypeEnum.POSSalesRefund,
        ]
      );
      const settingsList = await getUserTransactionsSettings(
        [
          DailyTransactionTypeEnum.POSSales,
          DailyTransactionTypeEnum.POSSalesRefund,
        ],
        true,
        SystemConfiguration.cacheKey.posSettingsList
      );
      const currentTransactionSetting = settingsList.filter(
        (p) => p.TransactionType_ID === transactionType
      )[0];
      setUserTransactionPermissionRequest({
        ...userTransactionPermissionRequest,
        isPosPriceEditable: posSetting.HasPermToEditPrice,
        isPosDiscountEditable: posSetting.HasPermToDiscount,
      });
      setTransactionUserSettingList(settingsList);
      setTransactionUserSetting(currentTransactionSetting);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    // getTotalPricePost(setTransactionData, transactionData);
  }, [transactionData.TransactionDetaillist]);
  useEffect(() => {
    setIsOpen(true); // Open the sidebar when this component mounts
    // Optionally, clean up to reset state when component unmounts
    return () => {
      setIsOpen(false); // Close the sidebar when this component unmounts
    };
  }, [setIsOpen]);
  //# endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <>
        <PosActionButtons
          transactionData={transactionData}
          setTransactionData={setTransactionData}
          showPaymentDetailsModel={showPaymentDetailsModel}
          setShowPaymentDetailsModel={setShowPaymentDetailsModel}
          showPrintoutPdfModel={showPrintoutPdfModel}
          setShowPrintoutPdfModel={setShowPrintoutPdfModel}
          beneficiary={beneficiary}
          employee={employee}
          posSetting={posSetting}
          transactionSetting={transactionUserSetting}
          printTransactionId={printTransactionId}
          onActionEvent={async (o) => {
            await handleAction(o);
          }}
        />
        <PosTransactionSummary
          companySettings={companySettings}
          data={transactionData}
        />
        <div className="card p-3">
          <div className="row g-3">
            <div className="col-lg-2 col-md-2">
              <ul className="list-group pos-list">
                {categoriesList?.map((item, index) => (
                  <li
                    key={index}
                    className={`list-group-item list-group-item-action ${
                      activeTabItem === Number(item.value) ? "active" : " "
                    }`}
                    id={`category_btn_${index}`}
                    onClick={async () =>
                      await handleItemsList(Number(item.value))
                    }
                  >
                    {isArabic ? item.nameAr : item.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-4 col-md-4 mb-3">
              <LoadingBox isLoading={isItemListLoading}>
                <ul className="d-flex flex-wrap pos-list pos-list-item p-2">
                  {itemsList?.map((selectedItem, index) => (
                    <li
                      className="flex-grow-1 list-group-item list-group-item-action p-1 w-auto"
                      key={index}
                    >
                      <ButtonBox
                        variant="outline-success"
                        className="w-100"
                        id={`item_btn_${index}`}
                        onClick={() =>
                          handleAddItemPos(
                            selectedItem,
                            setTransactionData,
                            transactionData
                          )
                        }
                      >
                        {isArabic
                          ? selectedItem.ItemName
                          : selectedItem.ItemName_En}
                      </ButtonBox>
                    </li>
                  ))}
                </ul>
              </LoadingBox>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="row mb-5">
                <div className="col-8">
                  {InvoiceTypeComponent && (
                    <POSInvoiceType
                      setTransactionData={setTransactionData}
                      InvoiceType={transactionData.InvoiceType}
                      onActionEvent={async (o) => {
                        await handleAction(o);
                      }}
                    >
                      <POSInvoiceType.DeliveryButton
                        onActionEvent={async (o) => {
                          await handleAction(o);
                        }}
                      />
                      <POSInvoiceType.TakeawayButton
                        onActionEvent={async (o) => {
                          await handleAction(o);
                        }}
                      />
                      <POSInvoiceType.DineInButton
                        table_id={transactionData.Table_ID}
                        onActionEvent={async (o) => {
                          await handleAction(o);
                        }}
                      />
                    </POSInvoiceType>
                  )}
                  <TextBox
                    inputName="searchText"
                    inputValue={searchText}
                    labelName={""}
                    inputRef={searchTextInputRef}
                    placeHolder={getLabelName("barcode")}
                    onChange={(e: any) => {
                      setSearchText(e.target.value);
                    }}
                    onBlur={async () => {
                      if (searchText !== null && searchText !== "") {
                        setLoading(true);
                        const items = await getPosItemList(
                          null,
                          searchText,
                          null,
                          posSetting.Store_ID
                        );
                        if (items !== null && items.length != 0) {
                          await handleAddItemPos(
                            items[0],
                            setTransactionData,
                            transactionData
                          );
                        }
                        setLoading(false);
                      }
                    }}
                  />
                </div>
                {/*</div>*/}
                <div className="col-12 mt-3">
                  <TableComponent
                    columns={columns}
                    data={transactionData.TransactionDetaillist.filter(
                      (item) => item.rowState !== RowStateEnum.Delete
                    )}
                    totalRows={1000}
                    currentPage={1}
                    pageSize={10000}
                    paginationType="none"
                    conditionalRowStyles={handleItemRowStyle(
                      transactionData.TransactionDetaillist || []
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {userActionUiModel.showLoginModel && (
        <PosUserPermissionLogin
          userPermissionType={userActionUiModel.userPermissionType}
          onActionEvent={async (o: RequestActionModel) => {
            setUserActionUiModel({
              ...userActionUiModel,
              showLoginModel: false,
            });
            await handleAction(o);
          }}
        />
      )}
    </>
  );
  //#endregion
};
