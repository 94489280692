import React, {FC} from "react";
import {CustomerComplexReportSearchParamsModel, PageEnum} from "../../../models";
import {ReportCustomersComplexSearchPanel, ReportPage,} from "../../../components";
import {searchAllSalesAndReturnTransactionsReport} from "../../../serviceBroker/transationReportApiServiceBroker";
import {getPageNameByPageId, getUserId} from "../../../utils";

export const ReportAllCustomersTransactions: FC = () => {
    const initialState: CustomerComplexReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchAllSalesAndReturnTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportFollowCustomersInTransaction)}
            SearchPanel={ReportCustomersComplexSearchPanel}
            formId={PageEnum.ReportFollowCustomersInTransaction}
        />
    );
};
