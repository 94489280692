import React, {FC} from "react";
import {AcTransactionComponent} from "../../components";
import {AcTransactionTypeEnum, PageEnum} from "../../models";

export const MoneyOut: FC = () => {
    return (
        <AcTransactionComponent
            acTransactionType={AcTransactionTypeEnum.MoneyOut}
            formId={PageEnum.moneyOut}
        />
    );
};
export const MoneyIn: FC = () => {
    return (
        <AcTransactionComponent
            acTransactionType={AcTransactionTypeEnum.MoneyIn}
            formId={PageEnum.moneyIn}
        />
    );
};
export const SupplierPaymentsPage: FC = () => {
    return (
        <AcTransactionComponent
            acTransactionType={AcTransactionTypeEnum.SupplierPayments}
            formId={PageEnum.SuppliersPayments}
        />
    );
};
export const CustomerPaymentPage: FC = () => {
    return (
        <AcTransactionComponent
            acTransactionType={AcTransactionTypeEnum.CustomerPayment}
            formId={PageEnum.CustomersPayments}
        />
    );
};
export const RefundSupplierPaymentsPage: FC = () => {
    return (
        <AcTransactionComponent formId={PageEnum.RefundSuppliersPayment}
                                acTransactionType={AcTransactionTypeEnum.RefundSupplierPayments}
        />
    );
};
export const RefundCustomerPaymentPage: FC = () => {
    return (
        <AcTransactionComponent
            formId={PageEnum.RefundCustomersPayment}
            acTransactionType={AcTransactionTypeEnum.RefundCustomerPayments}
        />
    );
};

export const AcComplexTransaction: FC = () => {
    return <AcComplexTransaction/>;
};
