import {useRef, useState} from "react";
import {
    BeneficiaryResponseModel,
    CompanySettingModel,
    DailyTransactionTypeEnum,
    LookupItemModel,
    PosTransactionsOptionsModifyTypeEnum,
    PosUserActionsUiRequestModel,
    PosUserPermissionActionTypeEnum,
    POSUserSettingModel,
    ToastModel,
    TransactionDetailResponseModel,
    TransactionItemResponseModel, UserTransactionPermissionRequestModel,
    UserTransactionsSettingResponseModel,
    ValidationErrorModel,
} from "../../../../../models";
import {getCompanySettings, transactionDetailInitialValue} from "../../../common/businessLogic/transactionBl";
import {beneficiaryInitialValues, employeeInitialValues} from "../../../common/businessLogic/posTransactionBl";

export const usePosTransactionsState = (transactionTypeID: DailyTransactionTypeEnum) => {
//#region state
    const [loading, setLoading] = useState(false);
    const [isItemListLoading, setIsItemListLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>([]);
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
        variant: "success",
    });
    const [categoriesList, setCategoriesList] = useState<LookupItemModel[]>([]);
    const [itemsList, setItemsList] = useState<
        TransactionItemResponseModel[]
    >([]);
    const [itemPerAction, setItemPerAction] = useState<TransactionItemResponseModel | null>(null);
    const [transactionData, setTransactionData] = useState<TransactionDetailResponseModel>(transactionDetailInitialValue);
    const [employee, setEmployee] = useState<BeneficiaryResponseModel>(employeeInitialValues);
    const [beneficiary, setBeneficiary] = useState<BeneficiaryResponseModel>(beneficiaryInitialValues);
    const [showPaymentDetailsModel, setShowPaymentDetailsModel] = useState(false);
    const [activeTabItem, setActiveTabItem] = useState(0);
    const [printTransactionId, setPrintTransactionId] = useState(0);
    const [showPrintoutPdfModel, setShowPrintoutPdfModel] = useState(false);
    const [transactionType, setTransactionType] = useState<DailyTransactionTypeEnum>(transactionTypeID);
    const [searchText, setSearchText] = useState("");
    const searchTextInputRef = useRef<HTMLInputElement>(null);
    const [companySettings, setCompanySettings] = useState<CompanySettingModel>(getCompanySettings);
    const [showUserPermissionLoginModel, setShowUserPermissionLoginModel] = useState<boolean>(false);
    const [transactionUserSettingList, setTransactionUserSettingList] = useState<UserTransactionsSettingResponseModel[]>([]);
    const [userTransactionPermissionRequest, setUserTransactionPermissionRequest] = useState<UserTransactionPermissionRequestModel>({
        isTransactionPriceEditable: false,
        isTransactionDiscountEditable: false,
        isPosPriceEditable: false,
        isPosDiscountEditable: false,
    })
    const [transactionUserSetting, setTransactionUserSetting] = useState<UserTransactionsSettingResponseModel>({
        DeafultNote: "",
        DefaultCalcTypeID: 0,
        DefaultPaymentTypeID: 0,
        DirectPrintBillAfterSave: false,
        EnableButonAdd: false,
        EnableDiscount: false,
        EnablePrint: false,
        EnableShangPrice: false,
        ID: 0,
        Name: null,
        NumberOfCopy: 0,
        PeriodAllowForReturn: 0,
        PrinterName: "",
        PrinterPrepairingSalesInvoice: "",
        RemarksVisable: "",
        ShowButtonPrintPrepairingOrder: false,
        ShowCalcType: false,
        ShowCinfirm: false,
        ShowCostCenter: false,
        ShowCurrency: false,
        ShowCustomerCar: false,
        ShowDone: false,
        ShowEmployeeResponsibile: false,
        ShowNotefy: false,
        ShowPaymentType: false,
        ShowPrice: false,
        ShowPrintStickerButton: false,
        ShowRefrence: false,
        ShowSaveAndPrintButton: false,
        ShowTransPortCost: false,
        TransactionType_ID: 0,
        User_ID: 0,
        VerifyOnUpdate: false,
        rowState: 0
    });
    const [posSetting, setPosSetting] = useState<POSUserSettingModel>({
        AllowClickNew: false,
        AllowDeleteItems: false,
        AllowReturnWithoutBill: false,
        AllowReturn: false,
        Ask_MoneyBox_Station: false,
        HasPermToDiscount: false,
        HasPermToEditPrice: false,
        CreatedBy: 0,
        CreationDate: new Date(),
        Currency_ID: 0,
        CustomerInfo: undefined,
        Customer_ID: 0,
        DefaultDiscountType: 0,
        DefaultNoteType: 0,
        DefaultRatioDiscount: 0,
        Emp_ID: 0,
        EmployeeInfo: undefined,
        EnableChangeCalcType: false,
        EnableCloseDay: false,
        EnablePrintSaleReportAndPrintCloseDay: false,
        EnablePrintSavedTransaction: false,
        ID: 0,
        ModificationDate: new Date(),
        ModifiedBy: 0,
        PortName: "",
        PrintInvoiceInA4: false,
        ShowItemForSecondScreenCustomerDisplay: false,
        Store_ID: 0,
        SupplierInfo: undefined,
        UseCustomerDisplay: false,
        UseItemImageAsBackGround: false,
        User_ID: undefined,
        VerifyOnUpdate: false,
        WelcomeMessage: "",
        rowState: 0
    });
    const [userActionUiModel, setUserActionUiModel] = useState<PosUserActionsUiRequestModel>({
        showLoginModel: false,
        showInvoiceOptionsModel: false,
        showRefundModel: false,
        userPermissionType: PosUserPermissionActionTypeEnum.None,
        transactionsOptionsModifyType: PosTransactionsOptionsModifyTypeEnum.None,
    });
//#endregion
    return {
        loading, setLoading,
        isItemListLoading, setIsItemListLoading,
        validationErrors, setValidationErrors,
        toastModel, setToastModel,
        categoriesList, setCategoriesList,
        itemsList, setItemsList,
        transactionData, setTransactionData,
        employee, setEmployee,
        beneficiary, setBeneficiary,
        showPaymentDetailsModel, setShowPaymentDetailsModel,
        activeTabItem, setActiveTabItem,
        printTransactionId, setPrintTransactionId,
        showPrintoutPdfModel, setShowPrintoutPdfModel,
        transactionType, setTransactionType,
        searchText, setSearchText,
        companySettings, setCompanySettings,
        posSetting, setPosSetting,
        showUserPermissionLoginModel, setShowUserPermissionLoginModel,
        transactionUserSettingList, setTransactionUserSettingList,
        transactionUserSetting, setTransactionUserSetting,
        itemPerAction, setItemPerAction,
        userActionUiModel, setUserActionUiModel,
        userTransactionPermissionRequest, setUserTransactionPermissionRequest,
        searchTextInputRef
    }

}