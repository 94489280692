import React from "react";
import Dropzone, {Accept} from "react-dropzone";

export interface EntityUploaderProps {
    acceptTypes: Accept;
    handleFileReject: () => void;
    handleFileDrop: (files: any) => void;
}

export const EntityUploader: React.FC<EntityUploaderProps> = ({
                                                                  acceptTypes,
                                                                  handleFileReject,
                                                                  handleFileDrop,
                                                              }) => {
    return (
        <Dropzone
            key={`dropZone-LogoImage64`}
            accept={acceptTypes}
            maxFiles={1}
            onDrop={(files: File[]) => {
                handleFileDrop(files);
            }}
            onDropRejected={handleFileReject}
        >
            {({getRootProps, getInputProps}) => {
                return (
                    <div>
                        <div {...getRootProps()} className="file-upload-custom">
                            <input {...getInputProps()} />
                            <p>drag and drop here</p>
                            <aside>
                                <h4>Files</h4>
                                {/* <ul>
                  {acceptedFiles.map((file, index) => (
                    <li key={`file_${index}`}>
                      {file.name} - {file.size} bytes
                    </li>
                  ))}
                </ul> */}
                            </aside>
                        </div>
                    </div>
                );
            }}
        </Dropzone>
    );
};
