import React from "react";
import {FC} from "react";
import {Alert} from "react-bootstrap";
import {ValidationErrorModel} from "../../../models";
import {isArabicCurrentLanguage} from "../../../utils";

export const ErrorValidationBox: FC<{
    errors?: ValidationErrorModel[];
    variant?: string | null;
}> = ({errors, variant = "danger"}) => {
    //#region html
    if (errors !== null && errors !== undefined && errors?.length !== 0) {
        let isArabic: boolean = isArabicCurrentLanguage();
        return (
            <>
                <Alert key={variant} variant={variant || "danger"}>
                    {errors.map((error, index) => (
                        <div key={`error_key${index}`}>
                            {isArabic ? error.MessageAr : error.MessageEn}
                            <br/>
                        </div>
                    ))}
                </Alert>
            </>
        );
    }
    return null;
    //#endregion
};
