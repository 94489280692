import secureLocalStorage from "react-secure-storage";
import {SystemConfiguration} from "../../configuration";
import {KeyValueStateModel, LookupTypeEnum} from "../../models";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";

const LocalStorageSet = (name: string, value: string) => {
    localStorage.setItem(name, value);
};

const SecureLocalStorageSet = (name: string, value: string) => {
    secureLocalStorage.setItem(name, value);
};

const LocalStorageGet = (name: string): string | null => {
    return localStorage.getItem(name);
};

const SecureLocalStorageGet = (
    name: string
): string | object | number | boolean | null => {
    return secureLocalStorage.getItem(name);
};

const LocalStorageRemove = (name: string) => {
    localStorage.removeItem(name);
};

const SecureLocalStorageRemove = (name: string) => {
    secureLocalStorage.removeItem(name);
    //secureLocalStorage.clear();
};
const SecureLocalStorageClear = () => {
    // secureLocalStorage.removeItem(SystemConfiguration.keys.user);
    // secureLocalStorage.removeItem(SystemConfiguration.keys.token);
    // secureLocalStorage.removeItem(SystemConfiguration.keys.dictionary);
    // secureLocalStorage.removeItem(SystemConfiguration.keys.privileges);
    // secureLocalStorage.removeItem(SystemConfiguration.keys.menu);
    // secureLocalStorage.removeItem(SystemConfiguration.keys.menuItemName);
    // secureLocalStorage.removeItem(SystemConfiguration.keys.appConfig);
    //
    secureLocalStorage.clear();
    secureLocalStorage.setItem(SystemConfiguration.keys.homePageReloaded, "0");
};

const SecureLocalStorageClearLookupCache = async () => {
    let kvList: KeyValueStateModel[] = [];
    for (const key in LookupTypeEnum) {
        if (LookupTypeEnum.hasOwnProperty(key)) {
            const value = LookupTypeEnum[key];
            if (!isNaN(parseFloat(value))) {
                if (SecureLocalStorageGet(key)) {
                    SecureLocalStorageRemove(key);
                    kvList.push({key: key, value: value});
                }
            }
        }
    }

    if (kvList.length !== 0) {
        const lookupPromises = kvList.map(async (row) => {
            const lookupAPiResult = await getLookupByType(row.value, true, false);
        });
        // Wait for all promises to resolve before proceeding
        await Promise.all(lookupPromises);
    }
};
export {
    LocalStorageSet,
    LocalStorageGet,
    LocalStorageRemove,
    SecureLocalStorageSet,
    SecureLocalStorageGet,
    SecureLocalStorageRemove,
    SecureLocalStorageClear,
    SecureLocalStorageClearLookupCache,
};
