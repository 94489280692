import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {GridColumnsResponseModel, ResponseBaseModel} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getGridColumns = async (
    userId: number,
    gridId: number
): Promise<GridColumnsResponseModel[] | null> => {
    try {
        let url: string = `${AlYuserApiUrls.commonController.getControlsSettings}?userId=${userId}&idGrid=${gridId}`;
        const result: ResponseBaseModel<GridColumnsResponseModel[]> =
            await AlYusrAxiosApiInstance.get(url);
        return result !== null &&
        result !== undefined &&
        result.Result !== null &&
        result.Result !== undefined
            ? result.Result
            : null;
    } catch (err) {
        alert(err);
    }
    return null;
};
//#endregion
//#region post
export const saveGridColumns = async (columns: GridColumnsResponseModel[]) => {
    return await AlYusrAxiosApiInstance.post<ResponseBaseModel<GridColumnsResponseModel[]>>(
        AlYuserApiUrls.commonController.saveGvColumnControlSettingList,
        columns
    );
};
//#endregion
