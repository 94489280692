import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./store/store";
import {Provider} from "react-redux";
import {QueryClient, QueryClientProvider} from "react-query";
import reportWebVitals from "./reportWebVitals";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/css/mdi/css/materialdesignicons.min.css";
import "./assets/css/bundle.base.css";
import "./assets/css/style.css";
import "./index.css";
import "./assets/js/bundle.base.js";

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
});
root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <App/>
        </Provider>
    </QueryClientProvider>
);

// ReactDOM.render(
//   <Provider store={store}>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </Provider>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
