import React, {FC} from "react";
import {ReportCustomersComplexSearchPanel, ReportPage,} from "../../../components";
import {CustomerComplexReportSearchParamsModel, PageEnum} from "../../../models";
import {SearchReportCustomersAccount} from "../../../serviceBroker/customersReportApiServiceBroker";
import {getPageNameByPageId, getUserId} from "../../../utils";

export const ReportsCustomersAccounts: FC = () => {
    const initialState: CustomerComplexReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={SearchReportCustomersAccount}
            searchHeader={getPageNameByPageId(PageEnum.ReportAccountOfCustomer)}
            SearchPanel={ReportCustomersComplexSearchPanel}
            formId={PageEnum.ReportAccountOfCustomer}
        />
    );
};
