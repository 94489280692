import React, {FC} from "react";
import {CalcTypeMainComponent} from "../../components";
import {CalcTypeEnum, PageEnum} from "../../models";

export const CalcTypePage: FC<{ formId: number }> = ({formId = PageEnum.CalcType}) => {
    //#region html
    return (
        <>
            <CalcTypeMainComponent
                formId={formId || 0}
                calcType={CalcTypeEnum.CalcType}
            />
        </>
    );
    //#endregion
};

export const ApplicationDeliveryPage: FC<{ formId: number }> = ({
                                                                    formId = PageEnum.ApplicationDelivery,
                                                                }) => {
    //#region html
    return (
        <>
            <CalcTypeMainComponent
                formId={formId || 0}
                calcType={CalcTypeEnum.ApplicationDelivery}
            />
        </>
    );
    //#endregion
};
