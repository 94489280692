import React, {FC, useEffect, useState} from "react";
import {ButtonBox, ErrorValidationBox, LoadingBox, ModelDialogBox, TextBox} from "../../";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getLabelName} from "../../../utils";
import {
    ActionTypeEnum,
    AuthenticateUserPermissionRequestModel,
    DailyTransactionTypeEnum,
    PosUserPermissionActionTypeEnum,
    RequestActionModel,
    ValidationErrorModel
} from "../../../models";
import {getUserPOSPermission} from "../../../serviceBroker/userApiServiceBroker";

export const PosUserPermissionLogin: FC<{
    userPermissionType: PosUserPermissionActionTypeEnum,
    onActionEvent: (o: RequestActionModel) => void
}> = ({
          userPermissionType, onActionEvent = () => {
    }
      }) => {
    //#region state
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validationSchema] = useState(
        Yup.object({
            userName: Yup.string().required(getLabelName("Please enter username")),
            password: Yup.string().required(getLabelName("Please enter password")),
        })
    );
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    //#endregion
    //#region variables
    const initialValues: AuthenticateUserPermissionRequestModel = {
        userName: "",
        password: "",
        transactionType: DailyTransactionTypeEnum.None
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
        setShowModal(true);
    }, []);
    //#endregion
    //#region functions
    const handleAuthenticateUserToGetPermission = async (request: AuthenticateUserPermissionRequestModel) => {
        try {
            setLoading(true);
            const result = await getUserPOSPermission(request);
            let isUserHaveValidPermission: boolean = false;
            if (result !== null) {
                switch (userPermissionType) {
                    case PosUserPermissionActionTypeEnum.RefundAction:
                        isUserHaveValidPermission = result.AloowReturn;
                        break;
                    case PosUserPermissionActionTypeEnum.DiscountAction:
                    case PosUserPermissionActionTypeEnum.DiscountPercentageAction:
                        isUserHaveValidPermission = result.AllowDiscount;
                        break;
                    case PosUserPermissionActionTypeEnum.PrintAction:
                        isUserHaveValidPermission = result.EnablePrintSavedTransaction
                        break;
                    case PosUserPermissionActionTypeEnum.NewAction:
                        isUserHaveValidPermission = result.AllowClickNew
                        break
                    case PosUserPermissionActionTypeEnum.DeleteItemAction:
                        isUserHaveValidPermission = result.AllowDeleteItems
                        break;
                    case PosUserPermissionActionTypeEnum.EditItemPriceAction:
                        isUserHaveValidPermission = result.AllowEditPrice
                        break;
                    case PosUserPermissionActionTypeEnum.EditItemDiscountAction:
                        isUserHaveValidPermission = result.AllowDiscount
                        //isUserHaveValidPermission=true;
                        break;
                }
                if (isUserHaveValidPermission) {
                    setShowModal(false);
                    onActionEvent({
                        action: ActionTypeEnum.GetUserPOSPermission,
                        request: {...result, userPermissionType: userPermissionType}
                    })
                } else {
                    setValidationErrors([
                        {
                            MessageAr: getLabelName("You don't have permission for this action"),
                            MessageEn: getLabelName("You don't have permission for this action"),
                        }
                    ])
                }
            } else {
                setValidationErrors([
                    {
                        MessageAr: getLabelName("Erorr in User name or Password"),
                        MessageEn: getLabelName("Erorr in User name or Password"),
                    }
                ])
            }
            setLoading(false);
        } catch (err: any) {
            window.scrollTo(0, 0);
        }
    };
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        //enableReinitialize: true,
        onReset: async () => {
        },
        onSubmit: async (values) => {
            await handleAuthenticateUserToGetPermission(values);
        },
    });
    //#endregion
    //#region html
    return (<>
            {loading && <LoadingBox/>}
            <ModelDialogBox
                isModelVisible={showModal}
                isCloseButtonVisible={false}
                isEscapeCloseEnabled={true}
                onCloseEvent={() => {
                    setShowModal(false);
                    onActionEvent({action: ActionTypeEnum.Generic})
                }}
                size="sm"
            >
                <form onSubmit={formik.handleSubmit}>
                    {<ErrorValidationBox errors={validationErrors}/>}
                    <div>
                        <TextBox
                            labelName={getLabelName("User Name")}
                            inputName={"userName"}
                            errorText={formik.errors.userName}
                            inputValue={formik.values.userName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div>
                        <TextBox
                            labelName={getLabelName("password")}
                            inputName={"password"}
                            type="password"
                            errorText={formik.errors.password}
                            inputValue={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div>
                        <ButtonBox type="submit" iconType="account-plus" id="submitUserPermissionLoginButton" variant="success">
                            {getLabelName("login")}
                        </ButtonBox>
                        <ButtonBox type="button" iconType="close-circle" id="cancelUserPermissionLoginButton" variant="danger"
                                   onClick={async () => {
                                       setShowModal(false);
                                       onActionEvent({action: ActionTypeEnum.Generic})
                                   }}>
                            {getLabelName("cancel")}
                        </ButtonBox>
                    </div>
                </form>

            </ModelDialogBox>
        </>
    )
//#endregion
}