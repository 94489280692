
import React, {useState} from "react";
import {BrowserRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {RoutesComponent} from "./routes/route/routesComponent";
import "./App.css";
import {AppConfigurationModel,IThemeStateModel} from "./models";
import {useFetchAppConfiguration} from "./hooks";
import {FallbackErrorBoundaryRender} from "./components";
import {ErrorBoundary} from "react-error-boundary";
import {SidebarProvider} from "./context/sidebarContext";

function App() {
    const theme: IThemeStateModel = useSelector((state: any) => state.theme);
    const {data} = useFetchAppConfiguration();
    const [config, setConfig] = useState<AppConfigurationModel | null>(data);
    document.getElementsByTagName('html')[0].setAttribute("dir", theme.isRtl ? "rtl" : "ltr");
    if (config != null) {
        return (
            <div dir={theme.isRtl ? "rtl" : "ltr"}>
                <ErrorBoundary fallbackRender={FallbackErrorBoundaryRender}>
                    <SidebarProvider>
                        <BrowserRouter>
                            <RoutesComponent/>
                        </BrowserRouter>
                    </SidebarProvider>
                </ErrorBoundary>
            </div>
        );
    }
    return null;
}
export default App;
