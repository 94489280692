import {
    ResponseBaseModel, StoreTransferSearchTransactionRequestModel, StoreTransferSearchTransactionResponseModel,
    StoreTransferTransactionDetailResponseModel,
} from "../models";
import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
import {updateUrlWithParam} from "../utils";
//#region get
export const searchStoreTransferTransactions= async ( request: StoreTransferSearchTransactionRequestModel): Promise<ResponseBaseModel<StoreTransferSearchTransactionResponseModel[]>> => {
    let response: ResponseBaseModel<StoreTransferSearchTransactionResponseModel[]> = {
        PageCount: 0,
        TotalRecoredCount: 0,
    };
    let url=`${AlYuserApiUrls.transactionController.searchStoreTransferTransactionUrl}`;
    url=updateUrlWithParam(url,"searchparm.iD",request.id);
    url=updateUrlWithParam(url,"searchparm.creationDate",request.creationDate);
    url=updateUrlWithParam(url,"searchparm.userId",request.userId);
    url=updateUrlWithParam(url,"searchparm.employeeId",request.employeeId);
    url=updateUrlWithParam(url,"searchparm.code",request.code);
    url=updateUrlWithParam(url,"searchparm.operationUserId",request.operationUserId);
    url=updateUrlWithParam(url,"searchparm.store_IDTo",request.toStoreId);
    url=updateUrlWithParam(url,"searchparm.store_IDFrom",request.fromStoreId);
    url=updateUrlWithParam(url,"searchparm.fromDate",request.fromDate);
    url=updateUrlWithParam(url,"searchparm.toDate",request.toDate);
    url=updateUrlWithParam(url,"searchparm.pageNumber",request.pageNumber);
    url=updateUrlWithParam(url,"searchparm.pageSize",request.pageSize);
    response =await AlYusrAxiosApiInstance.get(url);
    return response;
}
export const getStoreTransferTransactionDetail = async (
    id: number
): Promise<StoreTransferTransactionDetailResponseModel | null> => {
    let response: ResponseBaseModel<StoreTransferTransactionDetailResponseModel>;
    let url: string = `${AlYuserApiUrls.transactionController.getFullTransactionByIdUrl}?id=${id}`;
    response = await AlYusrAxiosApiInstance.get(url);
    return response !== null &&
    response !== undefined &&
    response.Result !== null &&
    response.Result !== undefined
        ? response.Result
        : null;
};
//#endregion
//#region post
export const saveStoreTransferTransaction = async (
    request: StoreTransferTransactionDetailResponseModel
): Promise<ResponseBaseModel<StoreTransferTransactionDetailResponseModel>> => {
    let response: ResponseBaseModel<StoreTransferTransactionDetailResponseModel> = {};
    try {
        response = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.transactionController.saveStoreTransferTransactionUrl, request);
    } catch (err) {
        alert(err);
    }
    return response;
};
//#endregion