import React, {FC, ReactNode} from "react";

export interface HasChildren {
    children?: ReactNode;
    isLoading?: boolean;
}

const Loader = () => (
    <>
        <div id="preloader">
            <div id="status">
                <div className="spinner"></div>
            </div>
        </div>
    </>
);

export const LoadingBox: FC<HasChildren> = ({children, isLoading}) => {
    if (children !== undefined)
        return (
            <div className="loader-wrapper">
                {children}
                {isLoading && <Loader/>}
            </div>
        );
    return <Loader/>;
};
