import {TreeView, LoadingBox, AccountsForm, ToastBox, AccountsList, ButtonBox} from "..";
import {transformAccountsToTreeNode} from "./utils/transformers";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";
import {useAccountInfo, useAllAccounts} from "../../hooks";
import {Key} from "rc-tree/lib/interface";
import {initialAccountInfo} from "./state/state";
import {Accordion, Col, Row} from "react-bootstrap";
import {useMemo, useState} from "react";
import {AccountResponseResultModel, ToastModel} from "../../models";

export const AccountsTree = () => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [reFetchAccounts, setReFetchAccounts] = useState(false);
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    const {
        accounts,
        areAccountsLoading,
        handleAddAccount,
        handleDeleteAccount,
        handleModifyAccount,
    } = useAllAccounts();
    const {
        currentAccountInfo,
        isAccountInfoLoading,
        fetchAccountInfoByID,
        resetCurrentAccountInfo,
    } = useAccountInfo(initialAccountInfo);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
    const treeData = useMemo(
        () => transformAccountsToTreeNode(accounts, isArabic),
        [accounts]
    );
    //#endregion
    //#region variables
    const isPageLoading =
        areAccountsLoading || isAccountInfoLoading || isFormSubmitting;
    const parentID = selectedKeys.length !== 0 ? +selectedKeys[0] : null;
//#endregion
    //#region html
    return (
        <>
            {isPageLoading && <LoadingBox/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            <div className="card p-3 btn-fix d-flex align-items-end mb-2">
                <ButtonBox
                iconType="receipt"
                    variant="danger"
                    type="button"
                    role="span"
                    className="btn btn-sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        resetCurrentAccountInfo();
                    }}
                >
                    {getLabelName("New")}
                </ButtonBox>
            </div>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        {getLabelName("Accounts Tree")}
                    </Accordion.Header>
                    <Accordion.Body >
                        <Row>
                            <Col
                                md={3}
                                xs={12}
                                className="mb-sm-3 mb-md-0"
                            >
                                <TreeView
                                    treeData={treeData}
                                    loading={areAccountsLoading}
                                    selectedKeys={selectedKeys}
                                    onSelect={(keys: Key[]) => {
                                        setSelectedKeys(keys);
                                        resetCurrentAccountInfo();
                                    }}
                                />
                            </Col>
                            <Col md={9} xs={12}>
                                <AccountsForm
                                    setIsFormSubmitting={setIsFormSubmitting}
                                    switchToSavedAccount={async (
                                        account: AccountResponseResultModel,
                                        isNew: boolean
                                    ) => {
                                        if (isNew) {
                                            // handleAccountIDChange(account.ID);
                                            handleAddAccount(account);
                                        } else {
                                            handleModifyAccount(account);
                                        }
                                        setReFetchAccounts((prev) => !prev);
                                        // setSelectedKeys([`${id}`]);
                                    }}
                                    currentParentID={parentID}
                                    currentAccountInfo={currentAccountInfo}
                                    resetCurrentAccountInfo={resetCurrentAccountInfo}
                                    setToastModel={setToastModel}
                                />
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="1" className="mt-5">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body>
                        <AccountsList
                            parentID={parentID}
                            setIsAccountLoading={setIsFormSubmitting}
                            setToastModel={setToastModel}
                            handleDeleteAccount={handleDeleteAccount}
                            fetchAccountInfoByID={fetchAccountInfoByID}
                            reFetchAccounts={reFetchAccounts}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
};
