import {useEffect, useReducer} from "react";
import {AccountResponseResultModel, AcTransactionSearchModel, SearchAccountsRequestPayloadModel,} from "../../models";
import {searchAccounts} from "../../serviceBroker/accountApiServiceBroker";

enum ActionType {
    SET_SEARCHED_ACCOUNTS,
    SET_TOTAL_ROWS,
    SET_LOADING,
    SET_SEARCH_PARAMS,
    RESET_SEARCH_PARAMS,
    SET_PAGE_SIZE,
    SET_PAGE_NUMBER,
}

type Action = {
    type: ActionType;
    payload?: any;
};

interface AccountsState {
    searchedAccounts: AcTransactionSearchModel[];
    totalRows: number;
    loading: boolean;
    searchParams: SearchAccountsRequestPayloadModel;
}

const initialAccountsState: AccountsState = {
    searchedAccounts: [],
    totalRows: 1,
    loading: false,
    searchParams: {
        "searchItem.pageSize": 10,
        "searchItem.pageNumber": 1,
        "searchItem.parentId": null,
    },
};

const accountsReducer = (
    state: AccountsState,
    action: Action
): AccountsState => {
    switch (action.type) {
        case ActionType.SET_SEARCHED_ACCOUNTS:
            return {...state, searchedAccounts: action.payload};
        case ActionType.SET_TOTAL_ROWS:
            return {...state, totalRows: action.payload};
        case ActionType.SET_LOADING:
            return {...state, loading: action.payload};
        case ActionType.SET_PAGE_SIZE:
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    "searchItem.pageSize": action.payload,
                    "searchItem.pageNumber": 1,
                },
            };
        case ActionType.SET_PAGE_NUMBER:
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    "searchItem.pageNumber": action.payload,
                },
            };
        case ActionType.SET_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: {...state.searchParams, ...action.payload},
            };
        case ActionType.RESET_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: {
                    "searchItem.pageNumber": state.searchParams["searchItem.pageNumber"],
                    "searchItem.pageSize": state.searchParams["searchItem.pageSize"],
                    "searchItem.parentId": null,
                },
            };
        default:
            return state;
    }
};

const useSearchedAccounts = () => {
    const [state, dispatch] = useReducer(accountsReducer, initialAccountsState);

    const fetchAccounts = async () => {
        try {
            dispatch({type: ActionType.SET_LOADING, payload: true});
            const accounts = await searchAccounts(state.searchParams);
            if (accounts !== null) {
                dispatch({
                    type: ActionType.SET_SEARCHED_ACCOUNTS,
                    payload: accounts.Result as AccountResponseResultModel[],
                });
                dispatch({
                    type: ActionType.SET_TOTAL_ROWS,
                    payload: accounts.TotalRecoredCount as number,
                });
            }
        } catch (err) {
        } finally {
            dispatch({type: ActionType.SET_LOADING, payload: false});
        }
    };

    useEffect(() => {
        fetchAccounts().then(() => {
        });
    }, [state.searchParams]);

    return {
        searchedAccounts: state.searchedAccounts,
        areAccountsLoading: state.loading,
        searchParams: state.searchParams,
        setSearchParams: (params: SearchAccountsRequestPayloadModel) =>
            dispatch({type: ActionType.SET_SEARCH_PARAMS, payload: params}),
        resetSearchParams: () => dispatch({type: ActionType.RESET_SEARCH_PARAMS}),
        setPageSize: (pageSize: number) =>
            dispatch({type: ActionType.SET_PAGE_SIZE, payload: pageSize}),
        setPageNumber: (pageNumber: number) =>
            dispatch({type: ActionType.SET_PAGE_NUMBER, payload: pageNumber}),
        setSearchedAccounts: (accs: AccountResponseResultModel[]) =>
            dispatch({type: ActionType.SET_SEARCHED_ACCOUNTS, payload: accs}),
        currentPage: state.searchParams["searchItem.pageNumber"],
        totalRows: state.totalRows,
        currentPageSize: state.searchParams["searchItem.pageSize"],
        fetchAccounts,
    };
};

export default useSearchedAccounts;
