import Tree from "rc-tree";
import React, {FC, useState, useEffect} from "react";
import "./styles.css";
import {DataNode, Key} from "rc-tree/lib/interface";

export const TreeView: FC<{
    onSelect?: (key: Key[]) => void;
    treeData: DataNode[];
    loading?: boolean;
    selectedKeys: Key[];
}> = ({ treeData, onSelect, selectedKeys}) => {
    //#region state
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    //#endregion
    // #region functions
    const toggleExpand = (key: Key) => {
        if (expandedKeys.includes(key)) {
            // If key is already expanded, remove it from the expandedKeys
            setExpandedKeys(expandedKeys.filter(expKey => expKey !== key));
        } else {
            // Otherwise add it to expandedKeys
            setExpandedKeys([...expandedKeys, key]);
        }
    };
    const expandToSelectedNode = (nodes: DataNode[], selectedKey: Key) => {
        for (const node of nodes) {
            if (node.key === selectedKey) {
                return true;// Found the selected node, stop further recursion
            }
            if (node.children) {
                if (expandToSelectedNode(node.children, selectedKey)) {
                    if (!expandedKeys.includes(node.key)) {
                        setExpandedKeys((prevExpandedKeys) => [
                            ...prevExpandedKeys,
                            node.key,
                        ]);
                    }
                    return true; // Stop further recursion
                }
            }
        }
        return false;
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
        if (selectedKeys.length > 0) {
            expandToSelectedNode(treeData, selectedKeys[0]);
        }
    }, [selectedKeys, treeData]);
    //#endregion
    //#region html
    return (
        <>
            {treeData.length !== 0 && (
                <>
                    <Tree
                        showLine
                        showIcon={false}
                        onSelect={(selectedKeys, info) => {
                            onSelect?.(selectedKeys);
                            toggleExpand(info.node.key);
                        }}
                        treeData={treeData}
                        checkable={false}
                        selectable={true}
                        selectedKeys={selectedKeys}
                        expandedKeys={expandedKeys}
                        onExpand={(keys) => {
                            setExpandedKeys(keys);
                        }}
                    />
                </>
            )}
        </>
    );
    //#endregion
};
