import {FC} from "react";
import {ReportDebitCustomerSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {SearchReportDebitCustomerPage} from "../../../serviceBroker/customersReportApiServiceBroker";
import {DebitCustomerReportSearchParamsModel, PageEnum} from "../../../models";

export const ReportDebitCustomerPage: FC = () => {
    const initialState: DebitCustomerReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={SearchReportDebitCustomerPage}
            searchHeader={getPageNameByPageId(PageEnum.ReportCustomerDebits)}
            SearchPanel={ReportDebitCustomerSearchPanel}
            formId={PageEnum.ReportCustomerDebits}
        />
    );
};
