import {FC} from "react";
import {TransactionMainComponent} from "../../../components";
import {DailyTransactionTypeEnum, PageEnum} from "../../../models";

export const SalesRefundPage: FC = ({}) => {
    //#region html
    return (
        <>
            <TransactionMainComponent
                formId={PageEnum.ReturnSalesBill}
                transactionType={DailyTransactionTypeEnum.SalesRefund}
            />
        </>
    );
    //#endregion
};
// export const SalesRefundPage1: FC<{transactionId?:number|null}> = ({transactionId}) => {
//     // const { transactionId } = useParams();
//     console.log('res_v2',transactionId)
//     //#region html
//     return (
//         <>
//             <TransactionMainComponent
//                 transactionType={DailyTransactionTypeEnum.SalesRefund}
//                 transactionId={0}
//             />
//         </>
//     );
//     //#endregion
// };
