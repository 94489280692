import {FC, useState} from "react";
import {Accordion, Col, Row} from "react-bootstrap";
import {
    ButtonBox,
    LoadingBox,
    ToastBox, PrivilegesChecker, ErrorValidationBox
} from "..";
import {
    CategoryResponseModel,
    HasFormIdModel,
    RequestActionModel,
    ToastModel, ValidationErrorModel,
} from "../../models";

import {updateTaxPercentageOfCategory} from "../../serviceBroker/itemApiServiceBroker";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";

interface CategoryListTaxProps extends HasFormIdModel {
    request: CategoryResponseModel[];
    onActionEvent: (o: RequestActionModel) => void;
    onCompleteEvent?: any | null;
}

export const CategoryListTax: FC<CategoryListTaxProps> = ({
                                                              request, formId,
                                                          }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [taxPercentage, setTaxPercentage] = useState(0);
    const [checked, setChecked] = useState<number[]>([]);
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>([]);
    //#endregion
    //#region functions
    const handleAllChecked = (event: any) => {
        if (event.target.checked) {
            const normalizedArray = request.map(function (obj) {
                return obj.ID;
            });
            setChecked(normalizedArray);
        } else {
            setChecked([]);
        }
    };
    const isChecked = (item: number) =>
        checked.includes(item) ? "checked-item" : "not-checked-item";
    const handleCheck = (event: any) => {
        const updatedList = [...checked];
        if (event.target.checked) {
            updatedList.push(parseInt(event.target.value));
            setChecked(updatedList);
        } else {
            const list = updatedList.filter((word) => word != event.target.value);
            setChecked(list);
        }
    };
    //#endregion
    //#region html
    const handleSubmit = async () => {
        setValidationErrors([]);
        if (taxPercentage > 100 || taxPercentage < 0) {
            setValidationErrors([{MessageAr: getLabelName("Invalid Tax"), MessageEn: getLabelName("Invalid Tax")}]);
            return;
        }
        if (checked.length == 0) {
            setValidationErrors([{
                MessageAr: getLabelName("Select Category"),
                MessageEn: getLabelName("Select Category")
            }]);
            return;
        } else {
            const response = await updateTaxPercentageOfCategory({
                categoryIdsList: checked,
                TaxPercentage: taxPercentage,
                ModifcationDate: new Date(),
                ModifiedBy: 0,
            });
            setChecked([]);
            if (response.Errors != null && response.Errors.length !== 0) {
                setValidationErrors(response.Errors)
            } else {
                setToastModel({show: true});
            }
        }
    };
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({show: false});
                    }}
                />
            )}

            <div className="row">
                {loading && <LoadingBox/>}
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header></Accordion.Header>
                        <Accordion.Body>
                            <Row justify="start">
                                <div className="box d-flex align-items-center justify-content-start mb-4">
                                    {" "}
                                    <input
                                        type="checkbox"
                                        onChange={handleAllChecked}
                                        value="checkedall"
                                        id="selectAll"
                                    />
                                    <label className="mx-3" htmlFor="selectAll">
                                        {getLabelName("selectAll")}
                                    </label>
                                </div>

                                {/* {t("SelectAll")} */}
                            </Row>
                            <div
                                className="row row-cols-1 row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-4 row-cols-sm-1 g-sm-2 g-md-4 align-items-start mb-3">
                                {request !== null &&
                                    request.length !== 0 &&
                                    request.map((row: any, index: number) => {
                                        return (
                                            <>
                                                <div className="col-sm-3 ">
                                                    <div className="container">
                                                        <div key={index}>
                                                            <input
                                                                value={row.ID}
                                                                type="checkbox"
                                                                checked={checked.includes(row.ID)}
                                                                onChange={handleCheck}
                                                                id={row?.ID}
                                                                className="mx-3"
                                                            />
                                                            <label
                                                                htmlFor={row?.ID}
                                                                className={isChecked(row.ID)}
                                                            >
                                                                {isArabic ? row.Name : row.Name_En}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                            </div>

                            <Row>
                                <Col xs={2}>
                                    <label>{getLabelName(getLabelName("TaxPercentage"))}</label>
                                </Col>
                                <Col xs={3}>
                                    <input
                                        min={0}
                                        max={100}
                                        name="TaxPercentage"
                                        type="number"
                                        onChange={(e: any) => {
                                            setTaxPercentage(e.target.value);
                                        }}
                                        value={taxPercentage}
                                        className="form-control"
                                    />
                                </Col>
                            </Row>
                            <PrivilegesChecker formId={formId} action="EnableSave">
                                <div className="col-md-2 mt-3">
                                    <ButtonBox onClick={handleSubmit} iconType="content-save">{getLabelName("save")}</ButtonBox>
                                </div>
                            </PrivilegesChecker>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
    //#endregion
};
