import {useEffect, useState} from "react";
import {LookupItemModel} from "../../models";
import {getAllBoxAccounts} from "../../serviceBroker/accountApiServiceBroker";

export const useBoxAccounts = () => {
    const [boxAccounts, setBoxAccounts] = useState<LookupItemModel[]>([]);
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchAccountTransactions = async () => {
            setIsLoading(true);
            const fetchedBoxAccounts = await getAllBoxAccounts();
            if (fetchedBoxAccounts !== null) setBoxAccounts(fetchedBoxAccounts);
            setIsLoading(false);
        };
        fetchAccountTransactions().then(() => {
        });
    }, []);
    return {boxAccounts, areBoxAccountsLoading: loading};
};

