import {useFormik} from "formik";
import * as Yup from "yup";
import {FC, useState, useEffect} from "react";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";
import {Col, Form, Row} from "react-bootstrap";
import {saveTransactionSettings} from "../../serviceBroker/userApiServiceBroker";
import {ButtonBox, ErrorValidationBox, TextBox, LoadingBox, SelectBox} from "..";
import {
    ActionTypeEnum,
    LookupItemModel,
    LookupTypeEnum,
    RowStateEnum,
    UserTransactionSettingModel, ValidationErrorModel
} from "../../models";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";

export const TransactionSettings: FC<{
    userTransactionSettingModel: UserTransactionSettingModel;
    onActionEvent?: any | null;
    onComplete?: any | null;
    getUserTransactionSetting?: any | null;
}> = ({
          userTransactionSettingModel,
          onActionEvent = () => {
          },
          onComplete = () => {
          },
          getUserTransactionSetting = () => {
          },
      }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [defaultCalcTypeList, setDefaultCalcTypeList] = useState<LookupItemModel[]>(
        []
    );
    const [defaultCalcTypeId, setDefaultCalcTypeId] = useState<number>(
        userTransactionSettingModel.DefaultCalcTypeID
    );
    const [transactionTypeList, setTransactionTypeList] = useState<LookupItemModel[]>(
        []
    );
    const [paymentTypeList, setPaymentTypeList] = useState<LookupItemModel[]>([]);
    const [paymentTypeId, setPaymentTypeId] = useState<number>(
        userTransactionSettingModel.DefaultPaymentTypeID
    );
    const [transactionTypeId, setTransactionTypeId] = useState<number>(2);
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [validationSchema] = useState(Yup.object({}));
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            const [transactionTypes, calcTypes, paymentTypes] = await Promise.all([
                getLookupByType(LookupTypeEnum.TransactionType),
                getLookupByType(LookupTypeEnum.CalcType),
                getLookupByType(LookupTypeEnum.CalcType)
            ]);
            setTransactionTypeList(transactionTypes);
            setDefaultCalcTypeList(calcTypes);
            setPaymentTypeList(paymentTypes);
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, [transactionTypeId]);
    //#endregion
    //#region function
    const handleReset = async () => {
        onComplete(null);
    };
    const handleSubmit = async (request: UserTransactionSettingModel) => {
        try {
            setLoading(true);
            request.rowState = RowStateEnum.Update;
            request.TransactionType_ID = transactionTypeId;
            request.DefaultCalcTypeID = defaultCalcTypeId;
            request.DefaultPaymentTypeID = paymentTypeId;
            const res = await saveTransactionSettings(request);
            if (res != null && res.Errors != null && res.Errors.length !== 0) {
                setValidationErrors(res.Errors);
                onActionEvent({
                    id: ActionTypeEnum.TransactionSetting,
                    action: ActionTypeEnum.Failed,
                    request: isArabic
                        ? res?.Errors[0].MessageAr
                        : res?.Errors[0].MessageEn,
                    requestBeforeError: request,
                });
                setLoading(false);
            } else {
                setValidationErrors([]);
                onActionEvent({
                    id: ActionTypeEnum.TransactionSetting,
                    action: ActionTypeEnum.AddSuccess,
                    request: request,
                });
                setLoading(false);
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#en
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: userTransactionSettingModel,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, {resetForm}) => {
            await handleSubmit(values);
            resetForm();
        },
    });
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form onSubmit={formik.handleSubmit}>
                <div
                    className="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
                    <SelectBox
                        labelName={getLabelName("Trans Type")}
                        source={transactionTypeList}
                        isSingleSelect={true}
                        errorText={formik.errors.TransactionType_ID}
                        onStatusChange={(e: any) => {
                            formik.values.TransactionType_ID = e.value;
                            setTransactionTypeId(e.value);
                            getUserTransactionSetting(
                                userTransactionSettingModel.User_ID,
                                e.value
                            );
                        }}
                        selectedValues={[transactionTypeId.toString()]}
                        multiselectRef={undefined}
                    />
                    <SelectBox
                        labelName={getLabelName("Calc Type")}
                        source={defaultCalcTypeList}
                        isSingleSelect={true}
                        errorText={formik.errors.DefaultCalcTypeID}
                        onStatusChange={(e: any) => {
                            formik.values.DefaultCalcTypeID = e.value;
                            setDefaultCalcTypeId(e.value);
                        }}
                        selectedValues={[defaultCalcTypeId.toString()]}
                        multiselectRef={undefined}
                    />
                    <TextBox
                        labelName={getLabelName("Period Allow to Return")}
                        inputName={"PeriodAllowForReturn"}
                        errorText={formik.errors.PeriodAllowForReturn}
                        inputValue={formik.values.PeriodAllowForReturn}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />
                    <TextBox
                        labelName={getLabelName("Num. Copy")}
                        inputName={"NumberOfCopy"}
                        errorText={formik.errors.NumberOfCopy}
                        inputValue={formik.values.NumberOfCopy}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="number"
                    />
                    <SelectBox
                        labelName={getLabelName("Calc Type")}
                        source={paymentTypeList}
                        isSingleSelect={true}
                        errorText={formik.errors.DefaultPaymentTypeID}
                        onStatusChange={(e: any) => {
                            formik.values.DefaultPaymentTypeID = e.value;
                            setPaymentTypeId(e.value);
                        }}
                        selectedValues={[paymentTypeId.toString()]}
                        multiselectRef={undefined}
                    />
                </div>
                <Row className="mt-4">
                    <Col md="6" className="list-group-wrapper">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowPaymentType"
                                    checked={formik.values.ShowPaymentType}
                                    name="ShowPaymentType"
                                    label={getLabelName("Calc Type")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowEmployeeResponsibile"
                                    name="ShowEmployeeResponsibile"
                                    checked={formik.values.ShowEmployeeResponsibile}
                                    label={getLabelName("Employee")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowCalcType"
                                    checked={formik.values.ShowCalcType}
                                    name="ShowCalcType"
                                    label={getLabelName("Calc Type")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowCurrency"
                                    name="ShowCurrency"
                                    checked={formik.values.ShowCurrency}
                                    label={getLabelName("Currency")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableShangPrice"
                                    name="EnableShangPrice"
                                    checked={formik.values.EnableShangPrice}
                                    label={getLabelName("Allow Change Price")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableDiscount"
                                    checked={formik.values.EnableDiscount}
                                    name="EnableDiscount"
                                    label={getLabelName("Allow Discount")}
                                />
                            </li>
                        </ul>
                    </Col>

                    <Col md="6" className="list-group-wrapper">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="DirectPrintBillAfterSave"
                                    name="DirectPrintBillAfterSave"
                                    checked={formik.values.DirectPrintBillAfterSave}
                                    label={getLabelName("Direct Print After Reserve Bill")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="EnableButonAdd"
                                    name="EnableButonAdd"
                                    checked={formik.values.EnableButonAdd}
                                    label={getLabelName("Enable Button Add")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowPrice"
                                    checked={formik.values.ShowPrice}
                                    name="ShowPrice"
                                    label={getLabelName("Show Price")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowRefrence"
                                    name="ShowRefrence"
                                    checked={formik.values.ShowRefrence}
                                    label={getLabelName("Show Reference")}
                                />
                            </li>
                            <li className="list-group-item">
                                <Form.Check
                                    type="checkbox"
                                    onChange={formik.handleChange}
                                    id="ShowButtonPrintPrepairingOrder"
                                    name="ShowButtonPrintPrepairingOrder"
                                    checked={formik.values.ShowButtonPrintPrepairingOrder}
                                    label={getLabelName("Show Print Preparing order")}
                                />
                            </li>
                        </ul>
                    </Col>
                </Row>
                <div className="col-md-12 d-flex justify-content-end mt-3">
                    <ButtonBox type="submit" size="sm" variant="primary" className="mx-3" iconType="content-save">
                        {getLabelName("save")}
                    </ButtonBox>
                    <ButtonBox
                    iconType="close-circle"
                        variant="danger"
                        size="sm"
                        type="button"
                        onClick={handleReset}
                    >
                        {getLabelName("close")}
                    </ButtonBox>
                </div>
            </form>
        </>
    );
    //#endregion
};
