import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {calcTypeApiResponseModel, ResponseBaseModel, RowStateEnum, UserDeleteResponseModel} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getAllCalcTypes = async (isApplicationDelivery: boolean = false): Promise<
    ResponseBaseModel<calcTypeApiResponseModel[]>
> => {
    let response: ResponseBaseModel<calcTypeApiResponseModel[]> = {};
    try {
        let url: string = `${AlYuserApiUrls.calcTypeController.getAllCalcTypes}?isApplicationDelivery=${isApplicationDelivery}`;
        response = await AlYusrAxiosApiInstance.get(url);
    } catch (err) {
        alert(err);
    }
    // @ts-ignore
    return response;
}
export const getCalcTypeById = async (
    id: number
): Promise<ResponseBaseModel<calcTypeApiResponseModel>> => {
    let response: ResponseBaseModel<calcTypeApiResponseModel> = {};
    try {
        let url: string = `${AlYuserApiUrls.calcTypeController.getCalcTypeById}?calcTypeId=${id}`;
        response = await AlYusrAxiosApiInstance.get(url);
    } catch (err) {
        alert(err);
    }
    return response;
};
//#endregion
//#region post
export const addCalcType = async (
    request: calcTypeApiResponseModel
): Promise<ResponseBaseModel<calcTypeApiResponseModel>> => {
    let apiResponse: ResponseBaseModel<calcTypeApiResponseModel> = {};
    new Date();
    Number(RowStateEnum.Add);
    Number(RowStateEnum.Add);
    new Date();
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.calcTypeController.saveCalcType, {...request});
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
export const deleteCalcType = async (
    id: number
): Promise<UserDeleteResponseModel> => {
    let response: UserDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        let url: string = `${AlYuserApiUrls.calcTypeController.deleteCalcType}?id=${id}`;
        response = await AlYusrAxiosApiInstance.post(url);
        return response;
    } catch (err) {
        alert(err);
    }
    return response;
};
//#endregion

