import {FC, useState} from "react";
import {Toast} from "react-bootstrap";
import {getLabelName} from "../../../utils";
import {ToastVariantType} from "../../../models";

export const ToastBox: FC<{
    isShown: boolean;
    header?: string | null;
    body?: string | null;
    variant?: ToastVariantType | null;
    delayDuration?: number | null;
    onCloseEvent?: any;
}> = ({
          isShown = false,
          header,
          body,
          variant = "success",
          delayDuration = 3000,
          onCloseEvent,
      }) => {
    //#region variables
    const _variant: string =
        variant === null || variant === undefined ? "success" : variant;
    const _delayDuration: number =
        delayDuration === null || delayDuration === undefined
            ? 3000
            : delayDuration;
    const _body: string | null = body !== null && body !== undefined && body !== "" ? body : _variant === "success" ? getLabelName("Operation Done Success") : variant === "danger" ? getLabelName("Operation Failed") : null;
    //#endregion
    //#region state
    const [isShow, setIsShow] = useState(isShown);
    //#endregion
    //#region html
    return (
        <>
            {/* <ToastContainer className="p-3" position="middle-start"> */}
            <Toast
                onClose={() => {
                    setIsShow(false);
                    onCloseEvent();
                }}
                show={isShow}
                delay={_delayDuration}
                autohide={_delayDuration !== 0}
                bg={_variant}
            >
                {header && (
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto"></strong>
                        <small>{header}</small>
                    </Toast.Header>
                )}
                {_body && <Toast.Body><i className="mdi mdi-check-circle"></i> {_body}</Toast.Body>}
            </Toast>

        </>
    );
    //#endregion
};
