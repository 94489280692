//https://codesandbox.io/s/ccyuu?file=/src/movies.js  -->basic
//https://codesandbox.io/s/xmdju?file=/src/index.js:1950-1966  -->server side pagination
//https://codesandbox.io/s/fz295?file=/src/DataTable.js:1750-1763 -->filter
//https://react-data-table-component.netlify.app/
import React, {FC} from "react";
import DataTable, {Direction} from "react-data-table-component";
import {useTranslation} from "react-i18next";
import {isArabicCurrentLanguage} from "../../../utils";
import {DataTablePropsModel} from "../../../models";

type PaginationType = "none" | "client" | "server";

export interface SelectedRow<T> {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: T[];
}

export const TableComponent: FC<DataTablePropsModel> = ({
                                                       title,
                                                       columns = [],
                                                       data = [],
                                                       totalRows,
                                                       currentPage = 1,
                                                       pageSize,
                                                       paginationType = "none",
                                                       subHeaderComponent,
                                                       onPageSizeChange = () => {
                                                       },
                                                       onCurrentPageChange = () => {
                                                       },
                                                       conditionalRowStyles,
                                                       selectableRows = false,
                                                       onSelectedRowChange,
                                                   }) => {
    //#region state
    const {t} = useTranslation();
    //#endregion
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    let isPagination: boolean = paginationType !== "none";
    let isPaginationServer: boolean = paginationType === "server";
    const paginationComponentOptions = {
        // selectAllRowsItem: true,
        // selectAllRowsItemText: t("selectAllRowsFromTable"),
        rowsPerPageText: t("rowsPerPageText"),
        rangeSeparatorText: t("rangeSeparatorText"),
    };
    const rowsPerPage = [5, 10, 25, 50, 100, 200, 300, 500];
    //#endregion
    //#region private
    // const CustomPagination = (): any => {
    //   return (
    //     <>
    //       <Pager
    //         currentPage={currentPage}
    //         pageSize={pageSize}
    //         rowsCount={totalRows}
    //         onCurrentPageChange={onCurrentPageChange}
    //         onPageSizeChange={onPageSizeChange}
    //       />
    //     </>
    //   );
    // };
    //#endregion
    return (
        <>
            <DataTable
                title={title}
                columns={columns}
                data={data}
                paginationPerPage={pageSize}
                progressComponent={<>loading........</>}
                //progressPending={loading} // to be checked
                pagination={isPagination}
                paginationServer={isPaginationServer}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                direction={isArabic ? Direction.RTL : Direction.LTR}
                onChangePage={(e) => {
                    onCurrentPageChange(e);
                    //setCurrentPage(e);
                }}
                //@ts-ignore
                onChangeRowsPerPage={(e) => {
                    onPageSizeChange(e);
                    //setPageSize(e);
                }}
                selectableRowsHighlight
                selectableRowsSingle
                selectableRows={selectableRows}
                paginationComponentOptions={paginationComponentOptions}
                paginationRowsPerPageOptions={rowsPerPage}
                onSelectedRowsChange={onSelectedRowChange}
                striped
                responsive
                fixedHeader
                //subHeader
                subHeaderComponent={subHeaderComponent}
                conditionalRowStyles={conditionalRowStyles || []}
                noDataComponent={<div></div>}
                //customStyles={{backgroundColor: 'yellow'}}
                // paginationComponent={isCustomPagination ? CustomPagination : undefined}
            />
        </>
    );
};
