import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
    ResponseBaseModel, CurrencyResponseModel,
    CurrencyDeleteResponseModel,
    LookupItemModel,
    CurrenciesShortCutModel,
} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getCurrency = async (): Promise<CurrencyResponseModel[]> => {
    try {
        const result: ResponseBaseModel<CurrencyResponseModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.currencyController.getCurrencyList);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(err);
    }
    return [];
};
export const getCurrencyInformation = async (
    Id: any
): Promise<CurrencyResponseModel> => {
    try {
        const result: ResponseBaseModel<CurrencyResponseModel> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.currencyController.getCurrencyById}?currencyId=${Id}`);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : null;
    } catch (err) {
        throw err;
    }
};
export const getCurrenciesShortCutList = async (): Promise<LookupItemModel[]> => {
    try {
        const result: ResponseBaseModel<CurrenciesShortCutModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.currencyController.getCurrenciesShortCut);
        // @ts-ignore
        let response: LookupItemModel[] = [];
        result.Result?.forEach((item) => {
            response.push({
                name: item.ArabicName,
                nameAr: item.EnglishName,
                value: item.EnglishName,
            });
        });

        return response;
    } catch (err) {
        alert(err);
    }
    return [];
};
//#endregion
//#region post
export const addCurrency = async (
    request: CurrencyResponseModel
): Promise<CurrencyResponseModel> => {
    let apiResponse: CurrencyResponseModel = {
        ArabicName: "",
        EnglishName: "",
        ShortCutArab: "",
        ShorCutEng: "",
        IsDefault: false,
        Value: 0,
        Note: "",
        ArabicShourtCut: "",
        EnglishShourtCust: "",
        CountryName: "",
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Name: "",
        CreationDate: new Date(),
        VerifyOnUpdate: false,
        rowState: 0,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.currencyController.saveCurrency, {...request});
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
export const deleteCurrency = async (
    id: number
): Promise<CurrencyDeleteResponseModel> => {
    let apiResponse: CurrencyDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(`${AlYuserApiUrls.currencyController.deleteCurrency}?id=${id}`);
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
//#endregion
