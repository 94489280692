import {
    CloseDaySearchModel, ResponseBaseModel, SearchCloseDayRequestModel, CloseDayResponseModel
} from "../models";
import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const searchCloseDayData = async (request: SearchCloseDayRequestModel): Promise<ResponseBaseModel<CloseDaySearchModel[]>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.closeDayController.searchStation, {params: AxiosSearchParams(request, 'searchStationParamter')});
};
//#endregion
//#region post
export const saveStationCloseDay = async (
    request: CloseDayResponseModel
): Promise<CloseDayResponseModel> => {
    let apiResponse: CloseDayResponseModel = {
        stationId: 0,
        operationUserId: 0,
        AcctualAmountAfterClose: 0,
    };
    try {
        let url: string = `${AlYuserApiUrls.closeDayController.closeDayOfStation}?stationId=${request.stationId}&operationUserId=${request.operationUserId}`;
        apiResponse = await AlYusrAxiosApiInstance.post(url, {...request});
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
}
//#endregion


