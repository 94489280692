//#region style
export const buttonSizeStyle = {
    fontSize: '16px',
    padding: '10px 20px',
};
export const toggleDropdownItemStyle = {
    padding: '8px 16px', // Customize padding as needed
    color: '#333', // Customize text color
    backgroundColor: '#f8f9fa', // Customize background color
    cursor: 'pointer', // Add a pointer cursor for better usability
};


export const btnStyle = {
    width: "100%",
    minHeight: "100px",
    fontWeight: "bold",
};
export const listStyle = {
    listStyle: "none",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ccc",
    padding: "0px",
    height: "80vh",
    overflow: "auto",
};
export const beneficiaryListStyle = {
    listStyle: "none",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ccc",
    padding: "0px",
    width: "100%", // Set width to 80% of the parent container
    height: "80%", // Set height to 80% of the parent container
    maxHeight: "80vh", // Set a maximum height if needed
    overflow: "auto",
};
export const listItemStyle = {
    padding: "10px 0px",
    marginBottom: "10px",
    backgroundColor: "#f1f1f1",
    cursor: "pointer",
};
export const active = {
    padding: "10px 0px",
    marginBottom: "10px",
    backgroundColor: "#999",
    cursor: "pointer",
    color: "#fff",
};
export const smallBtnStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "25px",
    height: "31px",
    borderRadius: "50%",
    fontWeight: "bold",
};
//#endregion