import {FC, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    ActionTypeEnum,
    CustomerComponentDisplayModeEnum,
    CustomerResponseModel,
    HasFormIdModel,
    LookupItemModel,
    LookupTypeEnum,
    RequestActionModel,
    ResponseBaseModel,
    SupplierCustomerTypeEnum,
    ValidationErrorModel,
} from "../../models";
import {ButtonBox, ErrorValidationBox, LoadingBox, SelectBox, TextBox, PrivilegesChecker} from "..";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";
import {Accordion, Card} from "react-bootstrap";
import {addCustomer} from "../../serviceBroker/customerApiServiceBroker";
import {
    getCustomerInvoiceType,
} from "../../serviceBroker/customerApiServiceBroker";
import {getAllCountryIsoCode, getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";


interface AddCustomerProps extends HasFormIdModel {
    request: CustomerResponseModel;
    type: SupplierCustomerTypeEnum;
    displayMode?: CustomerComponentDisplayModeEnum | null;
    onActionEvent: (o: RequestActionModel) => void;
}

export const AddCustomer: FC<AddCustomerProps> = ({
                                                      request,
                                                      type = SupplierCustomerTypeEnum.Customer,
                                                      displayMode = CustomerComponentDisplayModeEnum.defaultMode,
                                                      onActionEvent = () => {
                                                      }, formId,
                                                  }) => {
    //#region variables
    request.CustomerType =
        request.CustomerType == null ? type : request.CustomerType;
    let initialValues: CustomerResponseModel = request;
    //#endregion
    //#region state
    const isArabic = isArabicCurrentLanguage();
    const [cityList, setCityList] = useState<LookupItemModel[]>([]);
    const [customerTypeList, setCustomerTypeList] = useState<LookupItemModel[]>([]);
    const [countryList, setCountryList] = useState<LookupItemModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [validationSchema] = useState(
        Yup.object({
            Name: Yup.string().required(getLabelName("customerNameAr.missing")),
        })
    );
    //#endregion
    //#region function
    useEffect(() => {
        const fillData = async () => {
            switch (displayMode) {
                case CustomerComponentDisplayModeEnum.defaultMode:
                    setLoading(true);
                    const cities = await getLookupByType(LookupTypeEnum.Cities);
                    setCityList(cities);
                    const customerTypes = await getCustomerInvoiceType();
                    setCustomerTypeList(customerTypes);
                    const countries = await getAllCountryIsoCode();
                    setCountryList(countries);
                    setLoading(false);
                    break;
            }
        };
        fillData().then(() => {
        });
    }, []);
    const handleSubmit = async (request: CustomerResponseModel) => {
        try {
            setLoading(true);
            const response: ResponseBaseModel<CustomerResponseModel> = await addCustomer(
                request
            );
            if (
                response != null &&
                response.Errors != null &&
                response.Errors.length !== 0
            ) {
                setValidationErrors(response.Errors);
                onActionEvent({
                    id: 0,
                    action: ActionTypeEnum.Failed,
                    request: isArabic
                        ? response.Errors[0].MessageAr
                        : response?.Errors[0].MessageEn,
                });
                setLoading(false);
            } else {
                setValidationErrors([]);
                onActionEvent({
                    id: 0,
                    action: ActionTypeEnum.AddSuccess,
                    request: response?.Result,
                });
                setLoading(false);
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm}) => {
            values.CustomerType = Number(type);
            await handleSubmit(values);
            resetForm();
        },
    });
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form onSubmit={formik.handleSubmit}>
                <div
                    className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 g-md-4 g-sm-4">
                    <TextBox
                        labelName={getLabelName("Arabic Name")} //{t("lookup.nameAr")}
                        inputName={"Name"}
                        errorText={formik.errors.Name}
                        inputValue={formik.values.Name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextBox
                        labelName={getLabelName("English Name")}
                        inputName={"Name_En"}
                        errorText={formik.errors.Name_En}
                        inputValue={formik.values.Name_En}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextBox
                        labelName={getLabelName("Code")}
                        inputName={"Code"}
                        errorText={formik.errors.Code}
                        inputValue={formik.values.Code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextBox
                        labelName={getLabelName("E-Mail")}
                        inputName={"Mail"}
                        errorText={formik.errors.Mail}
                        inputValue={formik.values.Mail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <>
                        <TextBox
                            labelName={getLabelName("Begin Bal")} //{t("lookup.nameAr")}
                            inputName={"BalanceOfPoint"}
                            errorText={formik.errors.Code}
                            inputValue={formik.values.BalanceOfPoint}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="number"
                        />
                        <TextBox
                            labelName={getLabelName("Max Debit")} //{t("lookup.nameAr")}
                            inputName={"MaxDebit"}
                            errorText={formik.errors.Code}
                            inputValue={formik.values.MaxDebit}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="number"
                        />
                        <TextBox
                            labelName={getLabelName("Phone")} //{t("lookup.nameAr")}
                            inputName={"Mobile"}
                            errorText={formik.errors.Mobile}
                            inputValue={formik.values.Mobile}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextBox
                            labelName={getLabelName("ID")} //{t("lookup.nameAr")}
                            inputName={"IDNumber"}
                            errorText={formik.errors.IDNumber}
                            inputValue={formik.values.IDNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </>

                    <TextBox
                        labelName={getLabelName("Tax Number")} //{t("lookup.nameAr")}
                        inputName={"TaxNumber"}
                        errorText={formik.errors.TaxNumber}
                        inputValue={formik.values.TaxNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextBox
                        labelName={getLabelName("Note")} //{t("lookup.nameAr")}
                        inputName={"Notes"}
                        errorText={formik.errors.Notes}
                        inputValue={formik.values.Notes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type={"textarea"}
                    />
                    <SelectBox
                        labelName={getLabelName("customer type")}
                        source={customerTypeList}
                        isSingleSelect={true}
                        onStatusChange={(e: any) => {
                            formik.values.CustomerType = e.value;
                            request.CustomerType = e.value;
                        }}
                        selectedValues={[request.CustomerType.toString()]}
                        multiselectRef={undefined}
                    />
                </div>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{getLabelName("Detail")}</Accordion.Header>
                        <Accordion.Body>
                            <Card>
                                <Card.Body>
                                    <div
                                        className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 g-md-4 g-sm-4">
                                        <TextBox
                                            labelName={getLabelName("Address")} //{t("lookup.nameAr")}
                                            inputName={"Address"}
                                            inputValue={formik.values.Address}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <SelectBox
                                            labelName={getLabelName("country")}
                                            source={countryList}
                                            isSingleSelect={true}
                                            onStatusChange={(e: any) => {
                                                formik.values.address.CountryCode = e.value;
                                                formik.setFieldValue("address.CountryCode", e.value);
                                                request.address.CountryCode = e.value;
                                            }}
                                            selectedValues={[request.address?.CountryCode]}
                                            multiselectRef={undefined}
                                        />

                                        <SelectBox
                                            labelName={getLabelName("City")}
                                            source={cityList}
                                            isSingleSelect={true}
                                            key="Select City"
                                            errorText={formik.errors.City_ID}
                                            onStatusChange={(e: any) => {
                                                formik.values.City_ID = e.value;
                                                formik.setFieldValue("City_IDe", e.value);
                                                request.City_ID = e.value;
                                            }}
                                            selectedValues={[request.City_ID.toString()]}
                                            multiselectRef={undefined}
                                        />

                                        <TextBox
                                            labelName={getLabelName("Area")} //{t("lookup.nameAr")}
                                            inputName={"address.RegionCity"}
                                            inputValue={formik.values.address?.RegionCity}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />

                                        <TextBox
                                            labelName={getLabelName("Street")} //{t("lookup.nameAr")}
                                            inputName={"address.Street"}
                                            inputValue={formik.values.address?.Street}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />

                                        <TextBox
                                            labelName={getLabelName("Building Number")} //{t("lookup.nameAr")}
                                            inputName={"address.buildingNumber"}
                                            inputValue={formik.values.address?.buildingNumber}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <TextBox
                                            labelName={getLabelName("Remarks")} //{t("lookup.nameAr")}
                                            inputName={"address.Remarks"}
                                            inputValue={formik.values.address?.Remarks}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            type={"textarea"}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end mt-4">
                        <PrivilegesChecker formId={formId} action="EnableSave">
                            <ButtonBox
                            iconType="content-save"
                                type="submit"
                                className="btn-gradient-primary "
                                variant=""
                            >
                                {getLabelName("Save")}
                            </ButtonBox>
                        </PrivilegesChecker>
                        {displayMode === CustomerComponentDisplayModeEnum.defaultMode && (
                            <ButtonBox
                            iconType="close-circle"
                                variant="danger"
                                type="button"
                                className="mx-3"
                                onClick={() => {
                                    onActionEvent({id: 0, action: ActionTypeEnum.Clear});
                                }}
                            >
                                {getLabelName("Cancel")}
                            </ButtonBox>
                        )}
                    </div>
                </div>
            </form>
        </>
    );
    //#endregion
};
