import React, {FC} from "react";
import {ReportItemsSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {ItemReportSearchParamsModel, PageEnum} from "../../../models";
import {searchReportEditUserItemData,} from "../../../serviceBroker/itemsReportApiServiceBroker";

export const ReportEditUserItemDataPage: FC = () => {
    const initialState: ItemReportSearchParamsModel = {lang: 1, userId: getUserId()};
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportEditUserItemData}
            searchHeader={getPageNameByPageId(PageEnum.ReportModifyUsersOnItems)}
            SearchPanel={ReportItemsSearchPanel}
            formId={PageEnum.ReportModifyUsersOnItems}
        />
    );
};
