import React, {Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useRef, useState} from "react";
import {ButtonBox, LoadingBox, SelectBox, TextBox} from "../..";
import {getLabelName, getLookUpItemValue, updateStateDynamically} from "../../../utils";
import {
    ActionTypeEnum,
    ChangeItemValueTypeEnum,
    DailyTransactionTypeEnum, FillItemMoveBetweenStoresSearchRequestModel, KeyValueStateModel,
    LookupItemFilterModel,
    LookupItemModel,
    LookupTypeEnum, RequestActionModel,
    StoreTransferTransactionDetailResponseModel,
    TransactionItemResponseModel,
    UserTransactionsSettingResponseModel
} from "../../../models";
import {useStoreTransferState} from "./helper/state/storeTransferState";
import { getTextBoxNumericOrDefaultValue, handleCalculateItemRecordValuesUpdate,
    transactionItemInitialValues
} from "../common/businessLogic/transactionBl";
import {handleStoreTransferChangeNewRecordItemCalculation} from "../common/businessLogic/storeTransferTransactionBl";
import {getLookupByType} from "../../../serviceBroker/lookupApiServiceBroker";

export const StoreTransferRegisterTransactionItem :FC<{
    data: StoreTransferTransactionDetailResponseModel,
    transactionItemObject: TransactionItemResponseModel,
    setTransactionItemObject: Dispatch<SetStateAction<TransactionItemResponseModel>>,
    lookups:LookupItemFilterModel[],
    setLookups: Dispatch<SetStateAction<LookupItemFilterModel[]>>,
    settings:UserTransactionsSettingResponseModel[],
    transactionType: DailyTransactionTypeEnum,
    fromStoreId:number|null,
    toStoreId:number|null,
    onActionEvent: (o: RequestActionModel) => void
}>=({data,transactionItemObject,setTransactionItemObject,lookups,setLookups, settings,transactionType,fromStoreId,toStoreId, onActionEvent = () => {}})=>{
    //#region state
    const {loading, setLoading} = useStoreTransferState();
    //const [itemPerCategoryList, setItemPerCategoryList] = React.useState<LookupItemModel[]>([]);
    const [itemList, setItemList] = React.useState<LookupItemModel[]>(
        lookups.find(p => p.key === LookupTypeEnum.Items)?.value || []
    );
    const [fillItemRequest,setFillItemRequest]=useState<FillItemMoveBetweenStoresSearchRequestModel>({
        fromStoreId:data?.Store_IDFrom||null,
        toStoreId:data?.Store_IDFrom||null,
        categoryId:transactionItemObject.Categ_ID||null
    });
    //#endregion
    //#region useEffect
    // useEffect(() => {
    //     fillItemsList(fillItemRequest).then(() => {});
    // }, [fillItemRequest]);

    useEffect(() => {
        updateFillItemRequestStateAndFillItems("fromStoreId", fromStoreId).then(() => {});
    }, [fromStoreId]);
    useEffect(() => {
        updateFillItemRequestStateAndFillItems("toStoreId", toStoreId).then(() => {});
    }, [toStoreId]);
    useEffect(() => {
        updateFillItemRequestStateAndFillItems("categoryId", toStoreId).then(() => {});
    }, [fillItemRequest.categoryId]);
    // useEffect(() => {
    //     fillData().then(() => {});
    // }, [lookups]);
    //#endregion
    //#region variables
    const calcTypeList=
        lookups.find(p => p.key === LookupTypeEnum.CalcType)?.value || [];
    const userTransactionSetting = settings.find(x => x.TransactionType_ID === Number(transactionType)) as UserTransactionsSettingResponseModel || {} as UserTransactionsSettingResponseModel;

    //#endregion
    //#region ref
    const itemCategorySelectBoxMultiselectRef = useRef<any>();
    const itemSelectBoxMultiselectRef = useRef<any>();
    //#endregion
    //#region functions
    const updateFillItemRequestStateAndFillItems=async (key:string ,value:any)=> {
        setFillItemRequest(prevState => {
            const currentState = {...prevState, [key]: value};
            fillItemsList(currentState).then(() => {
            });
            return currentState;
        });
        // setFillItemRequest(prevState => ({
        //     ...prevState,
        //     fromStoreId: fromStoreId, //?? prevState.fromStoreId,
        // }));

    }
    const fillItemsList = async (request:FillItemMoveBetweenStoresSearchRequestModel) => {
        setLoading(true);
        if (request.fromStoreId !== null && request.toStoreId !== null) {
            const itemsList = await getLookupByType(LookupTypeEnum.ItemsStoreTransfer, false, false, [
                {key: 'fromStoreId', value:  String(request.fromStoreId||'-1')},
                {key: 'toStoreId', value:  String(request.toStoreId||'-1')},
                {key: 'categoryId', value: String(request.categoryId||'')}
            ]);
            setItemList(itemsList);
            updateLookupItem(setLookups, LookupTypeEnum.Items, itemsList);
            //setItemPerCategoryList(itemsList)
        }
        else{
            setItemList([]);
            updateLookupItem(setLookups, LookupTypeEnum.Items, []);
            handleClearCategoryControl().then(() => {});
            //setItemPerCategoryList([])
        }
        handleClearItemControl().then(() => {});
        setLoading(false);
    }
    const updateLookupItem = (
        setLookups: Dispatch<SetStateAction<LookupItemFilterModel[]>>,
        key: LookupTypeEnum,
        newValue: LookupItemModel[]
    ) => {
        setLookups(prevState => {
            const existingItemIndex = prevState.findIndex(item => item.key === key);
            if (existingItemIndex >= 0) {
                const updatedState = [...prevState];
                updatedState[existingItemIndex].value = newValue;
                return updatedState;
            } else {
                //// Key not found, add new item
              return [...prevState];
            }
        });
    };
    const fillData = async () => {
        setLoading(true);
       //setItemPerCategoryList(lookups.find(p => p.key === LookupTypeEnum.ItemsPerCategory)?.value || []);
        setItemList(lookups.find(p => p.key === LookupTypeEnum.Items)?.value || []);
        setLoading(false);
    };
    const handleClearCategoryControl =async () => {
        itemCategorySelectBoxMultiselectRef.current && itemCategorySelectBoxMultiselectRef.current.clearValue();
    };
    const handleClearItemControl =async () => {
        itemSelectBoxMultiselectRef.current && itemSelectBoxMultiselectRef.current.clearValue();
    };
    const updateTransactionItemObjectState = async (key?: string | null, value?: any | null, keyValues?: KeyValueStateModel[] | null) => {
        await updateStateDynamically(
            setTransactionItemObject,
            transactionItemObject,
            key,
            value, keyValues
        );
    };
    //#endregion
    //#region html
    return(<>
        {loading && <LoadingBox/>}
        <div
            className="row row-cols-1 row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
            <SelectBox
                id="categorySelectBox"
                labelName={getLabelName("Category")}
                isSingleSelect={true}
                selectedValues={
                    transactionItemObject.Categ_ID !== null &&
                    transactionItemObject.Categ_ID !== undefined
                        ? [transactionItemObject.Categ_ID.toString()]
                        : []
                }
                source={lookups.find(x => x.key === LookupTypeEnum.CategoryType)?.value || []}
                //multiselectRef={itemCategorySelectBoxMultiselectRef}
                //@ts-ignore
                multiselectRef={(node: MutableRefObject<any>) => node ? (itemCategorySelectBoxMultiselectRef.current = node) : null}
                onStatusChange={async (e: LookupItemModel) => {
                    const value = getLookUpItemValue(e);
                    itemSelectBoxMultiselectRef.current && itemSelectBoxMultiselectRef.current.clearValue();
                    setTransactionItemObject({...transactionItemInitialValues, Categ_ID: Number(value)});

                    //await updateFillItemRequestStateAndFillItems("categoryId",value !== null && value !== undefined?Number(value):null);
                    //setFillItemRequest({...fillItemRequest,categoryId:value !== null && value !== undefined?Number(value):null});
                   // await fillItemListPerCategory(value, setItemPerCategoryList, itemList);
                }}
            />
            <SelectBox
                id="itemsSelectBox"
                labelName={getLabelName("Item")}
                isSingleSelect={true}
                selectedValues={
                    transactionItemObject.ItemUnit_ID !== null &&
                    transactionItemObject.ItemUnit_ID !== undefined
                        ? [transactionItemObject.ItemUnit_ID.toString()]
                        : []
                }
                source={itemList}
                //@ts-ignore
                multiselectRef={(node: MutableRefObject<any>) => node ? (itemSelectBoxMultiselectRef.current = node) : null}
                // onInputChange={(inputValue: string) => {
                //     handleSearchAndFilterItems(setItemList, setItemPerCategoryList, itemList, itemPerCategoryList, inputValue, isArabic).then()
                // }}
                onStatusChange={async (e: LookupItemModel) => {
                    const value = getLookUpItemValue(e);
                        const varTransactionItemObj = handleStoreTransferChangeNewRecordItemCalculation({
                            ...transactionItemObject,
                            ItemUnit_ID: Number(value),
                            ItemUnit_IDTo: Number(value),
                        }, data, userTransactionSetting, transactionType, itemList, calcTypeList);
                        setTransactionItemObject(varTransactionItemObj);
                }}
            />
            <TextBox
                labelName={getLabelName("Quantity")}
                inputName={"Quantity"}
                type="number"
                inputValue={transactionItemObject.Quantity}
                onChange={async (e: any) => {
                    const value: number = getTextBoxNumericOrDefaultValue(e);
                    await updateTransactionItemObjectState("Quantity", value)
                }}
            />
            <TextBox
                labelName={getLabelName("Price")}
                isReadOnly={!userTransactionSetting.EnableShangPrice}
                inputName={"Unit_PriceInCurrencyCalculated"}
                type="number"
                inputValue={transactionItemObject.Unit_PriceInCurrencyCalculated}
                onChange={async (e: any) => {
                    const value: number = getTextBoxNumericOrDefaultValue(e);
                    await updateTransactionItemObjectState(null, null, [{
                        key: "Unit_PriceInCurrencyCalculated",
                        value: value
                    }, {key: "changeValueType", value: ChangeItemValueTypeEnum.ChangePriceInCurrency}]);
                }}
                onBlur={async () => {
                    const obj = handleCalculateItemRecordValuesUpdate(transactionItemObject);
                    setTransactionItemObject(obj);
                }}
            />
        </div>
        <div className="row mt-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                <ButtonBox
                    iconType="plus"
                    id="addItemBtn"
                    variant=""
                    className="btn-gradient-info me-2"
                    size="sm"
                    onClick={async () => {
                        onActionEvent({
                            request: {item: transactionItemObject,itemCategorySelectBoxMultiselectRef, itemSelectBoxMultiselectRef},
                            action: ActionTypeEnum.AddItem,
                        });
                    }}
                >
                    {getLabelName("Add")}
                </ButtonBox>
            </div>
        </div>
    </>)
    //#endregion
}