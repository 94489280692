import { Table, Form, DatePicker, Space, Divider } from "antd";
import type { DatePickerProps } from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import {
  ButtonBox,
  LoadingBox,
  SelectBox,
  TextBox,
  PrivilegesChecker,
} from "..";
import {
  ActionTypeEnum,
  SearchStoreInventoryResponseModel,
  SearchStoreInventoryRequestModel,
  RequestActionModel,
  LookupTypeEnum,
  LookupItemModel,
  RowStateEnum,
  ResponseBaseModel,
  HasFormIdModel,
  StoreResponseModel,
  TransactionDetailModel,
} from "../../models";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import { getLabelName, getUserId, isArabicCurrentLanguage } from "../../utils";
import { saveInventoryChecking } from "../../serviceBroker/itemApiServiceBroker";

interface StoreInventoryItemListProps extends HasFormIdModel {
  getStoreInventoryItems: (
    searchModel: SearchStoreInventoryRequestModel
  ) => void;
  request: SearchStoreInventoryResponseModel[];
  onActionEvent: (o: RequestActionModel) => void;
  totalRows: number;
}

export const StoreInventoryItemList: FC<StoreInventoryItemListProps> = ({
  request,
  onActionEvent = () => {},
  getStoreInventoryItems = () => {},
  formId,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const columns = [
    {
      title: getLabelName("Item"),
      dataIndex: `${isArabic ? "ItemNameAr" : "ItemNameEn"}`,
      key: `${isArabic ? "ItemNameAr" : "ItemNameEn"}`,
    },
    {
      title: getLabelName("Unit"),
      dataIndex: `${isArabic ? "UnitNameAr" : "UnitNameEn"}`,
      key: `${isArabic ? "UnitNameAr" : "UnitNameAr"}`,
    },
    {
      title: getLabelName("PriceQutyBegBal"),
      dataIndex: "PriceQutyBegBal",
      key: "PriceQutyBegBal",
    },
    {
      title: getLabelName("Quantity System"),
      dataIndex: "BalanceProgram",
      key: "BalanceProgram",
    },
    {
      title: getLabelName("Actual Quantity"),
      dataIndex: "BalanceInventory",
      key: "BalanceInventory",
      render: (text: any, record: SearchStoreInventoryResponseModel) => {
        // if (editingRow?.ItemUnitId === record.ItemUnitId) {
        return (
          // <Form.Item
          //   name="BalanceInventory"
          //  >
          <>
            {" "}
            <input
              id={record.ItemUnitId.toString()}
              name="BalanceInventory"
              defaultValue={text}
              onChange={(e: any) => {
                if (transactionDetails) {
                  let existingItem = transactionDetails.filter(
                    (x) => x.ItemUnit_ID == record.ItemUnitId
                  );
                  if (existingItem.length > 0) {
                    transactionDetails.map((obj: any) => {
                      if (obj.ItemUnit_ID === record.ItemUnitId) {
                        obj.Current_Balance = Number(e.target.value);
                      }
                    });
                  } else {
                    transactionDetails.push({
                      CreatedBy: getUserId(),
                      Current_Balance: Number(e.target.value),
                      ID: 0,
                      ItemUnit_ID: record.ItemUnitId,
                      ModifiedBy: getUserId(),
                      PriceCost: record.CostPrice,
                      Factor: record.Factor,
                      ItemInstore_ID: record.ItemInstoreId,
                      rowState: RowStateEnum.Add,
                      NotesDetails: "",
                      OldQuantity: record.BalanceProgram,
                      PriceSale: record.PriceSale,
                      UnitName: record.UnitNameEn,
                    });
                  }
                  setTransactionDetails(transactionDetails);
                } else {
                  if (transactionDetails == undefined) {
                    transactionDetails = [];
                  }
                  transactionDetails.push({
                    CreatedBy: getUserId(),
                    Current_Balance: Number(e.target.value),
                    ID: 0,
                    ItemUnit_ID: record.ItemUnitId,
                    ModifiedBy: getUserId(),
                    PriceCost: record.CostPrice,
                    Factor: record.Factor,
                    ItemInstore_ID: record.ItemInstoreId,
                    rowState: RowStateEnum.Add,
                    NotesDetails: "",
                    OldQuantity: record.BalanceProgram,
                    PriceSale: record.PriceSale,
                    UnitName: record.UnitNameEn,
                  });
                  setTransactionDetails(transactionDetails);
                }
              }}
              type="text"
            />
          </>

          //</Form.Item>
        );
        // }
        //  else {
        //   return <p>{text}</p>;
        // }
      },
    },

    {
      title: getLabelName("PriceSale"),
      dataIndex: "PriceSale",
      key: "PriceSale",
    },
    // {
    //   title: "Actions",
    //   render: (_:any, record:SearchStoreInventoryResponse) => {
    //     return (
    //       <>

    //         <ButtonBox
    //           className="btn btn-primary btn-xs" style={{margin:"10px"}} type="button"
    //           onClick={() => {
    //             setEditingRow(record);
    //             form.setFieldsValue({
    //               BalanceInventory: record.BalanceInventory,
    //             });
    //           }}
    //         >
    //           {t("Edit")}
    //         </ButtonBox>

    //         <ButtonBox
    //           className="btn btn-primary btn-xs" style={{margin:"10px"}} type="submit">
    //           {t("save")}
    //         </ButtonBox>
    //       </>
    //     );
    //   },
    // },
  ];
  //#endregion
  //#region state
  const [emp, setEmp] = useState<number | null>(null);
  const [searchCategory, setSearchCategory] = useState<number | null>(null);
  const [date, setDate] = useState<string>(new Date().toISOString());
  let [transactionDetails, setTransactionDetails] =
    useState<TransactionDetailModel[]>();
  const [_, setEditingRow] = useState<SearchStoreInventoryResponseModel | null>(
    null
  );
  const [searchItemBarcode, setSearchItemBarcode] = useState(null);
  const [searchItemName, setSearchItemName] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [empList, setEmpList] = useState<LookupItemModel[]>([]);
  const [catList, setCatList] = useState<LookupItemModel[]>([]);
  const [searchStoreInventoryRequest, setSearchStoreInventoryRequest] =
    useState<SearchStoreInventoryRequestModel>({
      pageNumber: 1,
      pageSize: 50,
      userId: getUserId(),
      itemBarcode: null,
      categoryId: null,
      itemName: null,
      lang: isArabicCurrentLanguage() ? 1 : 2,
    });
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const [categoryList, employeeList] = await Promise.all([
        getLookupByType(LookupTypeEnum.CategoryType),
        getLookupByType(LookupTypeEnum.Employee),
      ]);
      setCatList(categoryList !== null ? categoryList : []);
      setEmpList(employeeList !== null ? employeeList : []);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region functions
  const onFinish = async () => {
    setLoading(true);

    if (transactionDetails && transactionDetails.length > 0) {
      const res: ResponseBaseModel<StoreResponseModel> =
        await saveInventoryChecking({
          Emp_ID: emp,
          Date: date,
          Note: remarks,
          rowState: RowStateEnum.Add,
          ID: 0,
          CreatedBy: getUserId(),
          ModifiedBy: 0,
          Station_ID: 0,
          TotalMony: 0,
          TransactionDetaillist: transactionDetails,
        });
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Failed,
          request: isArabic
            ? res.Errors[0].MessageAr
            : res?.Errors[0].MessageEn,
        });
        setLoading(false);
      } else {
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Success,
          request: res?.Result,
        });
        setEditingRow(null);
        getStoreInventoryItems(searchStoreInventoryRequest);
        setLoading(false);
      }
    }
  };
  const handleOnChangeDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setDate(dateString);
  };
  const handleSearch = async () => {
    setLoading(true);
    const obj = { ...searchStoreInventoryRequest };
    obj.categoryId = searchCategory;
    obj.itemBarcode = searchItemBarcode;
    obj.itemName = searchItemName;
    setSearchStoreInventoryRequest(obj);
    getStoreInventoryItems(obj);
    setLoading(false);
  };
  const disabledDate = (current: any) => {
    return current && current.endOf("day") > dayjs().endOf("day");
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <div className="row row-cols-3 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 g-sm-3 g-md-4 align-items-start">
        <label style={{ width: "10%" }} htmlFor="Date">
          {getLabelName("Date")}
        </label>
        <DatePicker
          defaultValue={dayjs()}
          disabledDate={disabledDate}
          style={{ width: "30%" }}
          onChange={handleOnChangeDate}
        />

        <SelectBox
          labelName={getLabelName("Employee")}
          source={empList}
          isSingleSelect={true}
          onStatusChange={(e: any) => {
            setEmp(e.value);
          }}
          selectedValues={[emp == null ? "" : setEmp.toString()]}
          multiselectRef={undefined}
        />
      </div>
      {/* <div
        style={{ margin: "5px" }}
        className="row row-cols-1 row-cols-xxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-4 row-cols-sm-1 g-sm-2 g-md-4 align-items-start"
      > */}
      <div
        style={{ margin: "5px" }}
        className="row row-cols-2 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start"
      >
        <TextBox
          labelName={getLabelName("Remarks")}
          inputName={"remarks"}
          inputValue={remarks}
          onChange={(e: any) => {
            setRemarks(e.target.value);
          }}
          type="textarea"
        />
      </div>

      {/* <div className="row row-cols-1 row-cols-xxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-4 row-cols-sm-1 g-sm-2 g-md-4 align-items-start"> */}
      <div className="row row-cols-2 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start">
        <Space direction="vertical"></Space>
      </div>
      <Divider />
      <div className="row row-cols-2 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start">
        {/* <div className="row row-cols-1 row-cols-xxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-4 row-cols-sm-1 g-sm-2 g-md-4 align-items-start"> */}
        <br />
        <TextBox
          labelName={getLabelName("Go To Item")}
          inputName={"ItemName"}
          inputValue={searchItemName}
          onChange={(e: any) => {
            setSearchItemName(e.target.value);
          }}
        />
        <TextBox
          labelName={getLabelName("ItemBarcode")}
          inputName={"ItemBarcode"}
          inputValue={searchItemBarcode}
          onChange={(e: any) => {
            setSearchItemBarcode(e.target.value);
          }}
        />

        <PrivilegesChecker formId={formId} action="EnableSearch">
          <SelectBox
            labelName={getLabelName("Category")}
            source={catList}
            isSingleSelect={true}
            onStatusChange={(e: any) => {
              setSearchCategory(e.value);
            }}
            selectedValues={[
              searchCategory == null ? "" : searchCategory.toString(),
            ]}
            multiselectRef={undefined}
          />
          <ButtonBox
            iconType="magnify"
            style={{ width: "10%" }}
            onClick={async () => {
              await handleSearch();
            }}
          >
            {getLabelName("Search")}
          </ButtonBox>
        </PrivilegesChecker>
      </div>

      {/* <TableComponent
        columns={columns}
        data={request}
        totalRows={totalRows}
        currentPage={searchStoreInventoryRequest.pageNumber || 1}
        pageSize={searchStoreInventoryRequest.pageSize || defaultPageSize}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationType="server"
      /> */}
      <Form>
        <Table dataSource={request} columns={columns} />
      </Form>
      <PrivilegesChecker formId={formId} action="EnableSave">
        <ButtonBox
          iconType="content-save"
          className="btn btn-primary btn-xs"
          disabled={!transactionDetails || transactionDetails.length == 0}
          onClick={onFinish}
          style={{ margin: "10px" }}
        >
          {getLabelName("save")}
        </ButtonBox>
      </PrivilegesChecker>
    </>
  );
  //#endregion
};
