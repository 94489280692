import {FC} from "react";
import {NavDropdown} from "react-bootstrap";
import {MenuModel} from "../../../models";
import {Link} from "react-router-dom";
import {RoutePageConfigurations} from "../../../configuration";

export const MenuItem: FC<{
    request: MenuModel;
    isArabic: boolean;
}> = ({request, isArabic = false}) => {
    //#region variables
    const isParent: boolean =
        request.ChildBusinessObject !== null &&
        request.ChildBusinessObject.length !== 0;
    //#endregion
    //#region html
    if (isParent) {
        return (
            <>
                <NavDropdown
                    as="li"
                    title={isArabic ? request.ArabicName : request.Name}
                    id={`nav-dropdown-obj-${request.ID}`}
                >
                    {request.ChildBusinessObject.map((row, index) => {
                        return <MenuItem request={row} key={index} isArabic={isArabic}/>;
                    })}
                </NavDropdown>
                {/* </Navbar> */}
            </>
        );
    } else {
        return (
            <NavDropdown.Item
                id={`nav-dropdown-item-${request.ID}`}
                as={Link}
                to={request.WebUrl != null ? request.WebUrl : RoutePageConfigurations.notFoundPage}
            >
                {isArabic ? request.ArabicName : request.Name}
            </NavDropdown.Item>
        );
    }
    //#endregion
};

