import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Accordion } from "react-bootstrap";
import { useFormik } from "formik";
import {
  ButtonBox,
  LoadingBox,
  ModelDialogBox,
  PaymentDetails,
  PrivilegesChecker,
  RefundItemList,
  RegisterTransactionHeader,
  RegisterTransactionItem,
  TableComponent,
} from "../..";
import {
  ActionTypeEnum,
  CompanySettingModel,
  ComponentActionTypeEnum,
  DailyTransactionTypeEnum,
  HasFormIdModel,
  LookupItemModel,
  LookupTypeEnum,
  PaymentTypeEnum,
  PaymentValueDetailsModel,
  RequestActionModel,
  TransactionDetailResponseModel,
  TransactionItemResponseModel,
  UserTransactionsSettingResponseModel,
  ValidationErrorModel,
} from "../../../models";
import {
  generateGuid,
  getLabelName,
  getPageNameByUrl,
  isArabicCurrentLanguage,
  updateStateDynamically,
} from "../../../utils";
import { saveTransaction } from "../../../serviceBroker/transactionApiServiceBroker";
import {
  addItem,
  getAccountBalance,
  getCompanySettings,
  handleTransactionTotalValues,
  handleUpdateExistingTransactionValues,
  initializeTransactionValuesAndUserTransactionsConfiguration,
  isRefundTransaction,
  transactionDetailInitialValue,
  transactionItemInitialValues,
  UpdateTransactionActionUser,
  userTransactionsSettingInitialValues,
  validateRefundSubmitRequestAdditionalValidation,
  validateSubmitRequest,
} from "../common/businessLogic/transactionBl";
import {
  handleGroupItemsBasedOnId,
  validateAddItem,
} from "../common/businessLogic/commonBl";
import {
  getTransactionItemsColumns,
  handleRowStyle,
} from "../common/uiHelper/dataTableColumns";
import { TableColumn } from "react-data-table-component";
import { orderBy } from "lodash";
import { getLookupByType } from "../../../serviceBroker/lookupApiServiceBroker";

interface RegisterTransactionProps extends HasFormIdModel {
  transactionType: DailyTransactionTypeEnum;
  request?: TransactionDetailResponseModel | null;
  setActionType: Dispatch<SetStateAction<ComponentActionTypeEnum>>;
  actionType: ComponentActionTypeEnum;
  onActionEvent: (o: RequestActionModel) => void;
}

export const RegisterTransaction: FC<RegisterTransactionProps> = ({
  transactionType,
  request,
  setActionType,
  actionType,
  onActionEvent = () => {},
  formId,
}) => {
  //#region state
  const [isArabic] = useState<boolean>(isArabicCurrentLanguage());
  const [guidKey, setGuidKey] = useState<string>("");
  const [transactionTypeState, setTransactionTypeState] =
    useState<DailyTransactionTypeEnum>(transactionType);
  const [showRefundItemModel, setShowRefundItemModel] = useState(false);
  const [showPaymentDetailsModel, setShowPaymentDetailsModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [beneficiaryList, setBeneficiaryList] = useState<LookupItemModel[]>([]);
  const [paymentTypeList, setPaymentTypeList] = useState<LookupItemModel[]>([]);
  const [currencyList, setCurrencyList] = useState<LookupItemModel[]>([]);
  const [storeEmployeeList, setStoreEmployeeList] = useState<LookupItemModel[]>(
    []
  );
  const [costCenterList, setCostCenterList] = useState<LookupItemModel[]>([]);
  const [calcTypeList, setCalcTypeList] = useState<LookupItemModel[]>([]);
  const [categoryList, setCategoryList] = useState<LookupItemModel[]>([]);
  const [itemList, setItemList] = useState<LookupItemModel[]>([]);
  const [itemsPerCategoryList, setItemPerCategoryList] = useState<
    LookupItemModel[]
  >([]);
  const [userTransactionSettingList, setUserTransactionSettingList] = useState<
    UserTransactionsSettingResponseModel[]
  >([]);
  const [userTransactionSetting, setUserTransactionSetting] =
    useState<UserTransactionsSettingResponseModel>(
      userTransactionsSettingInitialValues
    );
  const [initialValues] = useState<TransactionDetailResponseModel>(
    request ?? transactionDetailInitialValue
  );
  let [data, setData] = useState<TransactionDetailResponseModel>(
    request || initialValues
  );
  const [refundTransactionData, setRefundTransactionData] =
    useState<TransactionDetailResponseModel>(initialValues);
  const [transactionItemObject, setTransactionItemObject] =
    useState<TransactionItemResponseModel>(transactionItemInitialValues);
  const [pageName, setPageName] = useState<string>("");
  const [companySettings] = useState<CompanySettingModel>(getCompanySettings);
  const [forceRerender, setForceRerender] = useState(false);
  const columns: TableColumn<TransactionItemResponseModel>[] = useMemo(
    () => [
      ...getTransactionItemsColumns(
        isArabic,
        transactionTypeState,
        setData,
        data,
        companySettings
      ),
    ],
    [isArabic, transactionTypeState, data, setData, forceRerender]
  );
  //#endregion
  //#region variables
  const isSalesComponent: boolean = [
    DailyTransactionTypeEnum.SalesInvoice,
    DailyTransactionTypeEnum.SalesRefund,
    DailyTransactionTypeEnum.ShowPrice,
    DailyTransactionTypeEnum.Order,
  ].includes(transactionTypeState);
  //#endregion
  //#region ref
  const itemCategorySelectBoxMultiselectRef = useRef<any>();
  const itemSelectBoxMultiselectRef = useRef<any>();
  const customerSelectBoxMultiselectRef = useRef<any>();
  const calcTypeSelectBoxMultiselectRef = useRef<any>();
  const paymentTypeSelectBoxMultiselectRef = useRef<any>();
  const currencySelectBoxMultiselectRef = useRef<any>();
  const storeEmployeeSelectBoxMultiselectRef = useRef<any>();
  const costCenterSelectBoxMultiselectRef = useRef<any>();
  const transactionSettingStatusButtonRef = useRef<any>();
  //#endregion
  //#region function
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddPaymentValuesSuccess:
        setShowPaymentDetailsModel(false);
        const paymentDetails: PaymentValueDetailsModel =
          request.request as PaymentValueDetailsModel;
        let dataObjet: TransactionDetailResponseModel = {
          ...data,
          CashMoney: paymentDetails.cash,
          CardMoney: paymentDetails.credit,
          DelayMoney: paymentDetails.delay,
          OthersMoney: paymentDetails.voucher,
          Voucher_ID: paymentDetails.voucherId,
          //CreditValue
        };
        setData(dataObjet);
        await handleSaveTransactionDetails(dataObjet);
        break;
      case ActionTypeEnum.TransactionRefund:
        await handleClearControls(false);
        // @ts-ignore
        const modifyTransactionObject: TransactionDetailResponseModel =
          request.request;
        setTransactionTypeState(modifyTransactionObject.TransactionType);
        if (
          modifyTransactionObject.TransactionDetaillist !== null &&
          modifyTransactionObject.TransactionDetaillist !== undefined &&
          modifyTransactionObject.TransactionDetaillist.length !== 0
        ) {
          modifyTransactionObject.TransactionDetaillist.forEach((row) => {
            row = handleUpdateExistingTransactionValues(row);
            return { ...row };
          });
        }
        const balance: number = await getAccountBalance(
          modifyTransactionObject.Customer_ID !== null &&
            modifyTransactionObject.Customer_ID !== undefined
            ? modifyTransactionObject.Customer_ID
            : 0,
          modifyTransactionObject.Currency_ID !== null &&
            modifyTransactionObject.Currency_ID !== undefined
            ? modifyTransactionObject.Currency_ID
            : null,
          beneficiaryList
        );
        const dataObject = handleTransactionTotalValues(
          modifyTransactionObject
        );
        setData({
          ...dataObject,
          balance: balance,
          TransactionDetaillist: [...dataObject.TransactionDetaillist],
        });
        // @ts-ignore
        setTransactionTypeState(modifyTransactionObject.transactionType);
        setGuidKey(generateGuid());
        break;
      case ActionTypeEnum.AddItem:
        await handleAddItem(request.request, transactionTypeState);
        break;
    }
  };
  const fillBasicDataPromiseAll = async () => {
    const [
      beneficiaryLookupList,
      CategoryLookupList,
      itemLookupList,
      paymentTypeLookupList,
      currencyLookupList,
      employeeLookupList,
      calcTypeLookupList,
      costCenterLookupList,
    ] = await Promise.all([
      isSalesComponent
        ? getLookupByType(LookupTypeEnum.Customers, true, false)
        : getLookupByType(LookupTypeEnum.Supplier, true, false),
      getLookupByType(LookupTypeEnum.CategoryType, true, true),
      getLookupByType(LookupTypeEnum.Items, true, true),
      getLookupByType(LookupTypeEnum.PaymentType, true, true),
      getLookupByType(LookupTypeEnum.Currency, true, true),
      getLookupByType(LookupTypeEnum.Employee, true, true),
      getLookupByType(LookupTypeEnum.CalcType, true, true),
      getLookupByType(LookupTypeEnum.CostCenter, true, true),
    ]);
    setBeneficiaryList(beneficiaryLookupList);
    setCategoryList(CategoryLookupList);
    setItemPerCategoryList(itemLookupList);
    setItemList(itemLookupList);
    setPaymentTypeList(paymentTypeLookupList);
    setCurrencyList(currencyLookupList);
    setStoreEmployeeList(employeeLookupList);
    setCalcTypeList(calcTypeLookupList);
    setCostCenterList(costCenterLookupList);
    await initializeTransactionValuesAndUserTransactionsConfiguration(
      setUserTransactionSettingList,
      setUserTransactionSetting,
      setData,
      data,
      [
        { key: "customers", value: beneficiaryLookupList },
        { key: "payments", value: paymentTypeLookupList },
        { key: "currencies", value: currencyLookupList },
        { key: "employees", value: employeeLookupList },
        { key: "calcTypes", value: calcTypeLookupList },
        { key: "costCenters", value: costCenterLookupList },
      ],
      transactionTypeState,
      true
    ); //,"Tf1Si3LlCp"
  };
  const handleAddItem = async (
    request: TransactionItemResponseModel,
    transactionType: DailyTransactionTypeEnum
  ) => {
    request.Transaction_Type = Number(transactionType);
    request = await addItem(request, data, itemList);
    const errorList: ValidationErrorModel[] = await validateAddItem(request);
    if (errorList !== null && errorList.length !== 0) {
      onActionEvent({
        action: ActionTypeEnum.RaiseError,
        request: errorList,
      });
      return;
    } else {
      onActionEvent({
        action: ActionTypeEnum.ClearError,
      });
      //transactionType;
    }

    data.TransactionDetaillist = handleGroupItemsBasedOnId(
      data.TransactionDetaillist,
      request
    );
    const dataObject = handleTransactionTotalValues(data);

    setData({
      ...dataObject,
      TransactionDetaillist: [...dataObject.TransactionDetaillist],
    });
    itemCategorySelectBoxMultiselectRef.current &&
      itemCategorySelectBoxMultiselectRef.current.clearValue();
    itemSelectBoxMultiselectRef.current &&
      itemSelectBoxMultiselectRef.current.clearValue();
    setTransactionItemObject({
      ...request,
      ItemDiscount: 0,
      ItemDiscountRatio: 0,
      Quantity: 1,
    });
  };
  const handleSubmit = async (request: TransactionDetailResponseModel) => {
    // alert(request.TransactionType);
    // alert(transactionType)
    request.TransactionType = transactionTypeState;
    request = UpdateTransactionActionUser(request);
    const errorList: ValidationErrorModel[] = await validateSubmitRequest(
      request
    );
    switch (request.TransactionType) {
      case Number(DailyTransactionTypeEnum.SalesRefund):
      case Number(DailyTransactionTypeEnum.PurchasesRefund):
        const refundErrorList: ValidationErrorModel[] =
          await validateRefundSubmitRequestAdditionalValidation(request);
        if (refundErrorList !== null && refundErrorList.length !== 0) {
          errorList.push(...refundErrorList);
        }
        break;
    }
    if (errorList !== null && errorList.length !== 0) {
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.RaiseError,
        request: errorList,
      });
      return;
    }
    switch (Number(data.PaymentType_ID)) {
      case Number(PaymentTypeEnum.Multiple):
        setShowPaymentDetailsModel(true);
        break;
      default:
        await handleSaveTransactionDetails(request);
        break;
    }
  };
  const handleClearControls = async (isResetRequestState: boolean = true) => {
    currencySelectBoxMultiselectRef.current &&
      currencySelectBoxMultiselectRef.current.clearValue();
    storeEmployeeSelectBoxMultiselectRef.current &&
      storeEmployeeSelectBoxMultiselectRef.current.clearValue();
    costCenterSelectBoxMultiselectRef.current &&
      costCenterSelectBoxMultiselectRef.current.clearValue();
    paymentTypeSelectBoxMultiselectRef.current &&
      paymentTypeSelectBoxMultiselectRef.current.clearValue();
    calcTypeSelectBoxMultiselectRef.current &&
      calcTypeSelectBoxMultiselectRef.current.clearValue();
    customerSelectBoxMultiselectRef.current &&
      customerSelectBoxMultiselectRef.current.clearValue();
    itemCategorySelectBoxMultiselectRef.current &&
      itemCategorySelectBoxMultiselectRef.current.clearValue();
    itemSelectBoxMultiselectRef.current &&
      itemSelectBoxMultiselectRef.current.clearValue();
    if (isResetRequestState) {
      setData({ ...initialValues });
    }
    setTransactionItemObject({ ...transactionItemInitialValues });
  };
  const handleSaveTransactionDetails = async (
    request: TransactionDetailResponseModel
  ) => {
    setLoading(true);
    const result = await saveTransaction(request);

    if (
      result.Errors !== null &&
      result.Errors !== undefined &&
      result.Errors.length !== 0
    ) {
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.RaiseError,
        request: result.Errors,
      });
    } else {
      onActionEvent({
        action: ActionTypeEnum.AddSuccess,
      });
    }
    setLoading(false);
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    //enableReinitialize: true,
    onReset: async () => {
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.Clear,
      });
    },
    onSubmit: async () => {
      await handleSubmit(data);
    },
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await fillBasicDataPromiseAll();
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    setPageName(getPageNameByUrl());
  }, [isArabicCurrentLanguage()]);
  useEffect(() => {
    if (actionType !== ComponentActionTypeEnum.None) {
      const fillData = async () => {
        setActionType(ComponentActionTypeEnum.None);
        switch (actionType) {
          case ComponentActionTypeEnum.Clear:
            await handleClearControls(true);
            break;
          case ComponentActionTypeEnum.Add:
            break;
          case ComponentActionTypeEnum.Edit:
            await handleClearControls(false);
            // @ts-ignore
            const modifyTransactionObject: TransactionDetailResponseModel =
              request; // || initialValues;
            if (
              modifyTransactionObject !== null &&
              modifyTransactionObject !== undefined &&
              modifyTransactionObject.TransactionDetaillist !== null &&
              modifyTransactionObject.TransactionDetaillist !== undefined &&
              modifyTransactionObject.TransactionDetaillist.length !== 0
            ) {
              modifyTransactionObject.TransactionDetaillist.forEach((row) => {
                row = handleUpdateExistingTransactionValues(row);
                return { ...row };
              });
            }
            const balance: number = await getAccountBalance(
              modifyTransactionObject.Customer_ID !== null &&
                modifyTransactionObject.Customer_ID !== undefined
                ? modifyTransactionObject.Customer_ID
                : 0,
              modifyTransactionObject.Currency_ID !== null &&
                modifyTransactionObject.Currency_ID !== undefined
                ? modifyTransactionObject.Currency_ID
                : null,
              beneficiaryList
            );

            const dataObject = handleTransactionTotalValues(
              modifyTransactionObject
            );
            setData({
              ...dataObject,
              balance: balance,
              TransactionDetaillist: [...dataObject.TransactionDetaillist],
            });
            break;
          case ComponentActionTypeEnum.Refund:
            // @ts-ignore
            setRefundTransactionData(request);
            break;
          case ComponentActionTypeEnum.Reset:
            setLoading(true);
            await handleClearControls(true);
            //@ts-ignore
            await initializeTransactionValuesAndUserTransactionsConfiguration(
              setUserTransactionSettingList,
              setUserTransactionSetting,
              setData,
              request!,
              [
                { key: "customers", value: beneficiaryList },
                { key: "payments", value: paymentTypeList },
                { key: "currencies", value: currencyList },
                { key: "employees", value: storeEmployeeList },
                { key: "calcTypes", value: calcTypeList },
                { key: "costCenters", value: costCenterList },
              ],
              transactionTypeState,
              false
            ); //,"Tf1Si3LlCp"
            setTransactionTypeState(transactionType);
            setTransactionItemObject(transactionItemInitialValues);
            setLoading(false);
            break;
          default:
            break;
        }
      };
      fillData().then(() => {});
    }
  }, [actionType]);
  useEffect(() => {
    const fillData = async () => {
      const orderedBeneficiaryList = orderBy(
        beneficiaryList.filter((p) => p.isAdded === true),
        ["value"],
        ["desc"]
      );
      if (
        orderedBeneficiaryList !== null &&
        orderedBeneficiaryList !== undefined &&
        orderedBeneficiaryList.length !== 0
      ) {
        await updateStateDynamically(
          setData,
          data,
          "Customer_ID",
          Number(orderedBeneficiaryList[0].value)
        );
      }
    };
    fillData().then(() => {});
  }, [beneficiaryList]);
  useEffect(() => {
    setShowRefundItemModel(
      refundTransactionData !== null &&
        refundTransactionData.TransactionDetaillist !== null &&
        refundTransactionData.TransactionDetaillist.length !== 0
    );
  }, [refundTransactionData]);
  useEffect(() => {
    if (guidKey !== "") {
      setForceRerender((prev) => !prev);
    }
  }, [guidKey]);
  //#endregion
  //#region html
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {loading && <LoadingBox />}
        <div className="card p-3 btn-fix d-flex align-items-end mb-2">
          {data.TransactionDetaillist.length > 0 && (
            <PrivilegesChecker formId={formId} action="EnableSave">
              <ButtonBox
                iconType="content-save"
                type="submit"
                className="btn btn-sm btn-gradient-primary"
                variant=""
              >
                {getLabelName("Save")}
              </ButtonBox>
            </PrivilegesChecker>
          )}
          <ButtonBox
            iconType="receipt"
            variant="danger"
            type="button"
            className="btn btn-sm"
            onClick={formik.handleReset}
          >
            {getLabelName("New")}
          </ButtonBox>
        </div>

        {/*transaction header*/}
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{pageName}</Accordion.Header>
            <Accordion.Body>
              <RegisterTransactionHeader
                formik={formik}
                data={data}
                transactionItemObject={transactionItemObject}
                userTransactionSetting={userTransactionSetting}
                companySettings={companySettings}
                transactionType={transactionTypeState}
                isSalesComponent={isSalesComponent}
                beneficiaryList={beneficiaryList}
                itemList={itemList}
                calcTypeList={calcTypeList}
                currencyList={currencyList}
                paymentTypeList={paymentTypeList}
                storeEmployeeList={storeEmployeeList}
                costCenterList={costCenterList}
                setData={setData}
                setTransactionItemObject={setTransactionItemObject}
                setBeneficiaryList={setBeneficiaryList}
                customerSelectBoxMultiselectRef={
                  customerSelectBoxMultiselectRef
                }
                storeEmployeeSelectBoxMultiselectRef={
                  storeEmployeeSelectBoxMultiselectRef
                }
                calcTypeSelectBoxMultiselectRef={
                  calcTypeSelectBoxMultiselectRef
                }
                costCenterSelectBoxMultiselectRef={
                  costCenterSelectBoxMultiselectRef
                }
                paymentTypeSelectBoxMultiselectRef={
                  paymentTypeSelectBoxMultiselectRef
                }
                currencySelectBoxMultiselectRef={
                  currencySelectBoxMultiselectRef
                }
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/*register item*/}
        {!isRefundTransaction(transactionTypeState) && (
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span>
                  {" ".concat(getLabelName("Add Items To"), pageName)}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <RegisterTransactionItem
                  transactionType={transactionTypeState}
                  data={data}
                  transactionItemObject={transactionItemObject}
                  setTransactionItemObject={setTransactionItemObject}
                  userTransactionSetting={userTransactionSetting}
                  companySettings={companySettings}
                  itemList={itemList}
                  setItemList={setItemList}
                  itemsPerCategoryList={itemsPerCategoryList}
                  setItemPerCategoryList={setItemPerCategoryList}
                  categoryList={categoryList}
                  calcTypeList={calcTypeList}
                  itemCategorySelectBoxMultiselectRef={
                    itemCategorySelectBoxMultiselectRef
                  }
                  itemSelectBoxMultiselectRef={itemSelectBoxMultiselectRef}
                  onActionEvent={(o: RequestActionModel) => {
                    handleAction(o).then(() => {});
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {/*transaction items list*/}
        {data.TransactionDetaillist.length > 0 && (
          <Accordion defaultActiveKey="2">
            <Accordion.Item eventKey="2">
              <Accordion.Body>
                <>
                  <div className="item-unit-list">
                    <div className="section-title">
                      <span>{getLabelName("Item Transaction")}</span>
                    </div>
                    <TableComponent
                      columns={columns}
                      data={data.TransactionDetaillist}
                      totalRows={1000}
                      currentPage={1}
                      pageSize={10000}
                      paginationType="none"
                      conditionalRowStyles={handleRowStyle(
                        data.TransactionDetaillist || []
                      )}
                    />
                  </div>
                </>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {/*payment Details*/}
        <ModelDialogBox
          isXCloseButtonVisible={true}
          isModelVisible={showPaymentDetailsModel}
          isCloseButtonVisible={false}
          isEscapeCloseEnabled={true}
          size="lg"
          title={"payment details"}
          onCloseEvent={() => {
            setShowPaymentDetailsModel(false);
          }}
        >
          <PaymentDetails
            setShowPaymentDetailsModel={setShowPaymentDetailsModel}
            totalPayment={data.TotalNet}
            onActionEvent={(o: RequestActionModel) => {
              handleAction(o).then(() => {});
            }}
          />
        </ModelDialogBox>
        {showRefundItemModel && (
          <RefundItemList
            request={refundTransactionData}
            onActionEvent={(o: RequestActionModel) => {
              setShowRefundItemModel(false);
              handleAction(o).then(() => {});
            }}
          />
        )}
        <ButtonBox
          style={{ display: "none" }}
          ref={transactionSettingStatusButtonRef}
          onClick={async () => {}}
        />
      </form>
    </>
  );
  //#endregion
};
