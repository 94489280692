import {useEffect, useReducer} from "react";
import {
    AcTransactionSearchModel,
    SearchAccountsRequestPayloadModel,
    SearchItemApiResponseModel,
    SearchItemRequestModel,
    SearchItemResponseModel,
    SearchItemsTypeEnum,
} from "../../models";
import {searchItems} from "../../serviceBroker/itemApiServiceBroker";

enum ActionType {
    SET_SEARCHED_ACCOUNTS,
    SET_TOTAL_ROWS,
    SET_LOADING,
    SET_SEARCH_PARAMS,
    RESET_SEARCH_PARAMS,
    SET_PAGE_SIZE,
    SET_PAGE_NUMBER,
}

type Action = {
    type: ActionType;
    payload?: any;
};

interface ItemsState {
    searchedItems: AcTransactionSearchModel[];
    totalRows: number;
    loading: boolean;
    searchParams: SearchItemRequestModel;
}

const initialItemsState: ItemsState = {
    searchedItems: [],
    totalRows: 1,
    loading: false,
    searchParams: {
        pageSize: 10,
        pageNumber: 1,
        searchType: SearchItemsTypeEnum.None,
        categoryId: 1,
    },
};

const itemsReducer = (state: ItemsState, action: Action): ItemsState => {
    switch (action.type) {
        case ActionType.SET_SEARCHED_ACCOUNTS:
            return {...state, searchedItems: action.payload};
        case ActionType.SET_TOTAL_ROWS:
            return {...state, totalRows: action.payload};
        case ActionType.SET_LOADING:
            return {...state, loading: action.payload};
        case ActionType.SET_PAGE_SIZE:
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    pageSize: action.payload,
                    pageNumber: 1,
                },
            };
        case ActionType.SET_PAGE_NUMBER:
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    pageNumber: action.payload,
                },
            };
        case ActionType.SET_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: {...state.searchParams, ...action.payload},
            };
        case ActionType.RESET_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: {
                    pageNumber: state.searchParams["pageNumber"],
                    pageSize: state.searchParams["pageSize"],
                    searchType: SearchItemsTypeEnum.None,
                },
            };
        default:
            return state;
    }
};

const useSearchedItems = (initialSearchParams: SearchItemRequestModel) => {
    const [state, dispatch] = useReducer(itemsReducer, {
        ...initialItemsState,
        searchParams: initialSearchParams,
    });

    const fetchItems = async () => {
        try {
            dispatch({type: ActionType.SET_LOADING, payload: true});
            const items = await searchItems(state.searchParams);
            if (items !== null) {
                dispatch({
                    type: ActionType.SET_SEARCHED_ACCOUNTS,
                    payload: items.Result as SearchItemApiResponseModel[],
                });
                dispatch({
                    type: ActionType.SET_TOTAL_ROWS,
                    payload: items.TotalRecoredCount as number,
                });
            }
        } catch (err) {
        } finally {
            dispatch({type: ActionType.SET_LOADING, payload: false});
        }
    };

    useEffect(() => {
        fetchItems().then(() => {
        });
    }, [state.searchParams]);

    return {
        searchedItems: state.searchedItems,
        areItemsLoading: state.loading,
        searchParams: state.searchParams,
        setSearchParams: (params: SearchAccountsRequestPayloadModel) =>
            dispatch({type: ActionType.SET_SEARCH_PARAMS, payload: params}),
        resetSearchParams: () => dispatch({type: ActionType.RESET_SEARCH_PARAMS}),
        setPageSize: (pageSize: number) =>
            dispatch({type: ActionType.SET_PAGE_SIZE, payload: pageSize}),
        setPageNumber: (pageNumber: number) =>
            dispatch({type: ActionType.SET_PAGE_NUMBER, payload: pageNumber}),
        setSearchedItems: (accs: SearchItemResponseModel[]) =>
            dispatch({type: ActionType.SET_SEARCHED_ACCOUNTS, payload: accs}),
        currentPage: state.searchParams["pageNumber"],
        totalRows: state.totalRows,
        currentPageSize: state.searchParams["pageSize"],
        fetchItems,
    };
};

export default useSearchedItems;
