import {FC, useEffect, useState} from "react";
import {Accordion, Card} from "react-bootstrap";
import {LoadingBox, StoreInventoryItemList, ToastBox} from "../../components";
import {
    ActionTypeEnum,
    RequestActionModel,
    SearchStoreInventoryRequestModel,
    SearchStoreInventoryResponseModel,
    ToastModel,
} from "../../models";
import {getLabelName, getUserId, isArabicCurrentLanguage,} from "../../utils";
import {itemSearchItemBalanceInventoryCheck} from "../../serviceBroker/itemApiServiceBroker";

export const StoreInventoryPage: FC<{ pageId: number }> = ({pageId}) => {
    //#region state
    const [loading, setLoading] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [objects, setObjects] = useState<SearchStoreInventoryResponseModel[]>([]);
    const [searchStoreInventoryRequest, setSearchStoreInventoryRequest] =
        useState<SearchStoreInventoryRequestModel>({
            pageNumber: 1,
            pageSize: 50,
            userId: getUserId(),
            itemBarcode: null,
            categoryId: null,
            itemName: null,
            lang: isArabicCurrentLanguage() ? 1 : 2,
        });
    const [toastModel, setToastModel] = useState<ToastModel>({show: false,});
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await getAllObjects(searchStoreInventoryRequest);
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region function
    const handleAction = async (request: RequestActionModel) => {
        switch (request.action) {
            case ActionTypeEnum.Success:
                setIsRefresh(true);
                setToastModel({
                    ...toastModel,
                    variant: "success",
                    show: true,
                });
                break;
            case ActionTypeEnum.Failed:
                setToastModel({
                    ...toastModel,
                    body: request.request,
                    variant: "danger",
                    show: true,
                });
                setIsRefresh(true);
                break;
        }
    };
    const getAllObjects = async (searchModel: SearchStoreInventoryRequestModel) => {
        setLoading(true);
        const result = await itemSearchItemBalanceInventoryCheck(searchModel);
        setObjects(result?.Result || []);
        setTotalRows(result?.TotalRecoredCount || 0);
        setLoading(false);
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}

            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: true});
                    }}
                />
            )}
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{getLabelName("store Inventory")}</Accordion.Header>
                    <Accordion.Body>
                        <Card>
                            {/* <Card.Header></Card.Header> */}
                            <Card.Body>
                                <StoreInventoryItemList
                                    request={objects}
                                    onActionEvent={async (o: RequestActionModel) => {
                                        await handleAction(o);
                                    }}
                                    getStoreInventoryItems={async (
                                        searchModel: SearchStoreInventoryRequestModel
                                    ) => {
                                        await getAllObjects(searchModel);
                                    }}
                                    totalRows={totalRows}
                                    formId={pageId}
                                />
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
};
