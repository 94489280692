import React, {FC} from "react";
import {ReportPage, ReportTreasurySearchPanel} from "../../../components";
import {PageEnum, TreasuryReportSearchParamsModel} from "../../../models";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {searchReportInMoney} from "../../../serviceBroker/treasuryReportApiServiceBroker";

export const ReportInMoneyPage: FC = () => {
    const initialState: TreasuryReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportInMoney}
            searchHeader={getPageNameByPageId(PageEnum.ReportInMoney)}
            SearchPanel={ReportTreasurySearchPanel}
            formId={PageEnum.ReportInMoney}
        />
    );
};
