import {FC} from "react";
import {ControlSizeType, LabelSizeType} from "../../../models";

export const LabelBox: FC<{
    labelName: string;
    inputValue:
        | string
        | ReadonlyArray<string>
        | number
        | boolean
        | undefined
        | null;
    controlSize?: ControlSizeType;
    labelSize?: LabelSizeType;
    id?: string | undefined;
}> = ({
          labelName,
          inputValue,
          controlSize = "col flex-form-control",
          labelSize = "form-label", id
      }) => {
    return (
        <>
            <div
                className={controlSize}
            >
                <label id={`${id}_lt` || `label_title_${Math.random()}`} className={labelSize}>{labelName}</label>
                <label id={`${id}_lv` || `label_value_${Math.random()}`}
                       className="form-control read-only">{inputValue}</label>
            </div>
        </>
    );
};
