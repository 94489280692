export const TRANSLATIONS_AR = {
    "TRANSLATOR.SELECT": "اختر لغتك",
    "user name": "اسم المستخدم",
    "enter user name": "ادخل اسم المستخدم",
    "login.password": "كلمة المرور",
    "login.enter_password": "ادخل كلمة المرور",
    "login.submit": "تسجيل",
    "user nameMissing": "ادخل اسم المستخدم",
    "login.passwordMissing": "ادخل كلمة المرور",
    "logout.button": "تسجيل الخروج",
    "user.userName": "اسم المستخدم",
    "user.nameAr": "الاسم (ع)",
    "user.nameEn": "الاسم(ج)",
    "user.name": "الاسم",
    "user.password": "كلمة المرور",
    "user.isAdmin": "مدير النظام",
    "user.registerUser": "تسجيل مستخدم",
    "user.userList": "قائمة المستخدمين",
    "user.reset": "اعادة",
    "user.register.username.missing": "ادخل اسم المستخدم",
    "user.register.nameen.missing": "ادخل الاسم(ح)",
    "user.register.name.missing": "ادخل الاسم(ع)",
    "user.register.password.missing": "ادخل كلمة المرور",
    "process.Completed": "اكتملت العملية بنجاح",
    "process.failed": "حدث خطأ",
    CloseButton: "اغلاق",
    "user.Add": "اضافة",
    "user.Modify": "تعديل",
    "user.Delete": "حذف",
    "user.Permissions": "الصلاحيات",
    "confirmDialog.yes": "نعم",
    "confirmDialog.no": "لا",
    "confirmDialog.are_You_Sure": "هل أنت متأكد؟",
    "Permission.permissionName": "Name",
    "Permission.EnableSave": "Save",
    "Permission.EnableUpdate": "Update",
    "Permission.EnableDelete": "Delete",
    "Permission.EnableSearch": "Search",
    "Permission.IsEnabledForTablet": "Tablet",
    "save": "Save",
    "button.reset": "Reset",
    "cancel": "Cancel",
    "Company Name": "اسم الشركة",
    "Mobile": "الهاتف",
    "Logo": "نص الشعار",
    "Logo Image": "صورة الشعار",
    "reset": "اعادة",
    "unit.name": "الاسم",
    "unit.isDefault": "افتراضى",
    "unit.nameAr": "الاسم(ع)",
    "unit.nameEn": "الاسم(ح)",
    "unit.register.nameEn.missing": "ادخل الاسم(ح)",
    "unit.register.nameAr.missing": "ادخل الاسم(ع)",
    "entity.register": "تسجيل",
    "entity.reset": "اعادة",
    "lookup.name": "الاسم",
    "Edit": "تعديل",
    "Delete": "حذف",
    "lookup.Add": "اضافة",
    "lookup.nameAr": "الاسم(ع)",
    "lookup.nameEn": "الاسم(ج)",
    "lookup.Value": "القيمة",
    "lookup.register.nameAr.missing": "ادخل الاسم(ع)",
    "lookup.register.nameEn.missing": "ادخل الاسم(ج)",
    "lookup.register.value.missing": "ادخل القيمة",
    "Alyuser.Application": "اليسر",
    selectAllRowsFromTable: "الكل",
    rowsPerPageText: "عدد الصفوف في الصفحة:",
    rangeSeparatorText: "من",
    Add: "اضافة",
    "nameEn.missing": "ادخل الاسم(ح)",
    "nameAr.missing": "ادخل الاسم(ع)",
    "Search Criteria": "معايير البحث",
    "Search By Name": "الاسم",
    "Search By Code": "الكود",
    "Search By BarCode": "الباركود",
    Category: "المجموعة",
    Categories: "المجموعات",

    "Current Balance": "الرصيد الحالى",
    Modify: "تعديل",
    "currency.ShortCutArab": "الاختصار",
    "currency.Value": "القيمة",
    "currency.Modify": "تعديل",
    "currency.ArabicName": "الاسم عربى",
    "currency.EnglishName": "الاسم الانجليزى",
    "currency.titlePage": "اضافة عمله جديده",
    "Category.name": "المجموعه",
    SelectAll: "اختيار الكل",
    TaxPercentage: "نسبة الضريبه",
    "user.settings": "إعدادات المستخدم",
    mustpositive: "يجب أن يكون رقمًا موجبًا",
    mustintegar: "يجب أن يكون عددًا صحيحًا",
    "user.transactionsettings": "إعدادات العمليات",
    "user.posSetting": "اعدادات نقاط البيع",
    PointCalc: "إحتساب النقاط",
    CompanySettingDetails: "الشعار وبيانات الشركه",
    LoyaltySetting: "اعدادات قسائم الولاء",
    CompanySetting: "إعدادات الشركه",
    SystemSetting: "إعدادات النظام",
    validemail: "يجب ان يكون ايميل ",
    "itemId.missing": "اختر الصنف",
    "categoryId.missing": "اختر الفئة",
    "unitId.missing": "اختر الوحدة",
    "quantity.missing": "أضف الكمية",
    "price.missing": "أضف السعر",
    "customerId.missing": "اختر العميل",
    "CalcType_ID.missing": "اختر نوع المحاسبة",
    "PaymentType_ID.missing": "اختر نوع الدفع",
    "currencyId.missing": "اختر العملة",
    "date.missing": "أضف التاريخ",
    ElectronicBill: "الفاتورة الإلكترونية",
    Search: "بحث",
    ModifyCustomer: "تعديل العميل",
    AddCustomer: "اضافة العميل",
    ModifySupplier: "تعديل المورد",
    AddSupplier: "اضافة المورد",
    "customerNameAr.missing": "يجب ادخال الاسم",
    AddCategory: "اضافة مجموعه",
    ModifyCategory: "تعديل مجموعه",
    AddStore: "اضافة مخزن",
    ModifyStore: "تعديل مخزن",
    actions: "تعديل اوحذف",
    addEstehkak: "اضافة مستحق",
    addEstektaa: "اضافة مستقطع",
    "user.Information": "بيانات المستخدم",
    "Currency.Information": "بيانات العمله",
    required: "مطلوب",
    "processSuccess": "تمت العملية بنجاح",
    "dueRule.Information": "بنود  الاستحقاق",
    "subtractRule.Information": "بنود الاستقطاع",
    "addDueRule.Information": "اضافة بند الاستحقاق",
    "addSubtractRule.Information": "اضافة بند الاستقطاع",
    ArabicName: "الاسم عربى",
    EnglishName: "الاسم الانجليزى",
    Notes: " ملاحظات",
    Estehkaks: "الاستحقاقات",
    Subtracts: "الاستقطاعات",
    Suppliers: "الموردون",
    Customers: "العملاء",
    "employee.Information": "بيانات الموظف",
    "Employee.job": "الوظيفه",
    "Employee.Address": "العنوان",
    CustomerPayment: "مقبوضات العملاء",
    AddEstihkakSubtractRule: "اضافة بند",
    "Employee.List": "البيانات السابقه",
    "no units added to item": "لم يتم اضافة وحدات للصنف",
    thePercentage: "النسبة",

    "unit.add": "اضافة وحده",
    units: "الوحدات",
    storeInventory: "جرد المخزن",

    "transaction.data.header": "بيانات المعاملات",
    dictionary: "القاموس",
    "Employee.Mobile": "موبايل",
    "Employee.Percentage_Of_Sales": "نسبة المبيعات",
    "Employee.Name_EN": "الاسم الاجنبى",
    "Employee.name": "الاسم",
    selectedPrice: "السعر المختار",
    name: "الاسم العربي",
    calcType: "انواع المحاسبة",
    previousData: "بيانات سابقة",
    paymentType: "انواع الدفع",
    isEnable: "تمكين",
    selectAll: "اختيار الكل",
    "footer_copyRights": "حقوق النشر ©",
    "footer_allRightsReserved": "اليسر جميع الحقوق محفوظة",
    "empid.missing": "اختر الموظف",
    "ruleId.missing": "اختر البند",
    "cache.Refresh": "تحديث ذاكرة التخزين المؤقت",
    "closeDay.miniValue": "يجب أن تكون قيمة يوم الإغلاق 0 على الأقل",
    "refund.items": "اختر صنف واحد على الاقل",
    "refund.noItems.ForRefund": "الفاتورة لا يتوافر فيها اصناف مرتجع",
    "refund item qty": "لا يمكن أن تتجاوز الكمية الحد الأقصى للكمية",
    "pos.item.discount.validation": "الخصم لا يمكن أن يتجاوز سعر الوحدة",
    "Let's get started": "هيا بنا نبدأ",
    "Sign in to continue": "تسجيل الدخول للمتابعة",
    "Alyusr System": "نظام اليسر",
    "Alyusr.System.login.description": "لتقديم خدمات ممتازة للعملاء\n" +
        "والمحافظة على البيئة والصحة العامة و\n" +
        "تطوير أنظمة البناء والاستثمار في الموارد البشرية."
};
