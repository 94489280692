import React, {FC} from "react";
import {PageEnum, SuppliersSimpleReportSearchParamsModel} from "../../../models";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {ReportPage, ReportSuppliersSimpleSearchPanel,} from "../../../components";
import {searchReportSuppliersData} from "../../../serviceBroker/suppliersReportApiServiceBroker";

export const ReportSuppliersData: FC = () => {
    const initialState: SuppliersSimpleReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportSuppliersData}
            searchHeader={getPageNameByPageId(PageEnum.ReportSuppliersData)}
            SearchPanel={ReportSuppliersSimpleSearchPanel}
            formId={PageEnum.ReportSuppliersData}
        />
    );
};
