import {useEffect, useState} from "react";
import {getLookUp} from "../../components/transactions/common/businessLogic/transactionBl";
import {LookupItemModel, LookupTypeEnum} from "../../models";

const useStoredCurrencies = () => {
    const [storedCurrencies, setStoreCurrenciesList] = useState<LookupItemModel[]>([]);
    const [loading, setIsLoading] = useState(false);
    const fetchData = async () => {
        setIsLoading(true);
        const fetchedCurrencies = await getLookUp(
            [setStoreCurrenciesList],
            LookupTypeEnum.Currency,
            true,
            true
        );
        setStoreCurrenciesList(fetchedCurrencies);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchData().then(() => {
        });
    }, []);

    return {
        storedCurrencies,
        currenciesLoading: loading,
    };
};
export default useStoredCurrencies;
