import { Dropdown } from "react-bootstrap";
import React from "react";
import { FaEllipsisV } from "react-icons/fa";
import { HasChildren } from "../box/loadingBox";

const CustomToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: React.ReactNode;
      onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    },
    ref: any
  ) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(e);
      }}
    >
      {children}
    </a>
  )
);

interface CustomDropdownProps extends HasChildren {
  dropdownIcon: {
    color: string;
    size: number;
  };
  style?: React.CSSProperties;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  children,
  style,
  dropdownIcon: { color, size },
}) => {
  return (
    <Dropdown style={style}>
      <Dropdown.Toggle as={CustomToggle}>
        <FaEllipsisV size={size} fill={color} />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ zIndex: 1000 }}>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
