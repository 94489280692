import {FC} from "react";
import {TransactionMainComponent} from "../../../components";
import {DailyTransactionTypeEnum, PageEnum} from "../../../models";

export const PurchasesInvoicePage: FC = ({}) => {
    //#region html
    return (
        <>
            <TransactionMainComponent
                formId={PageEnum.PurchaseBill}
                transactionType={DailyTransactionTypeEnum.PurchasesInvoice}
            />
        </>
    );
    //#endregion
};
