import {useEffect, useState} from "react";
import {getAllAccounts} from "../../serviceBroker/accountApiServiceBroker";
import {AccountResponseResultModel} from "../../models";

const useAllAccounts = () => {
    const [accounts, setAccounts] = useState<AccountResponseResultModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchAccounts = async () => {
        setIsLoading(true);
        const fetchedAccounts = await getAllAccounts();
        setIsLoading(false);
        if (!fetchedAccounts) return [];
        setAccounts(fetchedAccounts);
    };

    const handleDeleteAccount = (ID: number) => {
        setAccounts((prev) => prev.filter(({ID: deletedId}) => deletedId !== ID));
    };

    const handleAddAccount = (account: AccountResponseResultModel) => {
        setAccounts((prev) => [...prev, account]);
    };

    const handleModifyAccount = (updatedAccount: AccountResponseResultModel) => {
        setAccounts((prev) =>
            prev.map((account) => {
                if (account.ID === updatedAccount.ID) return updatedAccount;
                return account;
            })
        );
    };

    useEffect(() => {
        fetchAccounts().then(() => {
        });
    }, []);

    return {
        accounts,
        areAccountsLoading: isLoading,
        fetchAccounts,
        handleDeleteAccount,
        handleAddAccount,
        handleModifyAccount,
    };
};

export default useAllAccounts;
