import {FC} from "react";
import {LookupFormTypeEnum, PageEnum} from "../../models";
import {LookupPage} from "./lookupPage";

export const CitiesLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Cities} formId={PageEnum.Cities}/>
}
export const CountriesLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Country} formId={PageEnum.Countries}/>

}
export const CostCenterLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.CostCenter} formId={PageEnum.CostCenters}/>
}
export const AdditionAndRecommendationRestaurantLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.AdditionAndRecommendationRestaurant}
                       formId={PageEnum.AdditionalRecommendOfItemsInRestaurant}/>
}
export const GradesLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Grade} formId={PageEnum.Grade}/>
}
export const NationalitiesLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Nationalities} formId={PageEnum.Nationalities}/>
}
export const ReligionsLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Religions} formId={PageEnum.Religions}/>
}
export const SocialStatusLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.SocialStatus} formId={PageEnum.SocialStatus}/>
}
export const EmployeesStatusLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Employee_Status} formId={PageEnum.EmployeeStatus}/>
}
export const OrdersStatusLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.OrderStatus} formId={PageEnum.ConsignOut}/>
}
export const CarsTypesLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Cars_Type} formId={PageEnum.CarsType}/>
}
export const CarsColorsLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Cars_Color} formId={PageEnum.CarsColors}/>
}
export const CarsServicesLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.Cars_Service} formId={PageEnum.CarService}/>
}
export const AdditionCostsLookupPage: FC = () => {
    return <LookupPage lookupType={LookupFormTypeEnum.AdditionCosts} formId={PageEnum.AdditionCosts}/>
}
