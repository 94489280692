import { FC, FormEvent, useState } from "react";
import {
  ActionTypeEnum,
  EmployeeListItemModel,
  HasFormIdModel,
} from "../../models";
import { getLabelName } from "../../utils";
import setEmployeeColumns from "./uiHelper/employeeColumns";
import { ButtonBox, PrivilegesChecker, TextBox, TableComponent } from "..";

interface EmployeeList extends HasFormIdModel {
  request: EmployeeListItemModel[];
  isModifyAble?: boolean | null;
  isDeleteAble?: boolean | null;
  isPermissionAble?: boolean | null;
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

export const EmployeeList: FC<EmployeeList> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region state
  const [searchedWord, setSearchedWord] = useState("");
  //#endregion
  //#region function
  const handleSearchEmployeeByName = () => {
    onActionEvent({ action: ActionTypeEnum.Search, request: searchedWord });
  };
  //#endregion
  //#region html
  return (
    <>
      <PrivilegesChecker formId={formId} action="EnableSearch">
        <div
          className="d-flex mb-4 justify-content-between"
          style={{ width: 290 }}
        >
          <TextBox
            inputName="search"
            inputValue={searchedWord}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              setSearchedWord(e.currentTarget.value)
            }
            labelName=""
            placeHolder={`${getLabelName("name")}, ${getLabelName("phone")}`}
            controlSize="w-10"
          />
          <ButtonBox onClick={handleSearchEmployeeByName}>
            {getLabelName("search")}
          </ButtonBox>
        </div>
      </PrivilegesChecker>
      {request != null && request.length !== 0 && (
        <TableComponent
          currentPage={1}
          totalRows={request.filter(({ shown }) => shown).length}
          pageSize={10}
          columns={setEmployeeColumns({ onActionEvent }, formId)}
          data={request.filter(({ shown }) => shown)}
          paginationType={"client"}
        />
      )}
    </>
  );
  //#endregion
};
