import {FC} from "react";
import {PageEnum, TransactionReportSearchParamsModel} from "../../../models";
import {ReportPage, ReportProfitOfSaleSearchPanel} from "../../../components";
import {searchReportProfitOfSales} from "../../../serviceBroker/transationReportApiServiceBroker";
import {getPageNameByPageId} from "../../../utils";

export const ReportProfitOfSalePage: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {
        lang: 1,
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportProfitOfSales}
            searchHeader={getPageNameByPageId(PageEnum.ReportProfitOfSales)}
            SearchPanel={ReportProfitOfSaleSearchPanel}
            formId={PageEnum.ReportProfitOfSales}
        />
    );
};
