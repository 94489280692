import React, {FC} from "react";
import {getMonth, getYear} from "date-fns";
import DatePicker, {
    ReactDatePickerCustomHeaderProps,
    ReactDatePickerProps,
} from "react-datepicker";
import _ from "lodash";
import {Form} from "react-bootstrap";
import {useField, useFormikContext} from "formik";
import {getLabelName} from "../../../utils";
import "./customDatePickerWidth.css";
import {
    ControlSizeType,
    InputDatePickerTimeDisplayModeEnum,
    LabelSizeType,
} from "../../../models";
import {ButtonBox} from "../..";
//#region types
type CustomDatePickerProps = ReactDatePickerProps & {
    selectedDate?: Date;
    InputLabel: string;
    maxDate?: Date | null | undefined;
    controlSize?: ControlSizeType;
    timeIntervals?: number | null | undefined;
    timeMode?: InputDatePickerTimeDisplayModeEnum | null;
    labelSize?: LabelSizeType;
};
//#endregion
//#region html
const InputHeader: FC<ReactDatePickerCustomHeaderProps> = (props) => {
    const {
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    } = props;

    const years: number[] = _.range(1920, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    return (
        <div
            style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
            }}
        >
            <ButtonBox onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
            </ButtonBox>
            <select
                value={getYear(date)}
                onChange={({target: {value}}) => changeYear(parseInt(value))}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <select
                value={months[getMonth(date)]}
                onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <ButtonBox onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
            </ButtonBox>
        </div>
    );
};
type CustomDatePickerPropsWithForm = Omit<
    ReactDatePickerProps,
    "onChange" | "name"
> & {
    onChange?(
        date: Date | null,
        event: React.SyntheticEvent<any> | undefined
    ): void;
    InputLabel?: string;
    name: string;
    controlSize?: ControlSizeType;
    labelSize?: LabelSizeType;
    timeIntervals?: number | null | undefined;
    timeMode?: InputDatePickerTimeDisplayModeEnum | null;
};
export const InputDatePickerInForm: FC<CustomDatePickerPropsWithForm> = (
    props
) => {
    const {
        name,
        InputLabel,
        controlSize = "col flex-form-control customDatePickerWidth",
        labelSize = "form-label",
        timeMode = InputDatePickerTimeDisplayModeEnum.None,
    } = props;
    const {setFieldValue} = useFormikContext();
    const [field, {error}] = useField(name);
    const renderError = () => {
        if (error) {
            return (
                <Form.Control.Feedback className="d-block" type="invalid">
                    {error}
                </Form.Control.Feedback>
            );
        }
        return null;
    };
    const isInvalidClassName = error ? "form-control is-invalid" : "form-control";
    return (
        <>
            <div className={`${controlSize}`}>
                <label className={labelSize}>
                    {getLabelName(InputLabel as string)}
                </label>
                <DatePicker
                    className={isInvalidClassName}
                    renderCustomHeader={(
                        headerProps: ReactDatePickerCustomHeaderProps
                    ) => <InputHeader {...headerProps} />}
                    selected={(field.value && new Date(field.value)) || null}
                    showTimeInput={
                        timeMode === InputDatePickerTimeDisplayModeEnum.TimeInput
                    }
                    showTimeSelect={
                        timeMode === InputDatePickerTimeDisplayModeEnum.TimeSelect
                    }
                    dateFormat={
                        timeMode !== InputDatePickerTimeDisplayModeEnum.None
                            ? "MMMM d, yyyy h:mm aa"
                            : "dd/MM/yyyy"
                    }
                    {...props}
                    onChange={(val) => {
                        setFieldValue(field.name, val);
                    }}
                />
                {renderError()}
            </div>
        </>
    );
};

export const InputDatePicker: FC<CustomDatePickerProps> = (props) => {
    const {
        selectedDate,
        InputLabel,
        controlSize = "col flex-form-control customDatePickerWidth",
        maxDate,
        timeIntervals = 30,
        timeMode = InputDatePickerTimeDisplayModeEnum.None,
        labelSize = "form-label",
    } = props;
    return (
        <>
            <div className={`${controlSize}`}>
                <label className={labelSize}>{getLabelName(InputLabel)}</label>
                <DatePicker
                    wrapperClassName="customDatePickerWidth"
                    renderCustomHeader={(
                        headerProps: ReactDatePickerCustomHeaderProps
                    ) => <InputHeader {...headerProps} />}
                    maxDate={maxDate}
                    selected={selectedDate}
                    timeIntervals={timeIntervals}
                    showTimeInput={
                        timeMode === InputDatePickerTimeDisplayModeEnum.TimeInput
                    }
                    showTimeSelect={
                        timeMode === InputDatePickerTimeDisplayModeEnum.TimeSelect
                    }
                    dateFormat={
                        timeMode !== InputDatePickerTimeDisplayModeEnum.None
                            ? "MMMM d, yyyy h:mm aa"
                            : "dd/MM/yyyy"
                    }
                    {...props}
                />
            </div>
        </>
    );
};
//#endregion
