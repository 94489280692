import {FC} from "react";
import {EmployeeDueAndSubtractRules} from "../../components";
import {PageEnum} from "../../models";

export const EmployeeDueRulesPage: FC<{ formId: number }> = ({
                                                                 formId = PageEnum.AdditionRules,
                                                             }) => {
    //#region html
    return (
        <EmployeeDueAndSubtractRules formId={formId}/>
    );
    //#endregion
};
