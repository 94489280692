import React, {FormEvent} from "react";
import {TextBox, ButtonBox} from "../..";
import {getLabelName} from "../../../utils";

interface SearchBoxProps {
    onChangeHandler: (e: FormEvent<HTMLInputElement>) => void;
    searchedWord: string;
    onSearchHandler: React.MouseEventHandler<HTMLButtonElement>;
    name?: string;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
                                                        onChangeHandler,
                                                        searchedWord,
                                                        name,
                                                        onSearchHandler,
                                                    }) => {
    return (
        <div className="d-flex mb-4 justify-content-between" style={{width: 290}}>
            <TextBox
                inputName="search"
                inputValue={searchedWord}
                onChange={onChangeHandler}
                labelName=""
                placeHolder={getLabelName(name || "search")}
                controlSize="w-10"
            />
            <ButtonBox iconType="magnify" onClick={onSearchHandler}>{getLabelName("search")}</ButtonBox>
        </div>
    );
};