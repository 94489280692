import React, { useState } from "react";
import {
  DataTableProps,
  GridColumnsHookParams,
} from "../../../../models";
import {useGridColumns} from "../../../../hooks";
import {  Dropdown } from "react-bootstrap";
import { ModelDialogBox,LoadingBox,CustomDropdown,TableComponent } from "../../..";
import {getFormPrivileges, getLabelName} from "../../../../utils";
import RenderGridColumnsSettings from "./rendering/renderGridColumnsSettings";
import {TableColumnBase} from "react-data-table-component/dist/src/DataTable/types";

interface GridTableProps<T> extends Omit<DataTableProps, "columns"> {
  columnsProps?: GridColumnsHookParams<T>|null;
    columns?: TableColumnBase[]|null  ;
   // isFetchColumnFromServer?: boolean|null;
}
interface ManualColumnsComponentProps {
    columns: TableColumnBase[];
    data: any[];
    tableProps: Omit<DataTableProps, "columns" | "data">;
}

interface ServerColumnsComponentProps {
    data: any[];
    tableProps: Omit<DataTableProps, "columns" | "data">;
    columnsProps: GridColumnsHookParams<any>;
}


export const GridTable = <T extends {}>({
  columnsProps,
  data,
    columns,
    // isFetchColumnFromServer=true,
  ...tableProps
}: GridTableProps<T>): React.ReactElement => {
    if (data.length === 0)
        return <p className="text-center ">No previous data to show</p>;
    else if( columnsProps && columns?.length!==0) // && isFetchColumnFromServer===true
        return <ServerGridComponent columnsProps={columnsProps}  data={data} tableProps={tableProps} />;
    else if(columns?.length!==0)// && isFetchColumnFromServer===false
        return <ClientGridComponent columns={columns||[]} data={data} tableProps={tableProps} />;
    else return <p className="text-center ">not able to generate columns</p>;
};


const ServerGridComponent: React.FC<ServerColumnsComponentProps> = ({
                                                                           data,
                                                                           tableProps,
                                                                           columnsProps,
                                                                       }) => {
    console.log('res_x_1_render_server_component')
    const {
        gridColumns,
        fetchGridColumns,
        isGridColumnsLoading,
        columnsSettings,
    } = useGridColumns({
        ...columnsProps,
        data,
    });
    const { ColumnsSettingId: gridId } = getFormPrivileges(columnsProps.formId);
    const [isGridColumnsModalOpen, setIsGridColumnsOpen] = useState(false);
    if (data.length === 0)
        return <p className="text-center ">No previous data to show</p>;
    return (
        <LoadingBox isLoading={isGridColumnsLoading}>
            <div className="position-relative">
                <CustomDropdown
                    style={{
                        position: "absolute",
                        left: "20px",
                        top: "11px",
                        zIndex: 1,
                    }}
                    dropdownIcon={{ color: "#FFF", size: 20 }}
                >
                    <Dropdown.Item
                        style={{ minWidth: "15rem" }}
                        onClick={() => setIsGridColumnsOpen(true)}
                    >
                        {getLabelName("Show/Hide Columns")}
                    </Dropdown.Item>
                </CustomDropdown>
                <TableComponent {...tableProps} data={data} columns={gridColumns} />
            </div>
            <ModelDialogBox
                isModelVisible={isGridColumnsModalOpen}
                size="xl"
                onCloseEvent={() => setIsGridColumnsOpen(false)}
                title={"columns"}
            >
                <RenderGridColumnsSettings
                    gridId={gridId}
                    onComplete={() => setIsGridColumnsOpen(false)}
                    fetchGridColumns={fetchGridColumns}
                    columnsSettings={columnsSettings}
                    isColumnsLoading={isGridColumnsLoading}
                />
            </ModelDialogBox>
        </LoadingBox>
    );
};

const ClientGridComponent : React.FC<ManualColumnsComponentProps> = ({ columns, data, tableProps }) => {
    console.log('res_x_1_render_render_component')
    return (
        <TableComponent
            {...tableProps}
            data={data}
            columns={columns}
        />
    );
};
