import React from "react";

export const Loading = () => {
    //#region html
    return (
        <div id="loader">
            <div id="status">
                <div className="spinner"></div>
            </div>
        </div>
    );
    //#endregion
};

