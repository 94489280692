import {
    GeneralPrintResponseModel,
    ResponseBaseModel,
    SuppliersComplexReportSearchParamsModel,
    SuppliersSimpleReportSearchParamsModel
} from "../models";
import AlYusrAxiosApiInstance, {AxiosSearchParams} from "../axios/alYusrAxiosApiInstance";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const searchReportSuppliersData = async (request: SuppliersSimpleReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.supplierReportController.reportSuppliersData, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReportSuppliersPayments = async (request: SuppliersComplexReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.supplierReportController.reportSuppliersPayments, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReportSuppliersAccount = async (request: SuppliersComplexReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.supplierReportController.reportSuppliersAccount, {params: AxiosSearchParams(request, 'searchParmeter')});
};
export const searchReportAllSuppliersTransaction = async (request: SuppliersComplexReportSearchParamsModel): Promise<ResponseBaseModel<GeneralPrintResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.supplierReportController.reportAllSuppliersTransaction, {params: AxiosSearchParams(request, 'searchParmeter')});
};
//#endregion

