import {DataNode} from "rc-tree/lib/interface";
import {AccountResponseResultModel} from "../../../models";

export const transformAccountsToTreeNode = (
    accounts: AccountResponseResultModel[],
    isArabic: boolean
): DataNode[] => {
    // Create a dictionary to quickly look up accounts by their ID
    const accountMap = new Map(accounts.map((account) => [account.ID, account]));

    // Recursive function to build the tree hierarchy
    const buildTree = (accountId: number): DataNode => {
        const account = accountMap.get(accountId);
        if (!account) {
            return {key: "", title: ""}; // Empty node if account not found
        }
        const {ID, ArabicName, EnglishName} = account;
        // Create the node for the current account
        const treeNode: DataNode = {
            key: ID!.toString(),
            title: isArabic ? ArabicName : EnglishName,
            children: [],
        };

        // Find and add children recursively
        const children = accounts
            .filter((childAccount) => childAccount.Parent_ID === ID)
            .map((childAccount) => buildTree(childAccount.ID as number));

        if (children.length > 0) {
            treeNode.children = children;
        }

        return treeNode;
    };
    // Find and build root nodes (accounts with Parent_ID = 0)
    return accounts
        .filter((account) => account.Parent_ID === 0)
        .map((rootAccount) => buildTree(rootAccount.ID as number));
};
