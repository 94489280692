import { PosTransactionsMainComponent,POSInvoiceType } from "../../../components";
import {
  DailyTransactionTypeEnum,
} from "../../../models";

export const PosPage = () => {
  //#region html
  return (
    <>
      <PosTransactionsMainComponent
        transactionTypeID={DailyTransactionTypeEnum.POSSales}
      />
    </>
  );
  //#endregion
};

export const RestaurantPosPage = () => {
  //#region html
  return (
    <>
      <PosTransactionsMainComponent
        InvoiceTypeComponent={POSInvoiceType}
        transactionTypeID={DailyTransactionTypeEnum.POSResurant}
      />
    </>
  );
  //#endregion
};
