import React, {FC, MouseEventHandler, useState} from 'react';
import CardHeader from "react-bootstrap/CardHeader";
import {getLabelName} from "../../utils";
import {Card, Form, Row} from "react-bootstrap";
import {LookupItemModel, SearchRuleExpenseMoneyRequestModel, RuleExpRecipeAccountsEnum} from "../../models";
import {TextBox, ButtonBox} from '..';

export const RuleExpenseSearchPanel: FC<{
    acTransactionType: RuleExpRecipeAccountsEnum;
    header: string;
    searchParams: SearchRuleExpenseMoneyRequestModel;
    setSearchParams: Function;
    handelSearch: MouseEventHandler<HTMLButtonElement>;
    customerAccountLookups: LookupItemModel[];
    userLookUps: LookupItemModel[];
    allTreasuryInAccount: LookupItemModel[];
    allTreasuryOutAccount: LookupItemModel[];
    setShowAcTransactionModel: Function
}> = (props) => {
    //#region variables
    const {
        handelSearch,
        setShowAcTransactionModel,
    } = props;
    //#endregion
    //#region state
    const [searchValue, setSearchValue] = useState("");
    //#endregion
    //#region html
    return (
        <>
            <Card>
                <CardHeader>
                    <Row>
                        <div className="col-md-6 d-flex ">
                            {getLabelName(props.header)}
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <ButtonBox onClick={() => setShowAcTransactionModel(true)} iconType="plus">
                                {getLabelName("Add")}
                            </ButtonBox>
                        </div>
                    </Row>
                </CardHeader>
                <Card.Body>

                    <Form>
                        <Row>
                            <TextBox
                                labelName={"Search"}
                                inputName={"filterTextBox"}
                                inputValue={searchValue}
                                onChange={(e: any) => {
                                    setSearchValue(e.target.value);
                                }}
                            />
                        </Row>

                        <div className="accordion-footer">
                            <div className="col-md-12 d-flex justify-content-end">
                                <ButtonBox className="btn btn-orange" iconType="magnify" variant="outline-primary"
                                           onClick={handelSearch}
                                >
                                    {getLabelName("Search")}
                                </ButtonBox>
                            </div>

                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
    //#endregion
};