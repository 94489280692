import dateFormat from "dateformat";
import {HasCreationDateModel} from "../../models";

export const getLastDayTime = (inputDate: Date): Date => {
    const dateCopy = new Date(inputDate);
    dateCopy.setHours(23, 59, 59, 999); // Set the time to the last millisecond of the day
    return dateCopy;
};

export const getFirstDayTime = (inputDate: Date): Date => {
    const dateCopy = new Date(inputDate);
    dateCopy.setHours(0, 0, 0, 0); // Set the time to the first millisecond of the day
    return dateCopy;
};

export const formatDate = (
    value: string | Date | null | undefined,
    formatter?: string | null
): string => {
    formatter =
        formatter === null || formatter === undefined || formatter === ""
            ? "dd/mm/yyyy"
            : formatter;
    return value === null || value === undefined || value === ""
        ? ""
        : dateFormat(value, formatter);
};



type SortingMode = "asc" | "desc";


export const sortByDate = (array: HasCreationDateModel[], mode?: SortingMode) => {
    return array.sort((a, b) => {
        const dateA = new Date(a.CreationDate);
        const dateB = new Date(b.CreationDate);

        if (mode === "desc") {
            return dateB.getTime() - dateA.getTime();
        } else {
            return dateA.getTime() - dateB.getTime();
        }
    });
};