import {DataNode} from "rc-tree/lib/interface";
import {FC, useEffect, useState} from "react";
import {TreeViewModel} from "../../models";
import {getCategoryTree} from "../../serviceBroker/categoryApiServiceBroker";

export const TreeViewPage: FC<{}> = () => {
    const [treeData, setTreeData] = useState<TreeViewModel[]>([]);
    useEffect(() => {
        const fillData = async () => {
            const data: TreeViewModel[] = await getCategoryTree();
            setTreeData(data);
        };
        fillData().then(() => {
        });
    }, []);
    const onSelectItem = (e: DataNode[]) => {
    };
    return (
        <>
            <span>dummy</span>
        </>
    );
};
