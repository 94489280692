import {AccountAcBeginBalanceTypeEnum, RowStateEnum, TransactionRowDataModel} from "../../models";

export const roundNumber = (
  value: number | null,
  fractionDigits: number | null = 2
): number => {
  try {
    return value !== null && value !== undefined
      ? Number(value.toFixed(fractionDigits || 2))
      : 0;
  } catch {
    return 0;
  }
};

export const getOriginalValueInCurrency = (
  value: number,
  valueEntity: number
): number => {
  return roundNumber(value / valueEntity);
};
export const getValueInCurrency = (
  value: number,
  valueEntity: number = 1
): number => {
  return roundNumber(value * valueEntity);
};

export const sumType = (
    type: AccountAcBeginBalanceTypeEnum,
    transactions: TransactionRowDataModel[]
) => {
  return transactions
      .filter(({rowState}) => rowState !== RowStateEnum.Delete)
      .filter(({valueType}) => valueType === type)
      .map(({amount}) => amount)
      .reduce((accumulator, curr) => +accumulator + +curr, 0);
};
