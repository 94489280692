import {FC, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {LoadingBox} from "..";
import {ValidationErrorModel, LoyaltySettingModel} from "../../models";
import {getLabelName} from "../../utils";
import {saveVoucherSettings} from "../../serviceBroker/companySettingApiServiceBroker";
import {Card} from "react-bootstrap";
import {ButtonBox, ErrorValidationBox, TextBox} from "..";

export const LoyaltySetting: FC<{
    request?: LoyaltySettingModel | null;
    onComplete?: any | null;
}> = ({
          request, onComplete = () => {
    }
      }) => {
    //#region variables
    const initialValues: LoyaltySettingModel = request ?? {
        CreatedBy: 30,
        CreationDate: new Date(),
        DaysExpire: 0,
        ID: 0,
        IsActive: false,
        ModificationDate: new Date(),
        ModifiedBy: 0,
        Name: "",
        Points: 0,
        TermsCondations: "",
        Value: 0,
        ValueOfPoint: 0,
        VerifyOnUpdate: false,
        rowState: 0,
    };
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [validationSchema] = useState(
        Yup.object({
            ValueOfPoint: Yup.number()
                .positive(getLabelName("mustpositive"))
                .integer(getLabelName("mustintegar")),
            Points: Yup.number()
                .positive(getLabelName("mustpositive"))
                .integer(getLabelName("mustintegar")),
            Value: Yup.number().positive(getLabelName("mustpositive")).integer(getLabelName("mustintegar")),
            DaysExpire: Yup.number()
                .positive(getLabelName("mustpositive"))
                .integer(getLabelName("mustintegar")),
        })
    );
    //#endregion
    //#region function
    const handleSubmit = async (data: LoyaltySettingModel) => {
        try {
            setLoading(true);
            // @ts-ignore
            const res = await saveVoucherSettings(data);
            if (res != null && res.Errors != null && res.Errors.length !== 0) {
                setValidationErrors(res.Errors);
                setLoading(false);
            } else {
                setValidationErrors([]);
                setLoading(false);
                onComplete(true);
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        // enableReinitialize: true,
        onReset: () => {
        },
        onSubmit: async (values) => {
            await handleSubmit(values);
            // resetForm();
        },
    });
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            <form onSubmit={formik.handleSubmit}>
                <Card className="card-custom">
                    <Card.Header>
                        <span>{getLabelName("PointCalc")}</span>
                    </Card.Header>
                    <Card.Body>
                        <div
                            className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
                            <TextBox
                                labelName={getLabelName("Value Of Point")}
                                inputName={"ValueOfPoint"}
                                errorText={formik.errors.ValueOfPoint}
                                inputValue={formik.values.ValueOfPoint}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="number"
                            />

                            {/* <Form.Check
                type="checkbox"
                onChange={formik.handleChange}
                id="IsActive"
                name="IsActive"
                checked={formik.values.IsActive}
                label={getLabelName("Is Active")}
              /> */}
                            <TextBox
                                labelName={getLabelName("Points Of Voucher")}
                                inputName={"Points"}
                                errorText={formik.errors.Points}
                                inputValue={formik.values.Points}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="number"
                            />
                            <TextBox
                                labelName={getLabelName("Value of Vouchers")}
                                inputName={"Value"}
                                errorText={formik.errors.Value}
                                inputValue={formik.values.Value}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="number"
                            />
                            <TextBox
                                labelName={getLabelName("Days of Expire")}
                                inputName={"DaysExpire"}
                                errorText={formik.errors.DaysExpire}
                                inputValue={formik.values.DaysExpire}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="number"
                            />
                            <TextBox
                                labelName={getLabelName("Is Active")}
                                inputName={"IsActive"}
                                errorText={formik.errors.IsActive}
                                inputValue={formik.values.IsActive}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="checkbox"
                            />
                            <div
                                className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 flex-form-control">
                                <TextBox
                                    labelName={getLabelName("Terms and Condations")}
                                    inputName={"TermsCondations"}
                                    errorText={formik.errors.TermsCondations}
                                    inputValue={formik.values.TermsCondations}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="textarea"
                                    controlSize={"w-100"}
                                />
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-end">
                        <ButtonBox type="submit" className="mx-2" variant="primary" iconType="content-save">
                            {getLabelName("save")}
                        </ButtonBox>
                        <ButtonBox
                        iconType="refresh"
                            variant="primary"
                            type="button"
                            onClick={formik.handleReset}
                        >
                            {getLabelName("reset")}
                        </ButtonBox>
                    </Card.Footer>
                </Card>
            </form>
        </>
    );
    //#endregion
};
