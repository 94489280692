import {LookupItemModel, LookupTypeEnum} from "../../models";
import {LocalStorageRemove, SecureLocalStorageGet, SecureLocalStorageSet} from "..";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";

export const reValidateLocalStorageCachedData = async (
    type: LookupTypeEnum
) => {
    const cacheKey: string =
        Object.keys(LookupTypeEnum)[Object.values(LookupTypeEnum).indexOf(type)];
    const data = SecureLocalStorageGet(cacheKey);
    if (data === null) return;
    LocalStorageRemove(cacheKey);
    await getLookupLocalStorageCachedData(type);
};

//#region private
const getLookupLocalStorageCachedData = async (
    type: LookupTypeEnum
): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[];
    const cacheKey: string =
        Object.keys(LookupTypeEnum)[Object.values(LookupTypeEnum).indexOf(type)];
    const data = SecureLocalStorageGet(cacheKey);
    if (data != null) {
        response = JSON.parse(data as string) as LookupItemModel[];
    } else {
        response = await getLookupByType(type, false, false);
        if (response != null && response.length != 0) {
            SecureLocalStorageSet(cacheKey, JSON.stringify(response));
        }
    }
    return response;
};
//#endregion