import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
    CommissionCategoryPayloadModel,
    EmployeeCommissionCategoryModel,
    EmployeeDeleteResponseModel,
    EmployeeResponseModel,
    EstehekakModel,
    EstehekakResponseModel,
    ResponseBaseModel,
} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getAllEmployees = async (): Promise<
    ResponseBaseModel<EmployeeResponseModel[]>
> => {
    return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.employeeController.getAllEmployees);
};
export const getEmployeeInformation = async (
    Id: any
): Promise<EmployeeResponseModel> => {
    try {
        const result: ResponseBaseModel<EmployeeResponseModel> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.employeeController.getEmployeeInformation}?employeeId=${Id}`);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : null;
    } catch (err) {
        throw err;
    }
};
export const getEmployeeCommission = async (employeeID: number) => {
    try {
        const result: ResponseBaseModel<EmployeeCommissionCategoryModel[]> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.employeeController.getEmployeeCommissionPerCategory}?employeeId=${employeeID}`);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : null;
    } catch (err) {
        throw err;
    }
};
//#endregion
//#region post
export const saveEmployee = async (
    request: EmployeeResponseModel
): Promise<ResponseBaseModel<EmployeeResponseModel>> => {
    let response: ResponseBaseModel<EmployeeResponseModel> = {};
    const url: string = `SaveEmployee`;
    const postResult: ResponseBaseModel<EmployeeResponseModel> =
        await AlYusrAxiosApiInstance.post(url, request);
    response.Errors = postResult.Errors;
    if (postResult.Result) {
    }
    return response;
};

export const deleteEmployee = async (
    id: number
): Promise<EmployeeDeleteResponseModel> => {
    let apiResponse: EmployeeDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        let url: string = `DeleteEmployee?id=${id}`;
        apiResponse = await AlYusrAxiosApiInstance.post(url);
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
export const saveEmployeeEstihkakSubtract = async (
    request: EstehekakModel
): Promise<ResponseBaseModel<EstehekakResponseModel>> => {
    const url: string = `/SaveEmployeeEstihkakSubtract`;
    return await AlYusrAxiosApiInstance.post(url, request);
};
export const deleteEmployeeEstihkakSubtract = async (
    id: number
): Promise<EmployeeDeleteResponseModel> => {
    let apiResponse: EmployeeDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        let url: string = `DeleteEmployeeEstihkakSubtract?id=${id}`;
        apiResponse = await AlYusrAxiosApiInstance.post(url);
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};

export const updateEmployeeCategory = async (
    comissionPayload: CommissionCategoryPayloadModel
) => {
    let apiResponse: EmployeeDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        let url: string = `Employee/SaveEmployeeCategoryProfit`;
        apiResponse = await AlYusrAxiosApiInstance.post(url, comissionPayload);
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
//#endregion