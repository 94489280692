import {FC} from "react";
import {ReportIncomeStatementPageSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {searchReportIncomeStatement} from "../../../serviceBroker/acTransactionReportApiServiceBroker";
import {IncomeStatementReportSearchParamsModel, PageEnum} from "../../../models";

export const ReportIncomeStatementPage: FC = () => {
    const initialState: IncomeStatementReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportIncomeStatement}
            searchHeader={getPageNameByPageId(PageEnum.IncomeStatement)}
            SearchPanel={ReportIncomeStatementPageSearchPanel}
            formId={PageEnum.IncomeStatement}
        />
    );
};
