import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  TextBox,
  SelectBox,
  RadioCheckBox,
  TableComponent,
  InputDatePicker,
  AcComplexTransactionSearch,
  LoadingBox,
  GenericModelPopupPdfReportViewer,
  ErrorValidationBox,
  ToastBox,
  ConfirmModelDialogBox,
  ButtonBox,
  PrivilegesChecker, GridTable,
} from "..";
import {getLabelName, getUserId, sumType} from "../../utils";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import {
  AcTransactionTypeEnum,
  AccountAcBeginBalanceTypeEnum,
  ActionButtonsModel,
  ActionTypeEnum,
  InputDatePickerTimeDisplayModeEnum,
  PrintTypeEnum,
  RequestActionModel,
  RowStateEnum,
  SelectItemModel,
  ToastModel,
  TransactionRowDataModel,
  ValidationErrorModel,
  VoucherTransactionSearchFormModel,
  AcDailyBoxDetailModel,
  DailyBoxPayloadModel,
  DailyBoxFormModel,
} from "../../models";
import { useFormik } from "formik";
import {
  useAccountsByType,
  useCostCenters,
  useStoredCurrencies,
  useStoredEmployees,
  useMainAccountTypes,
  useTransactions,
  useBoxAccounts,
} from "../../hooks";
import setCurrentTransactionColumns from "./columns/currentTransactionColumns";
import setAcBoxColumns from "./columns/dailyBoxColumns";
import {
  deleteAcTransaction,
  getAcDailyBoxDetailsById,
  saveCurrentDailyBox,
} from "../../serviceBroker/acTransactionsApiServiceBroker";

const initialValues: DailyBoxFormModel = {
  id: "",
  code: "",
  date: new Date(),
  type: null,
  cmbxCostCenter: null,
  boxAccount: null,
  employee: null,
  currency: null,
  amount: 0,
  toAccount: null,
  valueType: AccountAcBeginBalanceTypeEnum.CreditAccount,
  note: "",
  rowState: RowStateEnum.Add,
};

const selectionObtionSchema = yup
  .object()
  .shape({ label: yup.string(), value: yup.string() });

const validationSchema = yup.object({
  code: yup.string(),
  date: yup.date().required(getLabelName("required")),
  type: selectionObtionSchema.required(getLabelName("required")).nullable(),
  employee: selectionObtionSchema.required(getLabelName("required")).nullable(),
  cmbxCostCenter: selectionObtionSchema
    .required(getLabelName("required"))
    .nullable(),
  toAccount: selectionObtionSchema
    .required(getLabelName("required"))
    .nullable(),
  boxAccount: selectionObtionSchema
    .required(getLabelName("required"))
    .nullable(),
  amount: yup
    .number()
    .moreThan(0, getLabelName("Amount must be greater than 0"))
    .required(getLabelName("required")),
  currency: selectionObtionSchema.required(getLabelName("required")).nullable(),
  valueType: yup.string().required(getLabelName("required")),
  note: yup.string(),
});

export const AcDailyBox = () => {
  //#region state
  //#region custom hooks states
  const {
    searchedTransactions,
    areTransactionsLoading,
    setPageNumber,
    setPageSize,
    currentPage,
    currentPageSize,
    totalRows,
    fetchAccountTransactions,
    setSearchParams,
    resetSearchParams,
  } = useTransactions(AcTransactionTypeEnum.DailyBox);
  const { accountTypes, areAccountTypesLoading } = useMainAccountTypes();
  const { employeesLoading, storedEmployeeList } = useStoredEmployees();
  const { currenciesLoading, storedCurrencies } = useStoredCurrencies();
  const { boxAccounts, areBoxAccountsLoading } = useBoxAccounts();

  const { accounts, areAccountsLoading, setCurrentTypeId } =
    useAccountsByType();
  const { costCenters, areCostCentersLoading } = useCostCenters();
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });
  //#endregion
  const [currentJournalAcBox, setCurrentJournalAcBox] = useState<
    TransactionRowDataModel[]
  >([]);
  const [validationErrors] = useState<ValidationErrorModel[]>([]);
  //#region print states
  const [showPrintoutPdfModel, setShowPrintoutPdfModel] = useState(false);
  const [printTransactionId, setPrintTransactionId] = useState(0);
  //#endregion
  //#region acBox states
  const [currentAcBoxID, setCurrentAcBoxID] = useState<number | null>(null);
  const [deletedAcBoxID, setDeletedAcBoxID] = useState<number | null>(null);
  const [isCurrentAcBoxLoading, setIsCurrentAcBoxLoading] =
    useState<boolean>(false);
  //#endregion
  //#region feedback states
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
  });
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  //#endregion
  //#region Refs to clear value of select box
  const costCenter = useRef<any>();
  const type = useRef<any>();
  const toAccount = useRef<any>();
  const employee = useRef<any>();
  const currency = useRef<any>();
  const boxAccount = useRef<any>();
  //#endregion
  //#endregion
  //#region variables
  const totalCredit = sumType(
    AccountAcBeginBalanceTypeEnum.CreditAccount,
    currentJournalAcBox
  );
  const totalDebit = sumType(
    AccountAcBeginBalanceTypeEnum.DebitAccount,
    currentJournalAcBox
  );
  const isModifyingAcBox = currentAcBoxID !== null;
  const isDataLoading =
    areTransactionsLoading ||
    areAccountTypesLoading ||
    employeesLoading ||
    currenciesLoading ||
    isCurrentAcBoxLoading ||
    areCostCentersLoading ||
    areBoxAccountsLoading;
  //#endregion
  //#region effects
  useEffect(() => {
    resetAcBoxValues();
  }, [storedCurrencies, costCenters, storedEmployeeList, accountTypes]);

  useEffect(() => {
    if (currentAcBoxID === null) return;
    setIsCurrentAcBoxLoading(true);
    getAcDailyBoxDetailsById(`${currentAcBoxID}`).then((acBoxDetails) => {
      setIsCurrentAcBoxLoading(false);
      setCurrentJournalAcBox(
        (acBoxDetails?.Ac_TransactionDetails as AcDailyBoxDetailModel[]).map(
          ({
            ID,
            Type: valueType,
            Account_ID,
            CreditValue,
            DebitValue,
            AccountNameEn,
            rowState,
          }) => ({
            amount: valueType === 1 ? CreditValue : DebitValue,
            id: "" + ID,
            toAccount: {
              label: getLabelName(AccountNameEn as string),
              value: "" + Account_ID,
            },
            // temp
            note: "",
            valueType: valueType,
            type: null,
            rowState,
            isSaved: true,
            date: new Date(),
          })
        )
      );
      formik.setFieldValue("date", new Date(acBoxDetails?.Date as string));
      formik.setFieldValue(
        "boxAccount",
        boxAccounts.find(
          ({ value }) => value === "" + acBoxDetails?.BoxAccountId
        ) || null
      );
      formik.setFieldValue(
        "cmbxCostCenter",
        costCenters.find(
          ({ value }) => value === "" + acBoxDetails?.CostCenter_ID
        ) || null
      );
      formik.setFieldValue(
        "employee",
        storedEmployeeList.find(
          ({ value }) => value === "" + acBoxDetails?.Employee_Id
        ) || null
      );
      formik.setFieldValue(
        "currency",
        storedCurrencies.find(
          ({ value }) => value === "" + acBoxDetails?.Currency_ID
        ) || null
      );
      formik.setFieldValue("code", acBoxDetails?.Code || "");
    });
  }, [currentAcBoxID]);
  //#endregion
  //#region handlers
  const handleAcBoxSelect = async (id: number) => {
    if (id === currentAcBoxID) return;
    setCurrentAcBoxID(id);
    formik.setErrors({});
  };
  const handleTransactionModification = (id: string, value: number) => {
    setCurrentJournalAcBox((prev) =>
      prev.map((transaction) => {
        if (transaction.id === id)
          return {
            ...transaction,
            amount: value,
            rowState: transaction.isSaved
              ? RowStateEnum.Update
              : RowStateEnum.Add,
          };
        return transaction;
      })
    );
  };
  const resetAcBoxValues = () => {
    if (storedCurrencies.length > 1)
      formik.setFieldValue("currency", {
        value: storedCurrencies[1].value,
        label: getLabelName(storedCurrencies[1].name),
      });
    if (costCenters.length !== 0)
      formik.setFieldValue("cmbxCostCenter", {
        value: costCenters[0].value,
        label: getLabelName(costCenters[0].name),
      });
    if (storedEmployeeList.length > 1)
      formik.setFieldValue("employee", {
        value: storedEmployeeList[1].value,
        label: getLabelName(storedEmployeeList[1].name),
      });
    if (accountTypes.length !== 0) {
      formik.setFieldValue("type", {
        value: accountTypes[0].value,
        label: getLabelName(accountTypes[0].name),
      });
      setCurrentTypeId(
        accountTypes[0].value === null ? null : +accountTypes[0].value
      );
    }
    if (boxAccounts.length > 0) {
      formik.setFieldValue("boxAccount", {
        value: boxAccounts[0].value,
        label: getLabelName(boxAccounts[0].name),
      });
      setCurrentTypeId(
        !accountTypes[0] || accountTypes[0].value === null
          ? null
          : +accountTypes[0].value
      );
    }
  };
  const deleteActions: (deletedId: number) => ActionButtonsModel[] = (
    deletedID
  ) => {
    return [
      {
        text: getLabelName("yes"),
        onClick: async () => {
          await handleAcBoxDeletion(deletedID);
          setShowDeleteModel(false);
          setToastModel((prev) => ({ ...prev, show: true }));
          setDeletedAcBoxID(null);
        },
      },
      {
        text: getLabelName("No"),
        onClick: () => {
          setShowDeleteModel(false);
          setDeletedAcBoxID(null);
        },
      },
    ];
  };
  const handleDeletion = (deletedItemID: string) => {
    setCurrentJournalAcBox((prev) =>
      prev
        .filter(({ id, isSaved }) => {
          return !(!isSaved && id === deletedItemID);
        })
        .map((transaction) => {
          if (deletedItemID === transaction.id)
            return { ...transaction, rowState: RowStateEnum.Delete };
          return transaction;
        })
    );
  };
  const handleAcBoxDeletion = async (id: number) => {
    try {
      setIsCurrentAcBoxLoading(true);
      await deleteAcTransaction(id, getUserId());
      await fetchAccountTransactions();
      if (id === currentAcBoxID) resetFormData();
      setIsCurrentAcBoxLoading(false);
    } catch (err) {
      alert("item cannot be deleted");
    }
  };
  const handleVoucherPrint = (id: number) => {
    if (id === 0) return;
    setPrintTransactionId(id);
    setShowPrintoutPdfModel(true);
  };
  //#endregion
  //#region helpers
  const resetFormData = () => {
    setCurrentJournalAcBox([]);
    setCurrentTypeId(null);
    setCurrentAcBoxID(null);
    formik.resetForm();
    resetAcBoxValues();
  };
  const resetTransactionData = () => {
    setCurrentTypeId(null);
    if (type.current) type.current.clearValue();
    if (type.current) toAccount.current.clearValue();
    formik.setErrors({});
    formik.setFieldValue("amount", "");
    formik.setFieldValue("toAccount", null);
    formik.setFieldValue("note", "");
    formik.setFieldValue("valueType", "1");
  };
  const searchAcBoxes = async (params: VoucherTransactionSearchFormModel) => {
    setSearchParams({
      toDate: params.toDate,
      accountId: params.toAccount?.value,
      fromDate: params.fromDate,
      userId: params.user?.value,
      pageNumber: currentPage,
      pageSize: currentPageSize,
    });
  };
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.Clear:
        resetSearchParams();
        break;
      case ActionTypeEnum.Search:
        await searchAcBoxes(
          request.request as VoucherTransactionSearchFormModel
        );
        break;
    }
  };
  const isRemainingTransactionAvailable = (
    transactions: TransactionRowDataModel[]
  ) => {
    return transactions.some(
      ({ rowState }) => rowState !== RowStateEnum.Delete
    );
  };
  //#endregion
  //#region html
  return (
    <>
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <ConfirmModelDialogBox
        isModelVisible={showDeleteModel}
        onCloseEvent={() => {
          setShowDeleteModel(false);
          setDeletedAcBoxID(null);
        }}
        actions={deleteActions(deletedAcBoxID!)}
      >
        <>{getLabelName("Are You Sure You Want Delete ")}</>
      </ConfirmModelDialogBox>
      {showPrintoutPdfModel && (
        <GenericModelPopupPdfReportViewer
          keys={[{ key: "transactionId", value: printTransactionId }]}
          type={PrintTypeEnum.AcDailyBox}
          onCloseEvent={() => {
            setShowPrintoutPdfModel(false);
          }}
        />
      )}

      {isDataLoading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {getLabelName("Dayley Box")}
            <div>
              {" "}
              <ButtonBox
                variant="danger"
                type="button"
                role="span"
                className="btn btn-sm"
                onClick={(e) => {
                  e.stopPropagation();
                  resetFormData();
                }}
              >
                {getLabelName("New")}
              </ButtonBox>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <form
              onSubmit={async (e: React.FormEvent) => {
                e.preventDefault();
                const errors = await formik.validateForm();
                formik.setErrors(errors);
                if (Object.keys(errors).length !== 0) return;
                setCurrentJournalAcBox((prevState) => [
                  ...prevState,
                  {
                    amount: formik.values.amount,
                    note: formik.values.note,
                    toAccount: formik.values.toAccount,
                    valueType: +formik.values.valueType,
                    date: formik.values.date,
                    type: formik.values.type,
                    id: uuidv4(),
                    rowState: RowStateEnum.Add,
                    isSaved: false,
                  },
                ]);
                resetTransactionData();
              }}
            >
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
                <TextBox
                  inputValue={formik.values.code}
                  inputName="code"
                  type={"text"}
                  onChange={formik.handleChange}
                  labelName={getLabelName("code")}
                />
                <InputDatePicker
                  id="datePicker"
                  timeMode={InputDatePickerTimeDisplayModeEnum.None}
                  selectedDate={new Date(formik.values.date.toString())}
                  className="form-control"
                  InputLabel="date"
                  name="date"
                  onChange={(date: Date) => {
                    formik.values.date = date;
                    formik.setFieldValue("date", date);
                  }}
                  maxDate={new Date()}
                />
                <SelectBox
                  labelName={getLabelName("cmbxCostCenter")}
                  inputName="cmbxCostCenter"
                  multiselectRef={costCenter}
                  selectedValues={
                    formik.values.cmbxCostCenter === null
                      ? null
                      : [formik.values.cmbxCostCenter.value]
                  }
                  source={costCenters}
                  onStatusChange={(selectedValue: SelectItemModel) =>
                    formik.setFieldValue("cmbxCostCenter", selectedValue)
                  }
                  isSingleSelect={true}
                  errorText={getLabelName(
                    formik.errors.cmbxCostCenter as string
                  )}
                />
              </div>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
                <SelectBox
                  labelName={getLabelName("employee")}
                  inputName="employee"
                  multiselectRef={employee}
                  selectedValues={
                    formik.values.employee === null
                      ? null
                      : [formik.values.employee.value]
                  }
                  source={storedEmployeeList}
                  onStatusChange={(selectedValue: SelectItemModel) =>
                    formik.setFieldValue("employee", selectedValue)
                  }
                  isSingleSelect={true}
                  errorText={getLabelName(formik.errors.employee as string)}
                />
                <SelectBox
                  labelName={getLabelName("currency")}
                  inputName="currency"
                  multiselectRef={currency}
                  selectedValues={
                    formik.values.currency === null
                      ? null
                      : [formik.values.currency.value]
                  }
                  source={storedCurrencies}
                  onStatusChange={(selectedValue: SelectItemModel) =>
                    formik.setFieldValue("currency", selectedValue)
                  }
                  isSingleSelect={true}
                  errorText={getLabelName(formik.errors.currency as string)}
                />
                {boxAccounts.length !== 0 && (
                  <SelectBox
                    labelName={getLabelName("box account")}
                    inputName="box account"
                    multiselectRef={boxAccount}
                    selectedValues={
                      formik.values.boxAccount === null
                        ? null
                        : ["" + formik.values.boxAccount.value]
                    }
                    source={boxAccounts}
                    onStatusChange={(selectedValue: SelectItemModel) => {
                      formik.setFieldValue("boxAccount", selectedValue);
                    }}
                    isSingleSelect={true}
                    errorText={getLabelName(formik.errors.boxAccount as string)}
                  />
                )}
              </div>
              <div className="row row-cols-12 mb-3">
                <TextBox
                  type={"textarea"}
                  labelName={"Note"}
                  inputName="note"
                  inputValue={formik.values.note}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
                <SelectBox
                  labelName={getLabelName("type")}
                  inputName="type"
                  multiselectRef={type}
                  selectedValues={
                    formik.values.type === null
                      ? null
                      : [formik.values.type.value]
                  }
                  source={accountTypes}
                  onStatusChange={(selectedValue: SelectItemModel) => {
                    if (selectedValue === null) return setCurrentTypeId(null);
                    if (selectedValue.value !== formik.values.type?.value) {
                      formik.setFieldValue("type", selectedValue);
                      setCurrentTypeId(+selectedValue.value);
                      // formik.setFieldValue("toAccount", null);
                      // toAccount.current.clearValue();
                    }
                  }}
                  isSingleSelect={true}
                  errorText={getLabelName(formik.errors.type as string)}
                />

                {formik.values.type && (
                  <SelectBox
                    labelName={getLabelName("toAccount")}
                    inputName="toAccount"
                    isDataLoading={areAccountsLoading}
                    multiselectRef={toAccount}
                    selectedValues={
                      formik.values.toAccount === null
                        ? null
                        : ["" + formik.values.toAccount.value]
                    }
                    source={accounts}
                    onStatusChange={(selectedValue: SelectItemModel) => {
                      formik.setFieldValue("toAccount", selectedValue);
                    }}
                    isSingleSelect={true}
                    errorText={getLabelName(formik.errors.toAccount as string)}
                  />
                )}
              </div>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
                <TextBox
                  labelName={getLabelName("Money")}
                  type={"number"}
                  inputName="amount"
                  inputValue={formik.values.amount}
                  onChange={formik.handleChange}
                  errorText={getLabelName(formik.errors.amount as string)}
                />
                <RadioCheckBox
                  items={[
                    {
                      text: getLabelName("Value In"),
                      value: "" + AccountAcBeginBalanceTypeEnum.CreditAccount,
                      name: "valueType",
                    },
                    {
                      text: getLabelName("Value out"),
                      value: "" + AccountAcBeginBalanceTypeEnum.DebitAccount,
                      name: "valueType",
                    },
                  ]}
                  onChange={formik.handleChange}
                  type={"radio"}
                  selectedValues={["" + formik.values.valueType]}
                />
              </div>
              <div className="row mt-3 ">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end ">
                  <ButtonBox
                    type="submit"
                    className="btn-gradient-primary mx-3 btn-fw"
                  >
                    {getLabelName("add")}
                  </ButtonBox>
                </div>
              </div>
              <div className="row row-cols-1 mt-5">
                <TableComponent
                  columns={setCurrentTransactionColumns({
                    handleDeletion: handleDeletion,
                    handleAmountChange: handleTransactionModification,
                  })}
                  data={currentJournalAcBox.filter(
                    ({ rowState }) => rowState !== RowStateEnum.Delete
                  )}
                  totalRows={totalRows}
                  currentPage={currentPage}
                  pageSize={currentPageSize}
                  onCurrentPageChange={() => {}}
                  onPageSizeChange={() => {}}
                  paginationType="none"/>
                {isRemainingTransactionAvailable(currentJournalAcBox) && (
                  <div
                    className={`row row-cols-1 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mt-1`}
                    style={{ marginRight: 0 }}
                  >
                    <div>
                      <span>
                        {getLabelName("Total_In_Money")}: {totalCredit}
                      </span>
                    </div>
                    <div>
                      <span>
                        {getLabelName("Total_Out_Money")}: {totalDebit}
                      </span>
                    </div>
                  </div>
                )}
                {isRemainingTransactionAvailable(currentJournalAcBox) && (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end ">
                    <PrivilegesChecker formId={108} action="EnableSave">
                      <ButtonBox
                        type="button"
                        onClick={async () => {
                          const rowState = isModifyingAcBox
                            ? RowStateEnum.Update
                            : RowStateEnum.Add;
                          const acBox: DailyBoxPayloadModel = {
                            Date: formik.values.date.toISOString(),
                            Code: formik.values.code,
                            CostCenter_ID: formik.values.cmbxCostCenter
                              ?.value as string,
                            Currency_ID: formik.values.currency
                              ?.value as string,
                            User_ID: getUserId(),
                            BoxAccountId: formik.values.boxAccount
                              ?.value as string,
                            OperationUserId: getUserId(),

                            Employee_Id: formik.values.employee
                              ?.value as string,
                            Ac_TransactionDetails: currentJournalAcBox.map(
                              ({
                                amount,
                                toAccount,
                                valueType,
                                note,
                                id,
                                rowState,
                              }) => {
                                return {
                                  Note: note,
                                  Type: valueType,
                                  CreditValue: valueType === 1 ? +amount : 0,
                                  DebitValue: valueType === 2 ? +amount : 0,
                                  Account_ID: +toAccount!.value as number,
                                  // Temporary createdBy ID
                                  CreatedBy: getUserId(),
                                  rowState,
                                  ID: +id,
                                  TransactionHeaders_ID:
                                    isModifyingAcBox && currentAcBoxID !== null
                                      ? currentAcBoxID
                                      : undefined,
                                };
                              }
                            ),
                            CreatedBy: getUserId(),
                            rowState,
                            ID:
                              isModifyingAcBox && currentAcBoxID !== null
                                ? currentAcBoxID
                                : undefined,
                          };
                          setIsCurrentAcBoxLoading(true);
                          await saveCurrentDailyBox(acBox);
                          await fetchAccountTransactions();
                          setIsCurrentAcBoxLoading(false);
                          setToastModel((prev) => ({ ...prev, show: true }));
                          resetFormData();
                        }}
                        className="btn-gradient-primary mx-3 btn-fw"
                      >
                        {getLabelName("save")}
                      </ButtonBox>
                    </PrivilegesChecker>
                  </div>
                )}
              </div>
            </form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="1" className="mt-5">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <PrivilegesChecker formId={108} action="EnableSearch">
              <div className="mb-4">
                <AcComplexTransactionSearch onActionEvent={handleAction} />
              </div>
            </PrivilegesChecker>
            <div className="row row-cols-1">
              <GridTable
                columns={setAcBoxColumns({
                  handleDeletion: (id) => {
                    setShowDeleteModel(true);
                    setDeletedAcBoxID(id);
                  },
                  handleModification: handleAcBoxSelect,
                  handlePrint: handleVoucherPrint,
                  formId: 108,
                })}
                data={searchedTransactions}
                totalRows={totalRows}
                currentPage={currentPage}
                pageSize={currentPageSize}
                onCurrentPageChange={setPageNumber}
                onPageSizeChange={setPageSize}
                paginationType="server"
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
