import {useEffect, useState} from "react";
import {LookupItemModel} from "../../models";
import {getAccountsByMainAccountType} from "../../serviceBroker/accountApiServiceBroker";

export const useAccountsByType = () => {
    const [accounts, setAccounts] = useState<LookupItemModel[]>([]);
    const [currentTypeId, setCurrentTypeId] = useState<null | number>(null);
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchAccountTransactions = async () => {
            if (currentTypeId === null) return;
            setIsLoading(true);
            const fetchedAccounts = await getAccountsByMainAccountType(currentTypeId);
            if (fetchedAccounts !== null) setAccounts(fetchedAccounts);
            setIsLoading(false);
        };
        fetchAccountTransactions().then(() => {
        });
    }, [currentTypeId]);
    return {accounts, areAccountsLoading: loading, setCurrentTypeId};
};