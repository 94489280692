import {useEffect, useState} from "react";
import {LookupItemModel} from "../../models";
import {getAllCostCenters} from "../../serviceBroker/lookupApiServiceBroker";

export const useCostCenters = () => {
    const [costCenters, setCostCenters] = useState<LookupItemModel[]>([]);
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchAccountTransactions = async () => {
            setIsLoading(true);
            const fetchedCenters = await getAllCostCenters();
            if (fetchedCenters !== null) setCostCenters(fetchedCenters);
            setIsLoading(false);
        };
        fetchAccountTransactions().then(() => {
        });
    }, []);
    return {costCenters, areCostCentersLoading: loading};
};

