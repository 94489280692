import {FC} from "react";
import {ReportEmployeeSalesCommissionSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {searchReportEmployeeSalesCommission} from "../../../serviceBroker/transationReportApiServiceBroker";
import {EmployeeSalesCommissionReportSearchParamsModel, PageEnum} from "../../../models";

export const ReportEmployeeSalesCommissionPage: FC = () => {
    const initialState: EmployeeSalesCommissionReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportEmployeeSalesCommission}
            searchHeader={getPageNameByPageId(PageEnum.ReportEmployeeCommissionFromSales)}
            SearchPanel={ReportEmployeeSalesCommissionSearchPanel}
            formId={PageEnum.ReportEmployeeCommissionFromSales}
        />
    );
};
