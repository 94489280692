import {FC} from "react";
import {getLabelName} from "../../utils";

export const ForbiddenPage: FC = () => {
    return (
        <>
            {/* we need to add page with image */}
            <p>{getLabelName("ForbiddenPage request you don't have permissions to access page")} </p>
        </>
    );
};
