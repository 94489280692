import {FC} from "react";
import {TransactionMainComponent} from "../../../components";
import {DailyTransactionTypeEnum, PageEnum} from "../../../models";

export const RequestOutPage: FC = ({}) => {
    //#region html
    return (
        <>
            <TransactionMainComponent
                formId={PageEnum.Requests}
                transactionType={DailyTransactionTypeEnum.Order}
            />
        </>
    );
    //#endregion
};