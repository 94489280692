import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {ResponseBaseModel, BranchResponseModel} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getAllBranches = async (): Promise<ResponseBaseModel<
    BranchResponseModel[]
> | null> => {
    try {
        return await AlYusrAxiosApiInstance.get(AlYuserApiUrls.branchController.getAllBranches);
    } catch (err) {
        return null;
    }
};
export const getBranchById = async (BranchId: number): Promise<ResponseBaseModel<BranchResponseModel>> => {
    return await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.branchController.getBranchById}?`, {
        params: {
            BranchId,
        },
    });
};
//#endregion
//#region post
export const saveBranch: (
    branchInfo: BranchResponseModel
) => Promise<ResponseBaseModel<BranchResponseModel>> = async (branchInfo) => {
    return await AlYusrAxiosApiInstance.post<
        ResponseBaseModel<BranchResponseModel>
    >(AlYuserApiUrls.branchController.saveBranch, branchInfo);
};
export const deleteBranch = async (
    id: number
): Promise<ResponseBaseModel<BranchResponseModel>> => {
    let apiResponse: ResponseBaseModel<BranchResponseModel>;

    let url: string = `${AlYuserApiUrls.branchController.deleteBranch}?id=${id}`;
    apiResponse = await AlYusrAxiosApiInstance.post(url);
    if (apiResponse.Errors?.length !== 0) throw apiResponse.Errors;
    return apiResponse;
};
//#endregion
