import {FC, useEffect, useState} from "react";
import {getLabelName, getUserId} from "../../utils";
import {ActionTypeEnum, CloseDayResponseModel, RequestActionModel, ValidationErrorModel} from "../../models";
import {ButtonBox, ErrorValidationBox, LoadingBox, ModelDialogBox, TextBox} from "..";
import {useFormik} from 'formik';
import {saveStationCloseDay} from "../../serviceBroker/closeDayApiServiceBroker";
import * as Yup from "yup";
import {logoutUser} from "../../slice/userAuthincateSlice";
import {SystemConfiguration} from "../../configuration";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

export const CloseDayDetails: FC<{
    onActionEvent: (o: RequestActionModel) => void;
    stationId: number;
    userId: number;
}> = ({
          onActionEvent = () => {
          }, stationId, userId
      }) => {
    //#region state
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>([]);
    const [validationSchema] = useState(
        Yup.object({
            AcctualAmountAfterClose: Yup.number().required(getLabelName("required")).min(0, getLabelName("closeDay.miniValue")),
        })
    );
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            setShowModal(true);
            setLoading(false);
        }
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region formik
    const formik = useFormik({
        initialValues: {
            stationId: 0,
            operationUserId: 0,
            AcctualAmountAfterClose: 0,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values: CloseDayResponseModel) => {
            const newValues = {
                ...values,
                stationId: stationId,
                operationUserId: getUserId(),
            }
            await handleSubmit(newValues);
        },
    });
    //#endregion
    //#region function
    const handleLogout = async () => {
        dispatch(
            // @ts-ignore
            logoutUser());
        navigate(SystemConfiguration.other.logoutRedirectUrl);
    }
    const handleClose = () => {
        setShowModal(false);
        onActionEvent({action: ActionTypeEnum.CloseModelPopup});
    };
    const handleSubmit = async (request: CloseDayResponseModel) => {
        try {
            setLoading(true);
            const res: any = await saveStationCloseDay(request);
            if (res !== null && res !== undefined && res.Errors !== null && res.Errors !== undefined && res.Errors.length !== 0) {
                setValidationErrors(res.Errors);
                setLoading(false);
            } else {
                setValidationErrors([]);
                if (userId == getUserId()) {
                    await handleLogout();
                } else {
                    setShowModal(false);
                    onActionEvent({action: ActionTypeEnum.AddSuccess, id: userId});
                    setLoading(false);
                }
            }
        } catch (err: any) {
            setLoading(false);
            const errors: ValidationErrorModel[] = [{MessageAr: err, MessageEn: err}];
            setValidationErrors(errors);
        }
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            <ModelDialogBox
                isModelVisible={showModal}
                isCloseButtonVisible={false}
                isEscapeCloseEnabled={true}
                isXCloseButtonVisible={true}
                onCloseEvent={() => {
                    handleClose();
                }}
                size="sm"
            >
                {<ErrorValidationBox errors={validationErrors}/>}
                <form className="forms-sample" onSubmit={formik.handleSubmit}>
                    <div
                        className="row row-cols-1 row-cols-xxl-1 row-cols-xl-2 row-cols-lg-1 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start">
                        <TextBox
                            labelName={getLabelName("Acctual Money")}
                            inputName={"AcctualAmountAfterClose"}
                            errorText={formik.errors.AcctualAmountAfterClose}
                            inputValue={formik.values.AcctualAmountAfterClose}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="number"
                        />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                        <ButtonBox type="submit" variant="" className="btn-primary btn-sm mx-3" iconType="content-save">
                            {getLabelName("Save")}
                        </ButtonBox>
                        <ButtonBox
                        iconType="close-circle"
                            variant="danger"
                            type="button"
                            className="btn-sm"
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                        >
                            {getLabelName("Cancel")}
                        </ButtonBox>
                    </div>
                </form>
            </ModelDialogBox>
        </>
    );
    //#endregion
};
