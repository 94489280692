import {FC, MutableRefObject} from "react";
import {
    ControlSizeType,
    LabelSizeType,
    LookupItemModel,
    SelectItemModel,
} from "../../../models";
import {Form} from "react-bootstrap";

import Select from "react-select";
import {getLabelName, isArabicCurrentLanguage} from "../../../utils";

export const SelectBox: FC<{
    labelName: string;
    source: LookupItemModel[];
    excludedSource?: LookupItemModel[] | null;
    selectedValues?: string[] | null;
    isSingleSelect?: boolean;
    isMandatory?: boolean | null;
    errorText?: string | null;
    onStatusChange: any;
    onInputChange?: any | null; // (inputValue: string) => void; // Update the type of onInputChange
    //onMenuClose?:any| null;
    multiselectRef?: MutableRefObject<any>;
    controlSize?: ControlSizeType;
    labelSize?: LabelSizeType;
    id?: string | undefined;
    inputName?: string;
    isDataLoading?: boolean;
}> = ({
          labelName,
          source,
          excludedSource,
          selectedValues,
          isSingleSelect = false,
          isMandatory = false,
          errorText,
          onStatusChange,
          onInputChange,
          isDataLoading,
          //onMenuClose= () => {},
          multiselectRef,
          controlSize = "col flex-form-control",
          labelSize = "form-label",
          id,
          inputName,
      }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    let selectedRows: SelectItemModel[];
    //#endregion
    const data: SelectItemModel[] = [];
    if (
        source !== null &&
        source !== undefined &&
        source.length !== 0 &&
        excludedSource !== null &&
        excludedSource !== undefined &&
        excludedSource.length !== 0
    ) {
        source
            .filter((o1) => !excludedSource.some((o2) => o1.value === o2.value))
            .map((row) => {
                data.push({
                    label: isArabic ? row.nameAr : row.name,
                    value: row.value ?? "",
                });
            });
    } else {
        source.map((row) => {
            data.push({
                label: isArabic ? row.nameAr : row.name,
                value: row.value ?? "",
            });
        });
    }

    selectedRows =
        data.length !== 0 && selectedValues !== null && selectedValues?.length !== 0
            ? data.filter((p) => {
                return selectedValues?.some((y) => {
                    return p.value == y;
                });
            })
            : [];
    const renderError = () => {
        if (errorText) {
            return (
                <Form.Control.Feedback className="d-block" type="invalid">
                    {errorText}
                </Form.Control.Feedback>
            );
        }
        return null;
    };

    return (
        <>
            {/* <div
        className={`col-auto pt-2`}
      >
    </div> */}
            <div className={controlSize} style={{position: "relative"}}>
                <label htmlFor={inputName} className={labelSize}>
                    {getLabelName(labelName)}
                    {isMandatory && <span className="asterisk">*</span>}
                </label>
                <div className="control-wrap">
                    <Select
                        defaultValue={data[1]}
                        name={inputName}
                        id={id || `selectBox_${Math.random()}`}
                        value={
                            selectedRows !== null && selectedRows.length !== 0
                                ? selectedRows
                                : undefined
                        }
                        options={data}
                        closeMenuOnSelect={isSingleSelect}
                        ref={multiselectRef}
                        isSearchable={true}
                        onChange={onStatusChange}
                        onInputChange={onInputChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isClearable={true}
                        isLoading={isDataLoading}
                        isDisabled={isDataLoading}
                        placeholder={getLabelName("select")}
                    />
                    {errorText ? <>{errorText}</> : null}
                    {/* <div style={{}} className="spinner"></div> */}
                </div>
            </div>
        </>
    );
};
