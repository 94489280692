import { FC, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  ConfirmModelDialogBox,
  LoadingBox,
  ModelDialogBox,
  POSSettings,
  RegisterUser,
  ToastBox,
  TransactionSettings,
  UserPermissions,
  UserSettings,
  UsersList,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  HasFormIdModel,
  POSUserSettingModel,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  UserRegistrationResponseModel,
  UserSettingModel,
  UserTransactionSettingModel,
} from "../../models";
import {
  deleteUser,
  getPointOfSaleSetting,
  getTransactionSettings,
  getUserInformation,
  getUsers,
  getUserSetting,
} from "../../serviceBroker/userApiServiceBroker";
import { getLabelName, getUserId } from "../../utils";

export const UsersPage: FC<HasFormIdModel> = ({ formId }) => {
  //#region variables
  const userSettingInitialValues: UserSettingModel = {
    AddItemInNewLineInBill: false,
    AlertOfCustomerDidintPaySince: 0,
    AllowItemPriceSaleLessThanCost: false,
    AllowSaleLessThanCost: false,
    AlowRepeateFactoItemUnit: false,
    CheckMaxDebitOfCustomer: false,
    CreatedBy: 0,
    CreationDate: new Date(),
    DaysAlertBeforeExpire: 0,
    EnableManualInvoiceCode: true,
    ID: 0,
    Lang: 1,
    MaxDiscountPercentage: 0,
    ModificationDate: new Date(),
    ModifiedBy: 0,
    Name: "",
    PeriodAllowToEdit: 0,
    ShowScaleBarcode: false,
    PreventStoreOutOfItemLessZero: false,
    PrintBarcodeAfterPurches: false,
    PrintItemPrescriptionAfterSale: false,
    RequestDueDateOnDelayPayment: false,
    RigesterBillOnPayment: false,
    SelectionEmployeeIsRequierd: false,
    ShowAlert: false,
    ShowBarcode: false,
    ShowItemModifiers: false,
    ShowMinimumPriceItem: false,
    ShowProfitOfBill: false,
    User_ID: getUserId(),
    VerifyOnUpdate: false,
    rowState: 1,
    isArabic: false,
    isEnglish: false,
  };
  const userTransactionSettingInitialValues: UserTransactionSettingModel = {
    CreatedBy: 0,
    CreationDate: new Date(),
    DeafultNote: "",
    DefaultCalcTypeID: 0,
    DefaultPaymentTypeID: 0,
    DirectPrintBillAfterSave: false,
    EnableButonAdd: false,
    EnableDiscount: false,
    EnableShangPrice: false,
    ID: 0,
    ModificationDate: new Date(),
    ModifiedBy: 0,
    Name: "",
    NumberOfCopy: 0,
    PeriodAllowForReturn: 0,
    RemarksVisable: "",
    ShowButtonPrintPrepairingOrder: false,
    ShowCalcType: false,
    ShowCinfirm: false,
    ShowCurrency: false,
    ShowCustomerCar: false,
    ShowDone: false,
    ShowEmployeeResponsibile: false,
    ShowNotefy: false,
    ShowPaymentType: false,
    ShowPrice: false,
    ShowRefrence: false,
    TransactionType_ID: 0,
    User_ID: 30,
    VerifyOnUpdate: false,
    rowState: 0,
  };
  const posUserSettingInitialValues: POSUserSettingModel = {
    AllowClickNew: false,
    AllowDeleteItems: false,
    AllowReturnWithoutBill: false,
    AllowReturn: false,
    Ask_MoneyBox_Station: false,
    HasPermToDiscount: false,
    HasPermToEditPrice: false,
    CreatedBy: 0,
    CreationDate: new Date(),
    Currency_ID: 0,
    CustomerInfo: undefined,
    Customer_ID: 0,
    DefaultDiscountType: 0,
    DefaultNoteType: 0,
    DefaultRatioDiscount: 0,
    Emp_ID: 0,
    EmployeeInfo: undefined,
    EnableChangeCalcType: false,
    EnableCloseDay: false,
    EnablePrintSaleReportAndPrintCloseDay: false,
    EnablePrintSavedTransaction: false,
    ID: 0,
    ModificationDate: new Date(),
    ModifiedBy: 0,
    PortName: "",
    PrintInvoiceInA4: false,
    ShowItemForSecondScreenCustomerDisplay: false,
    Store_ID: 0,
    SupplierInfo: undefined,
    UseCustomerDisplay: false,
    UseItemImageAsBackGround: false,
    User_ID: undefined,
    VerifyOnUpdate: false,
    WelcomeMessage: "",
    rowState: 0,
  };
  const deleteUserActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleDeleteUser();
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setUser(null);
        setShowDeleteUserModel(false);
      },
    },
  ];
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserRegistrationResponseModel[]>([]);
  const [user, setUser] = useState<UserRegistrationResponseModel | null>(null);
  const [userSettingModel, setUserSettingModel] = useState<UserSettingModel>(
    userSettingInitialValues
  );
  const [pOSUserSettingModel, setPOSUserSettingModel] =
    useState<POSUserSettingModel>(posUserSettingInitialValues);
  const [userTransactionSettingModel, setUserTransactionSettingModel] =
    useState<UserTransactionSettingModel>(userTransactionSettingInitialValues);
  const [showTransactionSettingModel, setShowTransactionSettingModel] =
    useState(false);
  const [showUserPOSSettingModel, setShowUserPOSSettingModel] = useState(false);
  const [toastModel, setToastModel] = useState<ToastModel>({ show: false });
  const [showDeleteUserModel, setShowDeleteUserModel] = useState(false);
  const [showUserPermissionModel, setShowUserPermissionModel] = useState(false);
  const [showUserSettingModel, setShowUserSettingModel] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllUsers();
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const handleUserAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        setUser(null);
        await getAllUsers();
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        switch (request.id) {
          case ActionTypeEnum.UserSetting:
            setUserSettingModel(request.request);
            break;
          case ActionTypeEnum.TransactionSetting:
            setUserTransactionSettingModel(request.request);
            break;
          case ActionTypeEnum.POSSetting:
            setPOSUserSettingModel(request.request);
            break;
        }
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        window.scrollTo(0, 0);
        const userObjectUpdate = await getUserInformation(request.id!);
        // @ts-ignore
        userObjectUpdate.rowState = Number(RowStateEnum.Update);
        setUser(userObjectUpdate);
        break;
      case ActionTypeEnum.Delete:
        const userObjectDelete = await getUserInformation(request.id!);
        setUser(userObjectDelete);
        setShowDeleteUserModel(true);
        break;
      case ActionTypeEnum.TransactionSetting:
        const userTransactionSetting = await getTransactionSettings(
          // @ts-ignore
          request.id!,
          2
        );
        if (userTransactionSetting) {
          setUserTransactionSettingModel(userTransactionSetting);
        }
        setShowTransactionSettingModel(true);
        break;
      case ActionTypeEnum.POSSetting:
        const posSettings = await getPointOfSaleSetting(request.id!);
        if (posSettings) {
          posSettings.Customer_ID =
            posSettings.Customer_ID == null ? 0 : posSettings.Customer_ID;
          posSettings.Currency_ID =
            posSettings.Currency_ID == null ? 0 : posSettings.Currency_ID;

          posSettings.DefaultDiscountType =
            posSettings.DefaultDiscountType == null
              ? 0
              : posSettings.DefaultDiscountType;
          posSettings.DefaultNoteType =
            posSettings.DefaultNoteType == null
              ? 0
              : posSettings.DefaultNoteType;
          posSettings.Emp_ID =
            posSettings.Emp_ID == null ? 0 : posSettings.Emp_ID;
          setPOSUserSettingModel(posSettings);
        }
        setShowUserPOSSettingModel(true);
        break;
      case ActionTypeEnum.GrantPermissions:
        const userObject = await getUserInformation(request.id!);
        setUser(userObject);
        setShowUserPermissionModel(true);
        break;
      case ActionTypeEnum.UserSetting:
        const settings = await getUserSetting(request.id!);
        if (settings) {
          setUserSettingModel(settings);
        }
        setShowUserSettingModel(true);
        break;
      case ActionTypeEnum.Failed:
        setToastModel({
          ...toastModel,
          body: request.request,
          variant: "danger",
          show: true,
        });
        switch (request.id) {
          case ActionTypeEnum.UserSetting:
            setUserSettingModel(request.requestBeforeError);
            setLoading(false);
            break;
          case ActionTypeEnum.TransactionSetting:
            setUserTransactionSettingModel(request.requestBeforeError);
            break;
          case ActionTypeEnum.POSSetting:
            setPOSUserSettingModel(request.requestBeforeError);
            break;
          default:
            break;
        }
        break;
      case ActionTypeEnum.Clear:
        setUser(null);
        break;
    }
  };
  const getAllUsers = async () => {
    setLoading(true);
    const userList = await getUsers();
    setUsers(userList);
    setLoading(false);
  };
  const getUserTransactionSetting = async (
    userId: any,
    transactionTypeId: any
  ) => {
    const userTransactionSetting = await getTransactionSettings(
      userId,
      transactionTypeId
    );
    if (userTransactionSetting) {
      setUserTransactionSettingModel(userTransactionSetting);
    }
  };
  const handleDeleteUser = async () => {
    setShowDeleteUserModel(false);
    const deleteUserResponse = await deleteUser(user !== null ? user.ID : 0);
    const isSuccess: boolean = !(
      deleteUserResponse.Result.Errors != null &&
      deleteUserResponse.Result.Errors.length !== 0
    );
    if (isSuccess) {
      await getAllUsers();
    }
    setToastModel({
      ...toastModel,
      show: true,
      variant: isSuccess ? "success" : "danger",
    });
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {/*{<ErrorValidationBox errors={validationErrors} />}*/}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {getLabelName("user information")}
          </Accordion.Header>
          <Accordion.Body>
            <RegisterUser
              request={user}
              onActionEvent={async (o: RequestActionModel) => {
                await handleUserAction(o);
              }}
              formId={formId}
            />
            {/* transaction  setting*/}
            <ModelDialogBox
              isModelVisible={showTransactionSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("transaction settings")}
              size="xl"
            >
              <TransactionSettings
                getUserTransactionSetting={async (
                  userId: any,
                  transactionTypeId: any
                ) => {
                  await getUserTransactionSetting(userId, transactionTypeId);
                }}
                userTransactionSettingModel={userTransactionSettingModel}
                onComplete={() => {
                  setShowTransactionSettingModel(false);
                  setToastModel({ ...toastModel, show: true });
                }}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
            {/* Point Of Sale  setting*/}
            <ModelDialogBox
              isModelVisible={showUserPOSSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("pos setting")}
              size="xl"
            >
              <POSSettings
                pOSUserSettingModel={pOSUserSettingModel}
                onComplete={() => {
                  setShowUserPOSSettingModel(false);
                  setToastModel({ ...toastModel, show: true });
                }}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
            <ModelDialogBox
              isModelVisible={showUserPermissionModel}
              isCloseButtonVisible={false}
              size="xl"
              title={getLabelName("user permissions")}
            >
              <UserPermissions
                userObject={user}
                onComplete={() => {
                  setShowUserPermissionModel(false);
                  setToastModel({ ...toastModel, show: true });
                }}
              />
            </ModelDialogBox>
            {/* user  setting*/}
            <ModelDialogBox
              isModelVisible={showUserSettingModel}
              isCloseButtonVisible={false}
              title={getLabelName("user settings")}
              size="xl"
            >
              <UserSettings
                userSettingModel={userSettingModel}
                onComplete={() => {
                  setShowUserSettingModel(false);
                  setToastModel({ ...toastModel, show: true });
                }}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
              />
            </ModelDialogBox>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <ConfirmModelDialogBox
              isModelVisible={showDeleteUserModel}
              onCloseEvent={() => {
                setShowDeleteUserModel(false);
              }}
              actions={deleteUserActions}
            ></ConfirmModelDialogBox>
            {users && users.length !== 0 && (
              <UsersList
                request={users}
                isModifyAble={true}
                isDeleteAble={true}
                isPermissionAble={true}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleUserAction(o);
                }}
                onCompleteEvent={getAllUsers}
                formId={formId}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
