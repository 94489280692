import React, {FC} from "react";
import {PageEnum, SuppliersComplexReportSearchParamsModel} from "../../../models";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {searchReportSuppliersPayments} from "../../../serviceBroker/suppliersReportApiServiceBroker";
import {ReportPage, ReportSuppliersComplexSearchPanel,} from "../../../components";

export const ReportSuppliersPayments: FC = () => {
    const initialState: SuppliersComplexReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportSuppliersPayments}
            searchHeader={getPageNameByPageId(PageEnum.ReportSuppliersPayment)}
            SearchPanel={ReportSuppliersComplexSearchPanel}
            formId={PageEnum.ReportSuppliersPayment}
        />
    );
};
