import {Accordion, Card} from "react-bootstrap";
import {FC, useEffect, useState} from "react";
import {AddEstektaa, ConfirmModelDialogBox, EstektaaList, LoadingBox, ToastBox,} from "../../components";
import {
  AccrualSubtractRuleTypeEnum,
  ActionButtonsModel,
  ActionTypeEnum,
  EstehekakModel,
  PageEnum,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
} from "../../models";
import {deleteEmployeeEstihkakSubtract,} from "../../serviceBroker/employeesApiServiceBroker";
import {getLabelName, getPageNameByPageId, getUserId, isArabicCurrentLanguage,} from "../../utils";
import {
  getEstihkakSubtractById,
  searchEstihkakSubtract
} from "../../serviceBroker/employeeEstihkakSubtracApiServiceBroker";

export const EntitlementPage: FC<{ formId: number }> = ({formId = PageEnum.addSubtract}) => {
    //#region variables
    const initialValues: EstehekakModel = {
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Errors: [],
        rowState: Number(RowStateEnum.Add),
        CreationDate: new Date(),
        ModificationDate: new Date(),
        Notes: "",
        VerifyOnUpdate: false,
        Date: new Date(),
        Emp_ID: 0,
        EmployeeNameAr: "",
        EmployeeNameEn: "",
        EsthkakSubtractRuleAr: "",
        EsthkakSubtractRuleEn: "",
        EsthkSubtRule_ID: 0,
        Value: 0,
        User_ID: getUserId(),
    };
    const deleteActions: ActionButtonsModel[] = [
        {
            text: getLabelName("yes"),
            onClick: async () => {
                await handleAction({
                    id: object?.ID || 0,
                    request: object,
                    action: ActionTypeEnum.DeleteOperationStart,
                });
            },
        },
        {
            text: getLabelName("No"),
            onClick: () => {
                setObject(initialValues);
                setShowDeleteModel(false);
            },
        },
    ];
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [objects, setObjects] = useState<EstehekakModel[]>([]);
    const [pageName, setPageName] = useState<string | null>(null);
    const [object, setObject] = useState<EstehekakModel>(initialValues);
    const [pageNumber] = useState<number>(1);
    const [pageSize] = useState<number>(50);
    const [totalRows, setTotalRows] = useState(0);

    const [_, setShowAddModel] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [toastModel, setToastModel] = useState<ToastModel>({show: false,});
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await getAllObjects(
                null,
                null,
                Number(AccrualSubtractRuleTypeEnum.Subtract),
                pageSize,
                pageNumber
            );
        };
        fillData().then(() => {
        });
    }, []);
    useEffect(() => {
        if (formId !== 0 && formId !== null && formId !== undefined) {
            setPageName(getPageNameByPageId(formId));
        }
    }, [isArabicCurrentLanguage()]);
    //#endregion
    //#region function
    const getAllObjects = async (
        searchEmp: string | null,
        searchRule: string | null,
        typeId: number,
        pageSize: number,
        pageNumber: number
    ) => {
        setLoading(true);
        let emp = searchEmp != null ? parseInt(searchEmp) : null;
        let rule = searchRule != null ? parseInt(searchRule) : null;
        const result = await searchEstihkakSubtract(
            emp,
            rule,
            typeId,
            pageSize,
            pageNumber
        );
        setObjects(result?.Result || []);
        setTotalRows(result?.TotalRecoredCount || 0);
        setLoading(false);
    };
    const handleAction = async (request: RequestActionModel) => {
        switch (request.action) {
            case ActionTypeEnum.AddSuccess:
                setLoading(true);
                setObject(initialValues);
                await getAllObjects(
                    null,
                    null,
                    Number(AccrualSubtractRuleTypeEnum.Subtract),
                    pageSize,
                    pageNumber
                );
                setToastModel({
                    ...toastModel,
                    variant: "success",
                    show: true,
                });
                setLoading(false);
                break;
            case ActionTypeEnum.Clear:
                setLoading(true);
                setObject(initialValues);
                await getAllObjects(
                    null,
                    null,
                    Number(AccrualSubtractRuleTypeEnum.Subtract),
                    pageSize,
                    pageNumber
                );
                setLoading(false);
                break;
            case ActionTypeEnum.Add:
                setObject(initialValues);
                setShowAddModel(true);
                break;
            case ActionTypeEnum.Update:
                window.scrollTo(0, 0);
                let item = await getEstihkakSubtractById(request.id);
                //@ts-ignore
                item.Result.rowState = Number(RowStateEnum.Update);
                setObject(item.Result!);
                setShowAddModel(true);
                break;
            case ActionTypeEnum.Delete:
                setObject(request.request);
                setShowDeleteModel(true);
                break;
            case ActionTypeEnum.DeleteOperationStart:
                setLoading(true);
                setShowDeleteModel(false);
                const result = await deleteEmployeeEstihkakSubtract(request.id!);
                setLoading(false);
                //@ts-ignore
                await getAllObjects(
                    null,
                    null,
                    Number(AccrualSubtractRuleTypeEnum.Subtract),
                    pageSize,
                    pageNumber
                );
                await handleAction({
                    id: 0,
                    action: result.Result.Result ? ActionTypeEnum.Success : ActionTypeEnum.Failed
                });
                break;
            case ActionTypeEnum.DeleteSuccess:
            case ActionTypeEnum.Success:
                setToastModel({...toastModel, show: true});
                setIsRefresh(true);
                break;
            case ActionTypeEnum.Failed:
                setToastModel({
                    ...toastModel,
                    body: request.request,
                    variant: "danger",
                    show: true,
                });
                setObject(request.requestBeforeError);
                setIsRefresh(true);
                break;
        }
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: true});
                    }}
                />
            )}

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{pageName}</Accordion.Header>
                    <Accordion.Body>
                        <Card>
                            {/* <Card.Header></Card.Header> */}
                            <Card.Body>
                                <AddEstektaa
                                    request={object}
                                    onActionEvent={async (o: RequestActionModel) => {
                                        await handleAction(o);
                                        setShowAddModel(false);
                                    }}
                                    formId={formId}
                                />
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body>
                        <Card className="card-custom">
                            {/* <Card.Header></Card.Header> */}
                            <Card.Body>
                                {/* delete AccrualSubtractRule  */}
                                <ConfirmModelDialogBox
                                    isModelVisible={showDeleteModel}
                                    onCloseEvent={() => {
                                        setShowDeleteModel(false);
                                    }}
                                    actions={deleteActions}
                                >
                                </ConfirmModelDialogBox>

                                {/* Estehkak list */}
                                {objects && (
                                    //&& objects.length !== 0
                                    <EstektaaList
                                        formId={formId}
                                        getDueList={async (
                                            searchEmp: string | null,
                                            searchRule: string | null,
                                            typeId: number,
                                            pageSize: number,
                                            pageNumber: number
                                        ) => {
                                            await getAllObjects(
                                                searchEmp,
                                                searchRule,
                                                typeId,
                                                pageSize,
                                                pageNumber
                                            );
                                        }}
                                        onActionEvent={async (o: RequestActionModel) => {
                                            await handleAction(o);
                                        }}
                                        setIsRefresh={setIsRefresh}
                                        isRefresh={isRefresh}
                                        request={objects}
                                        totalRows={totalRows}
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
};
