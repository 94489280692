import { FC, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import {
  AddCurrency,
  ConfirmModelDialogBox,
  CurrencyList,
  ErrorValidationBox,
  LoadingBox,
  ToastBox,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  CurrencyResponseModel,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import {
  deleteCurrency,
  getCurrency,
  getCurrencyInformation,
} from "../../serviceBroker/currencyApiServiceBroker";
import { getLabelName } from "../../utils";

export const CurrencyPage: FC = () => {
  //#region variables
  const initialValues: CurrencyResponseModel = {
    ArabicName: "",
    EnglishName: "",
    ShortCutArab: "",
    ShorCutEng: "",
    IsDefault: false,
    Value: 0,
    Note: "",
    ArabicShourtCut: "",
    EnglishShourtCust: "",
    CountryName: "",
    ID: 0,
    rowState: Number(RowStateEnum.Add),
  };
  const deleteCurrencyActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleDeleteCurrency();
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setCurrency(initialValues);
        setShowDeleteCurrencyModel(false);
      },
    },
  ];
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [currencies, setCurrencies] = useState<CurrencyResponseModel[]>([]);
  const [currency, setCurrency] =
    useState<CurrencyResponseModel>(initialValues);
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
  });
  const [showDeleteCurrencyModel, setShowDeleteCurrencyModel] = useState(false);
  const [showToastModel, setShowToastModel] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      // console.log(currencies);
      await getAllCurrencies();
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const handleCurrencyAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        setCurrency(initialValues);
        await getAllCurrencies();
        setShowToastModel(true);
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        window.scrollTo(0, 0);
        let userUpdateObject = await getCurrencyInformation(request.id);
        // @ts-ignore
        userUpdateObject.rowState = Number(RowStateEnum.Update);
        setCurrency(userUpdateObject);
        break;
      case ActionTypeEnum.Delete:
        let userDeleteObject = await getCurrencyInformation(request.id);
        setCurrency(userDeleteObject);
        setShowDeleteCurrencyModel(true);
        break;
      case ActionTypeEnum.Clear:
        setCurrency(initialValues);
        break;
    }
  };
  const getAllCurrencies = async () => {
    setLoading(true);
    const CurrencyList = await getCurrency();
    setCurrencies(CurrencyList);
    setLoading(false);
  };
  const handleDeleteCurrency = async () => {
    setShowDeleteCurrencyModel(false);
    const deleteStoreResponse = await deleteCurrency(
      currency !== undefined ? currency.ID : 0
    );
    const isSuccess: boolean = !(
      deleteStoreResponse.Result.Errors != null &&
      deleteStoreResponse.Result.Errors.length !== 0
    );
    if (isSuccess) {
      await getAllCurrencies();
    } else {
      setValidationErrors(deleteStoreResponse.Result.Errors);
    }
    setToastModel({
      ...toastModel,
      show: true,
      variant: isSuccess ? "success" : "danger",
    });
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {showToastModel && (
        <ToastBox
          isShown={showToastModel}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setShowToastModel(false);
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {getLabelName("Currency Information")}
          </Accordion.Header>
          <Accordion.Body>
            <Card>
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                <AddCurrency
                  request={currency}
                  onActionEvent={async (o: RequestActionModel) => {
                    await handleCurrencyAction(o);
                  }}
                  formId={195}
                />
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Employee List")}</Accordion.Header>

          <Accordion.Body>
            <Card className="card-custom">
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                {/* delete user  */}
                <ConfirmModelDialogBox
                  isModelVisible={showDeleteCurrencyModel}
                  onCloseEvent={() => {
                    setShowDeleteCurrencyModel(false);
                  }}
                  actions={deleteCurrencyActions}
                ></ConfirmModelDialogBox>
                {/* user list */}
                {currencies && currencies.length !== 0 && (
                  <CurrencyList
                    request={currencies}
                    isModifyAble={true}
                    isDeleteAble={true}
                    isPermissionAble={false}
                    onActionEvent={async (o: RequestActionModel) => {
                      await handleCurrencyAction(o);
                    }}
                    onCompleteEvent={getAllCurrencies}
                    formId={195}
                  />
                )}
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
