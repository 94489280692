import React, {FC} from "react";
import {PageEnum, SuppliersComplexReportSearchParamsModel} from "../../../models";
import {getUserId} from "../../../utils";
import {ReportPage, ReportSuppliersComplexSearchPanel,} from "../../../components";
import {searchReportAllSuppliersTransaction} from "../../../serviceBroker/suppliersReportApiServiceBroker";

export const ReportAllSuppliersTransactions: FC = () => {
    const initialState: SuppliersComplexReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportAllSuppliersTransaction}
            searchHeader="Report All Suppliers Transactions"
            SearchPanel={ReportSuppliersComplexSearchPanel}
            formId={PageEnum.ReportFollowSuppliersInTransaction}
        />
    );
};
