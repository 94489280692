import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import "./i18n";
import {CookieGet, CookieSet} from "../../../utils";
import {SystemConfiguration} from "../../../configuration";
import arabicFlag from "../../../assets/images/flags/arabicFlag.png";
import englishFlag from "../../../assets/images/flags/englishFlag.png";

const languageLocalStorageName = SystemConfiguration.keys.culture;
const defaultUiLanguage = SystemConfiguration.keys.defaultUiLanguage;
const getDefaultLanguage = (): string => {
    // @ts-ignore
    const localeValueFomStorage = CookieGet(languageLocalStorageName);
    if (
        localeValueFomStorage === null ||
        localeValueFomStorage === undefined ||
        localeValueFomStorage === ""
    ) {
        // @ts-ignore
        CookieSet(languageLocalStorageName, defaultUiLanguage);
        // @ts-ignore
        return defaultUiLanguage;
    }
    return localeValueFomStorage;
};
const LangSwitcherReactI18: FC = () => {
    //#region state
    const {i18n} = useTranslation();
    const [language, setLanguage] = useState(getDefaultLanguage);
    //#endregion
    //#region function
    const handleLanguageSelect = (e: any) => {
        i18n.changeLanguage(e.target.value).then(() => {
        });
        setLanguage(e.target.value);
        CookieSet(languageLocalStorageName || "", e.target.value);
    };
    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language).then(() => {});
        setLanguage(language);
        CookieSet(languageLocalStorageName || "", language);
        // Set the language in your application state or context if necessary
        // Then refresh the page to apply the changes
        window.location.reload();
    };
//#endregion
    //#region html
    return (
        <>
            <div className="language-switch">
                {language !== "ar-AE" && (
                    <img
                        src={arabicFlag}
                        alt="Arabic"
                        className="btn-ar"
                        onClick={() => handleLanguageChange("ar-AE")}
                    />
                    // <ButtonBox
                    //   className="btn btn-gradient-primary btn-ar"
                    //   type="button"
                    //   value="ar-AE"
                    //   onClick={handleLanguageSelect}
                    // >
                    //   العربية
                    // </ButtonBox>
                )}
                {language === "ar-AE" && (
                    <img
                        src={englishFlag}
                        alt="English"
                        className="btn-en"
                        onClick={() => handleLanguageChange("en-US")}
                    />
                    //   <ButtonBox
                    //       className="btn btn-gradient-primary btn-en"
                    //       type="button"
                    //       value="en-US"
                    //       onClick={handleLanguageSelect}
                    // >
                    //   English
                    // </ButtonBox>
                )}
                {/* <select
          value={language}
          onChange={handleLanguageSelect}
          // defaultValue={language}
          className="form-select form-select-sm"
        >
          <option value="ar-AE">Ar</option>
          <option value="en-US">EN</option>
        </select>  */}
            </div>
        </>
    );
    //#endregion
};
export {LangSwitcherReactI18, getDefaultLanguage};
