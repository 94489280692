import {Table} from "antd";
import {FC} from "react";

export const AntdTableComponent: FC<{
    data: any[];
    columns: any[];
    rowKey?: string | null;
    isPaginationEnable?: boolean | null;
}> = ({data, columns,  isPaginationEnable = false}) => {
    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                locale={{emptyText: " "}}
                className="data-table-wrap"
                pagination={!isPaginationEnable ? false : undefined}
            />
        </>
    );
};
