import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
    ResponseBaseModel,
    StoreDeleteResponseModel,
    StoreResponseModel,
} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getStores = async (): Promise<StoreResponseModel[]> => {
    try {
        const result: ResponseBaseModel<StoreResponseModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.storeController.getStoreList);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(err);
    }
    return [];
};
//#endregion
//#region post
export const addStore = async (
    request: StoreResponseModel
): Promise<StoreResponseModel> => {
    let apiResponse: StoreResponseModel = {
        Address: "",
        Name_En: "",
        Name: "",
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Errors: [],
        rowState: 0,
        Branch_ID: 0,
        Code: "",
        CreationDate: new Date(),
        IsDefault: false,
        Phone: "",
        PrinterName: "",
        VerifyOnUpdate: false,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.storeController.saveStore, {...request});
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
export const deleteStore = async (id: number): Promise<StoreDeleteResponseModel> => {
    let apiResponse: StoreDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(`${AlYuserApiUrls.storeController.deleteStore}?id=${id}`);
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
//#endregion
