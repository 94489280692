import {
    DailyTransactionTypeEnum,
    ItemDiscountValuesModel,
    LookupItemFilterModel,
    LookupItemModel,
    LookupTypeEnum,
    RowStateEnum,
    StoreTransferTransactionDetailResponseModel,
    TransactionItemResponseModel, TransactionResponseModel,
    TransactionTotalValuesModel,
    UserTransactionsSettingResponseModel,
    ValidationErrorModel
} from "../../../../models";
import {Dispatch, SetStateAction} from "react";
import _ from "lodash";
import {
    getItemTaxPercentageValue,
    handleCalculateItemRecordValuesUpdate,
    handleCalculateNewItemDiscountValuesChange,
    handleCalculateNewItemValuesChange,
} from "./transactionBl";
import {
    generateGuid,
    getLabelName,
    getUserId,
    getUserStationId,
    getValueInCurrency,
    roundNumber
} from "../../../../utils";
import {
    calculateItemDiscount,
    calculateItemPrice,
    calculateItemTax,
    calculateItemUnitPriceOrPriceWithTax, getItemSalesPrice,getItemInStoreId
} from "./commonBl";
//#region variables
export const transactionResponseModel:TransactionResponseModel={
    code: "",
    creationDate: new Date(),
    currencyNameAr: "",
    currencyNameEn: "",
    customerNameAr: "",
    customerNameEn: "",
    discount: 0,
    employeeNameAr: "",
    employeeNameEn: "",
    id: 0,
    isValidForReturn: false,
    netMoney: 0,
    paymentTypeNameAr: "",
    paymentTypeNameEn: "",
    totalTax: 0,
    transactionType: 0
};
export const storeTransferTransactionDetailInitialValue:StoreTransferTransactionDetailResponseModel= {
    InstallmentPlanId: 0,
    SerialPOS: 0,
    TempHeaderID: 0,
    Customer_ID: null,
    Currency_ID: null,
    CalcType_ID: null,
    PaymentType_ID: null,
    IsSkipValidation: false,
    ValueOfPoint: 0,
    DiscountTypeEnum: 0,
    OrderDtatus: 0,
    SavedTotalMony: 0,
    TotalMonyCalculated: 0,
    TotalItemDiscount: 0,
    TotalTaxCalculated: 0,
    TotalTobaccoTaxCalculated: 0,
    TotalServiceTaxCalculated: 0,
    TotalQuantity: 0,
    TotalAddationalCostValueCalculated: 0,
    NetMoneyCalculated: 0,
    TempReservedBillTax: 0,
    TransactionDetaillist: [],
    Code: "",
    TransactionType: 0,
    TotalMony: 0,
    Discount: 0,
    Note: "",
    Refrence: 0,
    Confirmed: false,
    ACoount_No: 0,
    CostCenterRespon_ID: 0,
    Emp_ID: 0,
    Serial: 0,
    AnyConfirmed: 0,
    ISNotify: false,
    ISDone: false,
    Store_IDFrom: null,
    Store_IDTo: null,
    CashMoney: 0,
    CardMoney: 0,
    DelayMoney: 0,
    OthersMoney: 0,
    TransportCost: 0,
    ValueCurrency: 1,
    MoneyPayWritten: "",
    AllCashPayment: 0,
    CurrentCustomerBalance: 0,
    MoneyAdd: 0,
    Table_ID: 0,
    Offline: false,
    AC_TransactionHeaders_ID: 0,
    Branch_ID: 0,
    IsSuspicionsCheck: 0,
    IsOrderPrepaierd: false,
    TotalTax: 0,
    TotalCost: 0,
    Voucher_ID: 0,
    IsGenerateVoucher: false,
    IsPrinted: false,
    DiscountType: 0,
    Device_ID: 0,
    Order_Status_ID: 0,
    Tobacco_Value: 0,
    IsRegisterBeforeInstallSystem: false,
    InstallmentPlanHeader_ID: 0,
    Tax_Percentage: 0,
    Sender_ID: 0,
    Receiver_Id: 0,
    AcTransactionIdExtraPaid: 0,
    ExtraAmountPaid: 0,
    Car_Id: 0,
    NetMoney: 0,
    MortgageItemId: 0,
    MortgageValue: 0,
    MortgageItems: "",
    TotalAddationalCostValue: 0,
    NumberPrintCount: 0,
    TotalServiceTax: 0,
    IsRestTablePartialPayment: false,
    ID: 0,
    CreatedBy: getUserId(),
    User_Create: getUserId(),
    Station_ID: getUserStationId(),
    rowState: Number(RowStateEnum.Add),
    CreateDate: new Date(),
    Date: new Date(),
    CreationDate: new Date(),
    ModificationDate:null,
    ModifcationDate: null,
    ModifiedBy: null,
    Name: "",
}
//#endregion
//#region general
export const getLookupFilterItems = (lookups: LookupItemFilterModel[], type: LookupTypeEnum): LookupItemModel[] =>
    lookups.find(p => p.key === type)?.value ?? [];
//#endregion
//#region calculation
export const handleStoreTransferChangeNewRecordItemCalculation = (request: TransactionItemResponseModel, transactionRequest: StoreTransferTransactionDetailResponseModel, userTransactionSetting: UserTransactionsSettingResponseModel, transactionType: DailyTransactionTypeEnum, itemList: LookupItemModel[], calcTypeList: LookupItemModel[]): TransactionItemResponseModel => {
    const itemInStoreId: number | null = getItemInStoreId(Number(request.ItemUnit_ID), itemList);
    const itemSalePrice: number = itemInStoreId !== null ? getItemSalesPrice(
            Number(request.ItemUnit_ID || 0),
            transactionType,
            transactionRequest.CalcType_ID !== null && transactionRequest.CalcType_ID !== undefined ? transactionRequest.CalcType_ID : null,
            itemList,
            calcTypeList,
            userTransactionSetting,
        )
        : 0;
    const itemPriceCalculatedValues = handleCalculateNewItemValuesChange(itemList, Number(request.ItemUnit_ID || 0), itemSalePrice, transactionRequest.ValueCurrency, true, false);
    const discountCalculatedValues = handleCalculateNewItemDiscountValuesChange(request.ItemDiscount, transactionRequest.ValueCurrency, false);
    const itemTaxPercentage = itemInStoreId !== null ? getItemTaxPercentageValue(Number(request.ItemUnit_ID || 0), itemList) : 0;
    request.ItemInstore_ID = itemInStoreId;
    request.ItemInstoreIDTo=itemInStoreId;
    request.TaxPercentge = itemTaxPercentage;
    request.TaxPercentge = itemTaxPercentage;
    request.Unit_Price = itemPriceCalculatedValues.UnitPrice;
    request.UnitPriceWithTaxCalculated = itemPriceCalculatedValues.UnitPriceWithTax;
    request.Unit_PriceInCurrencyCalculated = itemPriceCalculatedValues.UnitPriceInCurrency;
    request.UnitPriceWithTaxInCurrency = itemPriceCalculatedValues.UnitPriceWithTaxInCurrency;
    request.ItemDiscount = discountCalculatedValues.ItemDiscount;
    request.ItemDiscountInCurrency = discountCalculatedValues.ItemDiscountInCurrency;
    return request;
}
export const handleCalculateExistItemValuesChange = async (
    setState: Dispatch<SetStateAction<StoreTransferTransactionDetailResponseModel>>,
    stateValue: StoreTransferTransactionDetailResponseModel,
    request: TransactionItemResponseModel
) => {
    let obj: TransactionItemResponseModel[] = _.map(stateValue.TransactionDetaillist, (row) => {
        if (row.rowKey == request.rowKey) {
            request.latestModifiedDate = new Date();
            request = handleCalculateItemRecordValuesUpdate(request);
            return request;
        } else {
            return row;
        }
    });
    const stateObject = handleStoreTransferTransactionTotalValues({...stateValue, TransactionDetaillist: obj});
    setState({
        ...stateObject,
        TransactionDetaillist: obj,
    });
}
export const handleStoreTransferTransactionTotalValues = (request: StoreTransferTransactionDetailResponseModel): StoreTransferTransactionDetailResponseModel => {
    let totalValuesResponse: TransactionTotalValuesModel = {
        totalMoney: 0,
        totalTax: 0,
        totalDiscount: 0,
        totalNet: 0,
        totalMoneyInCurrency: 0,
        totalTaxInCurrency: 0,
        totalDiscountInCurrency: 0,
        totalNetInCurrency: 0,
        totalDiscountPercentage: 0
    };
    let transportCost: number = 0;
    let moneyAdd: number = 0;
    let tobaccoValue: number = 0;
    let totalAdditionalCostValue: number = 0;
    if (request !== null && request !== undefined && request.TransactionDetaillist !== null && request.TransactionDetaillist !== undefined && request.TransactionDetaillist.length !== 0) {
        request.TransactionDetaillist.filter(p => p.rowState !== RowStateEnum.Delete).forEach(row => {
            const rowMoney: number = row.Unit_Price * row.Quantity;
            const rowTax = (row.Tax * row.Quantity);
            totalValuesResponse.totalMoney = roundNumber(totalValuesResponse.totalMoney + rowMoney);
            totalValuesResponse.totalTax = roundNumber(totalValuesResponse.totalTax + rowTax);
            totalValuesResponse.totalDiscount = roundNumber(totalValuesResponse.totalDiscount + (row.ItemDiscount));

            totalValuesResponse.totalMoneyInCurrency = totalValuesResponse.totalMoneyInCurrency + getValueInCurrency(rowMoney, row.ValueOfCurrency);
            totalValuesResponse.totalTaxInCurrency = totalValuesResponse.totalTaxInCurrency + getValueInCurrency(rowTax, row.ValueOfCurrency);
            totalValuesResponse.totalDiscountInCurrency = totalValuesResponse.totalDiscountInCurrency + getValueInCurrency(row.ItemDiscount, row.ValueOfCurrency);
        });

        totalValuesResponse.totalDiscountPercentage = totalValuesResponse.totalDiscount === 0 ? 0 : roundNumber((totalValuesResponse.totalDiscount / totalValuesResponse.totalMoney) * 100);

        totalValuesResponse.totalNet = roundNumber((totalValuesResponse.totalMoney - totalValuesResponse.totalDiscount + transportCost + moneyAdd + totalValuesResponse.totalTax + tobaccoValue + totalAdditionalCostValue));
        totalValuesResponse.totalNetInCurrency = roundNumber((totalValuesResponse.totalMoneyInCurrency - totalValuesResponse.totalDiscountInCurrency + transportCost + moneyAdd + totalValuesResponse.totalTaxInCurrency + tobaccoValue + totalAdditionalCostValue));
    }
    return request;
}
//#endregion
//#region post
export const updateTransactionItemStateOnChange = async (setState: Dispatch<SetStateAction<StoreTransferTransactionDetailResponseModel>>,
                                                         stateValue: StoreTransferTransactionDetailResponseModel,
                                                         request: TransactionItemResponseModel) => {
    let obj: TransactionItemResponseModel[] = stateValue.TransactionDetaillist.map((row) => {
        if (row.rowKey == request.rowKey) {
            return request;
        } else {
            return row;
        }
    });
    setState({
        ...stateValue,
        TransactionDetaillist: obj,
    });
}
export const addStoreTransferItem = async (
    request: TransactionItemResponseModel,
    data: StoreTransferTransactionDetailResponseModel,
    itemList: LookupItemModel[],
): Promise<TransactionItemResponseModel> => {
    try {
        const index = _.findIndex(data.TransactionDetaillist, {ItemUnit_ID: request.ItemUnit_ID});
        if (index !== -1) {
            const newItem = request;
            request = data.TransactionDetaillist[index];
            request.Quantity = Number(request.Quantity) + Number(newItem.Quantity);
            request.Unit_Price = newItem.Unit_Price;
            request.Unit_PriceInCurrencyCalculated = newItem.Unit_PriceInCurrencyCalculated;
            request.ItemDiscount = newItem.ItemDiscount !== 0 ? newItem.ItemDiscount : request.ItemDiscount;
            request.ItemDiscountInCurrency = newItem.ItemDiscountInCurrency !== 0 ? newItem.ItemDiscountInCurrency : request.ItemDiscountInCurrency;
        }
        request.ItemInstore_ID = request.ItemInstore_ID === null || request.ItemInstore_ID === undefined ? 0 : request.ItemInstore_ID;
        request.ItemInstoreIDTo= request.ItemInstoreIDTo === null || request.ItemInstoreIDTo === undefined ? 0 : request.ItemInstoreIDTo;
        const item = itemList.filter(
            //@ts-ignore
            (p) => p.value === request.ItemUnit_ID.toString()
        )[0];
        if (item !== null && item !== undefined) {
            request.ValueOfCurrency = data.ValueCurrency;
            request.ItemName = item.nameAr;
            request.ItemName_En = item.name;
            request.ItemCode = item.otherValue.code;
            request.Categ_ID =
                item.otherValue?.categoryId !== null
                    ? Number(item.otherValue.categoryId)
                    : null;
            request.UnitBalance =
                item.otherValue?.balance !== null
                    ? Number(item.otherValue.balance)
                    : null;
            request.TaxPercentge = item.otherValue?.taxPercentage !== null
                ? Number(item.otherValue.taxPercentage)
                : 0;
            request.CreatedBy = request.ID === 0 ? getUserId() : request.CreatedBy;
            request.ModifiedBy = request.ID !== 0 ? getUserId() : request.ModifiedBy;
            request.Factor = item.otherValue.factor;


            request.ItemDiscountInCurrency = request.ItemDiscountInCurrency || 0;
            request.ItemDiscount = request.ItemDiscount || 0;
            request.ItemDiscountRatio = request.ItemDiscountRatio || 0;//(data.discountRatio || 0);
            const calculatedDiscount: ItemDiscountValuesModel = calculateItemDiscount(request);
            request.ItemDiscount = calculatedDiscount.ItemDiscount;// calculateItemDiscount(request);
            request.ItemDiscountInCurrency = calculatedDiscount.ItemDiscountInCurrency;

            request.Unit_PriceInCurrencyCalculated = getValueInCurrency(request.Unit_Price, data.ValueCurrency);
            request.UnitPriceWithTaxCalculated = calculateItemUnitPriceOrPriceWithTax(request.Unit_Price, request.TaxPercentge, true);
            request.UnitPriceWithTaxInCurrency = getValueInCurrency(request.UnitPriceWithTaxCalculated, data.ValueCurrency);
            request.Tax = calculateItemTax(request);
            request.TaxInCurrency = getValueInCurrency(request.Tax, data.ValueCurrency);
            request.TotalTax = request.Tax * request.Quantity;
            request.TotalTaxInCurrency = getValueInCurrency(request.TotalTax, data.ValueCurrency);
            request.Total = calculateItemPrice(request);
            request.TotalInCurrency = getValueInCurrency(request.Total, data.ValueCurrency);
        }
        request.rowKey = generateGuid();
    } catch (e) {
    }
    //@ts-ignore
    return request;
}
//#endregion
//#region user
export const UpdateStoreTransferTransactionActionUser = (request: StoreTransferTransactionDetailResponseModel): StoreTransferTransactionDetailResponseModel => {
    request.Station_ID = request.Station_ID === 0 || request.Station_ID === null || request.Station_ID === undefined ? getUserStationId() : request.Station_ID;
    request.CreatedBy = request.rowState === Number(RowStateEnum.Add) && (request.CreatedBy === 0 || request.CreatedBy === null || request.CreatedBy === undefined) ? getUserId() : request.CreatedBy;
    request.User_Create = request.rowState === Number(RowStateEnum.Add) && (request.User_Create === 0 || request.User_Create === null || request.User_Create === undefined) ? getUserId() : request.User_Create;
    request.ModifiedBy = request.rowState === Number(RowStateEnum.Update) && (request.ModifiedBy === 0 || request.ModifiedBy === null || request.ModifiedBy === undefined) ? getUserId() : request.ModifiedBy;
    request.User_Update = request.rowState === Number(RowStateEnum.Update) && (request.User_Update === 0 || request.User_Update === null || request.User_Update === undefined) ? getUserId() : request.User_Update;
    return request;
}
//#endregion
//#region validation
export const validateStoreTransferSubmitRequest = async (
    request: StoreTransferTransactionDetailResponseModel
): Promise<ValidationErrorModel[]> => {
    let errors: ValidationErrorModel[] = [];
    if(request.Store_IDFrom === null || request.Store_IDFrom === undefined|| request.Store_IDFrom===0){
        errors.push({
            MessageAr: getLabelName("from store missing"),
            MessageEn: getLabelName("from store missing"),
        });
    }
    if(request.Store_IDTo === null || request.Store_IDTo === undefined|| request.Store_IDTo===0){
        errors.push({
            MessageAr: getLabelName("to store missing"),
            MessageEn: getLabelName("to store missing"),
        });
    }
    return errors;
}
//#endregion