import {MenuModel} from "../../models";
import {SecureLocalStorageGet,isArabicCurrentLanguage} from "..";
import {SystemConfiguration} from "../../configuration";
//#region public
export const getPageNameByPageId = (id: number): string => {
    const menuItems = JSON.parse(
        // @ts-ignore
        SecureLocalStorageGet(SystemConfiguration.keys.menu || "")
    ) as MenuModel[];
    const menuItem: MenuModel | null = searchDeeplyInMenuItemById(id, menuItems);
    return menuItem !== null && menuItem !== undefined ? isArabicCurrentLanguage() ? menuItem.ArabicName : menuItem.Name : "";
}
export const getPageNameByUrl = (url?: string | null): string => {
    const pageUrl: string = url === null || url === undefined || url === "" ? window.location.pathname.toLowerCase() : url;
    const menuItems = JSON.parse(
        // @ts-ignore
        SecureLocalStorageGet(SystemConfiguration.keys.menu || "")
    ) as MenuModel[];
    const menuItem: MenuModel | null = searchDeeplyInMenuItemByUrl(pageUrl, menuItems);
    return menuItem !== null && menuItem !== undefined ? isArabicCurrentLanguage() ? menuItem.ArabicName : menuItem.Name : "";
}

export const searchDeeplyInMenuItemById = (id: number, menuItems: MenuModel[]): MenuModel | null => {
    for (const obj of menuItems) {
        if (obj.ID === id) {
            return obj;
        }
        if (obj.ChildBusinessObject !== null) {
            const result = searchDeeplyInMenuItemById(id, obj.ChildBusinessObject);
            if (result) {
                return result;
            }
        }
    }
    return null;
};
export const searchDeeplyInMenuItemByUrl = (url: string, menuItems: MenuModel[]): MenuModel | null => {
    for (const obj of menuItems) {
        if (obj.WebUrl !== null && obj.WebUrl !== undefined && obj.WebUrl !== "" && obj.WebUrl.toLowerCase() === url.toLowerCase()) {
            return obj;
        }
        if (obj.ChildBusinessObject !== null) {
            const result = searchDeeplyInMenuItemByUrl(url, obj.ChildBusinessObject);
            if (result) {
                return result;
            }
        }
    }
    return null;
};
//#endregion


