import {TableColumn} from "react-data-table-component";
import {InstallmentPlanModel} from "../../../models";
import {getLabelName, isArabicCurrentLanguage} from "../../../utils";

const isArabic = isArabicCurrentLanguage();
const columns: TableColumn<InstallmentPlanModel>[] = [
    {
        name: getLabelName("Customer"),
        selector: (row) => (isArabic ? row.Customer_Name_AR : row.Customer_Name_En),
        sortable: true,
        allowOverflow: true,
    },
    {
        name: getLabelName("Total Paid"),
        selector: (row) => row.Total_Paid,
        sortable: true,
        allowOverflow: true,
    },
    {
        name: getLabelName("Total Money"),
        selector: (row) => row.Total_Amount,
        sortable: true,
        allowOverflow: true,
    },
    {
        name: getLabelName("Creation Date"),
        selector: (row) => row.CreationDate,
        sortable: true,
        allowOverflow: true,
    },
];

export default columns;
