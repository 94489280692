import React, { useState } from "react";
import { ActionTypeEnum, RequestActionModel } from "../../../models";
import { TextBox,ButtonBox } from "../..";
import { getLabelName } from "../../../utils";
interface CancellationForm {
  onActionEvent: (o: RequestActionModel) => void;
  handleCancel: () => void;
}

const CancellationForm: React.FC<CancellationForm> = ({
  onActionEvent,
  handleCancel,
}) => {
  const [reason, setReason] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onActionEvent({
      action: ActionTypeEnum.CancelReservation,
      request: reason,
    });
    handleCancel();
  };

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setReason((e.target as HTMLTextAreaElement).value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row row-cols-12 mb-3">
        <TextBox
          type={"textarea"}
          labelName={getLabelName("Reason")}
          inputName="reasong"
          inputValue={reason}
          onChange={handleChange}
          placeHolder={getLabelName("Reason")}
        />
      </div>
      <div className="col-md-3 ms-2 mb-2" style={{ marginRight: "auto" }}>
        <ButtonBox style={{ width: "100%" }} type="submit">
          {getLabelName("cancel reservation")}
        </ButtonBox>
      </div>
    </form>
  );
};

export default CancellationForm;
