import React, { FC } from "react";
import { PaymentTypeMainComponent } from "../../components";

export const PaymentTypePage: FC = () => {
  //#region html
  return (
    <>
      <PaymentTypeMainComponent />
    </>
  );
  //#endregion
};
