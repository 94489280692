import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
    AccrualSubtractItemModel,
    AccrualSubtractRuleDeleteResponseModel,
    EmployeeEstihkakSubtractDeleteResponseModel,
    EmployeeEstihkakSubtractResponseModel,
    EstehekakModel,
    ResponseBaseModel,
} from "../models";
import {AlYuserApiUrls} from "../configuration";
//#region get
export const getEmployeeEstihkak = async (): Promise<
    EmployeeEstihkakSubtractResponseModel[]
> => {
    try {
        const result: ResponseBaseModel<EmployeeEstihkakSubtractResponseModel[]> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.employeeEstihkakSubtractController.getAllEsthkakSubtractByRuleTypeId}?RuleTypeId=1`);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(err);
    }
    return [];
};
export const getEmployeeEstihkakExpense = async (): Promise<
    EmployeeEstihkakSubtractResponseModel[]
> => {
    try {
        const result: ResponseBaseModel<EmployeeEstihkakSubtractResponseModel[]> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.employeeEstihkakSubtractController.getAllEsthkakSubtractByRuleTypeId}?RuleTypeId=2`);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(err);
    }
    return [];
};
export const GetAccrualSubtractRules = async (
    RuleTypeId: any
): Promise<AccrualSubtractItemModel[]> => {
    try {
        const result: ResponseBaseModel<AccrualSubtractItemModel[]> =
            await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.employeeEstihkakSubtractController.getAllEsthkakSubtractByRuleTypeId}?RuleTypeId=${RuleTypeId}`);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : [];
    } catch (err) {
        alert(err);
    }
    return [];
};
export const GetAccrualSubtractRuleById = async (
    Id: any
): Promise<AccrualSubtractItemModel> => {
    try {
        let url: string = `${AlYuserApiUrls.employeeEstihkakSubtractController.getEstihkakSubtractRuleById}?Id=${Id}`;
        const result: ResponseBaseModel<AccrualSubtractItemModel> =
            await AlYusrAxiosApiInstance.get(url);
        // @ts-ignore
        return result !== null && result !== undefined ? result.Result : null;
    } catch (err) {
        throw err;
    }
};
export const getEstihkakSubtractById = async (
    id: any
): Promise<ResponseBaseModel<EstehekakModel>> => {
    return await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.employeeEstihkakSubtractController.GetEstihkakSubtractById}?Id=${id}`);
};
export const searchEstihkakSubtract = async (
    empId: number | null,
    ruleId: number | null,
    typeId: number,
    pageSize: number,
    pageNumber: number
): Promise<ResponseBaseModel<EstehekakModel[]>> => {
    let url: string = `${AlYuserApiUrls.employeeEstihkakSubtractController.searchEstihkakSubtract}?searchItem.typeId=${typeId}&searchItem.pageNumber=${pageNumber}&searchItem.pageSize=${pageSize}`;
    url = empId !== null ? `${url}&searchItem.employeeId=${empId}` : url;
    url = ruleId !== null ? `${url}&searchItem.esthkSubtRuleId=${ruleId}` : url;
    return await AlYusrAxiosApiInstance.get(url);
};
//#endregion
//#region post
export const deleteEstihkakSubtract = async (
    id: number
): Promise<EmployeeEstihkakSubtractDeleteResponseModel> => {
    let apiResponse: EmployeeEstihkakSubtractDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(`${AlYuserApiUrls.employeeEstihkakSubtractController.deleteEstihkakSubtractRule}?id=${id}`);

        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
export const SaveEstihkakSubtractRule = async (
    request: EmployeeEstihkakSubtractResponseModel
): Promise<EmployeeEstihkakSubtractResponseModel> => {
    let apiResponse: EmployeeEstihkakSubtractResponseModel = {
        Notes: "",
        TypeRule_ID: 0,
        Name_En: "",
        Row_State: 0,
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: "",
        Name: "",
        CreationDate: new Date(),
        ModificationDate: new Date(),
        VerifyOnUpdate: false,
        rowState: 1,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.employeeEstihkakSubtractController.saveEstihkakSubtractRule, {...request});
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
export const SaveAccrualSubtractRule = async (
    request: AccrualSubtractItemModel
): Promise<AccrualSubtractItemModel> => {
    let apiResponse: AccrualSubtractItemModel = {
        ID: 0,
        CreatedBy: 0,
        ModifiedBy: 0,
        Errors: [],
        rowState: 0,
        CreationDate: new Date(),
        ModificationDate: new Date(),
        Name: "",
        Name_En: "",
        Notes: "",
        Row_State: 0,
        TypeRule_ID: 1,
        VerifyOnUpdate: false,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(AlYuserApiUrls.employeeEstihkakSubtractController.saveEstihkakSubtractRule, {...request});
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
export const deleteEstihkakSubtractRule = async (
    id: number
): Promise<AccrualSubtractRuleDeleteResponseModel> => {
    let apiResponse: AccrualSubtractRuleDeleteResponseModel = {
        Errors: [],
        Result: {
            Result: false,
            Errors: [],
        },
        Status: 0,
    };
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(`${AlYuserApiUrls.employeeEstihkakSubtractController.deleteEstihkakSubtractRule}?id=${id}`);
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
//#endregion

