import {useEffect, useState} from "react";
import {LookupItemModel} from "../../models";
import {getAllMainAccountType} from "../../serviceBroker/accountApiServiceBroker";

const useMainAccountTypes = () => {
    const [accountTypes, setAccountTypes] = useState<LookupItemModel[]>([]);

    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchAccountTransactions = async () => {
            setIsLoading(true);
            const fetchedAccountTypes = await getAllMainAccountType();
            if (fetchedAccountTypes !== null) setAccountTypes(fetchedAccountTypes);
            setIsLoading(false);
        };
        fetchAccountTransactions().then(() => {
        });
    }, []);
    return {accountTypes, areAccountTypesLoading: loading};
};

export default useMainAccountTypes;
