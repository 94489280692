import {FC} from "react";
import {ReportIncomeStatementPageSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {IncomeStatementReportSearchParamsModel, PageEnum} from "../../../models";
import {searchReportBalanceSheet,} from "../../../serviceBroker/acTransactionReportApiServiceBroker";

export const ReportBalanceSheetPage: FC = () => {
    const initialState: IncomeStatementReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchReportBalanceSheet}
            searchHeader={getPageNameByPageId(PageEnum.BalanceSheet)}
            SearchPanel={ReportIncomeStatementPageSearchPanel}
            formId={PageEnum.BalanceSheet}
        />
    );
};
