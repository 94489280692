import React, {FC} from "react";
import {useTranslation} from "react-i18next";

export const LayoutFooter: FC<any> = () => {
    //#region state
    const {t} = useTranslation();
    //#endregion
    //#region html
    return (
        <div className="footer">
            {`${t("footer_copyRights")} ${new Date().getFullYear()} ${t("footer_allRightsReserved")}`}
        </div>
    );
    //#endregion
};
