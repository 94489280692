import {FC} from "react";
import {ReportDebitSupplierSearchPanel, ReportPage} from "../../../components";
import {getPageNameByPageId, getUserId} from "../../../utils";
import {SearchReportDebitSupplierPage} from "../../../serviceBroker/customersReportApiServiceBroker";
import {DebitCustomerReportSearchParamsModel, PageEnum} from "../../../models";

export const ReportDebitSupplierPage: FC = () => {
    const initialState: DebitCustomerReportSearchParamsModel = {
        lang: 1,
        userId: getUserId(),
    };
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={SearchReportDebitSupplierPage}
            searchHeader={getPageNameByPageId(PageEnum.ReportSupplierDebits)}
            SearchPanel={ReportDebitSupplierSearchPanel}
            formId={PageEnum.ReportSupplierDebits}
        />
    );
};
