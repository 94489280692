import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
  ResponseBaseModel,
  RestaurantResponseModel,
  RestaurantDeleteResponseModel,
  TreeViewModel,
  TransactionDetailResponseModel,
} from "../models";
import { AlYuserApiUrls } from "../configuration";
//#region get
export const getRestaurant = async (): Promise<RestaurantResponseModel[]> => {
  try {
    const result: ResponseBaseModel<RestaurantResponseModel[]> =
      await AlYusrAxiosApiInstance.get(
        AlYuserApiUrls.restaurantTableController.getAllRestaurantTables
      );
    // @ts-ignore
    return result !== null && result !== undefined ? result.Result : [];
  } catch (err) {
    alert(err);
  }
  return [];
};
//#endregion
//#region post
export const saveRestaurant = async (
  request: RestaurantResponseModel
): Promise<RestaurantResponseModel> => {
  let apiResponse: RestaurantResponseModel = {
    Code: "",
    Notes: "",
    Parent_ID: 0,
    IsParent: false,
    AllParent: null,
    IsDefault: false,
    ShowInPOS: false,
    Name_En: "",
    PrinterName: "",
    BackBolor: 0,
    FontName: "",
    FontSize: 0,
    FontColor: 0,
    HeightButton: 0,
    WidthButton: 0,
    ImagePath: "",
    Active: false,
    IsPrinted: false,
    RemarksOfChamges: "",
    PrintDate: "",
    UserPrinted: "",
    UserIdPrinted: 0,
    ReservedDate: "",
    UserNameReserved: "",
    UserIdReserved: 0,
    Row_Status: 0,
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    Name: "",
    CreationDate: "",
    ModificationDate: null,
    VerifyOnUpdate: false,
    rowState: 1,
    Errors: [],
  };
  try {
    apiResponse = await AlYusrAxiosApiInstance.post(
      AlYuserApiUrls.restaurantTableController.saveRestaurantTable,
      { ...request }
    );
    return apiResponse;
  } catch (err) {
    alert(err);
  }
  return apiResponse;
};
export const deleteRestaurant = async (
  id: number
): Promise<RestaurantDeleteResponseModel> => {
  let apiResponse: RestaurantDeleteResponseModel = {
    Errors: [],
    Result: {
      Result: false,
      Errors: [],
    },
    Status: 0,
  };
  try {
    apiResponse = await AlYusrAxiosApiInstance.post(
      `${AlYuserApiUrls.restaurantTableController.deleteRestaurantTable}?id=${id}`
    );
    return apiResponse;
  } catch (err) {
    alert(err);
  }
  return apiResponse;
};
//#endregion
//#region private
const generateRestaurantTree = (
  request: RestaurantResponseModel[],
  parentId: number
): TreeViewModel[] => {
  let response: TreeViewModel[] = [];
  request
    .filter((p) => p.Parent_ID === parentId && p.ID !== 0)
    .forEach((row) => {
      response.push({
        name: row.Name_En,
        nameAr: row.Name,
        key: row.ID.toString(),
        children: generateRestaurantTree(request, row.ID),
      });
    });
  return response;
};

export const getReservedTransactions = async (tableID: number) => {
  try {
    const result: ResponseBaseModel<TransactionDetailResponseModel> =
      await AlYusrAxiosApiInstance.get(
        `${AlYuserApiUrls.restaurantTableController.getReservedTransactionByTableId}?table_id=${tableID}`
      );
    // @ts-ignore
    return result !== null && result !== undefined ? result.Result : null;
  } catch (err) {
    alert(err);
  }
};
//#endregion
