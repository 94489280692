import {ColumnsType} from "antd/es/table";
import {getLabelName} from "../../../utils";
import {GatherItemModel} from "../../../models";
import {TextBox, ButtonBox} from "../../";

export const setGatheredItemsColumns = (
    handleQtyEdit: (newQty: number, id: number | string) => void,
    handleDeletion: (ID: number | string) => void
) => {
    const gatheredTableColumns: ColumnsType<GatherItemModel> = [
        {
            title: getLabelName("item"),
            key: "Factor_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <p className="m-0">{row.ItemNameAr}</p>
            ),
            width: "20%",
        },
        {
            title: getLabelName("quantity"),
            key: "Factor_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <TextBox
                    inputName="quantity"
                    inputValue={row.Quantity}
                    labelName=""
                    onChange={(e: any) => {
                        if (e.target.value > 0)
                            handleQtyEdit(e.target.value, row.ID ? row.ID : row.rowKey!);
                    }}
                />
            ),
            width: "10%",
        },
        {
            title: getLabelName("price"),
            key: "Factor_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <p className="m-0">{row.PriceSale}</p>
            ),
            width: "10%",
        },
        {
            title: getLabelName("cost"),
            key: "Factor_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <p className="m-0">{row.PriceCost}</p>
            ),
            width: "10%",
        },
        {
            title: getLabelName("total"),
            key: "Factor_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <p className="m-0">{row.PriceSale * row.Quantity}</p>
            ),
            width: "10%",
        },
        {
            title: getLabelName("delete"),
            key: "Factor_", //+generateGuid(),
            render: (row: GatherItemModel) => (
                <ButtonBox onClick={() => handleDeletion(row.ID ? row.ID : row.rowKey!)} iconType="delete">
                    {getLabelName("delete")}
                </ButtonBox>
            ),
            width: "10%",
        },
    ];
    return gatheredTableColumns;
};
