import {FC, useEffect, useState} from "react";
import {
    AddEstihkaksubtractExpenseRule,
    ConfirmModelDialogBox,
    EmployeeEstihkakSubtractRuleList,
    ErrorValidationBox,
    LoadingBox,
    ModelDialogBox,
    ToastBox
} from "../../components";
import {
    ActionButtonsModel,
    ActionTypeEnum,
    EmployeeEstihkakSubtractResponseModel,
    RequestActionModel,
    ToastModel,
    ValidationErrorModel
} from "../../models";
import {
    deleteEstihkakSubtract,
    getEmployeeEstihkakExpense,
} from "../../serviceBroker/employeeEstihkakSubtracApiServiceBroker";
import {getLabelName} from "../../utils";

export const EstihkakSubtractExpenseRulePage: FC = () => {
    //#region variables
    const deleteCurrencyActions: ActionButtonsModel[] = [
        {
            text: getLabelName("yes"),
            onClick: async () => {
                await handleDeleteCurrency();
            },
        },
        {
            text: getLabelName("no"),
            onClick: () => {
                setEmployeeEstihkakSubtract(undefined);
                setShowDeleteEstihkakRuleModel(false);
            },
        },
    ];
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [validationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [employeeEstihkakSubtractRuleList, setEmployeeEstihkakSubtractRuleList] = useState<EmployeeEstihkakSubtractResponseModel[]>([]);
    const [employeeEstihkakSubtract, setEmployeeEstihkakSubtract] = useState<EmployeeEstihkakSubtractResponseModel | undefined>(undefined);
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    const [showAddEstihkakRuleModel, setShowAddEstihkakRuleModel] = useState(false);
    const [showModifyEstihkakRuleModel] = useState(false);
    const [showDeleteEstihkakRuleModel, setShowDeleteEstihkakRuleModel] = useState(false);
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            // console.log(currencies);
            await getAllEmployeeEstihkakRule();
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region function
    const handleCurrencyAction = async (request: RequestActionModel) => {
        let filteredCurrency = employeeEstihkakSubtractRuleList.find((x) => x.ID === request.id);
        switch (request.action) {
            case ActionTypeEnum.Update:
                // @ts-ignore
                request.request.rowState = 2;
                setEmployeeEstihkakSubtract(request.request);
                break;
            case ActionTypeEnum.Delete:
                setEmployeeEstihkakSubtract(filteredCurrency);
                setShowDeleteEstihkakRuleModel(true);
                break;
        }
    };
    const getAllEmployeeEstihkakRule = async () => {
        setLoading(true);
        const EmployeeEstihkakRuleList = await getEmployeeEstihkakExpense();
        setEmployeeEstihkakSubtractRuleList(EmployeeEstihkakRuleList);
        setLoading(false);
    };
    const handleAddComplete = async (
        _request: EmployeeEstihkakSubtractResponseModel,
        status: boolean
    ) => {
        switch (status) {
            case true:
                setLoading(true);
                await getAllEmployeeEstihkakRule();
                setToastModel({...toastModel, show: true, variant: "success"});
                setLoading(false);
                break;
            case false:
                setLoading(true);
                await getAllEmployeeEstihkakRule();
                setToastModel({...toastModel, show: true, variant: "danger"});
                setLoading(false);
                break;
        }
    };
    const handleDeleteCurrency = async () => {
        setShowDeleteEstihkakRuleModel(false);
        const deleteStoreResponse = await deleteEstihkakSubtract(
            employeeEstihkakSubtract !== undefined ? employeeEstihkakSubtract.ID : 0
        );
        const isSuccess: boolean = !(deleteStoreResponse.Result.Errors != null &&
            deleteStoreResponse.Result.Errors.length !== 0);
        setToastModel({...toastModel, show: true, variant: isSuccess ? "success" : "danger"});
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}

            {/* delete store  */}
            <ConfirmModelDialogBox
                isModelVisible={showDeleteEstihkakRuleModel}
                onCloseEvent={() => {
                    setShowDeleteEstihkakRuleModel(false);
                }}
                actions={deleteCurrencyActions}
            >
            </ConfirmModelDialogBox>
            {/* add store  */}
            <ModelDialogBox
                isModelVisible={showAddEstihkakRuleModel}
                isCloseButtonVisible={false}
                //onCloseEvent={() => {setShowAddUserModel(false);}}
                title={getLabelName("Add Subtract")}
            ></ModelDialogBox>
            <AddEstihkaksubtractExpenseRule
                onActionEvent={async (o: RequestActionModel) => {
                    setShowAddEstihkakRuleModel(false);
                    await handleAddComplete(o.request, true);
                }}
                request={employeeEstihkakSubtract}
            />
            {/* modify store  */}
            <ModelDialogBox
                isModelVisible={showModifyEstihkakRuleModel}
                isCloseButtonVisible={false}
            >
                {/*
        <AddCurrency   onActionEvent={(o: RequestAction) => {
            setShowModifyCurrencyModel(false);
            handleModifyComplete(o.request,true)
          }} request={currency} /> */}
            </ModelDialogBox>

            {/* store list */}

            {employeeEstihkakSubtractRuleList.length !== 0 && (
                <EmployeeEstihkakSubtractRuleList
                    request={employeeEstihkakSubtractRuleList}
                    onActionEvent={async (o: RequestActionModel) => {
                        await handleCurrencyAction(o);
                    }}
                />
            )}
        </>
    );

    //#endregion
};
