import * as yup from "yup";
import {
    InstallmentsConfigurationsModel,
} from "../../../../models";
import {getLabelName} from "../../../../utils";

const startDate = new Date();
export const endDate = (() => {
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 2);
    return endDate;
})();
export const initialValues: InstallmentsConfigurationsModel = {
    startPlan: startDate,
    endPlan: endDate,
    totalMoney: 0,
    numberOfInstalment: 1,
    Remarks: "",
};

export const validationSchema = yup.object({
    startPlan: yup.date().required(getLabelName("required")),
    endPlan: yup.date().required(getLabelName("required")),
    totalMoney: yup.number().required(getLabelName("required")),
    numberOfInstalment: yup
        .number()
        .min(1, getLabelName("cannot be less than 1 "))
        .required(getLabelName("required")),
    Remarks: yup.string(),
});
