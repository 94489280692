import React, {FC, useMemo, useState} from "react";
import {PrintTypeEnum, UserAlertResponseModel} from "../../models";
import {TableColumn} from "react-data-table-component";
import {getLabelName, getUserId, isArabicCurrentLanguage} from "../../utils";
import {ButtonBox, TableComponent, GenericModelPopupPdfReportViewer} from "..";

export const DashBoardAlertSummary: FC<{ request: UserAlertResponseModel[] }> = ({request}) => {
    //#region variable
    const isArabic = isArabicCurrentLanguage();
    const columns: TableColumn<UserAlertResponseModel>[] = useMemo(() => [
        {
            name: "#",
            selector: (_row, index) => Number(index || 0) + 1,
            width: "50px",
        },
        {
            name: getLabelName("message"),
            selector: (row) => isArabic ? row.MessageArabic || "" : row.MessageEnglish || "",
            //width: "50px",
        },
        {
            name: getLabelName("count"),
            selector: (row) => row.Count,
            //width: "50px",
        },
        {
            cell: (row: UserAlertResponseModel) => (
                <ButtonBox
                    iconType="printer"
                    variant="outline-dark"
                    className="btn-xs"
                    onClick={() => {
                        setPrintState({
                            ...printState, showPrintoutPdfModel: true, keysValues: [
                                {key: "alertSearch.alertTypeId", value: row.ID},
                                {key: "alertSearch.messageArabic", value: row.MessageArabic},
                                {key: "alertSearch.messageEnglish", value: row.MessageEnglish},
                                {key: "alertSearch.userId", value: getUserId()}
                            ]
                        })
                    }}
                >
                    {getLabelName("print")}
                </ButtonBox>
            ),
        },
    ], [isArabic]);
    //#endregion
    //#region state
    const [printState, setPrintState] = useState<{
        showPrintoutPdfModel: boolean,
        keysValues: { key: string, value: any }[]
    }>({
        showPrintoutPdfModel: false,
        keysValues: []
    });
    //#endregion
    //#region html
    return request !== null && request.length !== 0 ? (<>
        <div className="row">
            <div className="col-12 grid-margin">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title"></h4>
                        <div className="table-responsive">
                            <TableComponent
                                columns={columns}
                                data={request}
                                totalRows={1000}
                                currentPage={1}
                                pageSize={10000}
                                paginationType="none"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {printState.showPrintoutPdfModel && (
            <GenericModelPopupPdfReportViewer
                keys={printState.keysValues}
                type={PrintTypeEnum.UserAlertReport}
                onCloseEvent={() => {
                    setPrintState({...printState, showPrintoutPdfModel: false, keysValues: []})
                }}
            />
        )}
    </>) : null;
    //#endregion
}