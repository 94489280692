import React, { FC, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  ConfirmModelDialogBox,
  ErrorValidationBox,
  LoadingBox,
  ToastBox,
  AddCalcType,
  CalcTypeList,
} from "..";
import {
  ActionTypeEnum,
  calcTypeApiResponseModel,
  RequestActionModel,
  ToastModel,
  RowStateEnum,
  ActionButtonsModel,
  CalcTypeEnum,
  ValidationErrorModel,
} from "../../models";
import {
  deleteCalcType,
  getCalcTypeById,
} from "../../serviceBroker/calcTypeServiceBroker";
import {
  getLabelName,
  getPageNameByPageId,
  getResultFromResponse,
  scrollToDocumentTop,
} from "../../utils";

export const CalcTypeMainComponent: FC<{
  calcType: CalcTypeEnum;
  formId: number;
}> = ({ calcType, formId }) => {
  //#region state
  const [calcTypeObject, setCalcTypeObject] =
    useState<calcTypeApiResponseModel | null>(null);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
  });
  const [isRefresh, setIsRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region functions
  const handleDeleteCalcType = async () => {
    setLoading(true);
    calcTypeObject!.rowState = Number(RowStateEnum.Delete);
    calcTypeObject!.Row_Status = Number(RowStateEnum.Delete);
    const deleteResponse = await deleteCalcType(calcTypeObject!.ID);
    const isDeleteOperationSuccess: boolean =
      deleteResponse?.Result !== null && deleteResponse?.Result?.Result == true;
    setToastModel({
      ...toastModel,
      variant: isDeleteOperationSuccess ? "success" : "danger",
      body: isDeleteOperationSuccess
        ? getLabelName("process Success")
        : getLabelName("process Failed"),
      show: true,
    });
    if (isDeleteOperationSuccess) {
      setShowDeleteModel(false);
      setCalcTypeObject(null);
      setIsRefresh(true);
    }
    setLoading(false);
  };
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleDeleteCalcType();
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setCalcTypeObject(null);
        setShowDeleteModel(false);
      },
    },
  ];
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        scrollToDocumentTop();
        setValidationErrors([]);
        setToastModel({ ...toastModel, show: true });
        setCalcTypeObject(null);
        setIsRefresh(true);
        break;
      case ActionTypeEnum.Update:
        setLoading(true);
        scrollToDocumentTop();
        setValidationErrors([]);
        const calcTypeRequest = await getCalcTypeById(request.id || 0);
        const calcTypeResponse: calcTypeApiResponseModel | null =
          getResultFromResponse<calcTypeApiResponseModel>(calcTypeRequest);
        if (calcTypeResponse !== null) {
          setCalcTypeObject(calcTypeResponse);
        }
        setLoading(false);
        break;
      case ActionTypeEnum.Delete:
        if (request.request) {
          //request.request.rowState = Number(RowState.Delete);
          setCalcTypeObject(request.request);
          setShowDeleteModel(true);
        }
        break;
      case ActionTypeEnum.Failed:
        setValidationErrors(request.request);
        break;
      case ActionTypeEnum.Clear:
        setValidationErrors([]);
        setCalcTypeObject(null);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      {showDeleteModel && (
        <ConfirmModelDialogBox
          isModelVisible={showDeleteModel}
          onCloseEvent={() => {
            setShowDeleteModel(false);
          }}
          actions={deleteActions}
        ></ConfirmModelDialogBox>
      )}

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getPageNameByPageId(formId)}</Accordion.Header>
          <Accordion.Body>
            <AddCalcType
              calcType={calcType}
              request={calcTypeObject}
              onActionEvent={(o: RequestActionModel) => {
                handleAction(o).then();
              }}
              formId={formId}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("previous Data")}</Accordion.Header>
          <Accordion.Body>
            <CalcTypeList
              calcType={calcType}
              setIsRefresh={setIsRefresh}
              isRefresh={isRefresh}
              onActionEvent={(o: RequestActionModel) => {
                handleAction(o).then();
              }}
              formId={formId}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
