import React, {Dispatch, FC, SetStateAction, useState} from "react";
import {TextBox, ButtonBox} from "..";
import {getVoucherById} from "../../serviceBroker/voucherApiServiceBroker";
import {PaymentValueDetailsModel, ValidationErrorModel} from "../../models";
import {getLabelName, updateStateDynamically} from "../../utils";

export const VoucherDetails: FC<{
    totalPayment: number;
    paymentValues: PaymentValueDetailsModel;
    setPaymentValues: Dispatch<SetStateAction<PaymentValueDetailsModel>>;
    calculatePaymentTotalValues(request: PaymentValueDetailsModel): number;
    setErrorMsg: Dispatch<SetStateAction<ValidationErrorModel[]>>;
}> = ({
          totalPayment,
          paymentValues,
          setPaymentValues,
          calculatePaymentTotalValues,
          setErrorMsg,
      }) => {
    //#region state
    const [inputValue, setInputValue] = useState<string>("");
    //#endregion
    //#region functions
    const handleChangeInput = (e: React.ChangeEvent<any>) => {
        setInputValue(e.target.value);
        setErrorMsg([]);
    };
    const handleBtnClick = async () => {
        const res = await getVoucherById(inputValue);
        if (res.Result) {
            if (res.Result.IsValid) {
                const obj: PaymentValueDetailsModel = {
                    ...paymentValues,
                    voucher: res.Result.ValidAmount,
                };
                await updateStateDynamically(
                    setPaymentValues,
                    paymentValues,
                    null,
                    null,
                    [
                        {key: "voucher", value: res.Result.ValidAmount},
                        {key: "voucherId", value: res.Result.ID},
                        {
                            key: "remaining",
                            value: totalPayment - calculatePaymentTotalValues(obj),
                        },
                    ]
                );
            } else if (!res.Result.IsValid) {
                setErrorMsg([
                    {
                        MessageAr: getLabelName(
                            "The loyalty voucher is invalid, expired or has been used before"
                        ),
                        MessageEn: getLabelName(
                            "The loyalty voucher is invalid, expired or has been used before"
                        ),
                    },
                ]);
            }
        }

        if (res.Errors.length > 0) {
            res.Errors.map((error: ValidationErrorModel) => {
                setErrorMsg((prev) => [...prev, error]);
            });
        }
    };
    //#endregion
    //#region html
    return (
        <div className="row align-items-center">
            <div className="col-md-9 mb-sm-2 mb-lg-0">
                <TextBox
                    type="number"
                    labelName={getLabelName("Voucher")}
                    inputValue={inputValue}
                    inputName="voucherCode"
                    placeHolder={getLabelName("Please enter your voucher code")}
                    onChange={handleChangeInput}
                />
            </div>
            <div className="col-md-3">
                <ButtonBox variant="info" onClick={handleBtnClick} iconType="check-circle">
                    {getLabelName("Confirm")}
                </ButtonBox>
            </div>
        </div>
    );
    //#endregion
};
