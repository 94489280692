import {FC} from "react";
import {CustomerMainComponent} from "../../components";
import {PageEnum, SupplierCustomerTypeEnum} from "../../models";

export const SupplierPage: FC = () => {
    //#region html
    return (
        <CustomerMainComponent formId={PageEnum.Suppliers} type={SupplierCustomerTypeEnum.Supplier}/>
    );
    //#endregion
};
